import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Map } from "mapbox-gl";
import { MapManager } from "./map-manager/map-manager";

@Component({
  selector: 'pl-redux-map',
  templateUrl: './map.component.html',
  styleUrls: [ './map.component.scss' ]
})
export class MapComponent implements AfterViewInit {
  @Input() name!: string;
  @Input() mapStyle!: string;
  @Input() center!: [number, number];
  @Input() zoom = 16;

  @Output() loaded = new EventEmitter<MapManager>();

  @ViewChild('mapContainer') mapContainer!: ElementRef<HTMLDivElement>;

  private map!: Map;
  private mapManager!: MapManager;

  constructor() { }

  ngAfterViewInit(): void {
    this.setupMap();
  }

  private setupMap(): void {
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: this.mapStyle,
      zoom: this.zoom,
      center: this.center
    });

    this.map.on('load', () => {
      this.mapManager = new MapManager(
        this.map,
        this.mapContainer.nativeElement,
        this.name
      );

      this.loaded.emit(this.mapManager);
      document.querySelector('.mapboxgl-ctrl-attrib')?.remove();
      document.querySelector('.mapboxgl-ctrl-bottom-left')?.remove();
    });
  }
}
