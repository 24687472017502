<h2 *ngIf="graphTitle" class="graph-title">
  {{ graphTitle }}
</h2>

<div class="graph-content {{ interactable ? 'graph-content--interactable' : '' }}">
  <!-- LINES -->
  <div class="graph-lines">
    <ng-container *ngFor="let line of lines">
      <div class="line-name">{{ line.name }}</div>
      <div  class="graph-line">
        <ng-container *ngFor="let point of line.points">
          <div class="graph-line-point {{ point.type.symbol }}"
               [title]="showLabels ? point.label : ''"
               [ngStyle]="{ left: point.position + '%' }"
               (click)="interactable && point.pointClicked.emit()">

            <div *ngIf="point.active" class="active-orb">
              <div class="{{ point.type.symbol }}"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>


  <!-- LEGEND -->
  <div class="spacer" *ngIf="!showLegend && !skipSpacer"></div>
  <div class="legend" *ngIf="!showLegend && skipSpacer"></div>

  <div *ngIf="showLegend" style="text-align: right;" class="legend {{ moveLegendDown ? 'legend--down' : '' }}">
    <div *ngFor="let legend of legends" class="legend-item">
      <div [class]="legend.symbol"></div>
      <p>{{ legend.represents }}</p>
    </div>
  </div>
</div>
