import {createAction, props} from "@ngrx/store";
import {
  GetPlotSizingPayload,
  ResidencePayload,
  SavePlotAddressPayload,
  SavePlotSizingPayload,
  SaveResidencePayload,
  SelectPlotPayload
} from "../plot.models";

export const selectPlot = createAction('PL - Select Plot', props<SelectPlotPayload>());
export const getPlotSizing = createAction('PL - Get Plot Sizing', props<GetPlotSizingPayload>());
export const savePlotAddress = createAction('PL - Save Plot Address', props<SavePlotAddressPayload>());
export const savePlotSizing = createAction('PL - Save Plot Sizing', props<SavePlotSizingPayload>());
export const removeActivePlot = createAction('PL - Remove Active Plot', props<{ identifier: string }>());
export const retrieveResidences = createAction('PL - Retrieve Residences', props<ResidencePayload>());
export const saveResidences = createAction('PL - Save Residences', props<SaveResidencePayload>());
