import { HttpHeaders } from '@angular/common/http';

export type ServerList = Record<string, string> & SpecialServers;
export type SpecialServers = {
  // this is the server used when no server is passed to the api service
  default: string;

  // this is the server used when on localhost
  local: string;
}

export interface ApiModuleConfiguration {
  serverList: ServerList;
}

export enum CallType {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
  Put = 'put'
}

export interface HttpOptions {
  server?: string;
  observe?: 'body' | 'response' | 'event';
  headers?: HttpHeaders;
}

export type CompletedRequest = {
  lastSuccess: Date | null;
};

export interface StoredError {
  key?: string;
  statusCode?: number;
  message?: string;
}
