import {Component, Input} from "@angular/core";
import {PlanalogicTotals} from "../../../../state/plot/effects/demo.models";

@Component({
  selector: 'freemium-totals-graph',
  templateUrl: './freemium-totals.component.html',
  styleUrls: [ './freemium-totals.component.scss' ]
})
export class FreemiumTotalsComponent {
  @Input() totals!: PlanalogicTotals;
}
