import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { MapComponent } from "./map.component";
// @ts-ignore
import mapboxgl from 'mapbox-gl';

const declarations = [
  MapComponent
]

@NgModule({
  declarations: declarations,
  exports: declarations
})
export class MapModule {
  static forRoot(accessToken: string): ModuleWithProviders<MapModule> {
    const providers: Provider[] = [];

    mapboxgl.accessToken = accessToken;

    return { ngModule: MapModule, providers };
  }
}
