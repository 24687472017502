import { PlGisUtils } from "@planalogic/utilities";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import {Store} from "@ngrx/store";
import { FreemiumMapService, percelenLayerPrefix } from "../freemium-map.service";
import {PerceelDataSource} from "../plot-manager/data-sources/perceel.data-source";

enum LocationEvents {
  LoadedProvinceData = 'loaded province data'
}

export class LocationManager {
  constructor(
    private readonly mapService: FreemiumMapService,
    private readonly store: Store
  ) { }

  public flyToCentroide(centroide_ll: string, zoom: number): Promise<void> {
    const center = PlGisUtils.normalizePoint(centroide_ll);
    this.mapService.map.flyTo({ center, zoom });

    return new Promise((resolve) => {
      this.mapService.events.listenOnce('moveend', () => resolve());
    });
  }

  public async selectCentroide(centroide_ll: string, parcels: string[], province?: string): Promise<void> {
    province = province || this.mapService.provinces.getProvinceByPoint(centroide_ll);

    if (!province) return;

    return this.loadRelevantProvince(province, parcels).then((features) => {
      const { kadastraalnummer: identifier, plotSize } = features[0].properties as any;
      const dataSource = new PerceelDataSource(identifier, features, this.store);

      this.mapService.plots.activatePlot(identifier, plotSize, [], dataSource);
    });
  }

  private findConnectedParcelFeatures(parcels: string[], province?: string): MapboxGeoJSONFeature[] {
    const filters = parcels.map((parcel: string) => [ '==', 'kadastraalnummer', parcel ]);

    return this.mapService.provinces.queryProvinceSourceFeatures((abbreviation: string) => {
      return {
        source: {
          id: `${ percelenLayerPrefix }${ abbreviation }_source`,
          sourceLayer: `${ percelenLayerPrefix }${ abbreviation }`
        },
        filter: [ 'any', ...filters ]
      }
    }, province).filter(result => !!result);
  }

  private async loadRelevantProvince(province: string, parcels: string[]): Promise<MapboxGeoJSONFeature[]> {
    return new Promise(resolve => {
      if (this.mapService.provinces.isLoaded(province)) {
        const features = this.findConnectedParcelFeatures(parcels);
        resolve(features);
      }

      this.mapService.provinces.loadProvince(province);
      this.mapService.events.listen(LocationEvents.LoadedProvinceData, 'data', ({ isSourceLoaded }) => {
        const sourceLoaded = this.checkProvinceLoading(isSourceLoaded, parcels);
        if (!sourceLoaded) return;

        this.mapService.events.stop(LocationEvents.LoadedProvinceData);

        const features = this.findConnectedParcelFeatures(parcels);
        resolve(features);
      }, { layerId: 'province-features' })
    });
  }

  private checkProvinceLoading(sourceLoaded: boolean, parcels: string[]): boolean {
    if (!sourceLoaded) return false;

    const features = this.findConnectedParcelFeatures(parcels);
    return !!features.length;
  }
}
