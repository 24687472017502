import { NgModule } from "@angular/core";
import { BarGraphComponent } from "./bar-graph.component";
import { BarGraphBarComponent } from './bar/bar.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BarGraphComponent,
    BarGraphBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BarGraphComponent,
    BarGraphBarComponent
  ]
})
export class PlBarGraphModule { }
