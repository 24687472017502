<content-toggle [contentTitle]="dataTitle"
                [size]="size"
                [expanded]="expanded"
                iconOpened="/assets/icons/chevron-up.png"
                iconClosed="/assets/icons/chevron-down.png">

  <ng-container content-toggle-icons>
    <ng-content select="[content-toggle-icons]"></ng-content>
  </ng-container>

  <ng-content></ng-content>
</content-toggle>
