import { ScoutPageComponent } from "./scout-page.component";
import { NgModule } from "@angular/core";
import { ScoutCornerComponent } from "./corner/corner.component";
import { ScoutSideBarComponent } from "./side-bar/side-bar.component";
import { CommonModule } from "@angular/common";

const declarations = [
  ScoutPageComponent,
  ScoutCornerComponent,
  ScoutSideBarComponent
];

@NgModule({
  declarations,
  imports: [ CommonModule ],
  exports: declarations
})
export class PlScoutPageModule { }
