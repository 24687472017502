export class MapContainerService {
  private container!: HTMLElement;
  private map!: HTMLElement;

  public setMapContainer(container: HTMLElement): void {
    this.container = container;
  }

  public detachMap(mapElement: HTMLElement): void {
    mapElement = mapElement || this.map;
    this.map = mapElement;
    this.container.appendChild(mapElement);
  }

  public attachMap(parent: HTMLElement): boolean {
    if (!this.map) return false;

    return !!parent.appendChild(this.map);
  }
}
