<!-- REGULAR -->
<h2 *ngIf="size === 'regular'" class="content-header" (click)="toggle()">
  {{ contentTitle }}

  <div class="icons">
    <ng-content select="[content-toggle-icons]"></ng-content>

    <img *ngIf="!show" class="toggle-icon"  [src]="iconClosed">
    <img *ngIf="show" class="toggle-icon" [src]="iconOpened">
  </div>
</h2>

<!-- SMALL -->
<h3 *ngIf="size === 'small'" class="content-header" (click)="toggle()">
  {{ contentTitle }}

  <div class="icons">
    <ng-content select="[content-toggle-icons]"></ng-content>

    <img *ngIf="!show" class="toggle-icon" [src]="iconClosed">
    <img *ngIf="show" class="toggle-icon" [src]="iconOpened">
  </div>
</h3>

<div *ngIf="show" class="content">
  <ng-content></ng-content>
</div>

