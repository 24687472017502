import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'content-toggle',
  templateUrl: './content-toggle.component.html',
  styleUrls: [ './content-toggle.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ContentToggleComponent {
  @Input() contentTitle!: string;
  @Input() iconOpened!: string;
  @Input() iconClosed!: string;
  @Input() set expanded(value: boolean) {
    if (!value) return;
    this.show = value;
  };

  @HostBinding('class')
  @Input() size: 'small' | 'regular' = 'regular';

  @HostBinding('class.open')
  public show = false;

  public toggle(): void {
    this.show = !this.show;
  }
}
