import {SelectColorComponent} from "./select-color.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {PlColorPickerModule} from "../../../tools";
import {PlPopUpModule} from "../../../content-containers";

@NgModule({
  declarations: [ SelectColorComponent ],
  imports: [
    CommonModule,
    PlColorPickerModule,
    PlPopUpModule
  ],
  exports: [ SelectColorComponent ]
})
export class SelectColorModule { }
