import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import { MapManager } from "@planalogic/design-system";
import { FreemiumMap } from "./freemium-map/freemium-map";
import { ActiveFeature } from "./freemium-map/active-features";
import { noop, Observable, Subject, takeUntil } from "rxjs";
import { tap } from "rxjs/operators";
import {BagAddress, BagKadastraal, FreemiumColor} from "./freemium-map/freemium-map.models";
import {FormBuilder, FormControl} from "@angular/forms";
import {SelectColorFeature} from "./freemium-map/features/select-color/select-color";
import {Store} from "@ngrx/store";
import {FreemiumMapService} from "./freemium-map/freemium-map.service";
import {ActivePlotInformation} from "../../state/plot/plot.models";
import {MapContainerService} from "../../shared/map-container/map-container.service";
import {Router} from "@angular/router";
import {PlAngularUtils} from "@planalogic/utilities";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "@planalogic/toolbox";

@Component({
  selector: 'freemium-page',
  templateUrl: './freemium.component.html',
  styleUrls: [ './freemium.component.scss' ]
})
export class FreemiumPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mapParent') mapParentRef!: ElementRef<HTMLDivElement>;
  @ViewChild('mapElement', { read: ElementRef }) mapElementRef!: ElementRef<HTMLElement>;

  public plots$!: Observable<ActivePlotInformation[]>;
  public ActiveFeature = ActiveFeature;
  public featureForm = this.formBuilder.group({
    aerial: new FormControl(false),
    threeD: new FormControl(false),
    relatedPlots: new FormControl(false),
    maatvoeringColor: new FormControl('#1e75d1'),
    plotBorderColor: new FormControl('#2b3c67'),
    activePlotsColor: new FormControl('#bb2020')
  });
  public showInitialWelcomeText = true;
  public FreemiumColor = FreemiumColor;
  public loadNewMap = false;

  private destroyer$ = new Subject<void>();

  private get mapParent(): HTMLElement {
    return this.mapParentRef.nativeElement;
  }

  private get mapElement(): HTMLElement {
    return this.mapElementRef?.nativeElement;
  }

  constructor(
    public mapService: FreemiumMapService,
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private mapContainer: MapContainerService
  ) { }

  ngAfterViewInit(): void {
    PlAngularUtils.waitForPropagation(() => {
      this.loadNewMap = !this.mapContainer.attachMap(this.mapParent);

      if (this.loadNewMap) return;

      this.mapService.restore();
      this.initializeMap();

      // todo handle this better in the future
      setTimeout(() => () => window.dispatchEvent(new Event('resize')), 30);
    });
  }

  ngOnDestroy(): void {
    // this.mapService.stopAndDestroy();
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public mapLoaded(manager: MapManager): void {
    const freemiumMap = new FreemiumMap(manager, this.store);
    this.mapService.setMap(freemiumMap);
    freemiumMap.setMapService(this.mapService);

    PlAngularUtils.waitForPropagation(() => this.updateColors());

    this.initializeMap();
  }

  public toggleFeature(feature: string, value: boolean): void {
    this.mapService.features.toggleFeature(feature, value);
  }

  public selectColor(identifier: string, color: string): void {
    const colors = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);

    colors?.setColor(identifier, color);
  }

  public reset(): void {
    this.mapService.plots.reset();
  }

  public navigateTo(route: string): void {
    this.mapContainer.detachMap(this.mapElement);
    this.router.navigate([ route ]).then();
  }

  public viewLocation(event: Event, point: string): void {
    const clickEvent = event as PointerEvent;

    clickEvent.preventDefault();
    clickEvent.stopPropagation();

    this.mapService.location.flyToCentroide(point, 17).then(noop);
  }

  public foundAddress(bagResult: BagAddress) {
    if (!bagResult) return;

    this.reset();

    const { provincieafkorting, centroide_ll: point, gekoppeld_perceel: parcels } = bagResult;
    const province = provincieafkorting.toLowerCase();

    this.mapService.location.flyToCentroide(point, 17).then(() => {
      this.mapService.location.selectCentroide(point, parcels, province).then(noop);
    });
  }

  public foundKadastraal(kadastraalResult: BagKadastraal): void {
    if (!kadastraalResult) return;
    this.reset();

    const { centroide_ll: point, identificatie: parcel } = kadastraalResult;

    this.mapService.location.flyToCentroide(point, 17).then(() => {
      this.mapService.location.selectCentroide(point, [ parcel ]).then(noop);
    });
  }

  public trackByKadastraalNr(index: number, plot: ActivePlotInformation): string | void {
    return plot.identifier;
  }

  private initializeMap(): void {
    this.plots$ = this.mapService.plots.loadedPlots$.pipe(
      takeUntil(this.destroyer$),
      tap(() => PlAngularUtils.waitForPropagation(() => this.showInitialWelcomeText = false))
    );
  }

  private updateColors(): void {
    const colors = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);

    const activePlotsColor = colors?.getColor(FreemiumColor.ActivePlotsBorder);
    const maatvoeringColor = colors?.getColor(FreemiumColor.MaatvoeringLayer);
    const plotBorderColor = colors?.getColor(FreemiumColor.PlotBorders);

    maatvoeringColor && this.featureForm.get('maatvoeringColor')?.setValue(maatvoeringColor);
    plotBorderColor && this.featureForm.get('plotBorderColor')?.setValue(plotBorderColor);
    activePlotsColor && this.featureForm.get('activePlotsColor')?.setValue(activePlotsColor);
  }
}

// todo
// get totalBouwOppervlakte(): number {
//   return Object.values(this.activePlots).reduce((result: number, plot: any) => (
//     result + (plot.maatvoering?.bouwvlakoppervlakte || 0)
//   ), 0);
// }

// get totalPerceelOppervlakte(): number {
//   return Object.values(this.activePlots).reduce((result: number, plot: any) => (
//     result + (plot.maatvoering?.perceeloppervlakte || 0)
//   ), 0);
// }
