import {Component, ElementRef, HostBinding, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {PlAngularUtils} from "@planalogic/utilities";
import {PlDomUtils} from "../../../../../utilities/src/lib/dom/dom.utils";
import {Subscription} from "rxjs";

@Component({
  selector: 'pl-scout-page',
  templateUrl: './scout-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './scout-page.component.scss' ]
})
export class ScoutPageComponent {
  @ViewChild('expansionPanel') expansionPanel!: ElementRef<HTMLDivElement>;
  @HostBinding('class.scout-page--no-corner')
  @Input() skipCorner = false;

  public isExpanded = false;
  private checkedOutside$: Subscription | null = null;

  private checkForOutsideClicks(): void {
    return PlAngularUtils.waitForPropagation(() => {
      const container = this.expansionPanel.nativeElement;

      this.checkedOutside$ = PlDomUtils.clickedOutside(container)
        .subscribe(() => this.toggleExpansion(false));
    });
  }

  public toggleExpansion(value: boolean): void {
    this.isExpanded = value;

    this.checkedOutside$?.unsubscribe();
    value && this.checkForOutsideClicks();
  }
}
