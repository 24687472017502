import {createFeatureSelector, createSelector} from "@ngrx/store";
import {
  ActivePlotInformation,
  PlanalogicData,
  PlanalogicInformation,
  PlanalogicMetadata,
  PlotState,
  Residence
} from "../plot.models";

const selectPlotState = createFeatureSelector<PlotState>('plot');

export const selectActivePlots = createSelector(
  selectPlotState,
  (state: PlotState) => state.active.filter(plot => plot.loaded)
);

export function selectActivePlot(identifier: string) {
  return createSelector(
    selectActivePlots,
    (plots: ActivePlotInformation[]): ActivePlotInformation => plots.find(plot => plot.identifier === identifier) || {} as ActivePlotInformation
  );
}

export const selectPlanalogicMetaData = createSelector(
  selectPlotState,
  (state: PlotState) => state.planalogic.metadata
);

export const selectPlanalogicData = createSelector(
  selectPlotState,
  (state: PlotState) => state.planalogic.data
);

export const selectPlanalogicInformation = createSelector(
  selectPlanalogicData,
  selectPlanalogicMetaData,
  (data: PlanalogicData, metadata: PlanalogicMetadata): PlanalogicInformation => ({ data, metadata })
);

export const selectDemoPlot = createSelector(
  selectActivePlots,
  (plots: ActivePlotInformation[]) => {
    return plots.find(plot => plot.dimensions)
  }
);

export function selectPremises(identifier: string) {
  return createSelector(
    selectActivePlot(identifier),
    (plot: ActivePlotInformation): Residence[] => plot.premises as Residence[]
  );
}
