import { ClassConstructor } from "@planalogic/utilities";
import { AbstractMapFeature } from "./abstract.map-feature";
import { MapManager } from "../map-manager";

export class MapFeatureManager {
  private readonly features = new Map<string, AbstractMapFeature>();

  constructor(private manager: MapManager) {}

  public getFeature<T extends AbstractMapFeature>(feature: string): T {
    return this.features.get(feature) as T;
  }

  public toggleFeature(feature: string, value?: boolean) {
    const mapFeature = this.features.get(feature);
    mapFeature?.toggle(value);

    if(!mapFeature) {
      console.warn('Trying to toggle a feature that is not loaded:', feature);
    }
  }

  public registerFeature(name: string, Feature: ClassConstructor<AbstractMapFeature>): void {
    this.features.set(name, new Feature(this.manager));
  }

  public isActive(feature: string): boolean {
    return !!this.features.get(feature)?.isToggled;
  }
}
