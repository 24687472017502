import { AbstractMapFeature, MapManager } from "@planalogic/design-system";

export class AerialFeature extends AbstractMapFeature {
  constructor(private readonly manager: MapManager) {
    super();

    this.setupFeature();
  }

  protected override toggleOff(): void {
    this.manager.map.setLayoutProperty('lucht-foto-layer', 'visibility', 'none');
  }

  protected override toggleOn(): void {
    this.manager.map.setLayoutProperty('lucht-foto-layer', 'visibility', 'visible');
  }

  private setupFeature(): void {
    this.manager.createSourceLayer('lucht-foto-source', {
      type: 'raster',
      tiles: [
        'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Actueel_orthoHR'
      ],
      tileSize: 256
    });

    this.manager.createMapLayer('lucht-foto-layer', {
      id: 'lucht-foto-layer',
      type: 'raster',
      source: 'lucht-foto-source'
    });

    this.toggleOff();
  }
}
