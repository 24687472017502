import {Component, OnInit} from '@angular/core';
import nl from '@angular/common/locales/nl';
import {registerLocaleData} from "@angular/common";

@Component({
  selector: 'planalogic',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'building-scout';

  ngOnInit() {
    registerLocaleData(nl);
  }
}
