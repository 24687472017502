import { SuggestData, SuggestSource } from '@planalogic/design-system';
import { ApiService } from '@planalogic/toolbox';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class KadastraalSuggestSource implements SuggestSource {
  constructor(private apiService: ApiService) { }

  public suggest(term: string): Observable<SuggestData[]> {
    // todo fix any
    return this.apiService.get<any/*AddressResponse*/>(`suggest?q=${ term }&fq=type:perceel`, { server: 'locationService' })
      .pipe(
        map((result: any/*AddressResponse*/) => { // todo fix any
          const plots = result?.response?.docs || [];

          return plots.map((plot: any) => ({ label: plot.weergavenaam, value: plot.id }));
        })
      );
  }

  public lookup(id: any): any { // todo fix any
    return this.apiService.get(`lookup?id=${ id }`, { server: 'locationService' })
      .pipe(
        map((result: any) => result?.response?.docs), // todo fix any
        tap((results: any[]) => { // todo fix any
          console.log('Lookup results', results);
          if (results.length > 1) {
            console.warn('Picking first result from look-up but multiple exist', results);
          }
        }),
        map(results => results[0])
      );
  }
}
