import {Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {AbstractFormControlComponent} from "../../abstract";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'pl-select-color',
  templateUrl: './select-color.component.html',
  styleUrls: [ './select-color.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SelectColorComponent extends AbstractFormControlComponent<string> {
  @Input() id!: string;
  @Input() color = '#fff';

  @Output() selected = new EventEmitter<string>();

  public showPicker = false;
  public oldColor!: string;

  constructor(protected override control: NgControl, protected override elementRef: ElementRef) {
    super(control, elementRef);
  }

  public enterColor(color: string): void {
    const regex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

    if(!regex.test(color)) return;
    this.updateValue(color);
  }

  public updateValue(value: string, external?: boolean): void {
    this.propagateValue(value);
    this.color = value;

    if (!external) {
      this.selected.emit(value);
    }
  }

  public enablePicker(): void {
    this.showPicker = true;
    this.oldColor = this.color;
  }

  public approve(): void {
    this.showPicker = false;
  }

  public decline(): void {
    this.showPicker = false;
    this.updateValue(this.oldColor);
  }
}
