import {Pipe} from "@angular/core";

const purposeTranslations = {
  woonfunctie: 'Living',
  sportfunctie: 'Sports',
  onderwijsfunctie: 'Education',
  gezondheidszorgfunctie: 'Health care',
  winkelfunctie: 'Shop',
  bijeenkomstfunctie: 'Gathering',
  celfunctie: 'Cell',
  industriefunctie: 'Industrial',
  kantoorfunctie: 'Business',
  logiesfunctie: 'Lodging',
  overige: 'Other'
};

@Pipe({ name: 'purpose' })
export class PurposePipe {
  public transform(purposes: string): string {
    if (!purposes) return 'Shed';

    const all = purposes.split(',').map((purpose: string) => purposeTranslations[purpose as keyof typeof purposeTranslations]);

    return all.join(',');
  }
}
