import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { BarGraphBarComponent } from './bar/bar.component';

@Component({
  selector: 'pl-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: [ './bar-graph.component.scss' ]
})
export class BarGraphComponent {
  @Input() graphTitle!: string;
  @Input() yAxisLabel!: string;
  @Input() xAxisLabel!: string;

  @ContentChildren(BarGraphBarComponent) barsList!: QueryList<BarGraphBarComponent>;

  public bars!: BarGraphBarComponent[];

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.bars = this.barsList.toArray();
      this.barsList.changes.subscribe(() => this.bars = this.barsList.toArray());
    });
  }
}
