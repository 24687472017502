import { AfterViewInit, Component, ContentChildren, OnDestroy, QueryList, ViewEncapsulation } from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: [ './tab-group.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class TabGroupComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(TabComponent) tabList!: QueryList<TabComponent>;

  public tabs!: TabComponent[];
  public active!: TabComponent;

  private destroyer$ = new Subject<void>();

  ngAfterViewInit(): void {
    setTimeout(() => this.updateTabs());

    this.tabList.changes
      .pipe(takeUntil(this.destroyer$))
      .subscribe(() => this.updateTabs());
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
  }

  private updateTabs(): void {
    this.tabs = this.tabList.toArray();

    if (!this.active) {
      this.active = this.tabs[0];
    }
  }
}
