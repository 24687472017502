import {MapboxGeoJSONFeature} from "mapbox-gl";
import * as Turf from "@turf/turf";

export class PlGisUtils {
  static normalizeArray(gisArray: string | void): string[] {
    if (!gisArray || gisArray[0] !== '{' || gisArray[gisArray.length - 1] !== '}') {
      return [];
    }

    return gisArray.slice(1, -1).split(',');
  }

  static normalizeTable<T>(gisTable: string): T[] {
    return gisTable.split(',').reduce((data: T[], row: string) => {
      const [ id, type, height, surface, circumference ] = row.split(';');

      data.push({ id, type, height, surface, circumference } as T);
      return data;
    }, [] as T[]);
  }

  static normalizePoint(point: string): [ number, number ] {
    return point.slice(6, -1)
      .split(' ')
      .map(value => +value) as [ number, number ];
  }

  static unionizeFeatures(features: MapboxGeoJSONFeature[], properties: any): MapboxGeoJSONFeature[] {
    const unionized = features.reduce((unionized: any, feature: any) => {
      if (!unionized) return feature;

      return Turf.union(unionized as any, feature as any);
    });

    unionized.properties = properties;
    return [ unionized ];
  }
}
