import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {selectPlot} from "../actions/plot.actions";
import {filter} from "rxjs/operators";
import {map} from "rxjs";
import {PlanalogicData} from "../plot.models";
import {
  PlanalogicFsi,
  PlanalogicGfa,
  PlanalogicNfaGfa,
  PlanalogicSuVo,
  PlanalogicTotals
} from "./demo.models";
import {DemoService} from "../../../demo/demo-service";
import {setDemoData} from "../actions/demo.actions";

const demoPlots: string[] = [ 'WEE02-B-5630', 'GVH17-Y-3081' ];
const sizes: Record<string, [number, number]> = {
  'WEE02-B-5630': [60, 55],
  'GVH17-Y-3081': [55, 40]
}

@Injectable()
export class DemoEffects {
  demoPlotSizing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectPlot),
      filter(({ identifier }) => demoPlots.includes(identifier)),
      map(({ identifier }) => {
        const dimensions: [number, number] = sizes[identifier];

        return setDemoData({
          identifier,
          data: { dimensions, totals: this.getTotals(dimensions) }
        })
      })
    )
  );

  constructor(
    private actions$: Actions,
    private demoService: DemoService
  ) { }

  private getTotals([ x, y ]: [ number, number ]): PlanalogicTotals {
    const dataSet = this.demoService.getPlots()
      .filter(({ plotX, plotY }) => plotX === x && plotY === y);

    return {
      floorSpaceIndex: this.getFsiTotal(dataSet),
      grossFloorArea: this.getGfaTotal(dataSet),
      nettFloorAreaVsGrossFloorArea: this.getNfaGfaTotal(dataSet),
      surfaceVsVolume: this.getSuVoTotal(dataSet)
    }
  }

  private getFsiTotal(dataSet: PlanalogicData[]): PlanalogicFsi {
    const { fsiBin50, fsiBin75, fsiBin100 } = dataSet[0];

    return {
      fsi50: this.findData(dataSet, 'fsiPlotPerc', 0.5),
      fsi75: this.findData(dataSet, 'fsiPlotPerc', 0.75),
      fsi100: this.findData(dataSet, 'fsiPlotPerc', 1),
      fsiBin50, fsiBin75, fsiBin100
    }
  }

  private getGfaTotal(dataSet: PlanalogicData[]): PlanalogicGfa {
    const {
      gfaBin50Perc: gfaBin50,
      gfaBin75Perc: gfaBin75,
      gfaBin100Perc: gfaBin100
    } = dataSet[0];

    return {
      gfa50: this.findData(dataSet, 'gfaPlotPerc', 0.5),
      gfa75: this.findData(dataSet, 'gfaPlotPerc', 0.75),
      gfa100: this.findData(dataSet, 'gfaPlotPerc', 1),
      gfaBin50, gfaBin75, gfaBin100
    }
  }

  private getNfaGfaTotal(dataSet: PlanalogicData[]): PlanalogicNfaGfa {
    const { nfaGfaBin50, nfaGfaBin75, nfaGfaBin100 } = dataSet[0];

    return {
      nfaGfa50: this.findData(dataSet, 'nfaGfaPlotPerc', 0.5),
      nfaGfa75: this.findData(dataSet, 'nfaGfaPlotPerc', 0.75),
      nfaGfa100: this.findData(dataSet, 'nfaGfaPlotPerc', 1),
      nfaGfaBin50, nfaGfaBin75, nfaGfaBin100
    }
  }

  private getSuVoTotal(dataSet: PlanalogicData[]): PlanalogicSuVo {
    const { suVoBin50, suVoBin75, suVoBin100 } = dataSet[0];

    return {
      suVo50: this.findData(dataSet, 'suVoPlotPerc', 0.5),
      suVo75: this.findData(dataSet, 'suVoPlotPerc', 0.75),
      suVo100: this.findData(dataSet, 'suVoPlotPerc', 1),
      suVoBin50, suVoBin75, suVoBin100
    }
  }

  private findData(dataSet: PlanalogicData[], column: keyof PlanalogicData, percentage: number): PlanalogicData {
    const sortedLowToHigh = dataSet.sort((plotA, plotB) => {
      return plotA[column] > plotB[column] ? 1 : -1;
    });

    return percentage === 1 ?
      sortedLowToHigh[ sortedLowToHigh.length - 1 ] :
      sortedLowToHigh.filter(data => data[column] as number >= percentage)[0];
  }
}
