import {DataType} from "./object.models";

export class PlObjectHelpers {
  static getIndex(part: string | number): string | number {
    const isArrayIndex = !isNaN(part as number);

    return isArrayIndex ? +part : part;
  }

  static updateArray(context: any[], index: number, value: any): void {
    // todo need strategies here
    context[index] = [ ...context[index], ...value ];
  }

  static updateRecord(context: Record<string, any>, key: string, value: any): void {
    // todo need strategies here
    context[key] = { ...context[key], ...value };
  }

  static determineUpdateType(value: any): DataType {
    const type = typeof value;

    if (Array.isArray(value)) return DataType.Array;
    if (type === 'object' && value !== null) return DataType.Record;
    if (type === 'string' || type === 'number' || type === 'boolean') return DataType.Primitive;

    return DataType.Unknown;
  }
}
