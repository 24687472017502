import { NgModule } from '@angular/core';
import {
  PlBarGraphModule,
  PlContentToggleModule, PlDefaultPageModule,
  PlFormsModule, PlLineGraphModule, PlScoutPageModule, PlTabGroupModule, PlColorPickerModule
} from '@planalogic/design-system';
import { ExpanderComponent } from "./expander/expander.component";
import { CommonModule } from "@angular/common";
import {PurposePipe} from "./purpose/purpose.pipe";
import {ScatterPlotComponent} from "./scatter-plot/scatter-plot.component";
import {DropDownModule} from "../../../../../../libs/design-system/src/content-containers/drop-down/drop-down.module";

const sharedDeclarations = [
  ExpanderComponent,
  PurposePipe,
  ScatterPlotComponent
];

const sharedModules = [
  CommonModule,

  PlLineGraphModule,
  PlContentToggleModule,
  PlScoutPageModule,
  PlTabGroupModule,
  PlDefaultPageModule,
  PlFormsModule,
  PlBarGraphModule,
  DropDownModule
];

@NgModule({
  declarations: sharedDeclarations,
  imports: sharedModules,
  exports: [ ...sharedDeclarations, ...sharedModules ]
})
export class SharedModule { }
