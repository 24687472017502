import { noop } from 'rxjs';
import { NgControl, ValidationErrors } from '@angular/forms';
import { ElementRef } from '@angular/core';

export abstract class AbstractFormControlComponent<T = any> {
  abstract id: string;

  value!: T;
  disabled = false;
  showError = false;

  get valid(): boolean {
    return this.control && !this.control.invalid;
  }

  get errors(): ValidationErrors | null {
    return this.control && this.control.errors;
  }

  get showErrors(): boolean {
    const elementIsPristine = this.elementRef.nativeElement?.classList.contains('ng-pristine');

    if (elementIsPristine && !this.showError) { return false; }

    return !this.control.valid;
  }

  // method that is used to update the internal form control value
  protected propagateValue: (value: T) => void = noop;

  // method that is executed once the form control gets touched / blurred
  protected setFormControlTouched: () => void = noop;

  protected constructor(protected control: NgControl, protected elementRef: ElementRef) {
    if (!control) { return; }

    control.valueAccessor = this;
  }

  // function that funnels all the changes from different directions (different template events, external form control change, etc.)
  // this function typically calls the propagateValue to propagate the internal value state
  abstract updateValue(value: T, external?: boolean): void;

  // function that is executed when the form control value is updated externally
  writeValue(value: T): void {
    this.updateValue(value, true);
  }

  // function that is executed when the form control is disabled externally
  setDisabledState(value: boolean): void {
    this.disabled = value;
  }

  // channel through form value propagation to our own method
  registerOnChange(registerChangeFn: (value: T) => void): void {
    this.propagateValue = registerChangeFn;
  }

  // channel through form control blurred / touched event to our own method
  registerOnTouched(fn: () => void): void {
    this.setFormControlTouched = fn;
  }
}
