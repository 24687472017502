import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {
  getPlotSizing,
  retrieveResidences,
  savePlotAddress,
  savePlotSizing,
  saveResidences,
  selectPlot
} from "../actions/plot.actions";
import {FreemiumMapService} from "../../../pages/freemium/freemium-map/freemium-map.service";
import { concatMap, map, switchMap, zip } from "rxjs";
import {FreemiumApiService} from "../../../pages/freemium/api-service/freemium.api-service";
import {
  FreemiumColor,
  PlotSizing,
  PlotsizingResponse,
} from "../../../pages/freemium/freemium-map/freemium-map.models";
import {PlGisUtils} from "@planalogic/utilities";
import { AddressInformation, ResidencePayloadItem } from "../plot.models";
import {FeatureCollection} from "geojson";
import { SelectColorFeature } from "../../../pages/freemium/freemium-map/features/select-color/select-color";
import { ActiveFeature } from "../../../pages/freemium/freemium-map/active-features";
import { Store } from "@ngrx/store";
import { selectActivePlot } from "../selectors/plot.selectors";

@Injectable()
export class PlotEffects {
  getAddressInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectPlot),
      switchMap(({ identifier }) => this.apiService.getAddressInformation(identifier)
        .pipe(map(address => ({ identifier, address })))
      ),
      concatMap(({ identifier, address }) => {
        // ja ik moet even die laag query'en, even denken
        const province = address?.provincieafkorting.toLowerCase();
        const { gemeentenaam: township, huisnummer = '', postcode = '', provincienaam = '', straatnaam = '', centroide_ll: centroide } = address || {};
        const addressInformation: AddressInformation = {
          street: `${ straatnaam } ${ huisnummer }`,
          zipCode: `${ postcode } ${ provincienaam }`,
          township, province, centroide
        };

        return [
          savePlotAddress({ identifier, address: addressInformation }),
          getPlotSizing({ identifier, province })
        ];
      })
    ));

  getPlotSizing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPlotSizing),
      concatLatestFrom(({ identifier }) => this.store.select(selectActivePlot(identifier))),
      concatMap(([{ identifier, province }, activePlot]) => {
        const layer = `ruimtelijkeplannen_${ province }_source`;
        const sourceLayer = `percelen_ruimtelijkeplannen_${ province }`;
        const features = this.mapService.map.querySourceFeatures(layer, {
          sourceLayer,
          filter: [ 'any', [ '==', 'kadastraalnummer', identifier ] ]
        });
        const { properties } = this.getMostRecentPlotSizing(features) || {} as PlotsizingResponse;
        if (!properties) return [ savePlotSizing({ identifier, sizing: { loaded: true } as PlotSizing }) ];

        const bagFeatures = this.mapService.map.querySourceFeatures(`plots_${ province }_source`, {
          sourceLayer: `plots_${ province }`,
          filter: [ 'any', [ '==', 'kadastraalnummer', identifier ] ]
        }) as any || {};
        const bagJson = bagFeatures[0]?.properties?.bag_json;

        const {
          bouwvlak_vlag: bouwvlakVlag,
          maatvoering_vlag: maatvoeringVlag,
          plangebied: planningArea,
          perceeloppervlakte: plotSize,
          deeloppervlakte: buildingAreaSize,
          max_bouwhoogte: maxHeight
        } = properties;

        const shouldAlarmUser = !!(bouwvlakVlag || maatvoeringVlag);
        const plotFillColor = shouldAlarmUser ? '#ff00ff' : 'transparent';
        const plotBorderColor = shouldAlarmUser ? '#ff00ff' : '#bb2020';

        this.mapService.features
          .getFeature<SelectColorFeature>(ActiveFeature.SelectColors)
          .setColor(FreemiumColor.ActivePlotsFill, plotFillColor);

        this.mapService.features
          .getFeature<SelectColorFeature>(ActiveFeature.SelectColors)
          .setColor(FreemiumColor.ActivePlotsBorder, plotBorderColor);

        const includedIdentifiers = PlGisUtils.normalizeArray(properties.kadastraalnummers_in_bouwvlak);
        const residences = PlGisUtils.normalizeTable<ResidencePayloadItem>(bagJson) || [];
        const savePlotAction = savePlotSizing({ identifier, sizing: {
          includedIdentifiers, planningArea, buildingAreaSize, maxHeight: +maxHeight, plotSize,
          loaded: true,
        }});
        const retrieveResidencesAction = retrieveResidences({ identifier, residences });

        return [ savePlotAction, retrieveResidencesAction ];
      })
    )
  );

  retrieveResidences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveResidences),
      switchMap(({ residences: premises, identifier }) => {
        return zip(premises.map(premise => this.apiService.getResidenceInformation(premise.id).pipe(
          map(({ type, features }: any) => {
            if (!features[0]?.properties) return premise;

            features[0].properties = { ...features[0].properties, ...premise }

            return { features, type } as FeatureCollection;
          })
        ))).pipe(
          map(residences => saveResidences({ identifier, residences: residences as FeatureCollection[]}))
        )
      })
  ));

  constructor(
    private actions$: Actions,
    private store: Store,
    private mapService: FreemiumMapService,
    private apiService: FreemiumApiService) { }

  // todo check at a later date if this is still the desired approach
  private getMostRecentPlotSizing(features: any[]/*MapboxGeoJSONFeature[]*/): PlotsizingResponse | null {
    if (!features || !features.length) return null;

    return features
      .sort(({ properties: { datum: datumA } }, { properties: { datum: datumB } }) => {
        return datumA < datumB ? 1 : -1;
      })[0];
  }
}
