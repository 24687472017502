import { Component } from "@angular/core";
import {selectDemoPlot, selectPlanalogicData} from "../../state/plot/selectors/plot.selectors";
import {filter} from "rxjs/operators";
import {Store} from "@ngrx/store";

@Component({
  selector: 'pay-per-use-page',
  templateUrl: './pay-per-use.component.html',
  styleUrls: [ './pay-per-use.component.scss' ]
})
export class PayPerUseComponent {
  public data$ = this.store.select(selectPlanalogicData);
  public demoPlot$ = this.store.select(selectDemoPlot).pipe(filter(plot => !!plot));

  constructor(private store: Store) { }
}
