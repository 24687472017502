import { AbstractMapFeature } from "@planalogic/design-system";
import { ClassConstructor } from "@planalogic/utilities";
import { AerialFeature } from "./features/aerial/aerial.feature";
import { ThreeDFeature } from "./features/3d/3d-buildings.feature";
import { RelatedPlotsFeature } from "./features/related-plots/related-plots.feature";
import {SelectColorFeature} from "./features/select-color/select-color";

export enum ActiveFeature {
  Aerial = 'luchtfoto',
  ThreeD = '3D gebouwen',
  RelatedPlots = 'related plots',
  SelectColors = 'select colors'
}

type ActiveMapFeature = {
  name: ActiveFeature;
  feature: ClassConstructor<AbstractMapFeature>;
}

export const activeMapFeatures: ActiveMapFeature[] = [
  { name: ActiveFeature.Aerial, feature: AerialFeature },
  { name: ActiveFeature.ThreeD, feature: ThreeDFeature },
  { name: ActiveFeature.RelatedPlots, feature: RelatedPlotsFeature },
  { name: ActiveFeature.SelectColors, feature: SelectColorFeature }
];
