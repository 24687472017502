<ng-container *ngIf="information">
  <select #selectElement
          class="temporary-select"
          name="showData"
          [value]="information.metadata.category"
          (change)="onSelectionChange(selectElement.value)">

    <option value="floorSpaceIndex">FSI</option>
    <option value="grossFloorArea">GFA</option>
    <option value="nettFloorAreaVsGrossFloorArea">NFA/GFA</option>
    <option value="surfaceVsVolume">Surface/volume</option>
  </select>

  <!-- FSI -->
  <ng-container *ngIf="information.metadata.category === 'floorSpaceIndex'">
    <pl-line-graph *ngIf="totals?.floorSpaceIndex as fsi" [showLegend]="true" [showLabels]="true">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle"
                       [percentage]="fsi.fsi50.fsiPlotPerc"
                       [label]="fsi.fsi50.fsi"
                       [active]="information.data.img === fsi.fsi50.img"
                       (pointClicked)="updatePlanalogicData('fsi50', fsi.fsi50)">
        </pl-line-point>

        <pl-line-point [type]="square"
                       [percentage]="fsi.fsi75.fsiPlotPerc"
                       [label]="fsi.fsi75.fsi"
                       [active]="information.data.img === fsi.fsi75.img"
                       (pointClicked)="updatePlanalogicData('fsi75', fsi.fsi75)">
        </pl-line-point>

        <pl-line-point [type]="triangle"
                       [percentage]="fsi.fsi100.fsiPlotPerc"
                       [label]="fsi.fsi100.fsi"
                       [active]="information.data.img === fsi.fsi100.img"
                       (pointClicked)="updatePlanalogicData('fsi100', fsi.fsi100)">
        </pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="fsi.fsiBin50"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="fsi.fsiBin75"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="fsi.fsiBin100"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </ng-container>

  <ng-container *ngIf="information.metadata.category === 'grossFloorArea'">
    <pl-line-graph *ngIf="totals?.grossFloorArea as gfa" [showLegend]="true" [showLabels]="true">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle"
                       [percentage]="gfa.gfa50.gfaPlotPerc"
                       [label]="(gfa.gfa50.gfaTotal  | number:'1.0-2':'nl-NL') + ' m²'"
                       [active]="information.data.img === gfa.gfa50.img"
                       (pointClicked)="updatePlanalogicData('gfa50', gfa.gfa50)">
        </pl-line-point>

        <pl-line-point [type]="square"
                       [percentage]="gfa.gfa75.gfaPlotPerc"
                       [label]="(gfa.gfa75.gfaTotal  | number:'1.0-2':'nl-NL') + ' m²'"
                       [active]="information.data.img === gfa.gfa75.img"
                       (pointClicked)="updatePlanalogicData('gfa75', gfa.gfa75)">
        </pl-line-point>


        <pl-line-point [type]="triangle"
                       [percentage]="gfa.gfa100.gfaPlotPerc"
                       [label]="(gfa.gfa100.gfaTotal  | number:'1.0-2':'nl-NL') + ' m²'"
                       [active]="information.data.img === gfa.gfa100.img"
                       (pointClicked)="updatePlanalogicData('gfa100', gfa.gfa100)">
        </pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="(gfa.gfaBin50  | number:'1.0-2':'nl-NL') + ' m²'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="(gfa.gfaBin75  | number:'1.0-2':'nl-NL') + ' m²'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="(gfa.gfaBin100  | number:'1.0-2':'nl-NL') + ' m²'"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </ng-container>

  <ng-container *ngIf="information.metadata.category === 'nettFloorAreaVsGrossFloorArea'">
    <pl-line-graph *ngIf="totals?.nettFloorAreaVsGrossFloorArea as nfaGfa" [showLegend]="true" [showLabels]="true">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle"
                       [percentage]="nfaGfa.nfaGfa50.nfaGfaPlotPerc"
                       [label]="nfaGfa.nfaGfa50.nfaGfa + ' %'"
                       [active]="information.data.img === nfaGfa.nfaGfa50.img"
                       (pointClicked)="updatePlanalogicData('nfaGfa50', nfaGfa.nfaGfa50)">
        </pl-line-point>

        <pl-line-point [type]="square"
                       [percentage]="nfaGfa.nfaGfa75.nfaGfaPlotPerc"
                       [label]="nfaGfa.nfaGfa75.nfaGfa + ' %'"
                       [active]="information.data.img === nfaGfa.nfaGfa75.img"
                       (pointClicked)="updatePlanalogicData('nfaGfa75', nfaGfa.nfaGfa75)">
        </pl-line-point>

        <pl-line-point [type]="triangle"
                       [percentage]="nfaGfa.nfaGfa100.nfaGfaPlotPerc"
                       [label]="nfaGfa.nfaGfa100.nfaGfa + ' %'"
                       [active]="information.data.img === nfaGfa.nfaGfa100.img"
                       (pointClicked)="updatePlanalogicData('nfaGfa100', nfaGfa.nfaGfa100)">
        </pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="nfaGfa.nfaGfaBin50 + ' %'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="nfaGfa.nfaGfaBin75 + ' %'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="nfaGfa.nfaGfaBin100 + ' %'"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </ng-container>

  <ng-container *ngIf="information.metadata.category === 'surfaceVsVolume'">
    <pl-line-graph *ngIf="totals?.surfaceVsVolume as suVo" [showLegend]="true" [showLabels]="true">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle"
                       [percentage]="suVo.suVo50.suVoPlotPerc"
                       [label]="suVo.suVo50.surToVol"
                       [active]="information.data.img === suVo.suVo50.img"
                       (pointClicked)="updatePlanalogicData('suVo50', suVo.suVo50)">
        </pl-line-point>

        <pl-line-point [type]="square"
                       [percentage]="suVo.suVo75.suVoPlotPerc"
                       [label]="suVo.suVo75.surToVol"
                       [active]="information.data.img === suVo.suVo75.img"
                       (pointClicked)="updatePlanalogicData('suVo75', suVo.suVo75)">
        </pl-line-point>

        <pl-line-point [type]="triangle"
                       [percentage]="suVo.suVo100.suVoPlotPerc"
                       [label]="suVo.suVo100.surToVol"
                       [active]="information.data.img === suVo.suVo100.img"
                       (pointClicked)="updatePlanalogicData('suVo100', suVo.suVo100)">
        </pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="suVo.suVoBin50"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="suVo.suVoBin75"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="suVo.suVoBin100"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </ng-container>
</ng-container>
