import {GeoJSONSource, MapboxGeoJSONFeature, MapLayerEventType} from "mapbox-gl";
import { PerceelDataSource } from "./data-sources/perceel.data-source";
import { PlGisUtils } from "@planalogic/utilities";
import { ActiveFeature } from "../active-features";
import {from, ReplaySubject, Subject, switchMap, take, takeUntil, zip} from "rxjs";
import { ProvinceEvent } from "../province-manager/province-manager.models";
import {SelectColorFeature} from "../features/select-color/select-color";
import {FreemiumColor} from "../freemium-map.models";
import {Store} from "@ngrx/store";
import {removeActivePlot, selectPlot} from "../../../../state/plot/actions/plot.actions";
import { FreemiumMapService, percelenLayerPrefix } from "../freemium-map.service";
import {tap} from "rxjs/operators";
import { ResidencePayloadItem } from "../../../../state/plot/plot.models";

export class PlotManager {
  public get clickEvent(): ProvinceEvent {
    return {
      type: 'click',
      feature: 'percelen', // todo zo hier moet ik echt even goed naar kijken, duidelijke legacy nog
      callback: this.onPlotClicked.bind(this)
    };
  }

  private plots$ = new ReplaySubject<PerceelDataSource[]>(1);
  public loadedPlots$ = this.plots$.pipe(
    switchMap(sources => {
      return !sources.length ?
        from([[]]) :
        zip(sources.map(source => source.data$))
    })
  );

  private destroyer$ = new Subject<void>();
  private activePlots: Record<string, PerceelDataSource> = { };

  private get featureCollection(): GeoJSON.FeatureCollection {
    return Object.entries(this.activePlots).reduce((collection: GeoJSON.FeatureCollection, [ , activePlot ]) => { // todo fix any
      collection.features.push(...activePlot.features);
      return collection;
    }, { type: 'FeatureCollection', features: [] });
  }

  constructor(private mapService: FreemiumMapService, private store: Store) {
    this.setupManager();
  }

  // this generally gets triggered via a bound click event setup by the province-manager
  public onPlotClicked(layer: string, { point }: MapLayerEventType['mousedown']): void {
    const { properties } = this.mapService.map.queryRenderedFeatures(point, { layers: [ layer ] })[0] || {};
    if (!properties) return;

    const { kadastraalnummer: identifier, perceeloppervlakte: plotSize, bag_json: bagJson } = properties;
    const residences = PlGisUtils.normalizeTable<ResidencePayloadItem>(bagJson);
    const unionized = this.unionize(layer, properties);
    const dataSource = new PerceelDataSource(identifier, unionized, this.store);

    if (this.activePlots[identifier]) {
      return this.deactivatePlot(identifier);
    }

    this.activatePlot(identifier, plotSize, residences, dataSource);
  }

  public activatePlot(identifier: string, plotSize: number, residences: ResidencePayloadItem[], dataSource: PerceelDataSource): void {
    this.store.dispatch(selectPlot({
      identifier,
      plotSize,
      residences
    }));

    this.addActivePlot(identifier, dataSource, true);
  }

  public deactivatePlot(identifier: string): void {
    this.store.dispatch(removeActivePlot({ identifier }));

    this.removeActivePlot(identifier);
  }

  private removeActivePlot(identifier: string): void {
    delete this.activePlots[identifier];
    this.updatePlotFeatures();
  }

  public reset(): void {
    this.activePlots = {};
    this.updatePlotFeatures();
  }

  public destroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public restore(): void {
    const plots = this.mapService.getActivePlots() || {};
    this.reset();

    Object.entries(plots).forEach(([ identifier, dataSource ]) => {
      this.addActivePlot(identifier, dataSource, false, true);
    });
  }

  private addActivePlot(identifier: string, source: PerceelDataSource, reset = false, skipRelated = false): void {
    const addRelated = !skipRelated && this.mapService.features.isActive(ActiveFeature.RelatedPlots);

    addRelated && this.addRelatedPlots(source, reset);
    this.activePlots[identifier] = source;

    this.updatePlotFeatures();
  }

  private setupManager(): void {
    const colors = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);
    const highlightColor = colors?.getColor(FreemiumColor.ActivePlotsFill) || '#ff00ff';
    const borderColor = colors?.getColor(FreemiumColor.ActivePlotsBorder) || '#bb2020';

    this.mapService.createSourceLayer('active-plot', {
      type: 'geojson',
      data: this.featureCollection
    });

    this.mapService.createMapLayer('active-plot-highlight', {
      id: 'active-plot-highlight',
      source: 'active-plot',
      type: 'fill',
      minzoom: 15,
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': highlightColor,
        'fill-opacity': 0.25
      }
    });

    this.mapService.createMapLayer('active-plot-border', {
      id: 'active-plot-border',
      source: 'active-plot',
      type: 'line',
      minzoom: 15,
      paint: {
        'line-color': borderColor,
        'line-width': 2
      }
    });

    this.listenForActivePlotsBorderColors();
  }

  private listenForActivePlotsBorderColors(): void {
    const feature = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);

    feature?.onChange(FreemiumColor.ActivePlotsBorder).pipe(takeUntil(this.destroyer$))
      .subscribe(color => {
        this.mapService.map.setPaintProperty('active-plot-border', 'line-color', color);
      });

    feature?.onChange(FreemiumColor.ActivePlotsFill).pipe(takeUntil(this.destroyer$))
      .subscribe(color => {
        this.mapService.map.setPaintProperty('active-plot-highlight', 'fill-color', color);
      });
  }

  private updatePlotFeatures(): void {
    const collection = this.featureCollection;
    // todo sum up totals
    // const { features } = collection;
    // const coordinates = features.length && (features[0].geometry as any).coordinates[0][0];
    const geoSource = this.mapService.map.getSource('active-plot') as GeoJSONSource;

    geoSource.setData(collection);

    this.mapService.setActivePlots(this.activePlots);
    this.plots$.next(Object.values(this.activePlots));
  }

  private addRelatedPlots(source: PerceelDataSource, reset = false): void {
    reset && this.reset();

    source.data$
      .pipe(take(1)).subscribe(({ province, includedIdentifiers }) => {
        const features = includedIdentifiers
          .map(kadastraalNummer => this.mapKadastraalNrToFeatures(kadastraalNummer, province))
          .reduce((allFeatures, currentFeatures) => (
            [...allFeatures, ...currentFeatures]
          ), [ ...source.features ]);

        source.setFeatures(features);
        this.updatePlotFeatures();
      });
  }

  private mapKadastraalNrToFeatures(kadastraalNummer: string, province: string): MapboxGeoJSONFeature[] {
    return this.mapService.map.querySourceFeatures(`${ percelenLayerPrefix }${ province }_source`, {
      sourceLayer: `${ percelenLayerPrefix }${ province }`,
      filter: [
        '==', 'kadastraalnummer', kadastraalNummer
      ]
    })
  }

  private unionize(layer: string, properties: any): MapboxGeoJSONFeature[] {
    const features = this.mapService.map.queryRenderedFeatures({
      layers: [ layer ],
      filter: [
        '==', 'kadastraalnummer', properties.kadastraalnummer
      ]
    } as any);

    return PlGisUtils.unionizeFeatures(features, properties);
  }
}
