export class PickerCircle {
  public get context(): CanvasRenderingContext2D | null {
    return this.canvas.getContext('2d');
  }

  constructor(
    private readonly canvas: HTMLCanvasElement,
    private x: number,
    private y: number,
    private width: number,
    private useFixed = false
  ) { }

  public drawPicker(): void {
    const context = this.context;
    if (!context) return;

    context.beginPath();
    context.arc(this.x, this.y, this.width, 0, Math.PI * 2);
    context.strokeStyle = "black";
    context.stroke();
    context.closePath();
  }

  public clickedInside(x: number, y: number): boolean {
    const [ canvasLeft, canvasTop ] = this.getCanvasOffset();
    const mouseX = x - canvasLeft;
    const mouseY = y - canvasTop;
    const clickInVerticalBounds = (mouseY > this.y - this.width) && (mouseY < this.y + this.width);
    const clickInHorizontalBounds = (mouseX > this.x - this.width) && (mouseX < this.x + this.width);

    return clickInVerticalBounds && clickInHorizontalBounds;
  }

  public dragToPosition(x: number, y: number): void {
    const [ canvasLeft, canvasTop ] = this.getCanvasOffset();

    this.x = x - canvasLeft;
    this.y = y - canvasTop;
  }

  public getColor(): string {
    const context = this.context;
    if (!context) return '';

    const [ red, green, blue ] = context.getImageData(this.x, this.y, 1, 1).data;
    const redColor = `0${ red.toString(16) }`.slice(-2);
    const greenColor = `0${ green.toString(16) }`.slice(-2);
    const blueColor = `0${ blue.toString(16) }`.slice(-2);

    return `#${ redColor }${ greenColor }${ blueColor }`;
  }

  private getCanvasOffset(): [ number, number ] {
    const { left: canvasOffsetLeft, top: canvasOffsetTop } = this.canvas.getBoundingClientRect();
    const canvasLeft = this.useFixed ? canvasOffsetLeft : this.canvas.offsetLeft;
    const canvasTop = this.useFixed ? canvasOffsetTop : this.canvas.offsetTop;

    return [ canvasLeft, canvasTop ];
  }
}
