import { ProvinceSetUtils } from "./utils/province.utils";
import { Point, PointLike } from "mapbox-gl";
import { PlGisUtils } from "@planalogic/utilities";
import { LoadedProvinceSet, ProvinceEvent, SourceQuery, SourceQueryFn } from "./province-manager.models";
import {SelectColorFeature} from "../features/select-color/select-color";
import {ActiveFeature} from "../active-features";
import {FreemiumColor} from "../freemium-map.models";
import {Subject, takeUntil} from "rxjs";
import {FreemiumMapService} from "../freemium-map.service";

export class ProvinceManager {
  private provinces: { [ province: string ]: LoadedProvinceSet } = { };
  private destroyer$ = new Subject<void>();

  constructor(
    private readonly mapService: FreemiumMapService,
    private readonly events: ProvinceEvent[] = []
  ) {
    this.setupManager();
  }

  public loadProvince(provinceAbbreviation: string): void {
    if (this.provinces[provinceAbbreviation]) return;

    const newProvinceSet = this.createProvinceSet(provinceAbbreviation);

    this.provinces[provinceAbbreviation] = newProvinceSet;
    this.loadProvinceSet(provinceAbbreviation, newProvinceSet, this.events);
  }

  public queryProvinceSourceFeatures(queryFn: SourceQueryFn, province?: string): any[] {
    return Object.keys(this.provinces)
      .filter((abbreviation: string) => !province ? true : abbreviation === province)
      .map((abbreviation: string) => queryFn(abbreviation))
      .map((query: SourceQuery) => {
        const { source, filter } = query;
        const { id, sourceLayer } = source;
        const features = this.mapService.map.querySourceFeatures(id, {
          sourceLayer, filter
        });

        return features[ 0 ];
      })
  }

  public isLoaded(province: string): boolean {
    return !!this.provinces[province];
  }

  public getProvinceByPoint(point: PointLike | string): string {
    point = typeof point === 'string' ? PlGisUtils.normalizePoint(point) : point;

    const provinces = this.mapService.map
      .queryRenderedFeatures(point, { layers: [ 'province-features' ] })
      .map(({ properties }) => properties?.afkorting)
      .filter(properties => !!properties) || [];

    return provinces[0] || '';
  }

  public destroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private setupManager(): void {
    this.createSourceAndLayer();
    this.listenForColors();
    this.mapService.events.listen(
      'load-province-mouse-down',
      'mousedown',
      ({ point }) => this.onProvinceMouseDown(point),
      { layerId: 'province-features' }
    );
  }

  private listenForColors(): void {
    this.listenForMaatvoeringColors();
    this.listenForPlotBorderColors();
  }

  private listenForMaatvoeringColors(): void {
    const feature = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);

    feature?.onChange(FreemiumColor.MaatvoeringLayer).pipe(takeUntil(this.destroyer$))
      .subscribe(color => {
        Object.values(this.provinces)
          .map(({ maatvoering: { layer: { id }} }) => id)
          .forEach(id => this.mapService.map.setPaintProperty(id, 'fill-color', color));
      });
  }


  private listenForPlotBorderColors(): void {
    const feature = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);

    feature?.onChange(FreemiumColor.PlotBorders).pipe(takeUntil(this.destroyer$))
      .subscribe(color => {
        Object.values(this.provinces)
          .map(({ percelenBorders: { layer: { id }} }) => id)
          .forEach(id => this.mapService.map.setPaintProperty(id, 'line-color', color));
      });
  }

  private createProvinceSet(provinceAbbreviation: string): LoadedProvinceSet {
    const colors = this.mapService.features.getFeature<SelectColorFeature>(ActiveFeature.SelectColors);
    const maatvoeringFill = colors?.getColor(FreemiumColor.MaatvoeringLayer);
    const plotBorder = colors?.getColor(FreemiumColor.PlotBorders);

    return {
      maatvoering: ProvinceSetUtils.createMaatvoeringProvinceSet(provinceAbbreviation, maatvoeringFill),
      percelen: ProvinceSetUtils.createPercelenProvinceSet(provinceAbbreviation),
      percelenBorders: ProvinceSetUtils.createPercelenBorderProvinceSet(provinceAbbreviation, plotBorder)
    }
  }

  private loadProvinceSet(province: string, set: LoadedProvinceSet, events: ProvinceEvent[]): void {
    Object.entries(set).forEach(([ feature, { layer, source } ]) => {
      source && this.mapService.createSourceLayer(source.id, source.data);
      this.mapService.createMapLayer(layer.id, layer);

      this.events.filter(event => event.feature === feature)
        .filter(({ feature }) => !!feature)
        .forEach(({ type, feature: layerId, callback }) => {
          const id = `plots_${ province }_layer`;
console.log('Using layer id:', id);
          this.mapService.events.listen(id, type, (...args: any[]) => callback(id, ...args));
      });
    });
  }

  private onProvinceMouseDown(point: Point|PointLike): void {
    const provinces = this.mapService.map
      .queryRenderedFeatures(point, { layers: [ 'province-features' ] })
      .map(({ properties }) => properties?.afkorting)
      .filter(properties => !!properties);

    provinces.forEach(province => this.loadProvince(province));
  }

  private createSourceAndLayer(): void {
    this.mapService.createSourceLayer('provinces', {
      type: 'vector',
      url: 'mapbox://planalogic.provincie'
    })

    this.mapService.createMapLayer('province-features', {
      id: 'province-features',
      type: 'fill',
      source: 'provinces',
      'source-layer': 'provincie',
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': 'blue'
      }
    });
  }
}
