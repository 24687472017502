import {filter, fromEvent, map, Observable, take} from "rxjs";

export class PlDomUtils {
  static clickedOutside(element: HTMLElement): Observable<any> {
    return fromEvent(document, 'click').pipe(
      map(({ target }: any) => target),
      filter(target => !element.contains(target)),
      take(1)
    )
  }
}
