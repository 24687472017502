import { NgModule } from '@angular/core';
import { ContentToggleComponent } from './content-toggle.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ContentToggleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ContentToggleComponent
  ]
})
export class PlContentToggleModule { }
