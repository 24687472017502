import {AfterViewInit, Component, ContentChildren, ElementRef, Input, QueryList, ViewChild} from "@angular/core";
import {DropDownItemComponent} from "./drop-down-item.component";
import {PlAngularUtils} from "@planalogic/utilities";
import {PlDomUtils} from "../../../../utilities/src/lib/dom/dom.utils";

@Component({
  selector: 'pl-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: [ './drop-down.component.scss' ]
})
export class DropDownComponent implements AfterViewInit {
  @Input() buttonTitle!: string;

  @ViewChild('itemsContainer') itemsContainer!: ElementRef<HTMLDivElement>;
  @ContentChildren(DropDownItemComponent) itemsList!: QueryList<DropDownItemComponent>;

  public items: DropDownItemComponent[] = [];
  public showItems = false;

  ngAfterViewInit(): void {
    this.updateDropdownItems();
    this.itemsList.changes.subscribe(() => this.updateDropdownItems());
  }

  public toggleItems(): void {
    this.showItems = !this.showItems;

    PlAngularUtils.waitForPropagation(() => {
      const element = this.itemsContainer?.nativeElement;

      element && PlDomUtils.clickedOutside(element)
        .subscribe(() => this.showItems = false);
    });
  }

  private updateDropdownItems(): void {
    PlAngularUtils.waitForPropagation(() => this.items = this.itemsList.toArray());
  }
}
