import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'pl-default-page',
  templateUrl: './default.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './default.component.scss' ]
})
export class DefaultPageComponent {

}
