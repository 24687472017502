import { SuggestData, SuggestSource } from '@planalogic/design-system';
import { ApiService } from '@planalogic/toolbox';
import { map, tap } from 'rxjs/operators';
import { Address } from './address.models';
import { Observable } from 'rxjs';

export class AddressSuggestSource implements SuggestSource {
  constructor(private apiService: ApiService) { }

  public suggest(term: string): Observable<SuggestData[]> {
    return this.apiService.get<any/*AddressResponse*/>(`suggest?q=${ term }&fq=type:adres`, { server: 'locationService' })
      .pipe(
        map((result: any/*AddressResponse*/) => {
          const addresses = result?.response?.docs || [];

          return addresses.map((address: Address) => ({ label: address.weergavenaam, value: address.id }));
        }),
      );
  }

  public lookup(id: string): Observable<any> { // todo fix any
    return this.apiService.get(`lookup?id=${ id }`, { server: 'locationService' })
      .pipe(
        map((result: any) => result?.response?.docs),
        tap((results: any[]) => {
          if (results.length > 1) {
            console.warn('Picking first result from look-up but multiple exist', results);
          }
        }),
        map(results => results[0])
      );
  }
}
