import {Injectable} from "@angular/core";
import {FreemiumMap} from "./freemium-map";
import {AnyLayer, AnySourceData, Map as MapboxMap} from 'mapbox-gl';
import {MapFeatureManager, MapEventManager} from "@planalogic/design-system";
import {PlotManager} from "./plot-manager/plot-manager";
import {LocationManager} from "./location-manager/location-manager";
import {ProvinceManager} from "./province-manager/province-manager";
import {PerceelDataSource} from "./plot-manager/data-sources/perceel.data-source";

export const percelenLayerPrefix = 'plots_';

@Injectable()
export class FreemiumMapService {
  public current!: FreemiumMap;
  private activePlots!: Record<string, PerceelDataSource>;

  public get map(): MapboxMap {
    return this.current.manager.map;
  }

  public get events(): MapEventManager {
    return this.current.manager.events;
  }

  public get features(): MapFeatureManager {
    if (!this.current) throw new Error('No current map found in the map service!');

    return this.current.manager.features;
  }

  public get plots(): PlotManager {
    return this.current.plots;
  }

  public get provinces(): ProvinceManager {
    return this.current.provinces;
  }

  public get location(): LocationManager {
    return this.current.location;
  }

  public restore(): void {
    this.current.restore();
  }

  public stopAndDestroy(): void {
    this.events.stopAll();
    this.current.destroy();
  }

  public setMap(map: FreemiumMap): void {
    this.current = map;
  }

  public createSourceLayer(id: string, sourceData: AnySourceData): void {
    this.current.manager.createSourceLayer(id, sourceData);
  }

  public removeLayer(id: string): void {
    this.current.manager.removeLayer(id);
  }

  public createMapLayer(id: string, layer: AnyLayer): void {
    this.current.manager.createMapLayer(id, layer);
  }

  public setActivePlots(plots: Record<string, PerceelDataSource>): void {
    this.activePlots = plots;
  }

  public getActivePlots(): Record<string, PerceelDataSource> {
    return this.activePlots;
  }
}
