import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { ApiService } from './api.service';
import { ApiModuleConfiguration, ServerList } from './api.models';
import { HttpClientModule } from '@angular/common/http';

export class ApiConfiguration {
  serverList!: ServerList;
}

@NgModule({
  imports: [ HttpClientModule ],
  providers: [ ApiService ],
  exports: [ HttpClientModule ]
})
export class ApiModule {
  static forRoot(configuration: ApiModuleConfiguration): ModuleWithProviders<ApiModule> {
    const { serverList } = configuration;
    const providers: Provider[] = [];

    providers.push({ provide: ApiConfiguration, useValue: { serverList } });

    return { ngModule: ApiModule, providers }
  }
}
