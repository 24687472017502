import {DataType} from "./object.models";
import {
  PlObjectHelpers
} from "./object.helpers";

type ObjectContext = Record<string, any> | any[];

export class PlObjectUtils {
  static setProperty<Target extends Record<string, any>, Property extends keyof Target>(
    target: Target,
    property: Property,
    value: any
  ): void {
    if (typeof value === 'undefined') return;

    target[property] = value;
  }

  static clone<T extends Record<string, any> | any[]>(from: T, to: any): T {
    if (typeof from !== 'object' || from === null) return from;

    for (const key in from) {
      const value = from[key];
      const isArray = Array.isArray(value);

      to[key] = isArray ? [] : {};
      to[key] = PlObjectUtils.clone(from[key] as T, to[key]);
    }

    return to;
  }

  static merge<T extends Record<string, any> = Record<string, any>>(target: T = { } as T, ...values: T[]): T {
    return values.reduce((result: T, value: T) => PlObjectUtils.clone(value, result), target);
  }

  static updateValueByPath(context: ObjectContext, path: string, value: any): void {
    const parts = path.split(/\.|\[|\]/).filter(value => !!value);
    const lastIndex = PlObjectHelpers.getIndex(parts[parts.length - 1]);
    const target = parts.reduce((result: ObjectContext, part: string | number, index: number) => {
      if (index >= parts.length -1) { return result; }

      return (result as any)[PlObjectHelpers.getIndex(part)];
    }, context) as any;

    const updateType = PlObjectHelpers.determineUpdateType(target[lastIndex]);

    switch(updateType) {
      case DataType.Array:
        PlObjectHelpers.updateArray(target, lastIndex as number, value);
        break;

      case DataType.Record:
        PlObjectHelpers.updateRecord(target, lastIndex as string, value);
        break;

      case DataType.Primitive:
        target[lastIndex ] = value;
        break;
    }
  }
}
