<div class="color-container"
     [id]="id"
     (click)="enablePicker()"
     [ngStyle]="{
       'background-color': color
     }">
</div>

<label [for]="id">
  <ng-content></ng-content>
</label>

<pl-pop-up *ngIf="showPicker">
  <div class="color-picker-container">
    <pl-color-picker (picked)="updateValue($event)" [fixedPosition]="true"></pl-color-picker>
  </div>

  <section style="text-align: center">
    <input #colorElement type="text"
           [value]="color"
           (blur)="enterColor(colorElement.value)"/>
  </section>

  <section class="button-container">
    <button class="approve-button" (click)="approve()">V</button>
    <button class="decline-button" (click)="decline()">X</button>
  </section>
</pl-pop-up>
