import {AfterViewInit, Component, ElementRef, ViewChild} from "@angular/core";
import * as d3 from 'd3';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: [ './scatter-plot.component.scss' ]
})
export class ScatterPlotComponent implements AfterViewInit {
  @ViewChild('container') containerRef!: ElementRef<HTMLDivElement>;

  constructor(private http: HttpClient) { }

  ngAfterViewInit() {
    const element = this.containerRef?.nativeElement;

    if (!element) return;

    // set the dimensions and margins of the graph
    const margin = {top: 10, right: 30, bottom: 50, left: 70},
      width = 460 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3.create('svg')
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    svg.append("text")
      .attr("transform", "translate(" + (width/2) + " ," + (height+40) + ")")
      .style("text-anchor", "middle")
      .style("font", "18px einer grotesk")
      .text("Building Cost / m² in €")
      // .axis().tickValues([]);

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -(height/2))
      .attr("y", -50)
      .style("text-anchor", "middle")
      .style("font", "18px einer grotesk")
      .text("Net Floor Area (NFA) in m²");

    //Read the data
    this.http.get('assets/scatter-plot/scatter-plot.csv', {responseType: 'text'})
      .subscribe(csv => {
        const dataUri = "data:text/plain;base64," + btoa(unescape(encodeURIComponent(csv)));

        (d3.csv as any)(dataUri, (data: any) => {
          // Add X axis
          const x = d3.scaleLinear()
            .domain([1200, 3000])
            .range([ 0, width ]);

          svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x));

          // Add Y axis
          const y = d3.scaleLinear()
            .domain([5600, 18000])
            .range([ height, 0]);
          svg.append("g")
            .call(d3.axisLeft(y));


          // Add dots
          svg.append('g')
            .selectAll("dot")
            .data(data)
            .join("circle")
            // .attr("cx", function (d: any) {
            //   return x(d.BUILDINGCOST_M2GFA); }
            // )
            // .attr("cy", function (d: any) {
            //   return y(d.NFA_TOTAL);
            // } )
            .attr("r", 1.5)
            .style("fill", "#D3D3D3")

           const svgNode = svg.node();
           svgNode && element.append(svgNode);
        });
      });
  }
}
