import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ApiModule } from '@planalogic/toolbox';
import { SharedModule } from './shared/shared.module';
import { appRoutes } from "./app.routes";
import { LandingPageComponent } from "./pages/landing/landing.component";
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { PayPerUseComponent } from "./pages/pay-per-use/pay-per-use.component";
import { FreemiumModule } from "./pages/freemium/freemium.module";
import {StoreModule} from "@ngrx/store";
import {plotReducer} from "./state/plot/reducer/plot.reducer";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {EffectsModule} from "@ngrx/effects";
import {PlotEffects} from "./state/plot/effects/plot.effects";
import {DemoService} from "./demo/demo-service";
import {DemoEffects} from "./state/plot/effects/demo-effects";
import {MapContainerService} from "./shared/map-container/map-container.service";
import {MapContainerDirective} from "./shared/map-container/map-container.directive";
import {
  SubscriptionTotalsComponent
} from "./pages/subscription/graphs/subscription-totals/subscription-totals.component";

const pages = [
  LandingPageComponent,
  SubscriptionComponent,
  PayPerUseComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...pages,
    MapContainerDirective,
    SubscriptionTotalsComponent
  ],
  imports: [
    /* Angular modules */
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),

    /* Planalogic modules */
    SharedModule,
    FreemiumModule,
    // todo implement this in the respective feature modules
    ApiModule.forRoot({
      serverList: {
        default: '/api/',
        local: 'http://localhost:3000/api/',
        locationService: 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/',
        bag: 'https://service.pdok.nl/lv/bag/wfs/v2_0'
      }
    }),
    StoreModule.forRoot({ plot: plotReducer }),
    EffectsModule.forRoot([
      PlotEffects,
      DemoEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
  ],
  providers: [
    DemoService,
    MapContainerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
