import { AbstractMapFeature, MapManager } from "@planalogic/design-system";

export class ThreeDFeature extends AbstractMapFeature {
  constructor(private readonly manager: MapManager) {
    super();
  }

  protected override toggleOff(): void {
    this.manager.removeLayer('3d-buildings');
  }

  protected override toggleOn(): void {
    this.manager.createMapLayer('3d-buildings', {
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': [ '==', 'extrude', 'true' ],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#333',
          // Use an 'interpolate' expression to
          // add a smooth transition effect to
          // the buildings as the user zooms in.
          'fill-extrusion-height': [
            'interpolate',
            [ 'linear' ],
            [ 'zoom' ],
            15,
            0,
            15.05,
            [ 'get', 'height' ]
          ],
          'fill-extrusion-base': [
            'interpolate',
            [ 'linear' ],
            [ 'zoom' ],
            15,
            0,
            15.05,
            [ 'get', 'min_height' ]
          ],
          'fill-extrusion-opacity': 1,
        }
      }
    )
  }
}
