import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActivePlotInformation} from "../../../../state/plot/plot.models";

@Component({
  selector: 'plot-data',
  templateUrl: './plot-data.component.html',
  styleUrls: [ './plot-data.component.scss' ]
})
export class PlotDataComponent implements OnInit {
  @Input() plot!: ActivePlotInformation;
  @Input() nextStep!: string;

  @Output() goNext = new EventEmitter<void>();

  public ruimtelijkePlannenUrl!: string;

  public goToNextStep(): void {
    this.goNext.emit();
  }

  ngOnInit(): void {
    const { planningArea, zipCode } = this.plot;
    if (planningArea && zipCode) {
      const zipcodePrefix = zipCode.split(' ')[0];
      this.ruimtelijkePlannenUrl = `https://www.ruimtelijkeplannen.nl/?planidn=${ planningArea }&postcode=${ zipcodePrefix }`
    }
    console.log('PLOT:', this.plot);
  }
}
