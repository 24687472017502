<div class="search-container" [formGroup]="form">
  <auto-suggest #autoSuggest
                formControlName="search"
                placeholder="Search land registry or by address"
                [source]="suggestSource"
                [debounce]="100"
                (searchTerm)="onSearchTerm($event)"
                (selectedValue)="searchResult($event)"
                (hasReset)="hasReset.emit()">
  </auto-suggest>
</div>
