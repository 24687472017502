import {AbstractMapFeature, MapManager} from "@planalogic/design-system";
import {map, Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";

const colorKey = 'pl-map-colors';

export class SelectColorFeature extends AbstractMapFeature {
  private colors: Record<string, string> = { };
  private colorChange$ = new Subject<[string, string]>();

  constructor(private readonly manager: MapManager) {
    super();

    this.initialize();
  }

  public setColor(identifier: string, color: string): void {
    this.colors[identifier] = color;
    localStorage.setItem(colorKey, JSON.stringify(this.colors));

    this.colorChange$.next([ identifier, color ]);
  }

  public getColor(identifier: string): string {
    return this.colors[identifier];
  }

  public onChange(identifier: string): Observable<string> {
    return this.colorChange$.pipe(
      filter(([ id ]) => identifier === id),
      map(([ , color ]) => color)
    );
  }

  private initialize(): void {
    const colors = localStorage.getItem(colorKey) || '';

    try {
      this.colors = JSON.parse(colors);
    } catch { }
  }
}
