import { Component, Input } from "@angular/core";

@Component({
  selector: 'pl-line-legend',
  template: ``
})
export class LineLegendComponent {
  @Input() symbol!: string;
  @Input() represents!: string;
}
