<div class="premise-container">
  <ng-container *ngFor="let premise of premises$ | async; let last = last">
    <div class="table">
      <div class="tr">
        <div class="td">Building:</div>
        <div class="td">{{ premise.id }}</div>
      </div>

      <div class="tr">
        <div class="td">Height:</div>
        <div class="td">{{ premise.height | number:'1.0-2':'nl-NL' }} m</div>
      </div>

      <div class="tr">
        <div class="td">Built in:</div>
        <div class="td">{{ premise.bouwjaar }}</div>
      </div>

      <div class="tr">
        <div class="td">Circumference:</div>
        <div class="td">{{ premise.circumference | number:'1.0-2':'nl-NL' }} m</div>
      </div>

      <div class="tr">
        <div class="td">Surface:</div>
        <div class="td">{{ premise.surface | number:'1.0-2':'nl-NL' }} m²</div>
      </div>

      <div class="tr">
        <div class="td">Functional use:</div>
        <div class="td">{{ premise.gebruiksdoel | purpose }}</div>
      </div>
    </div>

    <hr *ngIf="!last"/>
  </ng-container>
</div>
