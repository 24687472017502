<header>
  <img class="logo" alt="logo" src="/assets/images/planalogic-logo.png"/>

  <div class="menu">
    <ng-content select="[page-header-menu]"></ng-content>
  </div>
</header>

<main>
  <ng-content></ng-content>
</main>

<aside>
  <ng-content select="[scout-info-panel]"></ng-content>
</aside>

<div class="corner" *ngIf="!skipCorner">
  <ng-content select="pl-scout-corner"></ng-content>
</div>

<footer>
  <ng-content select="[page-footer]"></ng-content>
</footer>

<div class="side-bar">
  <ng-content select="pl-scout-side-bar"></ng-content>

  <div #expansionPanel *ngIf="isExpanded" class="scout-expansion-panel">
    <ng-content select="[scout-expansion-panel]"></ng-content>

    <div class="scout-expansion-button" (click)="toggleExpansion(false)">
      <div class="expansion-arrow" *ngIf="isExpanded">
        <img src="/assets/icons/settings-gray.svg">
      </div>
    </div>
  </div>

  <div *ngIf="!isExpanded"
       class="scout-expansion-button scout-expansion-button--side-bar"
       (click)="toggleExpansion(true)">

    <img src="/assets/icons/settings-gradient.svg">
  </div>
</div>
