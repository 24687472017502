<h2 class="graph-title">
  {{ graphTitle }}
</h2>

<section class="row graph-content">
  <p class="y-axis-label">{{ yAxisLabel }}</p>
  <p class="x-axis-label">{{ xAxisLabel }}</p>

  <p *ngFor="let bar of bars" class="graph-bar-container">
    <span class="graph-bar"
          [ngStyle]="{
            height: bar.height + '%'
          }">

      <span class="graph-bar-value">
        {{ bar.amount }}
      </span>
    </span>
  </p>
</section>
