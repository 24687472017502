import {AfterViewInit, Component, Input, OnDestroy, OnInit} from "@angular/core";
import { PlanalogicTotals } from "../../../../state/plot/effects/demo.models";
import {PlanalogicData, PlanalogicInformation} from "../../../../state/plot/plot.models";
import {Store} from "@ngrx/store";
import {selectPlanalogicInformation} from "../../../../state/plot/selectors/plot.selectors";
import {filter} from "rxjs/operators";
import {Subject, takeUntil} from "rxjs";
import {setPlanalogicData} from "../../../../state/plot/actions/demo.actions";
import {PlAngularUtils, PlObjectUtils} from "@planalogic/utilities";

@Component({
  selector: 'subscription-totals',
  templateUrl: './subscription-totals.component.html',
  styleUrls: [ './subscription-totals.component.scss' ]
})
export class SubscriptionTotalsComponent implements OnInit, AfterViewInit, OnDestroy /*implements OnInit, OnChanges*/ {
  @Input() totals!: PlanalogicTotals;

  public information: PlanalogicInformation = {
    metadata: {
      category: 'floorSpaceIndex',
      indicator: 'fsi75'
    },
    data: {} as PlanalogicData
  };

  private destroyer$ = new Subject<void>();

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.observeInformation();
  }

  ngAfterViewInit() {
    PlAngularUtils.waitForPropagation(() => {
      if (Object.keys(this.information.data).length) return;

      const { category, indicator } = this.information.metadata;
      const data = (this.totals as any)[category][indicator];

      this.updatePlanalogicData(indicator, data);
    })
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private observeInformation(): void {
    this.store.select<PlanalogicInformation>(selectPlanalogicInformation)
      .pipe(
        takeUntil(this.destroyer$),
        filter(({ data }) => !!Object.keys(data).length)
      ).subscribe(information => this.information = PlObjectUtils.clone(information, {}));
  }


  public onSelectionChange(category: string): void {
    this.information.metadata.category = category;
    const categoryData = (this.totals as any)[category];
    const indicator = Object.keys(categoryData)[1];
    const data = categoryData[indicator];

    this.updatePlanalogicData(indicator, data);
  }

  public updatePlanalogicData(indicator: string, data: PlanalogicData): void {
    const { category } = this.information.metadata;

    this.store.dispatch(setPlanalogicData({
      indicator, data, category
    }))
  }
}
