import { Component, Input } from "@angular/core";

@Component({
  selector: 'pl-expander',
  templateUrl: './expander.component.html',
  styleUrls: [ './expander.component.scss' ]
})
export class ExpanderComponent {
  @Input() dataTitle!: string;
  @Input() size: 'small' | 'regular' = 'regular';
  @Input() expanded!: boolean;
}
