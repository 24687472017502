<h2>Benchmark & peergroup comparison</h2>

<div class="graph-containers">
  <div class="graph-container left">
    <pl-line-graph graphTitle="FSI" *ngIf="totals?.floorSpaceIndex as fsi" [showLegend]="false" [interactable]="false">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle" [percentage]="fsi.fsi50.fsiPlotPerc" [label]="fsi.fsi50.fsi"></pl-line-point>
        <pl-line-point [type]="square" [percentage]="fsi.fsi75.fsiPlotPerc" [label]="fsi.fsi75.fsi"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="fsi.fsi100.fsiPlotPerc"  [label]="fsi.fsi100.fsi"></pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="fsi.fsiBin50"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="fsi.fsiBin75"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="fsi.fsiBin100"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>

    <pl-line-graph *ngIf="totals?.grossFloorArea as gfa" graphTitle="GFA" [showLegend]="false" [interactable]="false">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle" [percentage]="gfa.gfa50.gfaPlotPerc" [label]="gfa.gfa50.gfaTotal + ' m'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]="gfa.gfa75.gfaPlotPerc" [label]="gfa.gfa75.gfaTotal + ' m'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="gfa.gfa100.gfaPlotPerc"  [label]="gfa.gfa100.gfaTotal + ' m'"></pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="gfa.gfaBin50 + ' m'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="gfa.gfaBin75 + ' m'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="gfa.gfaBin100 + ' m'"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </div>

  <div class="graph-container right">
    <pl-line-graph *ngIf="totals?.nettFloorAreaVsGrossFloorArea as nfaGfa" graphTitle="NFA / GFA" [moveLegendDown]="true" [showLegend]="true" [interactable]="false">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle" [percentage]="nfaGfa.nfaGfa50.nfaGfaPlotPerc" [label]="nfaGfa.nfaGfa50.nfaGfa + ' m'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]="nfaGfa.nfaGfa75.nfaGfaPlotPerc" [label]="nfaGfa.nfaGfa75.nfaGfa + ' m'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="nfaGfa.nfaGfa100.nfaGfaPlotPerc"  [label]="nfaGfa.nfaGfa100.nfaGfa + ' m'"></pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="nfaGfa.nfaGfaBin50 + ' m'"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="nfaGfa.nfaGfaBin75 + ' m'"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="nfaGfa.nfaGfaBin100 + ' m'"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>

    <pl-line-graph *ngIf="totals?.surfaceVsVolume as suVo" graphTitle="Surface / volume" [showLegend]="false" [interactable]="false" [skipSpacer]="true">
      <pl-line-legend #circle symbol="graph-circle" represents="median value"></pl-line-legend>
      <pl-line-legend #square symbol="graph-square" represents="75th percentile"></pl-line-legend>
      <pl-line-legend #triangle symbol="graph-triangle" represents="benchmark"></pl-line-legend>

      <pl-graph-line name="selected plot">
        <pl-line-point [type]="circle" [percentage]="suVo.suVo50.suVoPlotPerc" [label]="suVo.suVo50.surToVol"></pl-line-point>
        <pl-line-point [type]="square" [percentage]="suVo.suVo75.suVoPlotPerc" [label]="suVo.suVo75.surToVol"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="suVo.suVo100.suVoPlotPerc"  [label]="suVo.suVo100.surToVol"></pl-line-point>
      </pl-graph-line>

      <pl-graph-line name="peer group">
        <pl-line-point [type]="circle" [percentage]=".5" [label]="suVo.suVoBin50"></pl-line-point>
        <pl-line-point [type]="square" [percentage]=".75" [label]="suVo.suVoBin75"></pl-line-point>
        <pl-line-point [type]="triangle" [percentage]="1" [label]="suVo.suVoBin100"></pl-line-point>
      </pl-graph-line>
    </pl-line-graph>
  </div>
</div>
