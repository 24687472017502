import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: [ './tab.component.scss' ]
})
export class TabComponent {
  @Input() tabTitle!: string;

  @ViewChild('blueprint') blueprint!: TemplateRef<any>;
}
