<ng-container *ngIf="plot">
  <div class="table">
    <div class="tr">
      <div class="td">Land registration number:</div>
      <div class="td">{{ plot.identifier }}</div>
    </div>

    <div class="tr">
      <div class="td">Street:</div>
      <div class="td">{{ plot.street }}</div>
    </div>

    <div class="tr">
      <div class="td">Postal code:</div>
      <div class="td">{{ plot.zipCode }}</div>
    </div>

    <div class="tr">
      <div class="td">City:</div>
      <div class="td">{{ plot.township }}</div>
    </div>

    <div class="tr">
      <div class="td">Plot size:</div>
      <div class="td">
        {{ plot.plotSize | number:'1.0-2':'nl-NL' }} m²
      </div>
    </div>

    <div class="tr">
      <div class="td">Building area:</div>
      <div class="td">
        {{ plot.buildingAreaSize | number:'1.0-2':'nl-NL' }} m²
      </div>
    </div>

    <div class="tr">
      <div class="td">Max. building height:</div>
      <div class="td">
        {{ plot.maxHeight | number:'1.0-2':'nl-NL' }} m
      </div>
    </div>

    <div class="tr">
      <div class="td">Planning area:</div>
      <div class="td">{{ plot.planningArea }}</div>
    </div>
  </div>

  <section>
    <a *ngIf="ruimtelijkePlannenUrl"
       [href]="ruimtelijkePlannenUrl"
       style="margin-bottom: 16px"
       target="_blank">

      Open ruimtelijkeplannen
    </a>
  </section>

  <pl-expander dataTitle="Buildings" size="small" [expanded]="true">
    <premises [identifier]="plot.identifier"></premises>
  </pl-expander>

  <section>
    <button *ngIf="nextStep" (click)="goToNextStep()">View plot performance</button>
  </section>
</ng-container>
