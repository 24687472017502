<div class="tab-group__header">
  <div *ngFor="let tab of tabs" class="tab-group__tab-head"
       [ngClass]="{
          'tab-group__tab-head--active': active === tab
       }"
       (click)="active = tab">

    {{ tab.tabTitle }}
  </div>
</div>

<div class="tab-group__container">
  <div *ngFor="let tab of tabs" class="tab-group__tab-content">
    <ng-container *ngIf="active === tab">
      <ng-container *ngTemplateOutlet="tab.blueprint"></ng-container>
    </ng-container>
  </div>
</div>
