import { MapManager } from "@planalogic/design-system";
import { ActiveMapEvent, ActiveMapEventConfiguration, EventCallback } from "./event-manager.models";
import { MapLayerEventType } from "mapbox-gl";

export class MapEventManager {
  private events = new Map<string, ActiveMapEvent>();

  constructor(private manager: MapManager) { }

  public listen(identifier: string, event: string, listener: EventCallback, configuration: ActiveMapEventConfiguration = {}): void {
    const { layerId } = configuration;
    const current = this.events.get(identifier);
    const activeEvent = this.createEvent(identifier, event, listener, configuration);

    current && console.warn('Starting to listen to an event that already has that identifier registered');

    this.startListening(event, listener, layerId);
    this.events.set(identifier, activeEvent);
  }

  public listenOnce(event: string, listener: EventCallback, configuration: ActiveMapEventConfiguration = {}) {
    const { layerId } = configuration;

    !layerId ?
      this.manager.map.once(event, listener) :
      this.manager.map.once(event as keyof MapLayerEventType, layerId, listener);
  }

  public stop(identifier: string): void {
    const current = this.events.get(identifier);
    if (!current) return;

    const { callback, event, configuration } = current;
    const { layerId } = configuration || {};

    this.stopListening(event, callback, layerId);

    this.events.delete(identifier);
  }

  public stopAll(): void {
    Array.from(this.events.keys()).forEach(identifier => this.stop(identifier));
  }

  private createEvent(identifier: string, event: string, listener: EventCallback, configuration?: ActiveMapEventConfiguration): ActiveMapEvent {
    return {
      identifier,
      event,
      configuration,
      callback: listener
    }
  }

  private startListening(event: string, listener: EventCallback, layerId?: string): void {
    !layerId ?
      this.manager.map.on(event, listener) :
      this.manager.map.on(event as keyof MapLayerEventType, layerId, listener);
  }

  private stopListening(event: string, listener: EventCallback, layerId?: string): void {
    !layerId ?
      this.manager.map.off(event, listener) :
      this.manager.map.off(event as keyof MapLayerEventType, layerId, listener);
  }
}
