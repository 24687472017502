import {AfterViewInit, Directive, ElementRef, HostBinding} from "@angular/core";
import {MapContainerService} from "./map-container.service";

@Directive({ selector: '[map-container]' })
export class MapContainerDirective implements AfterViewInit {
  @HostBinding('style.position') position = 'fixed';
  @HostBinding('style.left') left = '-10000px';

  constructor(private elementRef: ElementRef, private service: MapContainerService) { }

  ngAfterViewInit(): void {
    const container: HTMLElement = this.elementRef.nativeElement;
    this.service.setMapContainer(container);
  }
}
