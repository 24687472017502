import {createReducer, on} from "@ngrx/store";
import {
  ActivePlotInformation, PlanalogicData,
  PlotState, Premise, Residence,
  SavePlotAddressPayload,
  SavePlotSizingPayload, SaveResidencePayload,
  SelectPlotPayload
} from "../plot.models";
import {
  removeActivePlot,
  savePlotAddress,
  savePlotSizing,
  saveResidences,
  selectPlot
} from "../actions/plot.actions";
import {PlObjectUtils, PlReduxUtils} from "@planalogic/utilities";
import {DemoData} from "../effects/demo.models";
import {setDemoData, setPlanalogicData} from "../actions/demo.actions";

const initialState: PlotState = {
  active: [],
  planalogic: {
    metadata: {
      category: '',
      indicator: ''
    },
    data: {} as PlanalogicData
  }
};

export const plotReducer = createReducer(
  initialState,
  on(selectPlot, selectPlotReducer),
  on(removeActivePlot, removeActivePlotReducer),
  on(savePlotSizing, savePlotSizingReducer),
  on(savePlotAddress, savePlotAddressReducer),
  on(saveResidences, saveResidencesReducer),
  on(setDemoData, setDemoDataReducer),
  on(setPlanalogicData, setPlanalogicDataReducer)
);

function savePlotSizingReducer(state: PlotState, { identifier, sizing }: SavePlotSizingPayload): PlotState {
  if (!sizing) { return { ...state }; }

  const index = state.active.findIndex(plot => plot.identifier === identifier);

  return PlReduxUtils.updateState(state, {
    path: `active[${ index }]`,
    value: sizing
  });
}

function removeActivePlotReducer(state: PlotState, { identifier }: { identifier: string }): PlotState {
  const clone = PlObjectUtils.clone<PlotState>(state, {});
  const index = clone.active.findIndex(plot => plot.identifier === identifier);

  clone.active.splice(index, 1);
  return clone;
}

function savePlotAddressReducer(state: PlotState, { identifier, address }: SavePlotAddressPayload): PlotState {
  if (!address) { return { ...state }; }

  const index = state.active.findIndex(plot => plot.identifier === identifier);

  return PlReduxUtils.updateState(state, {
    path: `active[${ index }]`,
    value: address
  });
}

function selectPlotReducer(state: PlotState, payload: SelectPlotPayload): PlotState {
  const newPlot = {
    ...payload,
    loaded: false,
    premises: [] as Premise[]
  } as ActivePlotInformation;

  return {
    ...state,
    active: [ ...state.active, newPlot ]
  };
}

function saveResidencesReducer(state: PlotState, { identifier, residences }: SaveResidencePayload): PlotState {
  const index = state.active.findIndex(plot => plot.identifier === identifier);
  const premises = residences.map(residence => {
    const properties = (residence?.features || []).reduce((total: Residence, { properties }) => {
      const newProperties = { ...total, ...properties } as Residence;
      newProperties.height = +newProperties.height;

      return newProperties;
    }, {} as Residence);

    return Object.keys(properties).length ? properties : residence;
  });

  return PlReduxUtils.updateState(state, {
    path: `active[${ index }].premises`,
    value: premises
  });
}

function setDemoDataReducer(state: PlotState, { identifier, data }: DemoData): PlotState {
  const index = state.active.findIndex(plot => plot.identifier === identifier);

  return PlReduxUtils.updateState(state, {
    path: `active[${ index }]`,
    value: data
  });
}

function setPlanalogicDataReducer(state: PlotState, { category, indicator, data }: { category: string, indicator: string, data: PlanalogicData }): PlotState {
  const newState = PlReduxUtils.updateState(state, {
    path: `planalogic.data`,
    value: data
  });

  return PlReduxUtils.updateState(newState, {
    path: `planalogic.metadata`,
    value: { category, indicator }
  });
}
