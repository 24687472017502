import { Component } from "@angular/core";
import {Store} from "@ngrx/store";
import {selectDemoPlot, selectPlanalogicData} from "../../state/plot/selectors/plot.selectors";
import {filter} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'subscription-page',
  templateUrl: './subscription.component.html',
  styleUrls: [ './subscription.component.scss' ]
})
export class SubscriptionComponent {
  public data$ = this.store.select(selectPlanalogicData);
  public demoPlot$ = this.store.select(selectDemoPlot).pipe(filter(plot => !!plot));

  constructor(private store: Store, private router: Router) { }

  public changeParameters(): void {
    this.router.navigate([ '/pay-per-use' ]);
  }
}
