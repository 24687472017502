import { LineGraphComponent } from "./line-graph.component";
import { NgModule } from "@angular/core";
import { LineComponent } from "./line/line.component";
import { LineLegendComponent } from "./legend/legend-item.component";
import { LinePointComponent } from "./line/point/line-point.component";
import { CommonModule } from "@angular/common";

const lineGraphDeclarations = [
  LineGraphComponent,
  LineComponent,
  LinePointComponent,
  LineLegendComponent
];

@NgModule({
  declarations: lineGraphDeclarations,
  imports: [ CommonModule ],
  exports: lineGraphDeclarations
})
export class PlLineGraphModule { }
