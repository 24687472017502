import {createAction, props} from "@ngrx/store";
import {DemoData} from "../effects/demo.models";
import {PlanalogicData} from "../plot.models";

export const setDemoData = createAction('PL - Set Demo Data', props<DemoData>())
export const setPlanalogicData = createAction('PL - Set Planalogic Data', props<{
  category: string;
  indicator: string;
  data: PlanalogicData;
}>())
