import {PlanalogicData} from "../state/plot/plot.models";
import {demoKavels} from "./data/demo-kavels";
import {Injectable} from "@angular/core";

@Injectable()
export class DemoService {
  private plots = demoKavels;

  public getPlots(): PlanalogicData[] {
    return this.plots;
  }
}
