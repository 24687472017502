import { NgModule } from '@angular/core';
import { AutoSuggestComponent } from './auto-suggest.component';
import { CommonModule } from '@angular/common';

const autoSuggestDeclarations = [
  AutoSuggestComponent
];

@NgModule({
  declarations: [ ...autoSuggestDeclarations ],
  imports: [
    CommonModule
  ],
  exports: [ ...autoSuggestDeclarations ]
})
export class AutoSuggestModule { }
