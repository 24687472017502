import {Component, EventEmitter, Input, Output} from "@angular/core";
import { LineLegendComponent } from "../../legend/legend-item.component";

@Component({
  selector: 'pl-line-point',
  template: ` `
})
export class LinePointComponent {
  @Input() type!: LineLegendComponent;
  @Input() set percentage(value: number) {
    this.position = value * 100;
  }
  @Input() label!: string | number;
  @Input() active = false;
  @Output() pointClicked = new EventEmitter<void>();

  public position!: number;
}
