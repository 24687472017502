import { ProvinceSet } from "../province-manager.models";
import { percelenLayerPrefix } from "../../freemium-map.service";

export class ProvinceSetUtils {
  // MAATVOERING / MEASUREMENTS
  static createMaatvoeringProvinceSet(
    provinceAbbreviation: string,
    fill = '#1e75d1',
    opacity = 0.25,
    outline = '#999'
  ): ProvinceSet {

    const sourceId = `ruimtelijkeplannen_${ provinceAbbreviation }_source`;
    return {
      source: {
        id: sourceId,
        data: {
          type: 'vector',
          url: `mapbox://planalogic.percelen_ruimtelijkeplannen_${ provinceAbbreviation }`
        }
      },
      layer: {
        id: `ruimtelijkeplannen_${ provinceAbbreviation }_layer`,
        source: sourceId,
        'source-layer': `percelen_ruimtelijkeplannen_${ provinceAbbreviation }`,
        type: 'fill',
        paint: {
          'fill-opacity': opacity,
          'fill-color': fill,
          'fill-outline-color': outline,
        }
      }
    }
  }

  // PERCELEN / PLOTS
  static createPercelenProvinceSet(provinceAbbreviation: string): ProvinceSet {
    return {
      source: {
        id: `${ percelenLayerPrefix }${ provinceAbbreviation }_source`,
        data: {
          type: 'vector',
          url: `mapbox://planalogic.${ percelenLayerPrefix }${ provinceAbbreviation }`
        }
      },
      layer: {
        id: `${ percelenLayerPrefix }${ provinceAbbreviation }_layer`,
        type: 'fill',
        source: `plots_${ provinceAbbreviation }_source`,
        'source-layer': `${ percelenLayerPrefix }${ provinceAbbreviation }`,
        paint: {
          'fill-color': 'transparent'
        }
      }
    }
  }

  static createPercelenBorderProvinceSet(provinceAbbreviation: string, lineColor = '#2b3c67', opacity = 0.25): ProvinceSet {
    return {
      layer: {
        id: `${ percelenLayerPrefix }borders_${ provinceAbbreviation }_layer`,
        type: 'line',
        source: `${ percelenLayerPrefix }${ provinceAbbreviation }_source`,
        'source-layer': `${ percelenLayerPrefix }${ provinceAbbreviation }`,
        paint: {
          'line-color': lineColor,
          'line-width': opacity
        }
      }
    }
  }
}
