export const demoKavels = [
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7644.7,
    "gfaFloors": 6734.8,
    "gfaCirc": 909.9,
    "nfaTotal": 5698.7,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.5,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1512.98,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7896,
    "gfaFloors": 6961.9,
    "gfaCirc": 934.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.6,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1508.93,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7896,
    "gfaFloors": 6961.9,
    "gfaCirc": 934.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.6,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1508.19,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6578.9,
    "gfaFloors": 5799.4,
    "gfaCirc": 779.5,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.6,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1530.23,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7638.9,
    "gfaFloors": 6734.8,
    "gfaCirc": 904.1,
    "nfaTotal": 5698.7,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.6,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1511.8,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7638.9,
    "gfaFloors": 6734.8,
    "gfaCirc": 904.1,
    "nfaTotal": 5698.7,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.6,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1512.57,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6321.8,
    "gfaFloors": 5572.3,
    "gfaCirc": 749.5,
    "nfaTotal": 4715.6,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.6,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1535.5,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6578.9,
    "gfaFloors": 5799.4,
    "gfaCirc": 779.5,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.6,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1531.13,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9207,
    "gfaFloors": 8118.3,
    "gfaCirc": 1088.7,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.6,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1494.65,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7896,
    "gfaFloors": 6961.9,
    "gfaCirc": 934.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.6,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1509.67,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9207,
    "gfaFloors": 8118.3,
    "gfaCirc": 1088.7,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.6,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1495.29,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7901.8,
    "gfaFloors": 6961.9,
    "gfaCirc": 939.9,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.6,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1509.33,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7638.9,
    "gfaFloors": 6734.8,
    "gfaCirc": 904.1,
    "nfaTotal": 5698.7,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.6,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1513.34,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6321.8,
    "gfaFloors": 5572.3,
    "gfaCirc": 749.5,
    "nfaTotal": 4715.6,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.6,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1536.43,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8956.1,
    "gfaFloors": 7897.4,
    "gfaCirc": 1058.7,
    "nfaTotal": 6681.8,
    "nrStorage": 75.5,
    "nrApartm": 115,
    "nfaGfa": 74.6,
    "volume": 23108.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4519.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1496.36,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8956.1,
    "gfaFloors": 7897.4,
    "gfaCirc": 1058.7,
    "nfaTotal": 6681.8,
    "nrStorage": 75.5,
    "nrApartm": 115,
    "nfaGfa": 74.6,
    "volume": 23108.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4519.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1497.02,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9207,
    "gfaFloors": 8118.3,
    "gfaCirc": 1088.7,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.6,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1495.93,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8956.1,
    "gfaFloors": 7897.4,
    "gfaCirc": 1058.7,
    "nfaTotal": 6681.8,
    "nrStorage": 75.5,
    "nrApartm": 115,
    "nfaGfa": 74.6,
    "volume": 23108.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4519.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1497.67,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8183.6,
    "gfaFloors": 7225.4,
    "gfaCirc": 958.2,
    "nfaTotal": 6115.5,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.7,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1504.32,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8183.6,
    "gfaFloors": 7225.4,
    "gfaCirc": 958.2,
    "nfaTotal": 6115.5,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.7,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6573.1,
    "gfaFloors": 5799.4,
    "gfaCirc": 773.7,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.7,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1530.67,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6573.1,
    "gfaFloors": 5799.4,
    "gfaCirc": 773.7,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.7,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1529.78,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6316,
    "gfaFloors": 5572.3,
    "gfaCirc": 743.7,
    "nfaTotal": 4715.6,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.7,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1535.96,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6316,
    "gfaFloors": 5572.3,
    "gfaCirc": 743.7,
    "nfaTotal": 4715.6,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.7,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1535.03,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8504.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 995.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.7,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1501.13,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8504.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 995.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.7,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1500.44,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9827,
    "gfaFloors": 8671,
    "gfaCirc": 1156,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.7,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1487.05,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6316.9,
    "gfaFloors": 5575.4,
    "gfaCirc": 741.5,
    "nfaTotal": 4718.4,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.7,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1536.67,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9827,
    "gfaFloors": 8671,
    "gfaCirc": 1156,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.7,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1487.65,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8504.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 995.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.7,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1501.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9827,
    "gfaFloors": 8671,
    "gfaCirc": 1156,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.7,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1488.25,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6570.9,
    "gfaFloors": 5799.4,
    "gfaCirc": 771.5,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.7,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1532.07,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8183.6,
    "gfaFloors": 7225.4,
    "gfaCirc": 958.2,
    "nfaTotal": 6115.5,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.7,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.04,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8510,
    "gfaFloors": 7508.5,
    "gfaCirc": 1001.5,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.7,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1501.5,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6570.9,
    "gfaFloors": 5799.4,
    "gfaCirc": 771.5,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.7,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1532.96,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6316.9,
    "gfaFloors": 5575.4,
    "gfaCirc": 741.5,
    "nfaTotal": 4718.4,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.7,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1537.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7885.7,
    "gfaFloors": 6961.9,
    "gfaCirc": 923.8,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.7,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1512.36,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8189.4,
    "gfaFloors": 7225.4,
    "gfaCirc": 964,
    "nfaTotal": 6115.5,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.7,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1504.7,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7631.7,
    "gfaFloors": 6737.9,
    "gfaCirc": 893.8,
    "nfaTotal": 5701.5,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.7,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1515.51,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9506.5,
    "gfaFloors": 8388,
    "gfaCirc": 1118.5,
    "nfaTotal": 7098.6,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.7,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1489.92,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9506.5,
    "gfaFloors": 8388,
    "gfaCirc": 1118.5,
    "nfaTotal": 7098.6,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.7,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1489.3,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9827,
    "gfaFloors": 8671,
    "gfaCirc": 1156,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.7,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1488.85,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9506.5,
    "gfaFloors": 8388,
    "gfaCirc": 1118.5,
    "nfaTotal": 7098.6,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.7,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1490.54,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9664.6,
    "gfaFloors": 8573.7,
    "gfaCirc": 1090.9,
    "nfaTotal": 7223.1,
    "nrStorage": 81.3,
    "nrApartm": 121,
    "nfaGfa": 74.7,
    "volume": 25876.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4519.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1479.95,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9506.5,
    "gfaFloors": 8388,
    "gfaCirc": 1118.5,
    "nfaTotal": 7098.6,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.7,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1491.16,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6311.1,
    "gfaFloors": 5575.4,
    "gfaCirc": 735.7,
    "nfaTotal": 4718.4,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.8,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1536.2,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6565.1,
    "gfaFloors": 5799.4,
    "gfaCirc": 765.7,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.8,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1531.61,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6311.1,
    "gfaFloors": 5575.4,
    "gfaCirc": 735.7,
    "nfaTotal": 4718.4,
    "nrStorage": 53.3,
    "nrApartm": 81,
    "nfaGfa": 74.8,
    "volume": 16307.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3174.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1537.13,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7880,
    "gfaFloors": 6961.9,
    "gfaCirc": 918.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.8,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1511.95,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6565.1,
    "gfaFloors": 5799.4,
    "gfaCirc": 765.7,
    "nfaTotal": 4908.7,
    "nrStorage": 55.5,
    "nrApartm": 84,
    "nfaGfa": 74.8,
    "volume": 16963.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3291.2,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1532.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7116.1,
    "gfaFloors": 6289.9,
    "gfaCirc": 826.2,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.8,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1527.58,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6925.6,
    "gfaFloors": 6121.9,
    "gfaCirc": 803.7,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.8,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1524.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7880,
    "gfaFloors": 6961.9,
    "gfaCirc": 918.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.8,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1511.2,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8421.3,
    "gfaFloors": 7446.3,
    "gfaCirc": 975,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1510.11,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8421.3,
    "gfaFloors": 7446.3,
    "gfaCirc": 975,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1509.41,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9735.4,
    "gfaFloors": 8605.8,
    "gfaCirc": 1129.6,
    "nfaTotal": 7280,
    "nrStorage": 82.3,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1496.61,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8496.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 987.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1501.83,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7626,
    "gfaFloors": 6737.9,
    "gfaCirc": 888.1,
    "nfaTotal": 5701.5,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.8,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1515.09,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7626,
    "gfaFloors": 6737.9,
    "gfaCirc": 888.1,
    "nfaTotal": 5701.5,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.8,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1514.32,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10352.3,
    "gfaFloors": 9155.4,
    "gfaCirc": 1196.9,
    "nfaTotal": 7748.3,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1489.15,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10435.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1217.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1482.01,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9735.4,
    "gfaFloors": 8605.8,
    "gfaCirc": 1129.6,
    "nfaTotal": 7280,
    "nrStorage": 82.3,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1496.01,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9493.5,
    "gfaFloors": 8391,
    "gfaCirc": 1102.5,
    "nfaTotal": 7101.4,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.8,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1491.31,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10435.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1217.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1482.57,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8496.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 987.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1502.52,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8178.7,
    "gfaFloors": 7228.5,
    "gfaCirc": 950.2,
    "nfaTotal": 6118.3,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.8,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1504.49,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7116.1,
    "gfaFloors": 6289.9,
    "gfaCirc": 826.2,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.8,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1528.41,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8178.7,
    "gfaFloors": 7228.5,
    "gfaCirc": 950.2,
    "nfaTotal": 6118.3,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.8,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.21,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9811,
    "gfaFloors": 8671,
    "gfaCirc": 1140,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1490.04,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8496.2,
    "gfaFloors": 7508.5,
    "gfaCirc": 987.7,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1503.21,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10352.3,
    "gfaFloors": 9155.4,
    "gfaCirc": 1196.9,
    "nfaTotal": 7748.3,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1489.72,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8935.1,
    "gfaFloors": 7900.5,
    "gfaCirc": 1034.6,
    "nfaTotal": 6684.6,
    "nrStorage": 75.5,
    "nrApartm": 115,
    "nfaGfa": 74.8,
    "volume": 23108.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4519.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1499.82,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9182.9,
    "gfaFloors": 8118.3,
    "gfaCirc": 1064.6,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.8,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1499.15,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9811,
    "gfaFloors": 8671,
    "gfaCirc": 1140,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1489.44,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9811,
    "gfaFloors": 8671,
    "gfaCirc": 1140,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1490.64,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10427.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1209.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.12,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10427.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1209.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1484.25,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8421.3,
    "gfaFloors": 7446.3,
    "gfaCirc": 975,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1510.81,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10427.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1209.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.69,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10435.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1217.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.13,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8427.1,
    "gfaFloors": 7446.3,
    "gfaCirc": 980.8,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1510.48,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8337.5,
    "gfaFloors": 7411.1,
    "gfaCirc": 926.4,
    "nfaTotal": 6237.1,
    "nrStorage": 70.2,
    "nrApartm": 104,
    "nfaGfa": 74.8,
    "volume": 22475.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3847,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1493.23,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8935.1,
    "gfaFloors": 7900.5,
    "gfaCirc": 1034.6,
    "nfaTotal": 6684.6,
    "nrStorage": 75.5,
    "nrApartm": 115,
    "nfaGfa": 74.8,
    "volume": 23108.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4519.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1500.48,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9182.9,
    "gfaFloors": 8118.3,
    "gfaCirc": 1064.6,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.8,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1498.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7880,
    "gfaFloors": 6961.9,
    "gfaCirc": 918.1,
    "nfaTotal": 5891.8,
    "nrStorage": 66.6,
    "nrApartm": 101,
    "nfaGfa": 74.8,
    "volume": 20363.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3964,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1512.69,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7626,
    "gfaFloors": 6737.9,
    "gfaCirc": 888.1,
    "nfaTotal": 5701.5,
    "nrStorage": 64.4,
    "nrApartm": 98,
    "nfaGfa": 74.8,
    "volume": 19708.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3847,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1515.86,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8178.7,
    "gfaFloors": 7228.5,
    "gfaCirc": 950.2,
    "nfaTotal": 6118.3,
    "nrStorage": 69.1,
    "nrApartm": 105,
    "nfaGfa": 74.8,
    "volume": 21143.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4103.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.92,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9182.9,
    "gfaFloors": 8118.3,
    "gfaCirc": 1064.6,
    "nfaTotal": 6867.1,
    "nrStorage": 77.6,
    "nrApartm": 118,
    "nfaGfa": 74.8,
    "volume": 23764.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4636.7,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1499.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8337.5,
    "gfaFloors": 7411.1,
    "gfaCirc": 926.4,
    "nfaTotal": 6237.1,
    "nrStorage": 70.2,
    "nrApartm": 104,
    "nfaGfa": 74.8,
    "volume": 22475.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3847,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1493.94,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 10051.1,
    "gfaFloors": 8878.5,
    "gfaCirc": 1172.6,
    "nfaTotal": 7515.4,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 74.8,
    "volume": 25978.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5032.2,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.87,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8680.1,
    "gfaFloors": 7723.7,
    "gfaCirc": 956.4,
    "nfaTotal": 6496.3,
    "nrStorage": 73.1,
    "nrApartm": 110,
    "nfaGfa": 74.8,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1502.23,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8242.8,
    "gfaFloors": 7284.5,
    "gfaCirc": 958.3,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 74.8,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1506.49,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8838,
    "gfaFloors": 7905.8,
    "gfaCirc": 932.2,
    "nfaTotal": 6613.6,
    "nrStorage": 74.4,
    "nrApartm": 110,
    "nfaGfa": 74.8,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1485.65,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 10046.2,
    "gfaFloors": 8881.6,
    "gfaCirc": 1164.6,
    "nfaTotal": 7518.2,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 74.8,
    "volume": 25978.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5032.2,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1484.58,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9493.5,
    "gfaFloors": 8391,
    "gfaCirc": 1102.5,
    "nfaTotal": 7101.4,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.8,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1491.93,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 10051.1,
    "gfaFloors": 8878.5,
    "gfaCirc": 1172.6,
    "nfaTotal": 7515.4,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 74.8,
    "volume": 25978.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5032.2,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1484.45,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 10051.1,
    "gfaFloors": 8878.5,
    "gfaCirc": 1172.6,
    "nfaTotal": 7515.4,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 74.8,
    "volume": 25978.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5032.2,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1485.04,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10435.2,
    "gfaFloors": 9217.6,
    "gfaCirc": 1217.6,
    "nfaTotal": 7803.7,
    "nrStorage": 88.2,
    "nrApartm": 134,
    "nfaGfa": 74.8,
    "volume": 26961.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5207.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.69,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9554.1,
    "gfaFloors": 8447,
    "gfaCirc": 1107.1,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 74.8,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1491.83,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 10046.2,
    "gfaFloors": 8881.6,
    "gfaCirc": 1164.6,
    "nfaTotal": 7518.2,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 74.8,
    "volume": 25978.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5032.2,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1485.16,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9493.5,
    "gfaFloors": 8391,
    "gfaCirc": 1102.5,
    "nfaTotal": 7101.4,
    "nrStorage": 80.2,
    "nrApartm": 122,
    "nfaGfa": 74.8,
    "volume": 24543.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4775.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1492.55,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9811,
    "gfaFloors": 8671,
    "gfaCirc": 1140,
    "nfaTotal": 7339.3,
    "nrStorage": 82.9,
    "nrApartm": 126,
    "nfaGfa": 74.8,
    "volume": 25362.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4922.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1491.24,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6925.6,
    "gfaFloors": 6121.9,
    "gfaCirc": 803.7,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.8,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1525.45,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8501.9,
    "gfaFloors": 7508.5,
    "gfaCirc": 993.4,
    "nfaTotal": 6356.2,
    "nrStorage": 71.8,
    "nrApartm": 109,
    "nfaGfa": 74.8,
    "volume": 21962.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4249.4,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1502.91,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9554.1,
    "gfaFloors": 8447,
    "gfaCirc": 1107.1,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 74.8,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1493.06,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.6,
    "gfaTotal": 10921.2,
    "gfaFloors": 9776.3,
    "gfaCirc": 1144.9,
    "nfaTotal": 8183.3,
    "nrStorage": 92.1,
    "nrApartm": 136,
    "nfaGfa": 74.9,
    "volume": 30314.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4818.1,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1462.55,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8832.2,
    "gfaFloors": 7905.8,
    "gfaCirc": 926.4,
    "nfaTotal": 6613.6,
    "nrStorage": 74.4,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1485.28,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 6981.5,
    "gfaFloors": 6227.6,
    "gfaCirc": 753.9,
    "nfaTotal": 5229.3,
    "nrStorage": 58.8,
    "nrApartm": 87,
    "nfaGfa": 74.9,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1519.24,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8832.2,
    "gfaFloors": 7905.8,
    "gfaCirc": 926.4,
    "nfaTotal": 6613.6,
    "nrStorage": 74.4,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1484.62,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7287.7,
    "gfaFloors": 6525.8,
    "gfaCirc": 761.9,
    "nfaTotal": 5462,
    "nrStorage": 61.5,
    "nrApartm": 91,
    "nfaGfa": 74.9,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1512.21,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7110.3,
    "gfaFloors": 6289.9,
    "gfaCirc": 820.4,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.9,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1527.16,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6919.8,
    "gfaFloors": 6121.9,
    "gfaCirc": 797.9,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1524.16,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7110.3,
    "gfaFloors": 6289.9,
    "gfaCirc": 820.4,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.9,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1527.98,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6919.8,
    "gfaFloors": 6121.9,
    "gfaCirc": 797.9,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1525.01,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7672.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 878.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 74.9,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1511.31,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7799.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 893.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 74.9,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1520.5,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8674.3,
    "gfaFloors": 7723.7,
    "gfaCirc": 950.6,
    "nfaTotal": 6496.3,
    "nrStorage": 73.1,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1501.19,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7672.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 878.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 74.9,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1512.07,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8674.3,
    "gfaFloors": 7723.7,
    "gfaCirc": 950.6,
    "nfaTotal": 6496.3,
    "nrStorage": 73.1,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1501.86,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7799.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 893.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 74.9,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1521.25,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9110.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1048.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 74.9,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1505.7,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7799.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 893.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 74.9,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1522.01,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7353,
    "gfaFloors": 6561.1,
    "gfaCirc": 791.9,
    "nfaTotal": 5510.3,
    "nrStorage": 62,
    "nrApartm": 93,
    "nfaGfa": 74.9,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1520.5,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8787.3,
    "gfaFloors": 7775,
    "gfaCirc": 1012.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 74.9,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1497.22,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7108,
    "gfaFloors": 6289.9,
    "gfaCirc": 818.1,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.9,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1529.3,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9041.3,
    "gfaFloors": 7999,
    "gfaCirc": 1042.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 74.9,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1500.14,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9110.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1048.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 74.9,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1505.06,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9041.3,
    "gfaFloors": 7999,
    "gfaCirc": 1042.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 74.9,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1500.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9041.3,
    "gfaFloors": 7999,
    "gfaCirc": 1042.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 74.9,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1501.44,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6917.5,
    "gfaFloors": 6121.9,
    "gfaCirc": 795.6,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1526.35,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8674.3,
    "gfaFloors": 7723.7,
    "gfaCirc": 950.6,
    "nfaTotal": 6496.3,
    "nrStorage": 73.1,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1502.54,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9730.8,
    "gfaFloors": 8615,
    "gfaCirc": 1115.8,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 74.9,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1496.73,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7799.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 893.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 74.9,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1522.76,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8294.7,
    "gfaFloors": 7390.1,
    "gfaCirc": 904.6,
    "nfaTotal": 6215.3,
    "nrStorage": 70,
    "nrApartm": 104,
    "nfaGfa": 74.9,
    "volume": 22475.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3847,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1501.45,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9047.1,
    "gfaFloors": 7999,
    "gfaCirc": 1048.1,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 74.9,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1501.14,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8237,
    "gfaFloors": 7284.5,
    "gfaCirc": 952.5,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 74.9,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.39,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 6981.5,
    "gfaFloors": 6227.6,
    "gfaCirc": 753.9,
    "nfaTotal": 5229.3,
    "nrStorage": 58.8,
    "nrApartm": 87,
    "nfaGfa": 74.9,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1520.09,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8237,
    "gfaFloors": 7284.5,
    "gfaCirc": 952.5,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 74.9,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1506.11,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8787.3,
    "gfaFloors": 7775,
    "gfaCirc": 1012.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 74.9,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1497.89,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8294.7,
    "gfaFloors": 7390.1,
    "gfaCirc": 904.6,
    "nfaTotal": 6215.3,
    "nrStorage": 70,
    "nrApartm": 104,
    "nfaGfa": 74.9,
    "volume": 22475.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3847,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1500.74,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7108,
    "gfaFloors": 6289.9,
    "gfaCirc": 818.1,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 74.9,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1530.12,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9110.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1048.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 74.9,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1506.35,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9730.8,
    "gfaFloors": 8615,
    "gfaCirc": 1115.8,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 74.9,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1497.94,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7353,
    "gfaFloors": 6561.1,
    "gfaCirc": 791.9,
    "nfaTotal": 5510.3,
    "nrStorage": 62,
    "nrApartm": 93,
    "nfaGfa": 74.9,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1521.3,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7672.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 878.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 74.9,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1512.84,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8989.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1033.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 74.9,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1496.04,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8237,
    "gfaFloors": 7284.5,
    "gfaCirc": 952.5,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 74.9,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1506.82,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8832.2,
    "gfaFloors": 7905.8,
    "gfaCirc": 926.4,
    "nfaTotal": 6613.6,
    "nrStorage": 74.4,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1485.95,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6917.5,
    "gfaFloors": 6121.9,
    "gfaCirc": 795.6,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1527.2,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9540.3,
    "gfaFloors": 8447,
    "gfaCirc": 1093.3,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 122,
    "nfaGfa": 74.9,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1489.64,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8989.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1033.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 74.9,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1496.69,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8226.7,
    "gfaFloors": 7284.5,
    "gfaCirc": 942.2,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 74.9,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1509.39,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8989.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1033.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 74.9,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1497.35,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9110.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1048.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 74.9,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1506.99,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9540.3,
    "gfaFloors": 8447,
    "gfaCirc": 1093.3,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 122,
    "nfaGfa": 74.9,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1489.03,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8787.3,
    "gfaFloors": 7775,
    "gfaCirc": 1012.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 74.9,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1498.55,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9231.8,
    "gfaFloors": 8275.4,
    "gfaCirc": 956.4,
    "nfaTotal": 6917.5,
    "nrStorage": 77.9,
    "nrApartm": 118,
    "nfaGfa": 74.9,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1498.35,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9540.3,
    "gfaFloors": 8447,
    "gfaCirc": 1093.3,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 122,
    "nfaGfa": 74.9,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1490.26,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10119,
    "gfaFloors": 9043.5,
    "gfaCirc": 1075.5,
    "nfaTotal": 7580.2,
    "nrStorage": 85.3,
    "nrApartm": 125,
    "nfaGfa": 74.9,
    "volume": 27884.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1467.58,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.6,
    "gfaTotal": 10900.6,
    "gfaFloors": 9751.5,
    "gfaCirc": 1149.1,
    "nfaTotal": 8163.9,
    "nrStorage": 91.9,
    "nrApartm": 135,
    "nfaGfa": 74.9,
    "volume": 30242.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1459.08,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.5,
    "gfaTotal": 10655,
    "gfaFloors": 9428.2,
    "gfaCirc": 1226.8,
    "nfaTotal": 7982.6,
    "nrStorage": 90.2,
    "nrApartm": 137,
    "nfaGfa": 74.9,
    "volume": 27577.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5317.7,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1479.7,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7321,
    "gfaFloors": 6537.1,
    "gfaCirc": 783.9,
    "nfaTotal": 5486.3,
    "nrStorage": 61.7,
    "nrApartm": 93,
    "nfaGfa": 74.9,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1527.72,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7321,
    "gfaFloors": 6537.1,
    "gfaCirc": 783.9,
    "nfaTotal": 5486.3,
    "nrStorage": 61.7,
    "nrApartm": 93,
    "nfaGfa": 74.9,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1526.92,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8639.9,
    "gfaFloors": 7699.6,
    "gfaCirc": 940.3,
    "nfaTotal": 6472.3,
    "nrStorage": 72.9,
    "nrApartm": 110,
    "nfaGfa": 74.9,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1509.04,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9708.3,
    "gfaFloors": 8632.8,
    "gfaCirc": 1075.5,
    "nfaTotal": 7273.6,
    "nrStorage": 81.9,
    "nrApartm": 121,
    "nfaGfa": 74.9,
    "volume": 26040,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1478.83,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10104.5,
    "gfaFloors": 8937.6,
    "gfaCirc": 1166.9,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 74.9,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1486.02,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9356.2,
    "gfaFloors": 8371.5,
    "gfaCirc": 984.7,
    "nfaTotal": 7012.3,
    "nrStorage": 78.9,
    "nrApartm": 116,
    "nfaGfa": 74.9,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1475.05,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10104.5,
    "gfaFloors": 8937.6,
    "gfaCirc": 1166.9,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 74.9,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1485.43,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8294.7,
    "gfaFloors": 7390.1,
    "gfaCirc": 904.6,
    "nfaTotal": 6215.3,
    "nrStorage": 70,
    "nrApartm": 104,
    "nfaGfa": 74.9,
    "volume": 22475.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3847,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1502.16,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.5,
    "gfaTotal": 10655,
    "gfaFloors": 9428.2,
    "gfaCirc": 1226.8,
    "nfaTotal": 7982.6,
    "nrStorage": 90.2,
    "nrApartm": 137,
    "nfaGfa": 74.9,
    "volume": 27577.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5317.7,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1480.25,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 10972.5,
    "gfaFloors": 9708.2,
    "gfaCirc": 1264.3,
    "nfaTotal": 8220.5,
    "nrStorage": 92.9,
    "nrApartm": 142,
    "nfaGfa": 74.9,
    "volume": 28396.4,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5463.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1482.63,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9039.1,
    "gfaFloors": 7999,
    "gfaCirc": 1040.1,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 74.9,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1502.45,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9356.2,
    "gfaFloors": 8371.5,
    "gfaCirc": 984.7,
    "nfaTotal": 7012.3,
    "nrStorage": 78.9,
    "nrApartm": 116,
    "nfaGfa": 74.9,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1475.68,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9730.8,
    "gfaFloors": 8615,
    "gfaCirc": 1115.8,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 74.9,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1498.54,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9730.8,
    "gfaFloors": 8615,
    "gfaCirc": 1115.8,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 74.9,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1497.34,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 10972.5,
    "gfaFloors": 9708.2,
    "gfaCirc": 1264.3,
    "nfaTotal": 8220.5,
    "nrStorage": 92.9,
    "nrApartm": 142,
    "nfaGfa": 74.9,
    "volume": 28396.4,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5463.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1483.17,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9356.2,
    "gfaFloors": 8371.5,
    "gfaCirc": 984.7,
    "nfaTotal": 7012.3,
    "nrStorage": 78.9,
    "nrApartm": 116,
    "nfaGfa": 74.9,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1476.31,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10119,
    "gfaFloors": 9043.5,
    "gfaCirc": 1075.5,
    "nfaTotal": 7580.2,
    "nrStorage": 85.3,
    "nrApartm": 125,
    "nfaGfa": 74.9,
    "volume": 27884.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1468.16,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9361.9,
    "gfaFloors": 8371.5,
    "gfaCirc": 990.4,
    "nfaTotal": 7012.3,
    "nrStorage": 78.9,
    "nrApartm": 116,
    "nfaGfa": 74.9,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1476.05,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8793.1,
    "gfaFloors": 7775,
    "gfaCirc": 1018.1,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 74.9,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1498.25,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9226,
    "gfaFloors": 8275.4,
    "gfaCirc": 950.6,
    "nfaTotal": 6917.5,
    "nrStorage": 77.9,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1498.64,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 6975.7,
    "gfaFloors": 6227.6,
    "gfaCirc": 748.1,
    "nfaTotal": 5229.3,
    "nrStorage": 58.8,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1519.65,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7624.2,
    "gfaFloors": 6798.2,
    "gfaCirc": 826,
    "nfaTotal": 5719.7,
    "nrStorage": 64.4,
    "nrApartm": 95,
    "nfaGfa": 75,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1503.12,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 9064.8,
    "gfaFloors": 8098.1,
    "gfaCirc": 966.7,
    "nfaTotal": 6799,
    "nrStorage": 76.5,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24833,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4103.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1485.61,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8381.2,
    "gfaFloors": 7470.2,
    "gfaCirc": 911,
    "nfaTotal": 6287.6,
    "nrStorage": 70.8,
    "nrApartm": 104,
    "nfaGfa": 75,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1491.86,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7811.7,
    "gfaFloors": 6991.5,
    "gfaCirc": 820.2,
    "nfaTotal": 5860.7,
    "nrStorage": 66,
    "nrApartm": 97,
    "nfaGfa": 75,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1497.41,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8407.9,
    "gfaFloors": 7452.5,
    "gfaCirc": 955.4,
    "nfaTotal": 6308.6,
    "nrStorage": 71.3,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1512.52,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7281.9,
    "gfaFloors": 6525.8,
    "gfaCirc": 756.1,
    "nfaTotal": 5462,
    "nrStorage": 61.5,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1512.59,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7664.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 870.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1513.63,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7102.3,
    "gfaFloors": 6289.9,
    "gfaCirc": 812.4,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1529.68,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9226,
    "gfaFloors": 8275.4,
    "gfaCirc": 950.6,
    "nfaTotal": 6917.5,
    "nrStorage": 77.9,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1498.01,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.7,
    "percBuilt": 0.5,
    "gfaTotal": 5602.7,
    "gfaFloors": 4959.4,
    "gfaCirc": 643.3,
    "nfaTotal": 4199.7,
    "nrStorage": 47.5,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14506.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 2786.9,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1551.71,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.7,
    "percBuilt": 0.5,
    "gfaTotal": 5602.7,
    "gfaFloors": 4959.4,
    "gfaCirc": 643.3,
    "nfaTotal": 4199.7,
    "nrStorage": 47.5,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14506.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 2786.9,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1552.75,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7811.7,
    "gfaFloors": 6991.5,
    "gfaCirc": 820.2,
    "nfaTotal": 5860.7,
    "nrStorage": 66,
    "nrApartm": 97,
    "nfaGfa": 75,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1498.16,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7315.2,
    "gfaFloors": 6537.1,
    "gfaCirc": 778.1,
    "nfaTotal": 5486.3,
    "nrStorage": 61.7,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1526.51,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7004.7,
    "gfaFloors": 6248.6,
    "gfaCirc": 756.1,
    "nfaTotal": 5251.1,
    "nrStorage": 59.1,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1513.59,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7281.9,
    "gfaFloors": 6525.8,
    "gfaCirc": 756.1,
    "nfaTotal": 5462,
    "nrStorage": 61.5,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1511.78,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6911.8,
    "gfaFloors": 6121.9,
    "gfaCirc": 789.9,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 75,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1525.89,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7470.2,
    "gfaFloors": 6612.5,
    "gfaCirc": 857.7,
    "nfaTotal": 5599.6,
    "nrStorage": 63.3,
    "nrApartm": 96,
    "nfaGfa": 75,
    "volume": 19341.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3715.9,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1513.92,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.8,
    "percBuilt": 0.52,
    "gfaTotal": 5793.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 665.8,
    "nfaTotal": 4342.4,
    "nrStorage": 49.1,
    "nrApartm": 75,
    "nfaGfa": 75,
    "volume": 14997.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 2874.7,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1555.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8574.5,
    "gfaFloors": 7663.5,
    "gfaCirc": 911,
    "nfaTotal": 6428.6,
    "nrStorage": 72.3,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1486.56,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7347.2,
    "gfaFloors": 6561.1,
    "gfaCirc": 786.1,
    "nfaTotal": 5510.3,
    "nrStorage": 62,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1520.09,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7664.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 870.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1512.86,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.52,
    "gfaTotal": 7724.2,
    "gfaFloors": 6836.5,
    "gfaCirc": 887.7,
    "nfaTotal": 5789.9,
    "nrStorage": 65.4,
    "nrApartm": 100,
    "nfaGfa": 75,
    "volume": 19996.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3832.9,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1516.03,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.8,
    "percBuilt": 0.52,
    "gfaTotal": 5793.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 665.8,
    "nfaTotal": 4342.4,
    "nrStorage": 49.1,
    "nrApartm": 75,
    "nfaGfa": 75,
    "volume": 14997.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 2874.7,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1554.49,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7102.3,
    "gfaFloors": 6289.9,
    "gfaCirc": 812.4,
    "nfaTotal": 5325.5,
    "nrStorage": 60.2,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 18398,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3547.4,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1528.85,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8220.9,
    "gfaFloors": 7284.5,
    "gfaCirc": 936.4,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1508.3,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 6975.7,
    "gfaFloors": 6227.6,
    "gfaCirc": 748.1,
    "nfaTotal": 5229.3,
    "nrStorage": 58.8,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1518.8,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9138.3,
    "gfaFloors": 8142.2,
    "gfaCirc": 996.1,
    "nfaTotal": 6855.5,
    "nrStorage": 77.2,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24605.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1483.09,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7817.5,
    "gfaFloors": 6991.5,
    "gfaCirc": 826,
    "nfaTotal": 5860.7,
    "nrStorage": 66,
    "nrApartm": 97,
    "nfaGfa": 75,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1497.82,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7791.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 885.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 75,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1522.04,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7004.7,
    "gfaFloors": 6248.6,
    "gfaCirc": 756.1,
    "nfaTotal": 5251.1,
    "nrStorage": 59.1,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 19075.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3174.2,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1512.75,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.76,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8419.9,
    "gfaFloors": 7465.9,
    "gfaCirc": 954,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1502.49,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7347.2,
    "gfaFloors": 6561.1,
    "gfaCirc": 786.1,
    "nfaTotal": 5510.3,
    "nrStorage": 62,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1520.89,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8407.9,
    "gfaFloors": 7452.5,
    "gfaCirc": 955.4,
    "nfaTotal": 6308.6,
    "nrStorage": 71.3,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1511.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7470.2,
    "gfaFloors": 6612.5,
    "gfaCirc": 857.7,
    "nfaTotal": 5599.6,
    "nrStorage": 63.3,
    "nrApartm": 96,
    "nfaGfa": 75,
    "volume": 19341.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3715.9,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1513.13,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.8,
    "percBuilt": 0.52,
    "gfaTotal": 5793.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 665.8,
    "nfaTotal": 4342.4,
    "nrStorage": 49.1,
    "nrApartm": 75,
    "nfaGfa": 75,
    "volume": 14997.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 2874.7,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1556.52,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7658.8,
    "gfaFloors": 6866.9,
    "gfaCirc": 791.9,
    "nfaTotal": 5743.8,
    "nrStorage": 64.7,
    "nrApartm": 98,
    "nfaGfa": 75,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1522.14,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9226,
    "gfaFloors": 8275.4,
    "gfaCirc": 950.6,
    "nfaTotal": 6917.5,
    "nrStorage": 77.9,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1497.37,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.52,
    "gfaTotal": 7724.2,
    "gfaFloors": 6836.5,
    "gfaCirc": 887.7,
    "nfaTotal": 5789.9,
    "nrStorage": 65.4,
    "nrApartm": 100,
    "nfaGfa": 75,
    "volume": 19996.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3832.9,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1516.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.7,
    "percBuilt": 0.5,
    "gfaTotal": 5602.7,
    "gfaFloors": 4959.4,
    "gfaCirc": 643.3,
    "nfaTotal": 4199.7,
    "nrStorage": 47.5,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14506.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 2786.9,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1553.8,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7791.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 885.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 75,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1522.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.52,
    "gfaTotal": 7724.2,
    "gfaFloors": 6836.5,
    "gfaCirc": 887.7,
    "nfaTotal": 5789.9,
    "nrStorage": 65.4,
    "nrApartm": 100,
    "nfaGfa": 75,
    "volume": 19996.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3832.9,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1517.55,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.7,
    "percBuilt": 0.5,
    "gfaTotal": 5602.7,
    "gfaFloors": 4959.4,
    "gfaCirc": 643.3,
    "nfaTotal": 4199.7,
    "nrStorage": 47.5,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14506.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 2786.9,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1554.85,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9033.3,
    "gfaFloors": 7999,
    "gfaCirc": 1034.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1501.45,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10863.5,
    "gfaFloors": 9686.8,
    "gfaCirc": 1176.7,
    "nfaTotal": 8143.8,
    "nrStorage": 91.7,
    "nrApartm": 138,
    "nfaGfa": 75,
    "volume": 29473.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4922.2,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1476.76,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7315.2,
    "gfaFloors": 6537.1,
    "gfaCirc": 778.1,
    "nfaTotal": 5486.3,
    "nrStorage": 61.7,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 20046.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3291.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1527.31,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8217.4,
    "gfaFloors": 7284.5,
    "gfaCirc": 932.9,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 105,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.93,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7791.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 885.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 75,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1523.55,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8405.2,
    "gfaFloors": 7446.3,
    "gfaCirc": 958.9,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1512.26,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9722.7,
    "gfaFloors": 8615,
    "gfaCirc": 1107.7,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1497.96,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9094.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1032.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1507.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8217.4,
    "gfaFloors": 7284.5,
    "gfaCirc": 932.9,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 105,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.21,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8779.3,
    "gfaFloors": 7775,
    "gfaCirc": 1004.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1498.56,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8483.4,
    "gfaFloors": 7521.9,
    "gfaCirc": 961.5,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1515.96,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7470.2,
    "gfaFloors": 6612.5,
    "gfaCirc": 857.7,
    "nfaTotal": 5599.6,
    "nrStorage": 63.3,
    "nrApartm": 96,
    "nfaGfa": 75,
    "volume": 19341.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3715.9,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1514.7,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9655.3,
    "gfaFloors": 8545.6,
    "gfaCirc": 1109.7,
    "nfaTotal": 7237.4,
    "nrStorage": 81.8,
    "nrApartm": 125,
    "nfaGfa": 75,
    "volume": 24995.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4791.1,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1492.97,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9337.8,
    "gfaFloors": 8265.6,
    "gfaCirc": 1072.2,
    "nfaTotal": 6999.5,
    "nrStorage": 79.1,
    "nrApartm": 120,
    "nfaGfa": 75,
    "volume": 24176.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4644.9,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1489.97,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8217.4,
    "gfaFloors": 7284.5,
    "gfaCirc": 932.9,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 105,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1504.64,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9655.3,
    "gfaFloors": 8545.6,
    "gfaCirc": 1109.7,
    "nfaTotal": 7237.4,
    "nrStorage": 81.8,
    "nrApartm": 125,
    "nfaGfa": 75,
    "volume": 24995.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4791.1,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1493.58,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9542.2,
    "gfaFloors": 8524.2,
    "gfaCirc": 1018,
    "nfaTotal": 7157.7,
    "nrStorage": 80.6,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1488.88,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9722.7,
    "gfaFloors": 8615,
    "gfaCirc": 1107.7,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1498.56,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8220.9,
    "gfaFloors": 7284.5,
    "gfaCirc": 936.4,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1509.01,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8483.4,
    "gfaFloors": 7521.9,
    "gfaCirc": 961.5,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1516.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9033.3,
    "gfaFloors": 7999,
    "gfaCirc": 1034.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1502.1,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8405.2,
    "gfaFloors": 7446.3,
    "gfaCirc": 958.9,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1512.96,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10338.9,
    "gfaFloors": 9161.6,
    "gfaCirc": 1177.3,
    "nfaTotal": 7756.1,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1491.62,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10338.9,
    "gfaFloors": 9161.6,
    "gfaCirc": 1177.3,
    "nfaTotal": 7756.1,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1491.05,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11407.1,
    "gfaFloors": 10230.4,
    "gfaCirc": 1176.7,
    "nfaTotal": 8556.9,
    "nrStorage": 96.3,
    "nrApartm": 146,
    "nfaGfa": 75,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1475.83,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8419.9,
    "gfaFloors": 7465.9,
    "gfaCirc": 954,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1501.79,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10863.5,
    "gfaFloors": 9686.8,
    "gfaCirc": 1176.7,
    "nfaTotal": 8143.8,
    "nrStorage": 91.7,
    "nrApartm": 138,
    "nfaGfa": 75,
    "volume": 29473.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4922.2,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1477.3,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9532.2,
    "gfaFloors": 8447,
    "gfaCirc": 1085.2,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 122,
    "nfaGfa": 75,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1490.27,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9331.6,
    "gfaFloors": 8335.5,
    "gfaCirc": 996.1,
    "nfaTotal": 6996.5,
    "nrStorage": 78.7,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 25527.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1478.4,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8407.9,
    "gfaFloors": 7452.5,
    "gfaCirc": 955.4,
    "nfaTotal": 6308.6,
    "nrStorage": 71.3,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1513.21,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 9064.8,
    "gfaFloors": 8098.1,
    "gfaCirc": 966.7,
    "nfaTotal": 6799,
    "nrStorage": 76.5,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24833,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4103.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1484.32,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9138.3,
    "gfaFloors": 8142.2,
    "gfaCirc": 996.1,
    "nfaTotal": 6855.5,
    "nrStorage": 77.2,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24605.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1482.45,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10815.3,
    "gfaFloors": 9654.7,
    "gfaCirc": 1160.6,
    "nfaTotal": 8111.8,
    "nrStorage": 91.3,
    "nrApartm": 138,
    "nfaGfa": 75,
    "volume": 29473.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4922.2,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1484.21,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.8,
    "percBuilt": 0.52,
    "gfaTotal": 5793.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 665.8,
    "nfaTotal": 4342.4,
    "nrStorage": 49.1,
    "nrApartm": 75,
    "nfaGfa": 75,
    "volume": 14997.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 2874.7,
    "surToVol": 0.306,
    "nrBuildings": 3,
    "costM2": 1557.53,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.52,
    "gfaTotal": 9033.3,
    "gfaFloors": 7999,
    "gfaCirc": 1034.3,
    "nfaTotal": 6773,
    "nrStorage": 76.5,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 23397.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4505.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1502.75,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8483.4,
    "gfaFloors": 7521.9,
    "gfaCirc": 961.5,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1518.04,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10088.5,
    "gfaFloors": 8937.6,
    "gfaCirc": 1150.9,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 75,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1487.75,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6911.8,
    "gfaFloors": 6121.9,
    "gfaCirc": 789.9,
    "nfaTotal": 5182.8,
    "nrStorage": 58.6,
    "nrApartm": 89,
    "nfaGfa": 75,
    "volume": 17906.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3459.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1526.74,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8483.4,
    "gfaFloors": 7521.9,
    "gfaCirc": 961.5,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1517.35,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7791.7,
    "gfaFloors": 6905.9,
    "gfaCirc": 885.8,
    "nfaTotal": 5844.2,
    "nrStorage": 66,
    "nrApartm": 101,
    "nfaGfa": 75,
    "volume": 20199.8,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3934.7,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1524.3,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8779.3,
    "gfaFloors": 7775,
    "gfaCirc": 1004.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1499.23,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8779.3,
    "gfaFloors": 7775,
    "gfaCirc": 1004.3,
    "nfaTotal": 6582.7,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 22742,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4388.7,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1499.9,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8220.9,
    "gfaFloors": 7284.5,
    "gfaCirc": 936.4,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1509.72,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9532.2,
    "gfaFloors": 8447,
    "gfaCirc": 1085.2,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 122,
    "nfaGfa": 75,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1490.89,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 10964.4,
    "gfaFloors": 9708.2,
    "gfaCirc": 1256.2,
    "nfaTotal": 8220.5,
    "nrStorage": 92.9,
    "nrApartm": 142,
    "nfaGfa": 75,
    "volume": 28396.4,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5463.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1484.78,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10336.3,
    "gfaFloors": 9155.4,
    "gfaCirc": 1180.9,
    "nfaTotal": 7748.3,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1492.56,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8405.2,
    "gfaFloors": 7446.3,
    "gfaCirc": 958.9,
    "nfaTotal": 6300.8,
    "nrStorage": 71.2,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1513.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9496.3,
    "gfaFloors": 8492.1,
    "gfaCirc": 1004.2,
    "nfaTotal": 7125.8,
    "nrStorage": 80.2,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1495.21,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10336.3,
    "gfaFloors": 9155.4,
    "gfaCirc": 1180.9,
    "nfaTotal": 7748.3,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1491.99,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9722.7,
    "gfaFloors": 8615,
    "gfaCirc": 1107.7,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1499.77,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.52,
    "gfaTotal": 7724.2,
    "gfaFloors": 6836.5,
    "gfaCirc": 887.7,
    "nfaTotal": 5789.9,
    "nrStorage": 65.4,
    "nrApartm": 100,
    "nfaGfa": 75,
    "volume": 19996.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3832.9,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1518.31,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9371.1,
    "gfaFloors": 8396.4,
    "gfaCirc": 974.7,
    "nfaTotal": 7031.7,
    "nrStorage": 79.1,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 25991.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4145.3,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1479.94,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9722.7,
    "gfaFloors": 8615,
    "gfaCirc": 1107.7,
    "nfaTotal": 7291.7,
    "nrStorage": 82.4,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1499.17,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7664.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 870.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1514.39,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8634.2,
    "gfaFloors": 7699.6,
    "gfaCirc": 934.6,
    "nfaTotal": 6472.3,
    "nrStorage": 72.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1508.66,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9711.3,
    "gfaFloors": 8605.8,
    "gfaCirc": 1105.5,
    "nfaTotal": 7280,
    "nrStorage": 82.3,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1499.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9496.3,
    "gfaFloors": 8492.1,
    "gfaCirc": 1004.2,
    "nfaTotal": 7125.8,
    "nrStorage": 80.2,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1493.97,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9094.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1032.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1508.31,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10336.3,
    "gfaFloors": 9155.4,
    "gfaCirc": 1180.9,
    "nfaTotal": 7748.3,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1491.42,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9839.9,
    "gfaFloors": 8821.9,
    "gfaCirc": 1018,
    "nfaTotal": 7383.3,
    "nrStorage": 83.1,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1492.28,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9607.9,
    "gfaFloors": 8552.7,
    "gfaCirc": 1055.2,
    "nfaTotal": 7201.3,
    "nrStorage": 81.1,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 25876.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4519.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1488.48,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8419.9,
    "gfaFloors": 7465.9,
    "gfaCirc": 954,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.18,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8634.2,
    "gfaFloors": 7699.6,
    "gfaCirc": 934.6,
    "nfaTotal": 6472.3,
    "nrStorage": 72.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1507.98,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 9064.8,
    "gfaFloors": 8098.1,
    "gfaCirc": 966.7,
    "nfaTotal": 6799,
    "nrStorage": 76.5,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24833,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4103.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1484.97,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9530,
    "gfaFloors": 8447,
    "gfaCirc": 1083,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 75,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1495.54,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9337.8,
    "gfaFloors": 8265.6,
    "gfaCirc": 1072.2,
    "nfaTotal": 6999.5,
    "nrStorage": 79.1,
    "nrApartm": 120,
    "nfaGfa": 75,
    "volume": 24176.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4644.9,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1490.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9496.3,
    "gfaFloors": 8492.1,
    "gfaCirc": 1004.2,
    "nfaTotal": 7125.8,
    "nrStorage": 80.2,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1494.59,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9607.9,
    "gfaFloors": 8552.7,
    "gfaCirc": 1055.2,
    "nfaTotal": 7201.3,
    "nrStorage": 81.1,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 25876.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4519.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1489.09,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8574.5,
    "gfaFloors": 7663.5,
    "gfaCirc": 911,
    "nfaTotal": 6428.6,
    "nrStorage": 72.3,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1487.24,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7470.2,
    "gfaFloors": 6612.5,
    "gfaCirc": 857.7,
    "nfaTotal": 5599.6,
    "nrStorage": 63.3,
    "nrApartm": 96,
    "nfaGfa": 75,
    "volume": 19341.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3715.9,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1515.49,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10084.9,
    "gfaFloors": 8937.6,
    "gfaCirc": 1147.3,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 129,
    "nfaGfa": 75,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1484.2,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8574.5,
    "gfaFloors": 7663.5,
    "gfaCirc": 911,
    "nfaTotal": 6428.6,
    "nrStorage": 72.3,
    "nrApartm": 106,
    "nfaGfa": 75,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1487.93,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9331.6,
    "gfaFloors": 8335.5,
    "gfaCirc": 996.1,
    "nfaTotal": 6996.5,
    "nrStorage": 78.7,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 25527.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1479.03,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9371.1,
    "gfaFloors": 8396.4,
    "gfaCirc": 974.7,
    "nfaTotal": 7031.7,
    "nrStorage": 79.1,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 25991.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4145.3,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1481.19,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9337.8,
    "gfaFloors": 8265.6,
    "gfaCirc": 1072.2,
    "nfaTotal": 6999.5,
    "nrStorage": 79.1,
    "nrApartm": 120,
    "nfaGfa": 75,
    "volume": 24176.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4644.9,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1491.23,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10084.9,
    "gfaFloors": 8937.6,
    "gfaCirc": 1147.3,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 129,
    "nfaGfa": 75,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1483.62,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8973.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1017.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1498.66,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9900.8,
    "gfaFloors": 8862.1,
    "gfaCirc": 1038.7,
    "nfaTotal": 7430.4,
    "nrStorage": 83.6,
    "nrApartm": 123,
    "nfaGfa": 75,
    "volume": 27354.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1470.32,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10383.8,
    "gfaFloors": 9260.7,
    "gfaCirc": 1123.1,
    "nfaTotal": 7785,
    "nrStorage": 87.6,
    "nrApartm": 130,
    "nfaGfa": 75,
    "volume": 28233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4775.9,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1474.8,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10663.7,
    "gfaFloors": 9534.1,
    "gfaCirc": 1129.6,
    "nfaTotal": 7998.3,
    "nrStorage": 90,
    "nrApartm": 132,
    "nfaGfa": 75,
    "volume": 29319.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1464.1,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10663.7,
    "gfaFloors": 9534.1,
    "gfaCirc": 1129.6,
    "nfaTotal": 7998.3,
    "nrStorage": 90,
    "nrApartm": 132,
    "nfaGfa": 75,
    "volume": 29319.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1463.55,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9900.8,
    "gfaFloors": 8862.1,
    "gfaCirc": 1038.7,
    "nfaTotal": 7430.4,
    "nrStorage": 83.6,
    "nrApartm": 123,
    "nfaGfa": 75,
    "volume": 27354.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1471.5,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.5,
    "gfaTotal": 9530,
    "gfaFloors": 8447,
    "gfaCirc": 1083,
    "nfaTotal": 7149,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 75,
    "volume": 24707.6,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4805.2,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1496.15,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.5,
    "gfaTotal": 10646.9,
    "gfaFloors": 9428.2,
    "gfaCirc": 1218.7,
    "nfaTotal": 7982.6,
    "nrStorage": 90.2,
    "nrApartm": 137,
    "nfaGfa": 75,
    "volume": 27577.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5317.7,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1481.35,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8217.4,
    "gfaFloors": 7284.5,
    "gfaCirc": 932.9,
    "nfaTotal": 6165.9,
    "nrStorage": 69.7,
    "nrApartm": 105,
    "nfaGfa": 75,
    "volume": 21307.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4132.4,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.35,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8973.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1017.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1499.32,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9371.1,
    "gfaFloors": 8396.4,
    "gfaCirc": 974.7,
    "nfaTotal": 7031.7,
    "nrStorage": 79.1,
    "nrApartm": 117,
    "nfaGfa": 75,
    "volume": 25991.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4145.3,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1480.57,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8973.9,
    "gfaFloors": 7956.5,
    "gfaCirc": 1017.4,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1499.97,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9607.9,
    "gfaFloors": 8552.7,
    "gfaCirc": 1055.2,
    "nfaTotal": 7201.3,
    "nrStorage": 81.1,
    "nrApartm": 121,
    "nfaGfa": 75,
    "volume": 25876.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4519.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1489.7,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8634.2,
    "gfaFloors": 7699.6,
    "gfaCirc": 934.6,
    "nfaTotal": 6472.3,
    "nrStorage": 72.9,
    "nrApartm": 110,
    "nfaGfa": 75,
    "volume": 23446.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3964,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1509.34,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.6,
    "gfaTotal": 11153.8,
    "gfaFloors": 9968.6,
    "gfaCirc": 1185.2,
    "nfaTotal": 8368.7,
    "nrStorage": 94.2,
    "nrApartm": 139,
    "nfaGfa": 75,
    "volume": 30590.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5032.2,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1462.76,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10383.8,
    "gfaFloors": 9260.7,
    "gfaCirc": 1123.1,
    "nfaTotal": 7785,
    "nrStorage": 87.6,
    "nrApartm": 130,
    "nfaGfa": 75,
    "volume": 28233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4775.9,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1474.23,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.5,
    "gfaTotal": 10646.9,
    "gfaFloors": 9428.2,
    "gfaCirc": 1218.7,
    "nfaTotal": 7982.6,
    "nrStorage": 90.2,
    "nrApartm": 137,
    "nfaGfa": 75,
    "volume": 27577.4,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5317.7,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1481.91,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9900.8,
    "gfaFloors": 8862.1,
    "gfaCirc": 1038.7,
    "nfaTotal": 7430.4,
    "nrStorage": 83.6,
    "nrApartm": 123,
    "nfaGfa": 75,
    "volume": 27354.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1470.91,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9094.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1032.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1508.95,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9711.3,
    "gfaFloors": 8605.8,
    "gfaCirc": 1105.5,
    "nfaTotal": 7280,
    "nrStorage": 82.3,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1500.87,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.6,
    "gfaTotal": 11445.2,
    "gfaFloors": 10242,
    "gfaCirc": 1203.2,
    "nfaTotal": 8582,
    "nrStorage": 96.6,
    "nrApartm": 142,
    "nfaGfa": 75,
    "volume": 31677,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5061.4,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1455.74,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9711.3,
    "gfaFloors": 8605.8,
    "gfaCirc": 1105.5,
    "nfaTotal": 7280,
    "nrStorage": 82.3,
    "nrApartm": 126,
    "nfaGfa": 75,
    "volume": 25199,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4892.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1500.26,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10815.3,
    "gfaFloors": 9654.7,
    "gfaCirc": 1160.6,
    "nfaTotal": 8111.8,
    "nrStorage": 91.3,
    "nrApartm": 138,
    "nfaGfa": 75,
    "volume": 29473.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4922.2,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1483.66,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.52,
    "gfaTotal": 8407.9,
    "gfaFloors": 7452.5,
    "gfaCirc": 955.4,
    "nfaTotal": 6308.6,
    "nrStorage": 71.3,
    "nrApartm": 109,
    "nfaGfa": 75,
    "volume": 21798.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4220.2,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1513.91,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9655.3,
    "gfaFloors": 8545.6,
    "gfaCirc": 1109.7,
    "nfaTotal": 7237.4,
    "nrStorage": 81.8,
    "nrApartm": 125,
    "nfaGfa": 75,
    "volume": 24995.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4791.1,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1494.19,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7664.7,
    "gfaFloors": 6793.9,
    "gfaCirc": 870.8,
    "nfaTotal": 5749.1,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75,
    "volume": 19872.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3876.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1515.16,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11407.1,
    "gfaFloors": 10230.4,
    "gfaCirc": 1176.7,
    "nfaTotal": 8556.9,
    "nrStorage": 96.3,
    "nrApartm": 146,
    "nfaGfa": 75,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1476.86,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9101.8,
    "gfaFloors": 8127.1,
    "gfaCirc": 974.7,
    "nfaTotal": 6829,
    "nrStorage": 76.9,
    "nrApartm": 113,
    "nfaGfa": 75,
    "volume": 24833,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4103.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1478.52,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10338.9,
    "gfaFloors": 9161.6,
    "gfaCirc": 1177.3,
    "nfaTotal": 7756.1,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1492.76,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11407.1,
    "gfaFloors": 10230.4,
    "gfaCirc": 1176.7,
    "nfaTotal": 8556.9,
    "nrStorage": 96.3,
    "nrApartm": 146,
    "nfaGfa": 75,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1476.35,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.52,
    "gfaTotal": 10338.9,
    "gfaFloors": 9161.6,
    "gfaCirc": 1177.3,
    "nfaTotal": 7756.1,
    "nrStorage": 87.6,
    "nrApartm": 134,
    "nfaGfa": 75,
    "volume": 26797.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5178.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1492.19,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7817.5,
    "gfaFloors": 6991.5,
    "gfaCirc": 826,
    "nfaTotal": 5860.7,
    "nrStorage": 66,
    "nrApartm": 97,
    "nfaGfa": 75,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1498.57,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10815.3,
    "gfaFloors": 9654.7,
    "gfaCirc": 1160.6,
    "nfaTotal": 8111.8,
    "nrStorage": 91.3,
    "nrApartm": 138,
    "nfaGfa": 75,
    "volume": 29473.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4922.2,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1483.12,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8945.5,
    "gfaFloors": 7960.8,
    "gfaCirc": 984.7,
    "nfaTotal": 6705.7,
    "nrStorage": 75.5,
    "nrApartm": 112,
    "nfaGfa": 75,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1486.95,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9094.7,
    "gfaFloors": 8062.3,
    "gfaCirc": 1032.4,
    "nfaTotal": 6819.6,
    "nrStorage": 77,
    "nrApartm": 118,
    "nfaGfa": 75,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1509.6,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.52,
    "gfaTotal": 9655.3,
    "gfaFloors": 8545.6,
    "gfaCirc": 1109.7,
    "nfaTotal": 7237.4,
    "nrStorage": 81.8,
    "nrApartm": 125,
    "nfaGfa": 75,
    "volume": 24995.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4791.1,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1494.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8945.5,
    "gfaFloors": 7960.8,
    "gfaCirc": 984.7,
    "nfaTotal": 6705.7,
    "nrStorage": 75.5,
    "nrApartm": 112,
    "nfaGfa": 75,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1487.61,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.6,
    "gfaTotal": 11445.2,
    "gfaFloors": 10242,
    "gfaCirc": 1203.2,
    "nfaTotal": 8582,
    "nrStorage": 96.6,
    "nrApartm": 142,
    "nfaGfa": 75,
    "volume": 31677,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5061.4,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1456.25,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9331.6,
    "gfaFloors": 8335.5,
    "gfaCirc": 996.1,
    "nfaTotal": 6996.5,
    "nrStorage": 78.7,
    "nrApartm": 115,
    "nfaGfa": 75,
    "volume": 25527.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1479.66,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.5,
    "gfaTotal": 10088.5,
    "gfaFloors": 8937.6,
    "gfaCirc": 1150.9,
    "nfaTotal": 7565.8,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 75,
    "volume": 26142.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5061.4,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1488.33,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7580.5,
    "gfaFloors": 6774.1,
    "gfaCirc": 806.4,
    "nfaTotal": 5695.1,
    "nrStorage": 64.1,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1509.98,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8343.3,
    "gfaFloors": 7446.1,
    "gfaCirc": 897.2,
    "nfaTotal": 6263,
    "nrStorage": 70.5,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1497.71,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6267.3,
    "gfaFloors": 5611.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4709.1,
    "nrStorage": 53,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1532.57,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10445.4,
    "gfaFloors": 9352.6,
    "gfaCirc": 1092.8,
    "nfaTotal": 7848.5,
    "nrStorage": 88.3,
    "nrApartm": 130,
    "nfaGfa": 75.1,
    "volume": 28789,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4644.9,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1466.07,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8893.8,
    "gfaFloors": 7936.7,
    "gfaCirc": 957.1,
    "nfaTotal": 6681.1,
    "nrStorage": 75.2,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1496.67,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.66,
    "gfaTotal": 11240.2,
    "gfaFloors": 10106.1,
    "gfaCirc": 1134.1,
    "nfaTotal": 8443,
    "nrStorage": 94.8,
    "nrApartm": 145,
    "nfaGfa": 75.1,
    "volume": 31990.1,
    "areaGroundFloor": 2187.4,
    "areaRoof": 2187.4,
    "areaFac": 4434.3,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1470.4,
    "category": "GCC0",
    "distCores": 43.5,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8893.8,
    "gfaFloors": 7936.7,
    "gfaCirc": 957.1,
    "nfaTotal": 6681.1,
    "nrStorage": 75.2,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1495.35,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8582.1,
    "gfaFloors": 7667.7,
    "gfaCirc": 914.4,
    "nfaTotal": 6448.6,
    "nrStorage": 72.6,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1511.63,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7580.5,
    "gfaFloors": 6774.1,
    "gfaCirc": 806.4,
    "nfaTotal": 5695.1,
    "nrStorage": 64.1,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1510.75,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8356.3,
    "gfaFloors": 7482.1,
    "gfaCirc": 874.2,
    "nfaTotal": 6278.8,
    "nrStorage": 70.7,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1491.04,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9100.4,
    "gfaFloors": 8118.1,
    "gfaCirc": 982.3,
    "nfaTotal": 6830.9,
    "nrStorage": 76.9,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24605.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1488.42,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6267.3,
    "gfaFloors": 5611.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4709.1,
    "nrStorage": 53,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1531.64,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.5,
    "gfaTotal": 7097,
    "gfaFloors": 6303.4,
    "gfaCirc": 793.6,
    "nfaTotal": 5332.3,
    "nrStorage": 60.2,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18437.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3620,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1520.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8550,
    "gfaFloors": 7643.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6424.6,
    "nrStorage": 72.3,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1517.11,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8356.3,
    "gfaFloors": 7482.1,
    "gfaCirc": 874.2,
    "nfaTotal": 6278.8,
    "nrStorage": 70.7,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1490.33,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8582.1,
    "gfaFloors": 7667.7,
    "gfaCirc": 914.4,
    "nfaTotal": 6448.6,
    "nrStorage": 72.6,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1512.31,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7653,
    "gfaFloors": 6866.9,
    "gfaCirc": 786.1,
    "nfaTotal": 5743.8,
    "nrStorage": 64.7,
    "nrApartm": 98,
    "nfaGfa": 75.1,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1521.74,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6476.9,
    "gfaFloors": 5743.4,
    "gfaCirc": 733.5,
    "nfaTotal": 4861.1,
    "nrStorage": 54.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 16799.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3262,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1544.91,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7618.4,
    "gfaFloors": 6798.2,
    "gfaCirc": 820.2,
    "nfaTotal": 5719.7,
    "nrStorage": 64.4,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1503.48,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.5,
    "gfaFloors": 6359.4,
    "gfaCirc": 801.1,
    "nfaTotal": 5379.9,
    "nrStorage": 60.8,
    "nrApartm": 93,
    "nfaGfa": 75.1,
    "volume": 18601.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3649.2,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1537.22,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6267.3,
    "gfaFloors": 5611.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4709.1,
    "nrStorage": 53,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1530.7,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6349.9,
    "gfaFloors": 5631.4,
    "gfaCirc": 718.5,
    "nfaTotal": 4766,
    "nrStorage": 53.8,
    "nrApartm": 81,
    "nfaGfa": 75.1,
    "volume": 16471.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3203.5,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1534.29,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6349.9,
    "gfaFloors": 5631.4,
    "gfaCirc": 718.5,
    "nfaTotal": 4766,
    "nrStorage": 53.8,
    "nrApartm": 81,
    "nfaGfa": 75.1,
    "volume": 16471.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3203.5,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1535.22,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8110.4,
    "gfaFloors": 7273.5,
    "gfaCirc": 836.9,
    "nfaTotal": 6092,
    "nrStorage": 68.6,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1514.84,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6476.9,
    "gfaFloors": 5743.4,
    "gfaCirc": 733.5,
    "nfaTotal": 4861.1,
    "nrStorage": 54.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 16799.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3262,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1545.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7653,
    "gfaFloors": 6866.9,
    "gfaCirc": 786.1,
    "nfaTotal": 5743.8,
    "nrStorage": 64.7,
    "nrApartm": 98,
    "nfaGfa": 75.1,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1522.51,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.13,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.13,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8803.9,
    "gfaFloors": 7889.5,
    "gfaCirc": 914.4,
    "nfaTotal": 6612.2,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1509.21,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9536.4,
    "gfaFloors": 8524.2,
    "gfaCirc": 1012.2,
    "nfaTotal": 7157.7,
    "nrStorage": 80.6,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1488.54,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8110.4,
    "gfaFloors": 7273.5,
    "gfaCirc": 836.9,
    "nfaTotal": 6092,
    "nrStorage": 68.6,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1515.56,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.5,
    "gfaFloors": 6359.4,
    "gfaCirc": 801.1,
    "nfaTotal": 5379.9,
    "nrStorage": 60.8,
    "nrApartm": 93,
    "nfaGfa": 75.1,
    "volume": 18601.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3649.2,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1538.04,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7894.4,
    "gfaFloors": 7051.7,
    "gfaCirc": 842.7,
    "nfaTotal": 5928.4,
    "nrStorage": 66.7,
    "nrApartm": 101,
    "nfaGfa": 75.1,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1517.98,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8343.3,
    "gfaFloors": 7446.1,
    "gfaCirc": 897.2,
    "nfaTotal": 6263,
    "nrStorage": 70.5,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1498.42,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9834.1,
    "gfaFloors": 8821.9,
    "gfaCirc": 1012.2,
    "nfaTotal": 7383.3,
    "nrStorage": 83.1,
    "nrApartm": 126,
    "nfaGfa": 75.1,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1491.96,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9536.4,
    "gfaFloors": 8524.2,
    "gfaCirc": 1012.2,
    "nfaTotal": 7157.7,
    "nrStorage": 80.6,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1487.92,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7024.3,
    "gfaFloors": 6283.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5277,
    "nrStorage": 59.4,
    "nrApartm": 87,
    "nfaGfa": 75.1,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1515.14,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8859.4,
    "gfaFloors": 7954.8,
    "gfaCirc": 904.6,
    "nfaTotal": 6657.3,
    "nrStorage": 74.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1480.97,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.5,
    "gfaFloors": 6359.4,
    "gfaCirc": 801.1,
    "nfaTotal": 5379.9,
    "nrStorage": 60.8,
    "nrApartm": 93,
    "nfaGfa": 75.1,
    "volume": 18601.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3649.2,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1538.86,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9834.1,
    "gfaFloors": 8821.9,
    "gfaCirc": 1012.2,
    "nfaTotal": 7383.3,
    "nrStorage": 83.1,
    "nrApartm": 126,
    "nfaGfa": 75.1,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1491.36,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.3,
    "gfaFloors": 7521.9,
    "gfaCirc": 953.4,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1517.39,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8550,
    "gfaFloors": 7643.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6424.6,
    "nrStorage": 72.3,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1517.8,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8116.2,
    "gfaFloors": 7273.5,
    "gfaCirc": 842.7,
    "nfaTotal": 6092,
    "nrStorage": 68.6,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1515.22,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8116.2,
    "gfaFloors": 7273.5,
    "gfaCirc": 842.7,
    "nfaTotal": 6092,
    "nrStorage": 68.6,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1515.94,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6476.9,
    "gfaFloors": 5743.4,
    "gfaCirc": 733.5,
    "nfaTotal": 4861.1,
    "nrStorage": 54.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 16799.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3262,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1546.73,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.3,
    "gfaFloors": 7521.9,
    "gfaCirc": 953.4,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1518.08,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8803.9,
    "gfaFloors": 7889.5,
    "gfaCirc": 914.4,
    "nfaTotal": 6612.2,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1510.55,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9536.4,
    "gfaFloors": 8524.2,
    "gfaCirc": 1012.2,
    "nfaTotal": 7157.7,
    "nrStorage": 80.6,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26073.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4249.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1489.15,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.5,
    "gfaFloors": 8068.5,
    "gfaCirc": 1023,
    "nfaTotal": 6827.3,
    "nrStorage": 77.1,
    "nrApartm": 118,
    "nfaGfa": 75.1,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1508.22,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7862.2,
    "gfaFloors": 7027.6,
    "gfaCirc": 834.6,
    "nfaTotal": 5904.4,
    "nrStorage": 66.5,
    "nrApartm": 101,
    "nfaGfa": 75.1,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1524.77,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.5,
    "gfaFloors": 8068.5,
    "gfaCirc": 1023,
    "nfaTotal": 6827.3,
    "nrStorage": 77.1,
    "nrApartm": 118,
    "nfaGfa": 75.1,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1508.87,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8803.9,
    "gfaFloors": 7889.5,
    "gfaCirc": 914.4,
    "nfaTotal": 6612.2,
    "nrStorage": 74.4,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1509.88,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8964.5,
    "gfaFloors": 7956.5,
    "gfaCirc": 1008,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 114,
    "nfaGfa": 75.1,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1494.97,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8538.4,
    "gfaFloors": 7627.6,
    "gfaCirc": 910.8,
    "nfaTotal": 6412.8,
    "nrStorage": 72.2,
    "nrApartm": 105,
    "nfaGfa": 75.1,
    "volume": 23170.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4036.5,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1492.25,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6349.9,
    "gfaFloors": 5631.4,
    "gfaCirc": 718.5,
    "nfaTotal": 4766,
    "nrStorage": 53.8,
    "nrApartm": 81,
    "nfaGfa": 75.1,
    "volume": 16471.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3203.5,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1536.14,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10291.6,
    "gfaFloors": 9228.6,
    "gfaCirc": 1063,
    "nfaTotal": 7731.5,
    "nrStorage": 87,
    "nrApartm": 132,
    "nfaGfa": 75.1,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1487.25,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.3,
    "gfaFloors": 7521.9,
    "gfaCirc": 953.4,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1518.78,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.52,
    "gfaTotal": 6476.9,
    "gfaFloors": 5743.4,
    "gfaCirc": 733.5,
    "nfaTotal": 4861.1,
    "nrStorage": 54.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 16799.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3262,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1547.63,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8964.5,
    "gfaFloors": 7956.5,
    "gfaCirc": 1008,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 114,
    "nfaGfa": 75.1,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1495.62,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8582.1,
    "gfaFloors": 7667.7,
    "gfaCirc": 914.4,
    "nfaTotal": 6448.6,
    "nrStorage": 72.6,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1513.68,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 12015.2,
    "gfaFloors": 10777,
    "gfaCirc": 1238.2,
    "nfaTotal": 9022.8,
    "nrStorage": 101.6,
    "nrApartm": 154,
    "nfaGfa": 75.1,
    "volume": 33363.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5249.8,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1472.03,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.5,
    "gfaFloors": 8068.5,
    "gfaCirc": 1023,
    "nfaTotal": 6827.3,
    "nrStorage": 77.1,
    "nrApartm": 118,
    "nfaGfa": 75.1,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1509.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.5,
    "gfaFloors": 6359.4,
    "gfaCirc": 801.1,
    "nfaTotal": 5379.9,
    "nrStorage": 60.8,
    "nrApartm": 93,
    "nfaGfa": 75.1,
    "volume": 18601.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3649.2,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1539.68,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8582.1,
    "gfaFloors": 7667.7,
    "gfaCirc": 914.4,
    "nfaTotal": 6448.6,
    "nrStorage": 72.6,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1513,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7894.4,
    "gfaFloors": 7051.7,
    "gfaCirc": 842.7,
    "nfaTotal": 5928.4,
    "nrStorage": 66.7,
    "nrApartm": 101,
    "nfaGfa": 75.1,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1518.72,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11725.4,
    "gfaFloors": 10487.2,
    "gfaCirc": 1238.2,
    "nfaTotal": 8805.2,
    "nrStorage": 99.1,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 32100.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5207.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1467.77,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.5,
    "gfaFloors": 8068.5,
    "gfaCirc": 1023,
    "nfaTotal": 6827.3,
    "nrStorage": 77.1,
    "nrApartm": 118,
    "nfaGfa": 75.1,
    "volume": 23600.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4607.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1510.16,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.5,
    "gfaTotal": 6349.9,
    "gfaFloors": 5631.4,
    "gfaCirc": 718.5,
    "nfaTotal": 4766,
    "nrStorage": 53.8,
    "nrApartm": 81,
    "nfaGfa": 75.1,
    "volume": 16471.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3203.5,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1537.07,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.97,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11725.4,
    "gfaFloors": 10487.2,
    "gfaCirc": 1238.2,
    "nfaTotal": 8805.2,
    "nrStorage": 99.1,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 32100.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5207.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1468.27,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.5,
    "gfaTotal": 7097,
    "gfaFloors": 6303.4,
    "gfaCirc": 793.6,
    "nfaTotal": 5332.3,
    "nrStorage": 60.2,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18437.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3620,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1521.43,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8356.3,
    "gfaFloors": 7482.1,
    "gfaCirc": 874.2,
    "nfaTotal": 6278.8,
    "nrStorage": 70.7,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1491.74,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7024.3,
    "gfaFloors": 6283.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5277,
    "nrStorage": 59.4,
    "nrApartm": 87,
    "nfaGfa": 75.1,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1515.97,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8411.8,
    "gfaFloors": 7465.9,
    "gfaCirc": 945.9,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75.1,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.91,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8964.5,
    "gfaFloors": 7956.5,
    "gfaCirc": 1008,
    "nfaTotal": 6732.2,
    "nrStorage": 76.1,
    "nrApartm": 114,
    "nfaGfa": 75.1,
    "volume": 23272.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4549,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1496.28,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.5,
    "gfaTotal": 7097,
    "gfaFloors": 6303.4,
    "gfaCirc": 793.6,
    "nfaTotal": 5332.3,
    "nrStorage": 60.2,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18437.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3620,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1522.26,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9250,
    "gfaFloors": 8309.7,
    "gfaCirc": 940.3,
    "nfaTotal": 6951,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 75.1,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1495.55,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8411.8,
    "gfaFloors": 7465.9,
    "gfaCirc": 945.9,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75.1,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1503.21,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7586.2,
    "gfaFloors": 6774.1,
    "gfaCirc": 812.1,
    "nfaTotal": 5695.1,
    "nrStorage": 64.1,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1510.41,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.5,
    "gfaTotal": 7097,
    "gfaFloors": 6303.4,
    "gfaCirc": 793.6,
    "nfaTotal": 5332.3,
    "nrStorage": 60.2,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18437.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3620,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1523.08,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.07,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9113.4,
    "gfaFloors": 8154.1,
    "gfaCirc": 959.3,
    "nfaTotal": 6846.7,
    "nrStorage": 77.1,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1481.67,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8538.4,
    "gfaFloors": 7627.6,
    "gfaCirc": 910.8,
    "nfaTotal": 6412.8,
    "nrStorage": 72.2,
    "nrApartm": 105,
    "nfaGfa": 75.1,
    "volume": 23170.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4036.5,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1492.94,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7781.4,
    "gfaFloors": 6955.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5844.9,
    "nrStorage": 65.8,
    "nrApartm": 96,
    "nfaGfa": 75.1,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1502.57,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7781.4,
    "gfaFloors": 6955.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5844.9,
    "nrStorage": 65.8,
    "nrApartm": 96,
    "nfaGfa": 75.1,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1503.33,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10207,
    "gfaFloors": 9099.3,
    "gfaCirc": 1107.7,
    "nfaTotal": 7667.2,
    "nrStorage": 86.3,
    "nrApartm": 129,
    "nfaGfa": 75.1,
    "volume": 27474.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1484.51,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9669.6,
    "gfaFloors": 8644.7,
    "gfaCirc": 1024.9,
    "nfaTotal": 7264.8,
    "nrStorage": 81.8,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1479.56,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8893.8,
    "gfaFloors": 7936.7,
    "gfaCirc": 957.1,
    "nfaTotal": 6681.1,
    "nrStorage": 75.2,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1496.01,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7320.7,
    "gfaFloors": 6566.8,
    "gfaCirc": 753.9,
    "nfaTotal": 5497.5,
    "nrStorage": 61.9,
    "nrApartm": 91,
    "nfaGfa": 75.1,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1505.67,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6267.3,
    "gfaFloors": 5611.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4709.1,
    "nrStorage": 53,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1533.51,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8538.4,
    "gfaFloors": 7627.6,
    "gfaCirc": 910.8,
    "nfaTotal": 6412.8,
    "nrStorage": 72.2,
    "nrApartm": 105,
    "nfaGfa": 75.1,
    "volume": 23170.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4036.5,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1493.63,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9113.4,
    "gfaFloors": 8154.1,
    "gfaCirc": 959.3,
    "nfaTotal": 6846.7,
    "nrStorage": 77.1,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1482.96,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10432.5,
    "gfaFloors": 9316.7,
    "gfaCirc": 1115.8,
    "nfaTotal": 7832.7,
    "nrStorage": 88.2,
    "nrApartm": 130,
    "nfaGfa": 75.1,
    "volume": 28397.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1472.52,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11677.3,
    "gfaFloors": 10447.1,
    "gfaCirc": 1230.2,
    "nfaTotal": 8765.3,
    "nrStorage": 98.7,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 32100.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5207.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1474.11,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9100.4,
    "gfaFloors": 8118.1,
    "gfaCirc": 982.3,
    "nfaTotal": 6830.9,
    "nrStorage": 76.9,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24605.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1489.06,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7781.4,
    "gfaFloors": 6955.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5844.9,
    "nrStorage": 65.8,
    "nrApartm": 96,
    "nfaGfa": 75.1,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1504.08,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8411.8,
    "gfaFloors": 7465.9,
    "gfaCirc": 945.9,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75.1,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1504.61,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7024.3,
    "gfaFloors": 6283.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5277,
    "nrStorage": 59.4,
    "nrApartm": 87,
    "nfaGfa": 75.1,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1516.81,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8356.3,
    "gfaFloors": 7482.1,
    "gfaCirc": 874.2,
    "nfaTotal": 6278.8,
    "nrStorage": 70.7,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1492.44,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9113.4,
    "gfaFloors": 8154.1,
    "gfaCirc": 959.3,
    "nfaTotal": 6846.7,
    "nrStorage": 77.1,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1482.31,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9100.4,
    "gfaFloors": 8118.1,
    "gfaCirc": 982.3,
    "nfaTotal": 6830.9,
    "nrStorage": 76.9,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24605.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1489.71,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8550,
    "gfaFloors": 7643.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6424.6,
    "nrStorage": 72.3,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1518.48,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10445.4,
    "gfaFloors": 9352.6,
    "gfaCirc": 1092.8,
    "nfaTotal": 7848.5,
    "nrStorage": 88.3,
    "nrApartm": 130,
    "nfaGfa": 75.1,
    "volume": 28789,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4644.9,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1466.63,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10985.1,
    "gfaFloors": 9844.6,
    "gfaCirc": 1140.5,
    "nfaTotal": 8251.7,
    "nrStorage": 92.9,
    "nrApartm": 141,
    "nfaGfa": 75.1,
    "volume": 30337.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1484.51,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7586.2,
    "gfaFloors": 6774.1,
    "gfaCirc": 812.1,
    "nfaTotal": 5695.1,
    "nrStorage": 64.1,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1511.18,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9870.5,
    "gfaFloors": 8826.1,
    "gfaCirc": 1044.4,
    "nfaTotal": 7414.6,
    "nrStorage": 83.4,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 26962.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1474.9,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9870.5,
    "gfaFloors": 8826.1,
    "gfaCirc": 1044.4,
    "nfaTotal": 7414.6,
    "nrStorage": 83.4,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 26962.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1474.31,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.6,
    "gfaTotal": 11758.7,
    "gfaFloors": 10515.2,
    "gfaCirc": 1243.5,
    "nfaTotal": 8834.5,
    "nrStorage": 99.4,
    "nrApartm": 147,
    "nfaGfa": 75.1,
    "volume": 32189.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5317.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1459.92,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8538.4,
    "gfaFloors": 7627.6,
    "gfaCirc": 910.8,
    "nfaTotal": 6412.8,
    "nrStorage": 72.2,
    "nrApartm": 105,
    "nfaGfa": 75.1,
    "volume": 23170.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4036.5,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1494.32,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10207,
    "gfaFloors": 9099.3,
    "gfaCirc": 1107.7,
    "nfaTotal": 7667.2,
    "nrStorage": 86.3,
    "nrApartm": 129,
    "nfaGfa": 75.1,
    "volume": 27474.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1485.09,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9656.6,
    "gfaFloors": 8608.7,
    "gfaCirc": 1047.9,
    "nfaTotal": 7249,
    "nrStorage": 81.6,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26040,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1485.92,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.3,
    "gfaFloors": 7521.9,
    "gfaCirc": 953.4,
    "nfaTotal": 6363,
    "nrStorage": 71.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 22001.6,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4322,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1519.47,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.32,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.32,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.6,
    "gfaTotal": 11460.1,
    "gfaFloors": 10266.9,
    "gfaCirc": 1193.2,
    "nfaTotal": 8601.4,
    "nrStorage": 96.8,
    "nrApartm": 143,
    "nfaGfa": 75.1,
    "volume": 31749.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5074.3,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1459.76,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8343.3,
    "gfaFloors": 7446.1,
    "gfaCirc": 897.2,
    "nfaTotal": 6263,
    "nrStorage": 70.5,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1499.12,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9834.1,
    "gfaFloors": 8821.9,
    "gfaCirc": 1012.2,
    "nfaTotal": 7383.3,
    "nrStorage": 83.1,
    "nrApartm": 126,
    "nfaGfa": 75.1,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1492.55,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.69,
    "gfaTotal": 11098.5,
    "gfaFloors": 9995,
    "gfaCirc": 1103.5,
    "nfaTotal": 8337.4,
    "nrStorage": 93.5,
    "nrApartm": 135,
    "nfaGfa": 75.1,
    "volume": 31646.6,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4561.8,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1452.37,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.6,
    "gfaTotal": 11202.5,
    "gfaFloors": 10024.6,
    "gfaCirc": 1177.9,
    "nfaTotal": 8416.4,
    "nrStorage": 94.7,
    "nrApartm": 139,
    "nfaGfa": 75.1,
    "volume": 30754.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5061.4,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1461.18,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8367.4,
    "gfaFloors": 7470.2,
    "gfaCirc": 897.2,
    "nfaTotal": 6287.6,
    "nrStorage": 70.8,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1493.58,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10291.6,
    "gfaFloors": 9228.6,
    "gfaCirc": 1063,
    "nfaTotal": 7731.5,
    "nrStorage": 87,
    "nrApartm": 132,
    "nfaGfa": 75.1,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1488.4,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9491.7,
    "gfaFloors": 8505.5,
    "gfaCirc": 986.2,
    "nfaTotal": 7132.4,
    "nrStorage": 80.3,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1506.57,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10291.6,
    "gfaFloors": 9228.6,
    "gfaCirc": 1063,
    "nfaTotal": 7731.5,
    "nrStorage": 87,
    "nrApartm": 132,
    "nfaGfa": 75.1,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1487.82,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10731.2,
    "gfaFloors": 9598.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8064.1,
    "nrStorage": 90.8,
    "nrApartm": 138,
    "nfaGfa": 75.1,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1490.22,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11725.4,
    "gfaFloors": 10487.2,
    "gfaCirc": 1238.2,
    "nfaTotal": 8805.2,
    "nrStorage": 99.1,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 32100.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5207.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1468.77,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8859.4,
    "gfaFloors": 7954.8,
    "gfaCirc": 904.6,
    "nfaTotal": 6657.3,
    "nrStorage": 74.9,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 24556.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3889.1,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1480.3,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7618.4,
    "gfaFloors": 6798.2,
    "gfaCirc": 820.2,
    "nfaTotal": 5719.7,
    "nrStorage": 64.4,
    "nrApartm": 95,
    "nfaGfa": 75.1,
    "volume": 20673.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1502.71,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10985.1,
    "gfaFloors": 9844.6,
    "gfaCirc": 1140.5,
    "nfaTotal": 8251.7,
    "nrStorage": 92.9,
    "nrApartm": 141,
    "nfaGfa": 75.1,
    "volume": 30337.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1485.58,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10731.2,
    "gfaFloors": 9598.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8064.1,
    "nrStorage": 90.8,
    "nrApartm": 138,
    "nfaGfa": 75.1,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1490.76,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.69,
    "gfaTotal": 9525,
    "gfaFloors": 8582.1,
    "gfaCirc": 942.9,
    "nfaTotal": 7155.1,
    "nrStorage": 80.2,
    "nrApartm": 116,
    "nfaGfa": 75.1,
    "volume": 27323.7,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3889.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1471.24,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 12015.2,
    "gfaFloors": 10777,
    "gfaCirc": 1238.2,
    "nfaTotal": 9022.8,
    "nrStorage": 101.6,
    "nrApartm": 154,
    "nfaGfa": 75.1,
    "volume": 33363.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5249.8,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1473,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10731.2,
    "gfaFloors": 9598.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8064.1,
    "nrStorage": 90.8,
    "nrApartm": 138,
    "nfaGfa": 75.1,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1491.31,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7781.4,
    "gfaFloors": 6955.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5844.9,
    "nrStorage": 65.8,
    "nrApartm": 96,
    "nfaGfa": 75.1,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1504.84,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10083.5,
    "gfaFloors": 9014.8,
    "gfaCirc": 1068.7,
    "nfaTotal": 7575.9,
    "nrStorage": 85.3,
    "nrApartm": 129,
    "nfaGfa": 75.1,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1488.62,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11677.3,
    "gfaFloors": 10447.1,
    "gfaCirc": 1230.2,
    "nfaTotal": 8765.3,
    "nrStorage": 98.7,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 32100.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5207.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1473.61,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.69,
    "gfaTotal": 9293.2,
    "gfaFloors": 8347.8,
    "gfaCirc": 945.4,
    "nfaTotal": 6977.8,
    "nrStorage": 78.3,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 26329.2,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3876.2,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1468.7,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9491.7,
    "gfaFloors": 8505.5,
    "gfaCirc": 986.2,
    "nfaTotal": 7132.4,
    "nrStorage": 80.3,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1505.33,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.69,
    "gfaTotal": 8505.9,
    "gfaFloors": 7631.9,
    "gfaCirc": 874,
    "nfaTotal": 6384.2,
    "nrStorage": 71.6,
    "nrApartm": 102,
    "nfaGfa": 75.1,
    "volume": 23971.9,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3620,
    "surToVol": 0.246,
    "nrBuildings": 3,
    "costM2": 1480.62,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.69,
    "gfaTotal": 9285.2,
    "gfaFloors": 8339.8,
    "gfaCirc": 945.4,
    "nfaTotal": 6969.6,
    "nrStorage": 78.2,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 26329.2,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3876.2,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1469.91,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9715.5,
    "gfaFloors": 8676.8,
    "gfaCirc": 1038.7,
    "nfaTotal": 7297.6,
    "nrStorage": 82.1,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1473.38,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9656.6,
    "gfaFloors": 8608.7,
    "gfaCirc": 1047.9,
    "nfaTotal": 7249,
    "nrStorage": 81.6,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26040,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1486.53,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.69,
    "gfaTotal": 10053.1,
    "gfaFloors": 9047.8,
    "gfaCirc": 1005.3,
    "nfaTotal": 7554.9,
    "nrStorage": 84.7,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 28686.5,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4132.4,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1462.45,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9715.5,
    "gfaFloors": 8676.8,
    "gfaCirc": 1038.7,
    "nfaTotal": 7297.6,
    "nrStorage": 82.1,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1473.98,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8411.8,
    "gfaFloors": 7465.9,
    "gfaCirc": 945.9,
    "nfaTotal": 6315.4,
    "nrStorage": 71.3,
    "nrApartm": 107,
    "nfaGfa": 75.1,
    "volume": 21837.8,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4292.7,
    "surToVol": 0.272,
    "nrBuildings": 3,
    "costM2": 1505.31,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.78,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7024.3,
    "gfaFloors": 6283.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5277,
    "nrStorage": 59.4,
    "nrApartm": 87,
    "nfaGfa": 75.1,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1517.64,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8550,
    "gfaFloors": 7643.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6424.6,
    "nrStorage": 72.3,
    "nrApartm": 110,
    "nfaGfa": 75.1,
    "volume": 23283,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1519.17,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.52,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8899.5,
    "gfaFloors": 7936.7,
    "gfaCirc": 962.8,
    "nfaTotal": 6681.1,
    "nrStorage": 75.2,
    "nrApartm": 112,
    "nfaGfa": 75.1,
    "volume": 24074.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1496.39,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.44,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.44,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9669.6,
    "gfaFloors": 8644.7,
    "gfaCirc": 1024.9,
    "nfaTotal": 7264.8,
    "nrStorage": 81.8,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1480.16,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9715.5,
    "gfaFloors": 8676.8,
    "gfaCirc": 1038.7,
    "nfaTotal": 7297.6,
    "nrStorage": 82.1,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1472.77,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9669.6,
    "gfaFloors": 8644.7,
    "gfaCirc": 1024.9,
    "nfaTotal": 7264.8,
    "nrStorage": 81.8,
    "nrApartm": 121,
    "nfaGfa": 75.1,
    "volume": 26431.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1480.77,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.63,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.63,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8343.3,
    "gfaFloors": 7446.1,
    "gfaCirc": 897.2,
    "nfaTotal": 6263,
    "nrStorage": 70.5,
    "nrApartm": 104,
    "nfaGfa": 75.1,
    "volume": 22639.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1499.82,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9491.7,
    "gfaFloors": 8505.5,
    "gfaCirc": 986.2,
    "nfaTotal": 7132.4,
    "nrStorage": 80.3,
    "nrApartm": 122,
    "nfaGfa": 75.1,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1505.95,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9113.4,
    "gfaFloors": 8154.1,
    "gfaCirc": 959.3,
    "nfaTotal": 6846.7,
    "nrStorage": 77.1,
    "nrApartm": 113,
    "nfaGfa": 75.1,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1483.6,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.6,
    "gfaTotal": 11989.9,
    "gfaFloors": 10732.6,
    "gfaCirc": 1257.3,
    "nfaTotal": 9000.1,
    "nrStorage": 101.3,
    "nrApartm": 149,
    "nfaGfa": 75.1,
    "volume": 33111.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5317.7,
    "surToVol": 0.22,
    "nrBuildings": 3,
    "costM2": 1453.18,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.6,
    "gfaTotal": 10982.8,
    "gfaFloors": 9807.2,
    "gfaCirc": 1175.6,
    "nfaTotal": 8250.8,
    "nrStorage": 92.9,
    "nrApartm": 138,
    "nfaGfa": 75.1,
    "volume": 29832.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5061.4,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1471.9,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7862.2,
    "gfaFloors": 7027.6,
    "gfaCirc": 834.6,
    "nfaTotal": 5904.4,
    "nrStorage": 66.5,
    "nrApartm": 101,
    "nfaGfa": 75.1,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1524.02,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9860.5,
    "gfaFloors": 8856.3,
    "gfaCirc": 1004.2,
    "nfaTotal": 7416.9,
    "nrStorage": 83.5,
    "nrApartm": 126,
    "nfaGfa": 75.2,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1488.15,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8724.3,
    "gfaFloors": 7820.1,
    "gfaCirc": 904.2,
    "nfaTotal": 6557.9,
    "nrStorage": 73.8,
    "nrApartm": 112,
    "nfaGfa": 75.2,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1505.85,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10077.8,
    "gfaFloors": 9014.8,
    "gfaCirc": 1063,
    "nfaTotal": 7575.9,
    "nrStorage": 85.3,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1487.7,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7048.3,
    "gfaFloors": 6307.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5301.6,
    "nrStorage": 59.7,
    "nrApartm": 87,
    "nfaGfa": 75.2,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1510.19,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7314.9,
    "gfaFloors": 6566.8,
    "gfaCirc": 748.1,
    "nfaTotal": 5497.5,
    "nrStorage": 61.9,
    "nrApartm": 91,
    "nfaGfa": 75.2,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1506.04,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10771.3,
    "gfaFloors": 9630.8,
    "gfaCirc": 1140.5,
    "nfaTotal": 8096,
    "nrStorage": 91.1,
    "nrApartm": 138,
    "nfaGfa": 75.2,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1484.84,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7231.1,
    "gfaFloors": 6481.1,
    "gfaCirc": 750,
    "nfaTotal": 5438.6,
    "nrStorage": 61.2,
    "nrApartm": 93,
    "nfaGfa": 75.2,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1537.55,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7856.5,
    "gfaFloors": 7027.6,
    "gfaCirc": 828.9,
    "nfaTotal": 5904.4,
    "nrStorage": 66.5,
    "nrApartm": 101,
    "nfaGfa": 75.2,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1524.36,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7856.5,
    "gfaFloors": 7027.6,
    "gfaCirc": 828.9,
    "nfaTotal": 5904.4,
    "nrStorage": 66.5,
    "nrApartm": 101,
    "nfaGfa": 75.2,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1523.61,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7671.3,
    "gfaFloors": 6893.2,
    "gfaCirc": 778.1,
    "nfaTotal": 5769.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1518.25,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7888.6,
    "gfaFloors": 7051.7,
    "gfaCirc": 836.9,
    "nfaTotal": 5928.4,
    "nrStorage": 66.7,
    "nrApartm": 101,
    "nfaGfa": 75.2,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1517.59,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7231.1,
    "gfaFloors": 6481.1,
    "gfaCirc": 750,
    "nfaTotal": 5438.6,
    "nrStorage": 61.2,
    "nrApartm": 93,
    "nfaGfa": 75.2,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1538.36,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6291.3,
    "gfaFloors": 5635.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4733.7,
    "nrStorage": 53.3,
    "nrApartm": 78,
    "nfaGfa": 75.2,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1526.03,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6543.3,
    "gfaFloors": 5865.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4918.4,
    "nrStorage": 55.4,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1546.21,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7888.6,
    "gfaFloors": 7051.7,
    "gfaCirc": 836.9,
    "nfaTotal": 5928.4,
    "nrStorage": 66.7,
    "nrApartm": 101,
    "nfaGfa": 75.2,
    "volume": 21481.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1518.33,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7671.3,
    "gfaFloors": 6893.2,
    "gfaCirc": 778.1,
    "nfaTotal": 5769.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1519.01,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9269.9,
    "gfaFloors": 8283.7,
    "gfaCirc": 986.2,
    "nfaTotal": 6968.7,
    "nrStorage": 78.4,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1507.44,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10077.8,
    "gfaFloors": 9014.8,
    "gfaCirc": 1063,
    "nfaTotal": 7575.9,
    "nrStorage": 85.3,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1488.28,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6543.3,
    "gfaFloors": 5865.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4918.4,
    "nrStorage": 55.4,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1547.11,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6291.3,
    "gfaFloors": 5635.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4733.7,
    "nrStorage": 53.3,
    "nrApartm": 78,
    "nfaGfa": 75.2,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1525.1,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 10004.3,
    "gfaFloors": 9037.2,
    "gfaCirc": 967.1,
    "nfaTotal": 7525.7,
    "nrStorage": 84.4,
    "nrApartm": 127,
    "nfaGfa": 75.2,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1492.72,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8724.3,
    "gfaFloors": 7820.1,
    "gfaCirc": 904.2,
    "nfaTotal": 6557.9,
    "nrStorage": 73.8,
    "nrApartm": 112,
    "nfaGfa": 75.2,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1506.53,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9244.3,
    "gfaFloors": 8309.7,
    "gfaCirc": 934.6,
    "nfaTotal": 6951,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 75.2,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1495.19,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8140.2,
    "gfaFloors": 7305.6,
    "gfaCirc": 834.6,
    "nfaTotal": 6124,
    "nrStorage": 68.9,
    "nrApartm": 104,
    "nfaGfa": 75.2,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1510.94,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6543.3,
    "gfaFloors": 5865.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4918.4,
    "nrStorage": 55.4,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1548,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7231.1,
    "gfaFloors": 6481.1,
    "gfaCirc": 750,
    "nfaTotal": 5438.6,
    "nrStorage": 61.2,
    "nrApartm": 93,
    "nfaGfa": 75.2,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1539.17,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8140.2,
    "gfaFloors": 7305.6,
    "gfaCirc": 834.6,
    "nfaTotal": 6124,
    "nrStorage": 68.9,
    "nrApartm": 104,
    "nfaGfa": 75.2,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1511.66,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9860.5,
    "gfaFloors": 8856.3,
    "gfaCirc": 1004.2,
    "nfaTotal": 7416.9,
    "nrStorage": 83.5,
    "nrApartm": 126,
    "nfaGfa": 75.2,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1487.55,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8724.3,
    "gfaFloors": 7820.1,
    "gfaCirc": 904.2,
    "nfaTotal": 6557.9,
    "nrStorage": 73.8,
    "nrApartm": 112,
    "nfaGfa": 75.2,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1507.2,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4036.5,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1530.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.5,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4036.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1509.5,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4036.5,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1531.57,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9244.3,
    "gfaFloors": 8309.7,
    "gfaCirc": 934.6,
    "nfaTotal": 6951,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 75.2,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1495.82,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9244.3,
    "gfaFloors": 8309.7,
    "gfaCirc": 934.6,
    "nfaTotal": 6951,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 75.2,
    "volume": 25738.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4006.1,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1494.55,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9860.5,
    "gfaFloors": 8856.3,
    "gfaCirc": 1004.2,
    "nfaTotal": 7416.9,
    "nrStorage": 83.5,
    "nrApartm": 126,
    "nfaGfa": 75.2,
    "volume": 27336.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4291.6,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1488.74,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11433.4,
    "gfaFloors": 10272.8,
    "gfaCirc": 1160.6,
    "nfaTotal": 8598.5,
    "nrStorage": 96.8,
    "nrApartm": 146,
    "nfaGfa": 75.2,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1473.14,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4036.5,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1532.32,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12266.8,
    "gfaFloors": 10977.8,
    "gfaCirc": 1289,
    "nfaTotal": 9223.3,
    "nrStorage": 103.8,
    "nrApartm": 157,
    "nfaGfa": 75.2,
    "volume": 33535,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5463.9,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1468.47,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11433.4,
    "gfaFloors": 10272.8,
    "gfaCirc": 1160.6,
    "nfaTotal": 8598.5,
    "nrStorage": 96.8,
    "nrApartm": 146,
    "nfaGfa": 75.2,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1473.65,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7918.8,
    "gfaFloors": 7097.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5958.8,
    "nrStorage": 67.1,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1531.19,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4036.5,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1533.06,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8724.3,
    "gfaFloors": 7820.1,
    "gfaCirc": 904.2,
    "nfaTotal": 6557.9,
    "nrStorage": 73.8,
    "nrApartm": 112,
    "nfaGfa": 75.2,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1507.87,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10905.4,
    "gfaFloors": 9775.1,
    "gfaCirc": 1130.3,
    "nfaTotal": 8197.4,
    "nrStorage": 92.3,
    "nrApartm": 140,
    "nfaGfa": 75.2,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1481.66,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7677.1,
    "gfaFloors": 6893.2,
    "gfaCirc": 783.9,
    "nfaTotal": 5769.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1518.65,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 10004.3,
    "gfaFloors": 9037.2,
    "gfaCirc": 967.1,
    "nfaTotal": 7525.7,
    "nrStorage": 84.4,
    "nrApartm": 127,
    "nfaGfa": 75.2,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1492.14,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9412.1,
    "gfaFloors": 8436.1,
    "gfaCirc": 976,
    "nfaTotal": 7078.1,
    "nrStorage": 79.7,
    "nrApartm": 121,
    "nfaGfa": 75.2,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1502.18,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9269.9,
    "gfaFloors": 8283.7,
    "gfaCirc": 986.2,
    "nfaTotal": 6968.7,
    "nrStorage": 78.4,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1508.71,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.62,
    "gfaTotal": 7677.1,
    "gfaFloors": 6893.2,
    "gfaCirc": 783.9,
    "nfaTotal": 5769.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 21309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3333.3,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1519.41,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7918.8,
    "gfaFloors": 7097.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5958.8,
    "nrStorage": 67.1,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1531.93,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 1.9,
    "percBuilt": 0.6,
    "gfaTotal": 6291.3,
    "gfaFloors": 5635.6,
    "gfaCirc": 655.7,
    "nfaTotal": 4733.7,
    "nrStorage": 53.3,
    "nrApartm": 78,
    "nfaGfa": 75.2,
    "volume": 17273.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 2786.9,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1526.97,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.84,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8388.4,
    "gfaFloors": 7514.2,
    "gfaCirc": 874.2,
    "nfaTotal": 6311.6,
    "nrStorage": 71,
    "nrApartm": 104,
    "nfaGfa": 75.2,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1485.53,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10077.8,
    "gfaFloors": 9014.8,
    "gfaCirc": 1063,
    "nfaTotal": 7575.9,
    "nrStorage": 85.3,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1488.87,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7231.1,
    "gfaFloors": 6481.1,
    "gfaCirc": 750,
    "nfaTotal": 5438.6,
    "nrStorage": 61.2,
    "nrApartm": 93,
    "nfaGfa": 75.2,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1539.99,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12049.5,
    "gfaFloors": 10819.3,
    "gfaCirc": 1230.2,
    "nfaTotal": 9064.3,
    "nrStorage": 102,
    "nrApartm": 154,
    "nfaGfa": 75.2,
    "volume": 33363.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5249.8,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1468.01,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.5,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4036.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1510.25,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8592.8,
    "gfaFloors": 7695.6,
    "gfaCirc": 897.2,
    "nfaTotal": 6461.4,
    "nrStorage": 72.7,
    "nrApartm": 106,
    "nfaGfa": 75.2,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1484.27,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12049.5,
    "gfaFloors": 10819.3,
    "gfaCirc": 1230.2,
    "nfaTotal": 9064.3,
    "nrStorage": 102,
    "nrApartm": 154,
    "nfaGfa": 75.2,
    "volume": 33363.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5249.8,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1468.49,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.5,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4036.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1511,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9269.9,
    "gfaFloors": 8283.7,
    "gfaCirc": 986.2,
    "nfaTotal": 6968.7,
    "nrStorage": 78.4,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1509.34,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9269.9,
    "gfaFloors": 8283.7,
    "gfaCirc": 986.2,
    "nfaTotal": 6968.7,
    "nrStorage": 78.4,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1508.08,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.6,
    "gfaTotal": 7314.9,
    "gfaFloors": 6566.8,
    "gfaCirc": 748.1,
    "nfaTotal": 5497.5,
    "nrStorage": 61.9,
    "nrApartm": 91,
    "nfaGfa": 75.2,
    "volume": 20233.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3216.3,
    "surToVol": 0.256,
    "nrBuildings": 3,
    "costM2": 1505.24,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10905.4,
    "gfaFloors": 9775.1,
    "gfaCirc": 1130.3,
    "nfaTotal": 8197.4,
    "nrStorage": 92.3,
    "nrApartm": 140,
    "nfaGfa": 75.2,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1482.19,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12266.8,
    "gfaFloors": 10977.8,
    "gfaCirc": 1289,
    "nfaTotal": 9223.3,
    "nrStorage": 103.8,
    "nrApartm": 157,
    "nfaGfa": 75.2,
    "volume": 33535,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5463.9,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1468.95,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12049.5,
    "gfaFloors": 10819.3,
    "gfaCirc": 1230.2,
    "nfaTotal": 9064.3,
    "nrStorage": 102,
    "nrApartm": 154,
    "nfaGfa": 75.2,
    "volume": 33363.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5249.8,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1468.98,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 10037.6,
    "gfaFloors": 8982.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7543.9,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1493.44,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7918.8,
    "gfaFloors": 7097.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5958.8,
    "nrStorage": 67.1,
    "nrApartm": 102,
    "nfaGfa": 75.2,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1530.44,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9412,
    "gfaFloors": 8445.3,
    "gfaCirc": 966.7,
    "nfaTotal": 7075.4,
    "nrStorage": 79.6,
    "nrApartm": 117,
    "nfaGfa": 75.2,
    "volume": 25991.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4145.3,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1475.02,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.69,
    "gfaTotal": 7730.9,
    "gfaFloors": 6959.9,
    "gfaCirc": 771,
    "nfaTotal": 5814.7,
    "nrStorage": 65.2,
    "nrApartm": 93,
    "nfaGfa": 75.2,
    "volume": 22006.3,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3203.5,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1493.26,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9412.1,
    "gfaFloors": 8436.1,
    "gfaCirc": 976,
    "nfaTotal": 7078.1,
    "nrStorage": 79.7,
    "nrApartm": 121,
    "nfaGfa": 75.2,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1503.43,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10099.9,
    "gfaFloors": 9052.1,
    "gfaCirc": 1047.8,
    "nfaTotal": 7598.2,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 75.2,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1498.98,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8592.8,
    "gfaFloors": 7695.6,
    "gfaCirc": 897.2,
    "nfaTotal": 6461.4,
    "nrStorage": 72.7,
    "nrApartm": 106,
    "nfaGfa": 75.2,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1483.58,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9349.9,
    "gfaFloors": 8367.6,
    "gfaCirc": 982.3,
    "nfaTotal": 7029.3,
    "nrStorage": 79.1,
    "nrApartm": 115,
    "nfaGfa": 75.2,
    "volume": 25527.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1475.68,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8388.4,
    "gfaFloors": 7514.2,
    "gfaCirc": 874.2,
    "nfaTotal": 6311.6,
    "nrStorage": 71,
    "nrApartm": 104,
    "nfaGfa": 75.2,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1486.23,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10131.5,
    "gfaFloors": 9083.6,
    "gfaCirc": 1047.9,
    "nfaTotal": 7621.2,
    "nrStorage": 85.8,
    "nrApartm": 125,
    "nfaGfa": 75.2,
    "volume": 27884.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1465.95,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9902.6,
    "gfaFloors": 8858.2,
    "gfaCirc": 1044.4,
    "nfaTotal": 7447.4,
    "nrStorage": 83.8,
    "nrApartm": 122,
    "nfaGfa": 75.2,
    "volume": 26962.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4549,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1469.73,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10099.9,
    "gfaFloors": 9052.1,
    "gfaCirc": 1047.8,
    "nfaTotal": 7598.2,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 75.2,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1500.14,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7805.4,
    "gfaFloors": 6979.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5869.5,
    "nrStorage": 66.1,
    "nrApartm": 96,
    "nfaGfa": 75.2,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1498.14,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10771.3,
    "gfaFloors": 9630.8,
    "gfaCirc": 1140.5,
    "nfaTotal": 8096,
    "nrStorage": 91.1,
    "nrApartm": 138,
    "nfaGfa": 75.2,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1485.39,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9237.8,
    "gfaFloors": 8259.6,
    "gfaCirc": 978.2,
    "nfaTotal": 6944.8,
    "nrStorage": 78.2,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1512.53,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9412.1,
    "gfaFloors": 8436.1,
    "gfaCirc": 976,
    "nfaTotal": 7078.1,
    "nrStorage": 79.7,
    "nrApartm": 121,
    "nfaGfa": 75.2,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1502.81,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10099.9,
    "gfaFloors": 9052.1,
    "gfaCirc": 1047.8,
    "nfaTotal": 7598.2,
    "nrStorage": 85.5,
    "nrApartm": 130,
    "nfaGfa": 75.2,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1499.56,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10485.5,
    "gfaFloors": 9392.7,
    "gfaCirc": 1092.8,
    "nfaTotal": 7889.5,
    "nrStorage": 88.8,
    "nrApartm": 130,
    "nfaGfa": 75.2,
    "volume": 28789,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4644.9,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1460.7,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9237.8,
    "gfaFloors": 8259.6,
    "gfaCirc": 978.2,
    "nfaTotal": 6944.8,
    "nrStorage": 78.2,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1513.17,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8388.4,
    "gfaFloors": 7514.2,
    "gfaCirc": 874.2,
    "nfaTotal": 6311.6,
    "nrStorage": 71,
    "nrApartm": 104,
    "nfaGfa": 75.2,
    "volume": 23031.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3715.9,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1484.83,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.69,
    "gfaTotal": 10069.1,
    "gfaFloors": 9063.8,
    "gfaCirc": 1005.3,
    "nfaTotal": 7571.4,
    "nrStorage": 84.9,
    "nrApartm": 122,
    "nfaGfa": 75.2,
    "volume": 28686.5,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4132.4,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1460.22,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10398,
    "gfaFloors": 9342.8,
    "gfaCirc": 1055.2,
    "nfaTotal": 7817.1,
    "nrStorage": 88,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 28879.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4561.8,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1464.18,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10771.3,
    "gfaFloors": 9630.8,
    "gfaCirc": 1140.5,
    "nfaTotal": 8096,
    "nrStorage": 91.1,
    "nrApartm": 138,
    "nfaGfa": 75.2,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1485.93,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.5,
    "gfaTotal": 7844.2,
    "gfaFloors": 6975.4,
    "gfaCirc": 868.8,
    "nfaTotal": 5898.6,
    "nrStorage": 66.6,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20402.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4036.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1511.75,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.19,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.19,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9412,
    "gfaFloors": 8445.3,
    "gfaCirc": 966.7,
    "nfaTotal": 7075.4,
    "nrStorage": 79.6,
    "nrApartm": 117,
    "nfaGfa": 75.2,
    "volume": 25991.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4145.3,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1473.78,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11433.4,
    "gfaFloors": 10272.8,
    "gfaCirc": 1160.6,
    "nfaTotal": 8598.5,
    "nrStorage": 96.8,
    "nrApartm": 146,
    "nfaGfa": 75.2,
    "volume": 31765,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4964.3,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1472.62,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 10004.3,
    "gfaFloors": 9037.2,
    "gfaCirc": 967.1,
    "nfaTotal": 7525.7,
    "nrStorage": 84.4,
    "nrApartm": 127,
    "nfaGfa": 75.2,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.31,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10329.4,
    "gfaFloors": 9268.7,
    "gfaCirc": 1060.7,
    "nfaTotal": 7771.5,
    "nrStorage": 87.5,
    "nrApartm": 132,
    "nfaGfa": 75.2,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1483.75,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12266.8,
    "gfaFloors": 10977.8,
    "gfaCirc": 1289,
    "nfaTotal": 9223.3,
    "nrStorage": 103.8,
    "nrApartm": 157,
    "nfaGfa": 75.2,
    "volume": 33535,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5463.9,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1469.43,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7805.4,
    "gfaFloors": 6979.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5869.5,
    "nrStorage": 66.1,
    "nrApartm": 96,
    "nfaGfa": 75.2,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1500.4,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 10037.6,
    "gfaFloors": 8982.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7543.9,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1494.03,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10771.3,
    "gfaFloors": 9630.8,
    "gfaCirc": 1140.5,
    "nfaTotal": 8096,
    "nrStorage": 91.1,
    "nrApartm": 138,
    "nfaGfa": 75.2,
    "volume": 29309.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1486.48,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.62,
    "gfaTotal": 13086.5,
    "gfaFloors": 11730.1,
    "gfaCirc": 1356.4,
    "nfaTotal": 9836.8,
    "nrStorage": 110.7,
    "nrApartm": 168,
    "nfaGfa": 75.2,
    "volume": 36161.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5749.4,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1465.95,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 6,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.62,
    "gfaTotal": 13086.5,
    "gfaFloors": 11730.1,
    "gfaCirc": 1356.4,
    "nfaTotal": 9836.8,
    "nrStorage": 110.7,
    "nrApartm": 168,
    "nfaGfa": 75.2,
    "volume": 36161.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5749.4,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1465.5,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 6,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9145.5,
    "gfaFloors": 8186.2,
    "gfaCirc": 959.3,
    "nfaTotal": 6879.5,
    "nrStorage": 77.4,
    "nrApartm": 113,
    "nfaGfa": 75.2,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1476.65,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 10037.6,
    "gfaFloors": 8982.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7543.9,
    "nrStorage": 84.9,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 27508.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1494.61,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11593.2,
    "gfaFloors": 10391.1,
    "gfaCirc": 1202.1,
    "nfaTotal": 8717.5,
    "nrStorage": 98.1,
    "nrApartm": 149,
    "nfaGfa": 75.2,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1480.08,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8592.8,
    "gfaFloors": 7695.6,
    "gfaCirc": 897.2,
    "nfaTotal": 6461.4,
    "nrStorage": 72.7,
    "nrApartm": 106,
    "nfaGfa": 75.2,
    "volume": 23561.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3876.2,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1484.95,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9237.8,
    "gfaFloors": 8259.6,
    "gfaCirc": 978.2,
    "nfaTotal": 6944.8,
    "nrStorage": 78.2,
    "nrApartm": 119,
    "nfaGfa": 75.2,
    "volume": 25084.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1513.8,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7805.4,
    "gfaFloors": 6979.6,
    "gfaCirc": 825.8,
    "nfaTotal": 5869.5,
    "nrStorage": 66.1,
    "nrApartm": 96,
    "nfaGfa": 75.2,
    "volume": 21204.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3620,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1498.89,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.17,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8562.4,
    "gfaFloors": 7651.6,
    "gfaCirc": 910.8,
    "nfaTotal": 6437.4,
    "nrStorage": 72.4,
    "nrApartm": 105,
    "nfaGfa": 75.2,
    "volume": 23170.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4036.5,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1488.89,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10398,
    "gfaFloors": 9342.8,
    "gfaCirc": 1055.2,
    "nfaTotal": 7817.1,
    "nrStorage": 88,
    "nrApartm": 129,
    "nfaGfa": 75.2,
    "volume": 28879.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4561.8,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1463.61,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11593.2,
    "gfaFloors": 10391.1,
    "gfaCirc": 1202.1,
    "nfaTotal": 8717.5,
    "nrStorage": 98.1,
    "nrApartm": 149,
    "nfaGfa": 75.2,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1480.59,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9349.9,
    "gfaFloors": 8367.6,
    "gfaCirc": 982.3,
    "nfaTotal": 7029.3,
    "nrStorage": 79.1,
    "nrApartm": 115,
    "nfaGfa": 75.2,
    "volume": 25527.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4292.7,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1477.57,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9145.5,
    "gfaFloors": 8186.2,
    "gfaCirc": 959.3,
    "nfaTotal": 6879.5,
    "nrStorage": 77.4,
    "nrApartm": 113,
    "nfaGfa": 75.2,
    "volume": 24996.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1477.29,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11593.2,
    "gfaFloors": 10391.1,
    "gfaCirc": 1202.1,
    "nfaTotal": 8717.5,
    "nrStorage": 98.1,
    "nrApartm": 149,
    "nfaGfa": 75.2,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1481.6,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.6,
    "gfaTotal": 7048.3,
    "gfaFloors": 6307.6,
    "gfaCirc": 740.7,
    "nfaTotal": 5301.6,
    "nrStorage": 59.7,
    "nrApartm": 87,
    "nfaGfa": 75.2,
    "volume": 19239,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3203.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1511.02,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10905.4,
    "gfaFloors": 9775.1,
    "gfaCirc": 1130.3,
    "nfaTotal": 8197.4,
    "nrStorage": 92.3,
    "nrApartm": 140,
    "nfaGfa": 75.2,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1482.73,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9444.2,
    "gfaFloors": 8468.2,
    "gfaCirc": 976,
    "nfaTotal": 7110,
    "nrStorage": 80,
    "nrApartm": 121,
    "nfaGfa": 75.3,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1498.5,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10323.6,
    "gfaFloors": 9268.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7771.5,
    "nrStorage": 87.5,
    "nrApartm": 132,
    "nfaGfa": 75.3,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1482.87,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6567.3,
    "gfaFloors": 5889.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4942.4,
    "nrStorage": 55.6,
    "nrApartm": 84,
    "nfaGfa": 75.3,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1540.74,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8828,
    "gfaFloors": 7921.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6644.2,
    "nrStorage": 74.8,
    "nrApartm": 113,
    "nfaGfa": 75.3,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1505.96,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8828,
    "gfaFloors": 7921.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6644.2,
    "nrStorage": 74.8,
    "nrApartm": 113,
    "nfaGfa": 75.3,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1505.3,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9444.2,
    "gfaFloors": 8468.2,
    "gfaCirc": 976,
    "nfaTotal": 7110,
    "nrStorage": 80,
    "nrApartm": 121,
    "nfaGfa": 75.3,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1497.26,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8427.2,
    "gfaFloors": 7628.7,
    "gfaCirc": 798.5,
    "nfaTotal": 6348.7,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.3,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1514.01,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7255.1,
    "gfaFloors": 6505.1,
    "gfaCirc": 750,
    "nfaTotal": 5462.6,
    "nrStorage": 61.5,
    "nrApartm": 93,
    "nfaGfa": 75.3,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1532.67,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8756.4,
    "gfaFloors": 7852.2,
    "gfaCirc": 904.2,
    "nfaTotal": 6589.8,
    "nrStorage": 74.2,
    "nrApartm": 112,
    "nfaGfa": 75.3,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1500.56,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8756.4,
    "gfaFloors": 7852.2,
    "gfaCirc": 904.2,
    "nfaTotal": 6589.8,
    "nrStorage": 74.2,
    "nrApartm": 112,
    "nfaGfa": 75.3,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1501.23,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6567.3,
    "gfaFloors": 5889.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4942.4,
    "nrStorage": 55.6,
    "nrApartm": 84,
    "nfaGfa": 75.3,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1541.63,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9444.2,
    "gfaFloors": 8468.2,
    "gfaCirc": 976,
    "nfaTotal": 7110,
    "nrStorage": 80,
    "nrApartm": 121,
    "nfaGfa": 75.3,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1497.88,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7255.1,
    "gfaFloors": 6505.1,
    "gfaCirc": 750,
    "nfaTotal": 5462.6,
    "nrStorage": 61.5,
    "nrApartm": 93,
    "nfaGfa": 75.3,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1533.48,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7942.8,
    "gfaFloors": 7121.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5982.7,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 75.3,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1525.97,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8134.5,
    "gfaFloors": 7305.6,
    "gfaCirc": 828.9,
    "nfaTotal": 6124,
    "nrStorage": 68.9,
    "nrApartm": 104,
    "nfaGfa": 75.3,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1510.53,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8134.5,
    "gfaFloors": 7305.6,
    "gfaCirc": 828.9,
    "nfaTotal": 6124,
    "nrStorage": 68.9,
    "nrApartm": 104,
    "nfaGfa": 75.3,
    "volume": 22508.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3547.4,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1511.26,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9586.4,
    "gfaFloors": 8651.3,
    "gfaCirc": 935.1,
    "nfaTotal": 7219.5,
    "nrStorage": 81,
    "nrApartm": 122,
    "nfaGfa": 75.3,
    "volume": 27393.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3934.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1502.17,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8756.4,
    "gfaFloors": 7852.2,
    "gfaCirc": 904.2,
    "nfaTotal": 6589.8,
    "nrStorage": 74.2,
    "nrApartm": 112,
    "nfaGfa": 75.3,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1501.9,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7255.1,
    "gfaFloors": 6505.1,
    "gfaCirc": 750,
    "nfaTotal": 5462.6,
    "nrStorage": 61.5,
    "nrApartm": 93,
    "nfaGfa": 75.3,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1534.29,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6567.3,
    "gfaFloors": 5889.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4942.4,
    "nrStorage": 55.6,
    "nrApartm": 84,
    "nfaGfa": 75.3,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1542.53,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7942.8,
    "gfaFloors": 7121.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5982.7,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 75.3,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1526.71,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8630.6,
    "gfaFloors": 7737.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6502.9,
    "nrStorage": 73.2,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1521.04,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.62,
    "gfaTotal": 7255.1,
    "gfaFloors": 6505.1,
    "gfaCirc": 750,
    "nfaTotal": 5462.6,
    "nrStorage": 61.5,
    "nrApartm": 93,
    "nfaGfa": 75.3,
    "volume": 19882.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3262,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1535.1,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2,
    "percBuilt": 0.62,
    "gfaTotal": 6567.3,
    "gfaFloors": 5889.1,
    "gfaCirc": 678.2,
    "nfaTotal": 4942.4,
    "nrStorage": 55.6,
    "nrApartm": 84,
    "nfaGfa": 75.3,
    "volume": 18080.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 2874.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1543.42,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.03,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.03,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9586.4,
    "gfaFloors": 8651.3,
    "gfaCirc": 935.1,
    "nfaTotal": 7219.5,
    "nrStorage": 81,
    "nrApartm": 122,
    "nfaGfa": 75.3,
    "volume": 27393.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3934.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1503.4,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9515.8,
    "gfaFloors": 8537.6,
    "gfaCirc": 978.2,
    "nfaTotal": 7164.3,
    "nrStorage": 80.6,
    "nrApartm": 122,
    "nfaGfa": 75.3,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1501.67,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8630.6,
    "gfaFloors": 7737.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6502.9,
    "nrStorage": 73.2,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1520.36,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10132,
    "gfaFloors": 9084.2,
    "gfaCirc": 1047.8,
    "nfaTotal": 7630.2,
    "nrStorage": 85.9,
    "nrApartm": 130,
    "nfaGfa": 75.3,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1494.43,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8433,
    "gfaFloors": 7628.7,
    "gfaCirc": 804.3,
    "nfaTotal": 6348.7,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.3,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1514.37,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10945.5,
    "gfaFloors": 9815.2,
    "gfaCirc": 1130.3,
    "nfaTotal": 8237.3,
    "nrStorage": 92.7,
    "nrApartm": 140,
    "nfaGfa": 75.3,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1476.43,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10945.5,
    "gfaFloors": 9815.2,
    "gfaCirc": 1130.3,
    "nfaTotal": 8237.3,
    "nrStorage": 92.7,
    "nrApartm": 140,
    "nfaGfa": 75.3,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1476.96,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11633.3,
    "gfaFloors": 10431.2,
    "gfaCirc": 1202.1,
    "nfaTotal": 8757.5,
    "nrStorage": 98.6,
    "nrApartm": 149,
    "nfaGfa": 75.3,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1475.7,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11633.3,
    "gfaFloors": 10431.2,
    "gfaCirc": 1202.1,
    "nfaTotal": 8757.5,
    "nrStorage": 98.6,
    "nrApartm": 149,
    "nfaGfa": 75.3,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1475.2,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10132,
    "gfaFloors": 9084.2,
    "gfaCirc": 1047.8,
    "nfaTotal": 7630.2,
    "nrStorage": 85.9,
    "nrApartm": 130,
    "nfaGfa": 75.3,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1495.01,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7942.8,
    "gfaFloors": 7121.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5982.7,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 75.3,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1528.19,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7942.8,
    "gfaFloors": 7121.1,
    "gfaCirc": 821.7,
    "nfaTotal": 5982.7,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 75.3,
    "volume": 21684.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3649.2,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1527.45,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.65,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8630.6,
    "gfaFloors": 7737.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6502.9,
    "nrStorage": 73.2,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1521.72,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9444.2,
    "gfaFloors": 8468.2,
    "gfaCirc": 976,
    "nfaTotal": 7110,
    "nrStorage": 80,
    "nrApartm": 121,
    "nfaGfa": 75.3,
    "volume": 25909.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4220.2,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1499.12,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8756.4,
    "gfaFloors": 7852.2,
    "gfaCirc": 904.2,
    "nfaTotal": 6589.8,
    "nrStorage": 74.2,
    "nrApartm": 112,
    "nfaGfa": 75.3,
    "volume": 24107.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3832.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1502.57,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8630.6,
    "gfaFloors": 7737.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6502.9,
    "nrStorage": 73.2,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1522.4,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.69,
    "gfaTotal": 7979.5,
    "gfaFloors": 7219,
    "gfaCirc": 760.5,
    "nfaTotal": 6011.3,
    "nrStorage": 67.4,
    "nrApartm": 97,
    "nfaGfa": 75.3,
    "volume": 23000.8,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3216.3,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1493.34,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9515.8,
    "gfaFloors": 8537.6,
    "gfaCirc": 978.2,
    "nfaTotal": 7164.3,
    "nrStorage": 80.6,
    "nrApartm": 122,
    "nfaGfa": 75.3,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1502.91,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10132,
    "gfaFloors": 9084.2,
    "gfaCirc": 1047.8,
    "nfaTotal": 7630.2,
    "nrStorage": 85.9,
    "nrApartm": 130,
    "nfaGfa": 75.3,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1495.59,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9515.8,
    "gfaFloors": 8537.6,
    "gfaCirc": 978.2,
    "nfaTotal": 7164.3,
    "nrStorage": 80.6,
    "nrApartm": 122,
    "nfaGfa": 75.3,
    "volume": 26112.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4322,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1502.29,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.8,
    "percBuilt": 0.62,
    "gfaTotal": 12512.7,
    "gfaFloors": 11231.7,
    "gfaCirc": 1281,
    "nfaTotal": 9418.9,
    "nrStorage": 106,
    "nrApartm": 160,
    "nfaGfa": 75.3,
    "volume": 34562.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5463.9,
    "surToVol": 0.218,
    "nrBuildings": 3,
    "costM2": 1465.32,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7830,
    "gfaFloors": 7023.6,
    "gfaCirc": 806.4,
    "nfaTotal": 5893.5,
    "nrStorage": 66.3,
    "nrApartm": 97,
    "nfaGfa": 75.3,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1494.08,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10323.6,
    "gfaFloors": 9268.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7771.5,
    "nrStorage": 87.5,
    "nrApartm": 132,
    "nfaGfa": 75.3,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1483.44,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8427.2,
    "gfaFloors": 7628.7,
    "gfaCirc": 798.5,
    "nfaTotal": 6348.7,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.3,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1514.7,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 11017.2,
    "gfaFloors": 9884.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8291.6,
    "nrStorage": 93.3,
    "nrApartm": 141,
    "nfaGfa": 75.3,
    "volume": 30337.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1480.9,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.69,
    "gfaTotal": 8504.5,
    "gfaFloors": 7667.8,
    "gfaCirc": 836.7,
    "nfaTotal": 6400.1,
    "nrStorage": 71.8,
    "nrApartm": 103,
    "nfaGfa": 75.3,
    "volume": 24363.6,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3459.7,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1482.08,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8606.6,
    "gfaFloors": 7713.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6478.9,
    "nrStorage": 72.9,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1525.79,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10323.6,
    "gfaFloors": 9268.7,
    "gfaCirc": 1054.9,
    "nfaTotal": 7771.5,
    "nrStorage": 87.5,
    "nrApartm": 132,
    "nfaGfa": 75.3,
    "volume": 28535.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4505.7,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1484,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9368.7,
    "gfaFloors": 8411.6,
    "gfaCirc": 957.1,
    "nfaTotal": 7053.3,
    "nrStorage": 79.4,
    "nrApartm": 116,
    "nfaGfa": 75.3,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1473.28,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10945.5,
    "gfaFloors": 9815.2,
    "gfaCirc": 1130.3,
    "nfaTotal": 8237.3,
    "nrStorage": 92.7,
    "nrApartm": 140,
    "nfaGfa": 75.3,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1477.5,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.6,
    "gfaTotal": 10907.3,
    "gfaFloors": 9799.6,
    "gfaCirc": 1107.7,
    "nfaTotal": 8213.1,
    "nrStorage": 92.4,
    "nrApartm": 135,
    "nfaGfa": 75.3,
    "volume": 30242.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4805.2,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1458.34,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8828,
    "gfaFloors": 7921.6,
    "gfaCirc": 906.4,
    "nfaTotal": 6644.2,
    "nrStorage": 74.8,
    "nrApartm": 113,
    "nfaGfa": 75.3,
    "volume": 24310.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3934.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1506.63,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7830,
    "gfaFloors": 7023.6,
    "gfaCirc": 806.4,
    "nfaTotal": 5893.5,
    "nrStorage": 66.3,
    "nrApartm": 97,
    "nfaGfa": 75.3,
    "volume": 21596.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3459.7,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1494.83,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12025.6,
    "gfaFloors": 10860.3,
    "gfaCirc": 1165.3,
    "nfaTotal": 9059.7,
    "nrStorage": 101.6,
    "nrApartm": 153,
    "nfaGfa": 75.3,
    "volume": 34448.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4892.9,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1474.91,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.8,
    "percBuilt": 0.62,
    "gfaTotal": 12512.7,
    "gfaFloors": 11231.7,
    "gfaCirc": 1281,
    "nfaTotal": 9418.9,
    "nrStorage": 106,
    "nrApartm": 160,
    "nfaGfa": 75.3,
    "volume": 34562.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5463.9,
    "surToVol": 0.218,
    "nrBuildings": 3,
    "costM2": 1464.85,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10132,
    "gfaFloors": 9084.2,
    "gfaCirc": 1047.8,
    "nfaTotal": 7630.2,
    "nrStorage": 85.9,
    "nrApartm": 130,
    "nfaGfa": 75.3,
    "volume": 27711.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4607.5,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1496.17,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.33,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9927.1,
    "gfaFloors": 8902.2,
    "gfaCirc": 1024.9,
    "nfaTotal": 7471.4,
    "nrStorage": 84.1,
    "nrApartm": 123,
    "nfaGfa": 75.3,
    "volume": 27354.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4388.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1466.64,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11633.3,
    "gfaFloors": 10431.2,
    "gfaCirc": 1202.1,
    "nfaTotal": 8757.5,
    "nrStorage": 98.6,
    "nrApartm": 149,
    "nfaGfa": 75.3,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1476.21,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 11017.2,
    "gfaFloors": 9884.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8291.6,
    "nrStorage": 93.3,
    "nrApartm": 141,
    "nfaGfa": 75.3,
    "volume": 30337.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1480.36,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 11017.2,
    "gfaFloors": 9884.7,
    "gfaCirc": 1132.5,
    "nfaTotal": 8291.6,
    "nrStorage": 93.3,
    "nrApartm": 141,
    "nfaGfa": 75.3,
    "volume": 30337.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4892.9,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1481.43,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.69,
    "gfaTotal": 7970.6,
    "gfaFloors": 7202.1,
    "gfaCirc": 768.5,
    "nfaTotal": 6000.3,
    "nrStorage": 67.3,
    "nrApartm": 97,
    "nfaGfa": 75.3,
    "volume": 23000.8,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3216.3,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1494.2,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10945.5,
    "gfaFloors": 9815.2,
    "gfaCirc": 1130.3,
    "nfaTotal": 8237.3,
    "nrStorage": 92.7,
    "nrApartm": 140,
    "nfaGfa": 75.3,
    "volume": 30134.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4791.1,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1478.04,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.69,
    "gfaTotal": 8504.5,
    "gfaFloors": 7667.8,
    "gfaCirc": 836.7,
    "nfaTotal": 6400.1,
    "nrStorage": 71.8,
    "nrApartm": 103,
    "nfaGfa": 75.3,
    "volume": 24363.6,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3459.7,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1481.39,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11633.3,
    "gfaFloors": 10431.2,
    "gfaCirc": 1202.1,
    "nfaTotal": 8757.5,
    "nrStorage": 98.6,
    "nrApartm": 149,
    "nfaGfa": 75.3,
    "volume": 31936.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5178.4,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1476.71,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.69,
    "gfaTotal": 10049.5,
    "gfaFloors": 9055.8,
    "gfaCirc": 993.7,
    "nfaTotal": 7563.1,
    "nrStorage": 84.8,
    "nrApartm": 121,
    "nfaGfa": 75.3,
    "volume": 28686.5,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4132.4,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1458.32,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9368.7,
    "gfaFloors": 8411.6,
    "gfaCirc": 957.1,
    "nfaTotal": 7053.3,
    "nrStorage": 79.4,
    "nrApartm": 116,
    "nfaGfa": 75.3,
    "volume": 25919.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4132.4,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1474.53,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.8,
    "percBuilt": 0.73,
    "gfaTotal": 12443.4,
    "gfaFloors": 11246.1,
    "gfaCirc": 1197.3,
    "nfaTotal": 9366,
    "nrStorage": 105.1,
    "nrApartm": 158,
    "nfaGfa": 75.3,
    "volume": 35875.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4964.3,
    "surToVol": 0.206,
    "nrBuildings": 3,
    "costM2": 1467.8,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8606.6,
    "gfaFloors": 7713.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6478.9,
    "nrStorage": 72.9,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1525.11,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8606.6,
    "gfaFloors": 7713.1,
    "gfaCirc": 893.5,
    "nfaTotal": 6478.9,
    "nrStorage": 72.9,
    "nrApartm": 111,
    "nfaGfa": 75.3,
    "volume": 23486.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4036.5,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1524.43,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.39,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11327.9,
    "gfaFloors": 10244.3,
    "gfaCirc": 1083.6,
    "nfaTotal": 8538.1,
    "nrStorage": 95.8,
    "nrApartm": 144,
    "nfaGfa": 75.4,
    "volume": 32646.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4505.7,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1477.38,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10448.4,
    "gfaFloors": 9451.8,
    "gfaCirc": 996.6,
    "nfaTotal": 7882.8,
    "nrStorage": 88.4,
    "nrApartm": 133,
    "nfaGfa": 75.4,
    "volume": 30020.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4220.2,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1489.97,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9586.3,
    "gfaFloors": 8659.3,
    "gfaCirc": 927,
    "nfaTotal": 7227.5,
    "nrStorage": 81.1,
    "nrApartm": 122,
    "nfaGfa": 75.4,
    "volume": 27393.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3934.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1503.44,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11327.9,
    "gfaFloors": 10244.3,
    "gfaCirc": 1083.6,
    "nfaTotal": 8538.1,
    "nrStorage": 95.8,
    "nrApartm": 144,
    "nfaGfa": 75.4,
    "volume": 32646.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4505.7,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1477.9,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9756.5,
    "gfaFloors": 8835.8,
    "gfaCirc": 920.7,
    "nfaTotal": 7361.2,
    "nrStorage": 82.6,
    "nrApartm": 124,
    "nfaGfa": 75.4,
    "volume": 28218.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3832.9,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1493.04,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9586.3,
    "gfaFloors": 8659.3,
    "gfaCirc": 927,
    "nfaTotal": 7227.5,
    "nrStorage": 81.1,
    "nrApartm": 122,
    "nfaGfa": 75.4,
    "volume": 27393.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3934.7,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1502.22,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.73,
    "gfaTotal": 8888.7,
    "gfaFloors": 8035.3,
    "gfaCirc": 853.4,
    "nfaTotal": 6697.8,
    "nrStorage": 75.1,
    "nrApartm": 113,
    "nfaGfa": 75.4,
    "volume": 25592.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3547.4,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1507.42,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.73,
    "gfaTotal": 8888.7,
    "gfaFloors": 8035.3,
    "gfaCirc": 853.4,
    "nfaTotal": 6697.8,
    "nrStorage": 75.1,
    "nrApartm": 113,
    "nfaGfa": 75.4,
    "volume": 25592.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3547.4,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1508.08,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.73,
    "gfaTotal": 8009.4,
    "gfaFloors": 7242.9,
    "gfaCirc": 766.5,
    "nfaTotal": 6042.5,
    "nrStorage": 67.8,
    "nrApartm": 102,
    "nfaGfa": 75.4,
    "volume": 22965.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3262,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1527.15,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.73,
    "gfaTotal": 10860.6,
    "gfaFloors": 9839.9,
    "gfaCirc": 1020.7,
    "nfaTotal": 8190.6,
    "nrStorage": 91.9,
    "nrApartm": 138,
    "nfaGfa": 75.4,
    "volume": 31447.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4291.6,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1482,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.69,
    "gfaTotal": 6956,
    "gfaFloors": 6287.9,
    "gfaCirc": 668.1,
    "nfaTotal": 5245.2,
    "nrStorage": 58.8,
    "nrApartm": 84,
    "nfaGfa": 75.4,
    "volume": 20040.7,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 2786.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1508.69,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.73,
    "gfaTotal": 8009.4,
    "gfaFloors": 7242.9,
    "gfaCirc": 766.5,
    "nfaTotal": 6042.5,
    "nrStorage": 67.8,
    "nrApartm": 102,
    "nfaGfa": 75.4,
    "volume": 22965.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3262,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1527.88,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.73,
    "gfaTotal": 7317.5,
    "gfaFloors": 6626.9,
    "gfaCirc": 690.6,
    "nfaTotal": 5520.9,
    "nrStorage": 61.9,
    "nrApartm": 93,
    "nfaGfa": 75.4,
    "volume": 21163.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 2874.7,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1534.71,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.73,
    "gfaTotal": 7317.5,
    "gfaFloors": 6626.9,
    "gfaCirc": 690.6,
    "nfaTotal": 5520.9,
    "nrStorage": 61.9,
    "nrApartm": 93,
    "nfaGfa": 75.4,
    "volume": 21163.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 2874.7,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1536.32,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8421.5,
    "gfaFloors": 7631,
    "gfaCirc": 790.5,
    "nfaTotal": 6350.3,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.4,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1515.02,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8427.2,
    "gfaFloors": 7631,
    "gfaCirc": 796.2,
    "nfaTotal": 6350.3,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.4,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1515.4,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.73,
    "gfaTotal": 7317.5,
    "gfaFloors": 6626.9,
    "gfaCirc": 690.6,
    "nfaTotal": 5520.9,
    "nrStorage": 61.9,
    "nrApartm": 93,
    "nfaGfa": 75.4,
    "volume": 21163.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 2874.7,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1535.51,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.11,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.11,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9756.5,
    "gfaFloors": 8835.8,
    "gfaCirc": 920.7,
    "nfaTotal": 7361.2,
    "nrStorage": 82.6,
    "nrApartm": 124,
    "nfaGfa": 75.4,
    "volume": 28218.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3832.9,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1494.24,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9756.5,
    "gfaFloors": 8835.8,
    "gfaCirc": 920.7,
    "nfaTotal": 7361.2,
    "nrStorage": 82.6,
    "nrApartm": 124,
    "nfaGfa": 75.4,
    "volume": 28218.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3832.9,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1494.85,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10448.4,
    "gfaFloors": 9451.8,
    "gfaCirc": 996.6,
    "nfaTotal": 7882.8,
    "nrStorage": 88.4,
    "nrApartm": 133,
    "nfaGfa": 75.4,
    "volume": 30020.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4220.2,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1491.1,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.69,
    "gfaTotal": 6956,
    "gfaFloors": 6287.9,
    "gfaCirc": 668.1,
    "nfaTotal": 5245.2,
    "nrStorage": 58.8,
    "nrApartm": 84,
    "nfaGfa": 75.4,
    "volume": 20040.7,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 2786.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1509.53,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.73,
    "gfaTotal": 8888.7,
    "gfaFloors": 8043.3,
    "gfaCirc": 845.4,
    "nfaTotal": 6705.8,
    "nrStorage": 75.2,
    "nrApartm": 113,
    "nfaGfa": 75.4,
    "volume": 25592.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3547.4,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1508.11,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9756.5,
    "gfaFloors": 8835.8,
    "gfaCirc": 920.7,
    "nfaTotal": 7361.2,
    "nrStorage": 82.6,
    "nrApartm": 124,
    "nfaGfa": 75.4,
    "volume": 28218.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3832.9,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1493.64,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.73,
    "gfaTotal": 10866.4,
    "gfaFloors": 9837.7,
    "gfaCirc": 1028.7,
    "nfaTotal": 8189,
    "nrStorage": 91.9,
    "nrApartm": 138,
    "nfaGfa": 75.4,
    "volume": 31447.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4291.6,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1481.76,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8701.3,
    "gfaFloors": 7858.9,
    "gfaCirc": 842.4,
    "nfaTotal": 6564.1,
    "nrStorage": 73.6,
    "nrApartm": 111,
    "nfaGfa": 75.4,
    "volume": 24767.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3649.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1521.42,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.73,
    "gfaTotal": 8888.7,
    "gfaFloors": 8043.3,
    "gfaCirc": 845.4,
    "nfaTotal": 6705.8,
    "nrStorage": 75.2,
    "nrApartm": 113,
    "nfaGfa": 75.4,
    "volume": 25592.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3547.4,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1507.45,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.73,
    "gfaTotal": 10866.4,
    "gfaFloors": 9837.7,
    "gfaCirc": 1028.7,
    "nfaTotal": 8189,
    "nrStorage": 91.9,
    "nrApartm": 138,
    "nfaGfa": 75.4,
    "volume": 31447.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4291.6,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1482.3,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8701.3,
    "gfaFloors": 7858.9,
    "gfaCirc": 842.4,
    "nfaTotal": 6564.1,
    "nrStorage": 73.6,
    "nrApartm": 111,
    "nfaGfa": 75.4,
    "volume": 24767.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3649.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1522.09,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.73,
    "gfaTotal": 10866.4,
    "gfaFloors": 9837.7,
    "gfaCirc": 1028.7,
    "nfaTotal": 8189,
    "nrStorage": 91.9,
    "nrApartm": 138,
    "nfaGfa": 75.4,
    "volume": 31447.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4291.6,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1481.22,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10448.4,
    "gfaFloors": 9451.8,
    "gfaCirc": 996.6,
    "nfaTotal": 7882.8,
    "nrStorage": 88.4,
    "nrApartm": 133,
    "nfaGfa": 75.4,
    "volume": 30020.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4220.2,
    "surToVol": 0.221,
    "nrBuildings": 3,
    "costM2": 1490.53,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8421.5,
    "gfaFloors": 7631,
    "gfaCirc": 790.5,
    "nfaTotal": 6350.3,
    "nrStorage": 71.2,
    "nrApartm": 107,
    "nfaGfa": 75.4,
    "volume": 24393.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3333.3,
    "surToVol": 0.236,
    "nrBuildings": 3,
    "costM2": 1515.71,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.26,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.73,
    "gfaTotal": 8009.4,
    "gfaFloors": 7242.9,
    "gfaCirc": 766.5,
    "nfaTotal": 6042.5,
    "nrStorage": 67.8,
    "nrApartm": 102,
    "nfaGfa": 75.4,
    "volume": 22965.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3262,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1528.61,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9998.5,
    "gfaFloors": 9047.4,
    "gfaCirc": 951.1,
    "nfaTotal": 7535.3,
    "nrStorage": 84.5,
    "nrApartm": 127,
    "nfaGfa": 75.4,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1494.19,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.69,
    "gfaTotal": 6956,
    "gfaFloors": 6287.9,
    "gfaCirc": 668.1,
    "nfaTotal": 5245.2,
    "nrStorage": 58.8,
    "nrApartm": 84,
    "nfaGfa": 75.4,
    "volume": 20040.7,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 2786.9,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1510.37,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.66,
    "gfaTotal": 9852.2,
    "gfaFloors": 8898.5,
    "gfaCirc": 953.7,
    "nfaTotal": 7428.5,
    "nrStorage": 83.3,
    "nrApartm": 126,
    "nfaGfa": 75.4,
    "volume": 27622.3,
    "areaGroundFloor": 2187.4,
    "areaRoof": 2187.4,
    "areaFac": 3792,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1467.34,
    "category": "GCC0",
    "distCores": 43.5,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY15_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.73,
    "gfaTotal": 8894.4,
    "gfaFloors": 8043.3,
    "gfaCirc": 851.1,
    "nfaTotal": 6705.8,
    "nrStorage": 75.2,
    "nrApartm": 113,
    "nfaGfa": 75.4,
    "volume": 25592.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3547.4,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1507.82,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.73,
    "gfaTotal": 10860.6,
    "gfaFloors": 9839.9,
    "gfaCirc": 1020.7,
    "nfaTotal": 8190.6,
    "nrStorage": 91.9,
    "nrApartm": 138,
    "nfaGfa": 75.4,
    "volume": 31447.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4291.6,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1482.54,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.73,
    "gfaTotal": 8701.3,
    "gfaFloors": 7858.9,
    "gfaCirc": 842.4,
    "nfaTotal": 6564.1,
    "nrStorage": 73.6,
    "nrApartm": 111,
    "nfaGfa": 75.4,
    "volume": 24767.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3649.2,
    "surToVol": 0.245,
    "nrBuildings": 3,
    "costM2": 1520.74,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9998.5,
    "gfaFloors": 9047.4,
    "gfaCirc": 951.1,
    "nfaTotal": 7535.3,
    "nrStorage": 84.5,
    "nrApartm": 127,
    "nfaGfa": 75.4,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.61,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.73,
    "gfaTotal": 9998.5,
    "gfaFloors": 9047.4,
    "gfaCirc": 951.1,
    "nfaTotal": 7535.3,
    "nrStorage": 84.5,
    "nrApartm": 127,
    "nfaGfa": 75.4,
    "volume": 28821.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4006.1,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.02,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8196.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 842.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 75.9,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1530.67,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8741.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 896.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 75.9,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1521.83,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8196.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 842.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 75.9,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1531.39,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 11030.4,
    "gfaFloors": 9889.6,
    "gfaCirc": 1140.8,
    "nfaTotal": 8370,
    "nrStorage": 94.6,
    "nrApartm": 143,
    "nfaGfa": 75.9,
    "volume": 28927.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5624.2,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1497.35,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8741.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 896.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 75.9,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1522.5,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8196.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 842.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 75.9,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1532.82,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8196.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 842.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 75.9,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1532.11,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8741.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 896.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 75.9,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1523.17,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 11030.4,
    "gfaFloors": 9889.6,
    "gfaCirc": 1140.8,
    "nfaTotal": 8370,
    "nrStorage": 94.6,
    "nrApartm": 143,
    "nfaGfa": 75.9,
    "volume": 28927.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5624.2,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1496.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.52,
    "gfaTotal": 11030.4,
    "gfaFloors": 9889.6,
    "gfaCirc": 1140.8,
    "nfaTotal": 8370,
    "nrStorage": 94.6,
    "nrApartm": 143,
    "nfaGfa": 75.9,
    "volume": 28927.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5624.2,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1497.88,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8733.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 888.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1523.87,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8499.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 865.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1524.18,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9107.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 926.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1515.88,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8499.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 865.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1524.87,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9107.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 926.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1516.53,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9107.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 926.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1517.17,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8499.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 865.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1525.56,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8733.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 888.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1523.2,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8499.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 865.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1526.25,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8733.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 888.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1524.55,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8895.3,
    "gfaFloors": 8030.2,
    "gfaCirc": 865.1,
    "nfaTotal": 6760.1,
    "nrStorage": 76.1,
    "nrApartm": 113,
    "nfaGfa": 76,
    "volume": 24277.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4234.2,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1511.79,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.46,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9277.5,
    "gfaFloors": 8395.7,
    "gfaCirc": 881.8,
    "nfaTotal": 7064.2,
    "nrStorage": 79.5,
    "nrApartm": 119,
    "nfaGfa": 76.1,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1516.29,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9099.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 918.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1517.19,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9277.5,
    "gfaFloors": 8395.7,
    "gfaCirc": 881.8,
    "nfaTotal": 7064.2,
    "nrStorage": 79.5,
    "nrApartm": 119,
    "nfaGfa": 76.1,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1516.92,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9099.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 918.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1517.84,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 910.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1518.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9099.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 918.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1518.48,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 910.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1519.15,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8725.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 880.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76.1,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1525.25,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8172.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 818.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 76.1,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1535.82,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8172.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 818.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 76.1,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1535.1,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8725.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 880.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76.1,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1524.58,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 910.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1519.8,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9277.5,
    "gfaFloors": 8395.7,
    "gfaCirc": 881.8,
    "nfaTotal": 7064.2,
    "nrStorage": 79.5,
    "nrApartm": 119,
    "nfaGfa": 76.1,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1517.56,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8725.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 880.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76.1,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1526.6,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.5,
    "gfaTotal": 8725.4,
    "gfaFloors": 7844.5,
    "gfaCirc": 880.9,
    "nfaTotal": 6637,
    "nrStorage": 75,
    "nrApartm": 114,
    "nfaGfa": 76.1,
    "volume": 22945.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4490.5,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1525.92,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9091.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 910.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1520.44,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8172.7,
    "gfaFloors": 7353.9,
    "gfaCirc": 818.8,
    "nfaTotal": 6220.2,
    "nrStorage": 70.3,
    "nrApartm": 107,
    "nfaGfa": 76.1,
    "volume": 21510.2,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4234.2,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1536.54,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9099.4,
    "gfaFloors": 8180.5,
    "gfaCirc": 918.9,
    "nfaTotal": 6922.5,
    "nrStorage": 78.2,
    "nrApartm": 118,
    "nfaGfa": 76.1,
    "volume": 23927.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4666,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1519.13,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.51,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.51,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.51,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.68,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9277.5,
    "gfaFloors": 8395.7,
    "gfaCirc": 881.8,
    "nfaTotal": 7064.2,
    "nrStorage": 79.5,
    "nrApartm": 119,
    "nfaGfa": 76.1,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1518.19,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.66,
    "gfaTotal": 10649.2,
    "gfaFloors": 9672.2,
    "gfaCirc": 977,
    "nfaTotal": 8103.7,
    "nrStorage": 90.8,
    "nrApartm": 135,
    "nfaGfa": 76.1,
    "volume": 28881.7,
    "areaGroundFloor": 2187.4,
    "areaRoof": 2187.4,
    "areaFac": 4036.5,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1433.77,
    "category": "CGC0",
    "distCores": 43.5,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY15_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9659.6,
    "gfaFloors": 8746.2,
    "gfaCirc": 913.4,
    "nfaTotal": 7351.9,
    "nrStorage": 82.7,
    "nrApartm": 122,
    "nfaGfa": 76.1,
    "volume": 26634.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4490.5,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1497.65,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.36,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6873.9,
    "gfaFloors": 6191.4,
    "gfaCirc": 682.5,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.2,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1555.64,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6873.9,
    "gfaFloors": 6191.4,
    "gfaCirc": 682.5,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.2,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1556.49,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6873.9,
    "gfaFloors": 6191.4,
    "gfaCirc": 682.5,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.2,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1557.34,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6873.9,
    "gfaFloors": 6191.4,
    "gfaCirc": 682.5,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.2,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1558.2,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10139.6,
    "gfaFloors": 9196.2,
    "gfaCirc": 943.4,
    "nfaTotal": 7725.6,
    "nrStorage": 87,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1503.12,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10139.6,
    "gfaFloors": 9196.2,
    "gfaCirc": 943.4,
    "nfaTotal": 7725.6,
    "nrStorage": 87,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1502.54,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10139.6,
    "gfaFloors": 9196.2,
    "gfaCirc": 943.4,
    "nfaTotal": 7725.6,
    "nrStorage": 87,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1503.69,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 841.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76.2,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1528.43,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 841.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76.2,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1529.12,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9611.5,
    "gfaFloors": 8714.1,
    "gfaCirc": 897.4,
    "nfaTotal": 7319.2,
    "nrStorage": 82.4,
    "nrApartm": 122,
    "nfaGfa": 76.2,
    "volume": 26634.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4490.5,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1503.7,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.36,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10091.5,
    "gfaFloors": 9164.1,
    "gfaCirc": 927.4,
    "nfaTotal": 7693.7,
    "nrStorage": 86.6,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1510.63,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8841.4,
    "gfaFloors": 8006.1,
    "gfaCirc": 835.3,
    "nfaTotal": 6735.5,
    "nrStorage": 75.8,
    "nrApartm": 113,
    "nfaGfa": 76.2,
    "volume": 24277.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4234.2,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1521.42,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8841.4,
    "gfaFloors": 8006.1,
    "gfaCirc": 835.3,
    "nfaTotal": 6735.5,
    "nrStorage": 75.8,
    "nrApartm": 113,
    "nfaGfa": 76.2,
    "volume": 24277.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4234.2,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1520.76,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10091.5,
    "gfaFloors": 9164.1,
    "gfaCirc": 927.4,
    "nfaTotal": 7693.7,
    "nrStorage": 86.6,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1510.04,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 841.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76.2,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1530.5,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8475.2,
    "gfaFloors": 7633.9,
    "gfaCirc": 841.3,
    "nfaTotal": 6458.1,
    "nrStorage": 73,
    "nrApartm": 110,
    "nfaGfa": 76.2,
    "volume": 22329.2,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4380.5,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1529.81,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.33,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10091.5,
    "gfaFloors": 9164.1,
    "gfaCirc": 927.4,
    "nfaTotal": 7693.7,
    "nrStorage": 86.6,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1509.46,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8841.4,
    "gfaFloors": 8006.1,
    "gfaCirc": 835.3,
    "nfaTotal": 6735.5,
    "nrStorage": 75.8,
    "nrApartm": 113,
    "nfaGfa": 76.2,
    "volume": 24277.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4234.2,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1522.08,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.51,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10091.5,
    "gfaFloors": 9164.1,
    "gfaCirc": 927.4,
    "nfaTotal": 7693.7,
    "nrStorage": 86.6,
    "nrApartm": 130,
    "nfaGfa": 76.2,
    "volume": 28038.8,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4666,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1511.21,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6865.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 674.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.3,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1558.3,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7176.4,
    "gfaFloors": 6471.4,
    "gfaCirc": 705,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.3,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1546.84,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7176.4,
    "gfaFloors": 6471.4,
    "gfaCirc": 705,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.3,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1547.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6865.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 674.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.3,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1557.44,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7176.4,
    "gfaFloors": 6471.4,
    "gfaCirc": 705,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.3,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1548.47,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6865.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 674.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.3,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1559.15,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7176.4,
    "gfaFloors": 6471.4,
    "gfaCirc": 705,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.3,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1549.29,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6865.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 674.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.3,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1560.01,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9229.4,
    "gfaFloors": 8371.6,
    "gfaCirc": 857.8,
    "nfaTotal": 7040.2,
    "nrStorage": 79.2,
    "nrApartm": 119,
    "nfaGfa": 76.3,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1525.24,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9229.4,
    "gfaFloors": 8371.6,
    "gfaCirc": 857.8,
    "nfaTotal": 7040.2,
    "nrStorage": 79.2,
    "nrApartm": 119,
    "nfaGfa": 76.3,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1524.61,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.62,
    "gfaTotal": 9229.4,
    "gfaFloors": 8371.6,
    "gfaCirc": 857.8,
    "nfaTotal": 7040.2,
    "nrStorage": 79.2,
    "nrApartm": 119,
    "nfaGfa": 76.3,
    "volume": 25412.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4380.5,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1523.97,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.56,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.56,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7168.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 696.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.4,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1548.56,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6857.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 666.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.4,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1559.23,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6857.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 666.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.4,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1560.09,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7168.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 696.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.4,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1549.38,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7522.4,
    "gfaFloors": 6843.6,
    "gfaCirc": 678.8,
    "nfaTotal": 5749.5,
    "nrStorage": 64.7,
    "nrApartm": 96,
    "nfaGfa": 76.4,
    "volume": 20877,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3561.5,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1541.07,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.67,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7522.4,
    "gfaFloors": 6843.6,
    "gfaCirc": 678.8,
    "nfaTotal": 5749.5,
    "nrStorage": 64.7,
    "nrApartm": 96,
    "nfaGfa": 76.4,
    "volume": 20877,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3561.5,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1541.85,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.67,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9369,
    "gfaFloors": 8460.5,
    "gfaCirc": 908.5,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1526.55,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7562.4,
    "gfaFloors": 6867.6,
    "gfaCirc": 694.8,
    "nfaTotal": 5774,
    "nrStorage": 65,
    "nrApartm": 96,
    "nfaGfa": 76.4,
    "volume": 20877,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3561.5,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1533.16,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.67,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7168.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 696.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.4,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1550.2,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9360.9,
    "gfaFloors": 8460.5,
    "gfaCirc": 900.4,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1527.85,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7562.4,
    "gfaFloors": 6867.6,
    "gfaCirc": 694.8,
    "nfaTotal": 5774,
    "nrStorage": 65,
    "nrApartm": 96,
    "nfaGfa": 76.4,
    "volume": 20877,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3561.5,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1534.72,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.67,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7168.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 696.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.4,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1551.02,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.6,
    "gfaTotal": 7522.4,
    "gfaFloors": 6843.6,
    "gfaCirc": 678.8,
    "nfaTotal": 5749.5,
    "nrStorage": 64.7,
    "nrApartm": 96,
    "nfaGfa": 76.4,
    "volume": 20877,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3561.5,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1542.63,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.13,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.67,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6857.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 666.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.4,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1560.94,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.1,
    "percBuilt": 0.5,
    "gfaTotal": 6857.8,
    "gfaFloors": 6191.4,
    "gfaCirc": 666.4,
    "nfaTotal": 5237.1,
    "nrStorage": 59.2,
    "nrApartm": 90,
    "nfaGfa": 76.4,
    "volume": 18109.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3561.5,
    "surToVol": 0.288,
    "nrBuildings": 3,
    "costM2": 1561.8,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.03,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9369,
    "gfaFloors": 8460.5,
    "gfaCirc": 908.5,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1528.43,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9360.9,
    "gfaFloors": 8460.5,
    "gfaCirc": 900.4,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1528.48,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9369,
    "gfaFloors": 8460.5,
    "gfaCirc": 908.5,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1527.18,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.62,
    "gfaTotal": 12720.5,
    "gfaFloors": 11559.1,
    "gfaCirc": 1161.4,
    "nfaTotal": 9724.1,
    "nrStorage": 109.4,
    "nrApartm": 165,
    "nfaGfa": 76.4,
    "volume": 35329.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5666.3,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1480.52,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.62,
    "gfaTotal": 12720.5,
    "gfaFloors": 11559.1,
    "gfaCirc": 1161.4,
    "nfaTotal": 9724.1,
    "nrStorage": 109.4,
    "nrApartm": 165,
    "nfaGfa": 76.4,
    "volume": 35329.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5666.3,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1480.99,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.62,
    "gfaTotal": 12720.5,
    "gfaFloors": 11559.1,
    "gfaCirc": 1161.4,
    "nfaTotal": 9724.1,
    "nrStorage": 109.4,
    "nrApartm": 165,
    "nfaGfa": 76.4,
    "volume": 35329.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5666.3,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1481.45,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9369,
    "gfaFloors": 8460.5,
    "gfaCirc": 908.5,
    "nfaTotal": 7155.7,
    "nrStorage": 80.8,
    "nrApartm": 123,
    "nfaGfa": 76.4,
    "volume": 24746.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4877.7,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1527.81,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7950.4,
    "gfaFloors": 7233.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6078.2,
    "nrStorage": 68.4,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1537.76,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 688.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.5,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1550.26,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7950.4,
    "gfaFloors": 7233.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6078.2,
    "nrStorage": 68.4,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1536.28,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7950.4,
    "gfaFloors": 7233.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6078.2,
    "nrStorage": 68.4,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1537.02,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 688.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.5,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1551.08,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9791,
    "gfaFloors": 8852.5,
    "gfaCirc": 938.5,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1517.04,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7910.4,
    "gfaFloors": 7209.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6054.2,
    "nrStorage": 68.1,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1543.81,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 688.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.5,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1551.9,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9782.9,
    "gfaFloors": 8852.5,
    "gfaCirc": 930.4,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1518.27,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7950.4,
    "gfaFloors": 7233.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6078.2,
    "nrStorage": 68.4,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1538.49,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9782.9,
    "gfaFloors": 8852.5,
    "gfaCirc": 930.4,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1518.87,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9791,
    "gfaFloors": 8852.5,
    "gfaCirc": 938.5,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1518.24,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9782.9,
    "gfaFloors": 8852.5,
    "gfaCirc": 930.4,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1519.47,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.2,
    "percBuilt": 0.52,
    "gfaTotal": 7160.3,
    "gfaFloors": 6471.4,
    "gfaCirc": 688.9,
    "nfaTotal": 5475,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 76.5,
    "volume": 18928.7,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 3707.7,
    "surToVol": 0.286,
    "nrBuildings": 3,
    "costM2": 1552.72,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.09,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.09,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.07,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.93,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7910.4,
    "gfaFloors": 7209.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6054.2,
    "nrStorage": 68.1,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1546.04,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9791,
    "gfaFloors": 8852.5,
    "gfaCirc": 938.5,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1517.64,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7910.4,
    "gfaFloors": 7209.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6054.2,
    "nrStorage": 68.1,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1544.55,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.62,
    "gfaTotal": 7910.4,
    "gfaFloors": 7209.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6054.2,
    "nrStorage": 68.1,
    "nrApartm": 102,
    "nfaGfa": 76.5,
    "volume": 22011.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3707.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1545.29,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.21,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.62,
    "gfaTotal": 12752.6,
    "gfaFloors": 11591.2,
    "gfaCirc": 1161.4,
    "nfaTotal": 9758.3,
    "nrStorage": 109.8,
    "nrApartm": 165,
    "nfaGfa": 76.5,
    "volume": 35329.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5666.3,
    "surToVol": 0.219,
    "nrBuildings": 3,
    "costM2": 1477.87,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9782.9,
    "gfaFloors": 8852.5,
    "gfaCirc": 930.4,
    "nfaTotal": 7488.8,
    "nrStorage": 84.6,
    "nrApartm": 127,
    "nfaGfa": 76.5,
    "volume": 25893.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5082.5,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1520.07,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7509.5,
    "gfaFloors": 6807.4,
    "gfaCirc": 702.1,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.6,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1557.08,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7509.5,
    "gfaFloors": 6807.4,
    "gfaCirc": 702.1,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.6,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1557.86,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7509.5,
    "gfaFloors": 6807.4,
    "gfaCirc": 702.1,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.6,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1558.64,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 10023.5,
    "gfaFloors": 9118.1,
    "gfaCirc": 905.4,
    "nfaTotal": 7677.3,
    "nrStorage": 86.4,
    "nrApartm": 128,
    "nfaGfa": 76.6,
    "volume": 27793.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4532.6,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1493.21,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7501.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 694,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.7,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1559.51,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7501.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 694,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.7,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1560.29,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7501.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 694,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.7,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1558.73,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10539.5,
    "gfaFloors": 9604.1,
    "gfaCirc": 935.4,
    "nfaTotal": 8084.6,
    "nrStorage": 91,
    "nrApartm": 137,
    "nfaGfa": 76.7,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1501.45,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10539.5,
    "gfaFloors": 9604.1,
    "gfaCirc": 935.4,
    "nfaTotal": 8084.6,
    "nrStorage": 91,
    "nrApartm": 137,
    "nfaGfa": 76.7,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1500.34,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10539.5,
    "gfaFloors": 9604.1,
    "gfaCirc": 935.4,
    "nfaTotal": 8084.6,
    "nrStorage": 91,
    "nrApartm": 137,
    "nfaGfa": 76.7,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1500.89,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 10047.6,
    "gfaFloors": 9142.2,
    "gfaCirc": 905.4,
    "nfaTotal": 7704.4,
    "nrStorage": 86.7,
    "nrApartm": 128,
    "nfaGfa": 76.7,
    "volume": 27793.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4532.6,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1489.78,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.24,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7501.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 694,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.7,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1561.08,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7493.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 686,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.8,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1560.37,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7868,
    "gfaFloors": 7143.4,
    "gfaCirc": 724.6,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.8,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1544.79,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7868,
    "gfaFloors": 7143.4,
    "gfaCirc": 724.6,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.8,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1544.05,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10563.6,
    "gfaFloors": 9628.2,
    "gfaCirc": 935.4,
    "nfaTotal": 8110.8,
    "nrStorage": 91.3,
    "nrApartm": 137,
    "nfaGfa": 76.8,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1497.05,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10563.6,
    "gfaFloors": 9628.2,
    "gfaCirc": 935.4,
    "nfaTotal": 8110.8,
    "nrStorage": 91.3,
    "nrApartm": 137,
    "nfaGfa": 76.8,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1497.61,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7868,
    "gfaFloors": 7143.4,
    "gfaCirc": 724.6,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.8,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1545.54,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7493.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 686,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.8,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1561.15,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 9996.5,
    "gfaFloors": 9076.5,
    "gfaCirc": 920,
    "nfaTotal": 7674.5,
    "nrStorage": 86.7,
    "nrApartm": 132,
    "nfaGfa": 76.8,
    "volume": 26548.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5265,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1530.74,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10563.6,
    "gfaFloors": 9628.2,
    "gfaCirc": 935.4,
    "nfaTotal": 8110.8,
    "nrStorage": 91.3,
    "nrApartm": 137,
    "nfaGfa": 76.8,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1498.17,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8198,
    "gfaFloors": 7483.6,
    "gfaCirc": 714.4,
    "nfaTotal": 6294.2,
    "nrStorage": 70.8,
    "nrApartm": 105,
    "nfaGfa": 76.8,
    "volume": 22678.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3948.8,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1537.66,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7493.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 686,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.8,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.7,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1561.93,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10563.6,
    "gfaFloors": 9628.2,
    "gfaCirc": 935.4,
    "nfaTotal": 8110.8,
    "nrStorage": 91.3,
    "nrApartm": 137,
    "nfaGfa": 76.8,
    "volume": 29302.4,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4708.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1498.72,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.5,
    "gfaTotal": 9996.5,
    "gfaFloors": 9076.5,
    "gfaCirc": 920,
    "nfaTotal": 7674.5,
    "nrStorage": 86.7,
    "nrApartm": 132,
    "nfaGfa": 76.8,
    "volume": 26548.7,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5265,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1531.32,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7868,
    "gfaFloors": 7143.4,
    "gfaCirc": 724.6,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.8,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1546.29,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8198,
    "gfaFloors": 7483.6,
    "gfaCirc": 714.4,
    "nfaTotal": 6294.2,
    "nrStorage": 70.8,
    "nrApartm": 105,
    "nfaGfa": 76.8,
    "volume": 22678.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3948.8,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1536.23,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8198,
    "gfaFloors": 7483.6,
    "gfaCirc": 714.4,
    "nfaTotal": 6294.2,
    "nrStorage": 70.8,
    "nrApartm": 105,
    "nfaGfa": 76.8,
    "volume": 22678.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3948.8,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1536.95,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.29,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.29,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7851.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 708.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1547.16,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7859.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 716.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.2,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1545.6,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7851.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 708.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1547.91,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8642,
    "gfaFloors": 7905.1,
    "gfaCirc": 736.9,
    "nfaTotal": 6646.1,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 76.9,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1534.58,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7851.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 708.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1548.66,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7859.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 716.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.2,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1546.35,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7859.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 716.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.2,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1547.1,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8642,
    "gfaFloors": 7905.1,
    "gfaCirc": 736.9,
    "nfaTotal": 6646.1,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 76.9,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1535.26,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7485.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 678,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.9,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1562.02,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8150,
    "gfaFloors": 7459.6,
    "gfaCirc": 690.4,
    "nfaTotal": 6269.6,
    "nrStorage": 70.5,
    "nrApartm": 105,
    "nfaGfa": 76.9,
    "volume": 22678.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3948.8,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1545.02,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7485.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 678,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.9,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1562.8,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10474.5,
    "gfaFloors": 9524.5,
    "gfaCirc": 950,
    "nfaTotal": 8055.1,
    "nrStorage": 91,
    "nrApartm": 136,
    "nfaGfa": 76.9,
    "volume": 27859.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5499,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1518.62,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.52,
    "gfaTotal": 10474.5,
    "gfaFloors": 9524.5,
    "gfaCirc": 950,
    "nfaTotal": 8055.1,
    "nrStorage": 91,
    "nrApartm": 136,
    "nfaGfa": 76.9,
    "volume": 27859.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5499,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1518.06,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.57,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7859.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 716.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.2,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1547.85,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8642,
    "gfaFloors": 7905.1,
    "gfaCirc": 736.9,
    "nfaTotal": 6646.1,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 76.9,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1535.94,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7851.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 708.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 76.9,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1549.41,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8150,
    "gfaFloors": 7459.6,
    "gfaCirc": 690.4,
    "nfaTotal": 6269.6,
    "nrStorage": 70.5,
    "nrApartm": 105,
    "nfaGfa": 76.9,
    "volume": 22678.8,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3948.8,
    "surToVol": 0.261,
    "nrBuildings": 3,
    "costM2": 1545.74,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.59,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7485.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 678,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.9,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1563.59,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8642,
    "gfaFloors": 7905.1,
    "gfaCirc": 736.9,
    "nfaTotal": 6646.1,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 76.9,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1536.62,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.3,
    "percBuilt": 0.5,
    "gfaTotal": 7485.4,
    "gfaFloors": 6807.4,
    "gfaCirc": 678,
    "nfaTotal": 5755.8,
    "nrStorage": 65,
    "nrApartm": 99,
    "nfaGfa": 76.9,
    "volume": 19911.5,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 3948.8,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1564.37,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.11,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.91,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7934.4,
    "gfaFloors": 7247.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6107.6,
    "nrStorage": 68.7,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1525.87,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9896.7,
    "gfaFloors": 9031.6,
    "gfaCirc": 865.1,
    "nfaTotal": 7617.4,
    "nrStorage": 85.7,
    "nrApartm": 127,
    "nfaGfa": 77,
    "volume": 27516.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4318.5,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1487.9,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9896.7,
    "gfaFloors": 9031.6,
    "gfaCirc": 865.1,
    "nfaTotal": 7617.4,
    "nrStorage": 85.7,
    "nrApartm": 127,
    "nfaGfa": 77,
    "volume": 27516.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4318.5,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1488.5,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7843.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 700.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1548.72,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7843.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 700.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1549.47,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7934.4,
    "gfaFloors": 7247.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6107.6,
    "nrStorage": 68.7,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1526.61,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7934.4,
    "gfaFloors": 7247.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6107.6,
    "nrStorage": 68.7,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1527.35,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10435.5,
    "gfaFloors": 9522.1,
    "gfaCirc": 913.4,
    "nfaTotal": 8035.5,
    "nrStorage": 90.4,
    "nrApartm": 134,
    "nfaGfa": 77,
    "volume": 28951.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4574.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1483.55,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7934.4,
    "gfaFloors": 7247.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6107.6,
    "nrStorage": 68.7,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1528.09,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7843.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 700.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1550.21,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.52,
    "gfaTotal": 7843.9,
    "gfaFloors": 7143.4,
    "gfaCirc": 700.5,
    "nfaTotal": 6041.3,
    "nrStorage": 68.2,
    "nrApartm": 102,
    "nfaGfa": 77,
    "volume": 20894.3,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4124.3,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1550.96,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.18,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.21,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.21,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10435.5,
    "gfaFloors": 9522.1,
    "gfaCirc": 913.4,
    "nfaTotal": 8035.5,
    "nrStorage": 90.4,
    "nrApartm": 134,
    "nfaGfa": 77,
    "volume": 28951.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4574.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1484.11,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10435.5,
    "gfaFloors": 9522.1,
    "gfaCirc": 913.4,
    "nfaTotal": 8035.5,
    "nrStorage": 90.4,
    "nrApartm": 134,
    "nfaGfa": 77,
    "volume": 28951.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4574.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1484.67,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10651,
    "gfaFloors": 9734.1,
    "gfaCirc": 916.9,
    "nfaTotal": 8197.5,
    "nrStorage": 92.2,
    "nrApartm": 137,
    "nfaGfa": 77,
    "volume": 29594.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4919.8,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1499.07,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10683.2,
    "gfaFloors": 9758.2,
    "gfaCirc": 925,
    "nfaTotal": 8224.6,
    "nrStorage": 92.5,
    "nrApartm": 137,
    "nfaGfa": 77,
    "volume": 29594.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4919.8,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1494.71,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9896.7,
    "gfaFloors": 9031.6,
    "gfaCirc": 865.1,
    "nfaTotal": 7617.4,
    "nrStorage": 85.7,
    "nrApartm": 127,
    "nfaGfa": 77,
    "volume": 27516.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4318.5,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1489.68,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8358.4,
    "gfaFloors": 7649.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6445.1,
    "nrStorage": 72.5,
    "nrApartm": 109,
    "nfaGfa": 77.1,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1530.46,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8137,
    "gfaFloors": 7423.4,
    "gfaCirc": 713.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.1,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1560.58,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8358.4,
    "gfaFloors": 7649.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6445.1,
    "nrStorage": 72.5,
    "nrApartm": 109,
    "nfaGfa": 77.1,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1531.16,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.5,
    "gfaFloors": 10044.1,
    "gfaCirc": 943.4,
    "nfaTotal": 8475.5,
    "nrStorage": 95.4,
    "nrApartm": 144,
    "nfaGfa": 77.1,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1491.95,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7950.4,
    "gfaFloors": 7263.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6126.5,
    "nrStorage": 68.9,
    "nrApartm": 102,
    "nfaGfa": 77.1,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1522.93,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8137,
    "gfaFloors": 7423.4,
    "gfaCirc": 713.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.1,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1559.86,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.5,
    "gfaFloors": 10044.1,
    "gfaCirc": 943.4,
    "nfaTotal": 8475.5,
    "nrStorage": 95.4,
    "nrApartm": 144,
    "nfaGfa": 77.1,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1492.49,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8594,
    "gfaFloors": 7881.1,
    "gfaCirc": 712.9,
    "nfaTotal": 6622.1,
    "nrStorage": 74.5,
    "nrApartm": 111,
    "nfaGfa": 77.1,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1542.91,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8358.4,
    "gfaFloors": 7649.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6445.1,
    "nrStorage": 72.5,
    "nrApartm": 109,
    "nfaGfa": 77.1,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1531.87,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11255.2,
    "gfaFloors": 10300.2,
    "gfaCirc": 955,
    "nfaTotal": 8678.7,
    "nrStorage": 97.7,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1498.16,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11255.2,
    "gfaFloors": 10300.2,
    "gfaCirc": 955,
    "nfaTotal": 8678.7,
    "nrStorage": 97.7,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1498.68,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8594,
    "gfaFloors": 7881.1,
    "gfaCirc": 712.9,
    "nfaTotal": 6622.1,
    "nrStorage": 74.5,
    "nrApartm": 111,
    "nfaGfa": 77.1,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1544.96,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.62,
    "gfaTotal": 8594,
    "gfaFloors": 7881.1,
    "gfaCirc": 712.9,
    "nfaTotal": 6622.1,
    "nrStorage": 74.5,
    "nrApartm": 111,
    "nfaGfa": 77.1,
    "volume": 23977.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4124.3,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1543.6,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.55,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8137,
    "gfaFloors": 7423.4,
    "gfaCirc": 713.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.1,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1562.02,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11223,
    "gfaFloors": 10276.1,
    "gfaCirc": 946.9,
    "nfaTotal": 8652.5,
    "nrStorage": 97.4,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1502.83,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7950.4,
    "gfaFloors": 7263.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6126.5,
    "nrStorage": 68.9,
    "nrApartm": 102,
    "nfaGfa": 77.1,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1523.67,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11255.2,
    "gfaFloors": 10300.2,
    "gfaCirc": 955,
    "nfaTotal": 8678.7,
    "nrStorage": 97.7,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1499.2,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8358.4,
    "gfaFloors": 7649.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6445.1,
    "nrStorage": 72.5,
    "nrApartm": 109,
    "nfaGfa": 77.1,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1532.57,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8137,
    "gfaFloors": 7423.4,
    "gfaCirc": 713.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.1,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1561.3,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11255.2,
    "gfaFloors": 10300.2,
    "gfaCirc": 955,
    "nfaTotal": 8678.7,
    "nrStorage": 97.7,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1499.72,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11223,
    "gfaFloors": 10276.1,
    "gfaCirc": 946.9,
    "nfaTotal": 8652.5,
    "nrStorage": 97.4,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1503.35,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.5,
    "gfaFloors": 10044.1,
    "gfaCirc": 943.4,
    "nfaTotal": 8475.5,
    "nrStorage": 95.4,
    "nrApartm": 144,
    "nfaGfa": 77.1,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.02,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11223,
    "gfaFloors": 10276.1,
    "gfaCirc": 946.9,
    "nfaTotal": 8652.5,
    "nrStorage": 97.4,
    "nrApartm": 146,
    "nfaGfa": 77.1,
    "volume": 31268,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5124.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1502.3,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.5,
    "gfaFloors": 10044.1,
    "gfaCirc": 943.4,
    "nfaTotal": 8475.5,
    "nrStorage": 95.4,
    "nrApartm": 144,
    "nfaGfa": 77.1,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.56,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.4,
    "percBuilt": 0.6,
    "gfaTotal": 7950.4,
    "gfaFloors": 7263.6,
    "gfaCirc": 686.8,
    "nfaTotal": 6126.5,
    "nrStorage": 68.9,
    "nrApartm": 102,
    "nfaGfa": 77.1,
    "volume": 22035.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3603.6,
    "surToVol": 0.253,
    "nrBuildings": 3,
    "costM2": 1524.4,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8374.4,
    "gfaFloors": 7665.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6463.4,
    "nrStorage": 72.7,
    "nrApartm": 109,
    "nfaGfa": 77.2,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1527.66,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8374.4,
    "gfaFloors": 7665.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6463.4,
    "nrStorage": 72.7,
    "nrApartm": 109,
    "nfaGfa": 77.2,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1528.36,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10379.4,
    "gfaFloors": 9497.6,
    "gfaCirc": 881.8,
    "nfaTotal": 8009.7,
    "nrStorage": 90.1,
    "nrApartm": 136,
    "nfaGfa": 77.2,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1497.3,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8129,
    "gfaFloors": 7423.4,
    "gfaCirc": 705.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.2,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1562.09,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8129,
    "gfaFloors": 7423.4,
    "gfaCirc": 705.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.2,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1561.37,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8374.4,
    "gfaFloors": 7665.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6463.4,
    "nrStorage": 72.7,
    "nrApartm": 109,
    "nfaGfa": 77.2,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1529.06,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.62,
    "gfaTotal": 8374.4,
    "gfaFloors": 7665.1,
    "gfaCirc": 709.3,
    "nfaTotal": 6463.4,
    "nrStorage": 72.7,
    "nrApartm": 109,
    "nfaGfa": 77.2,
    "volume": 23275.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3749.8,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1529.76,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.3,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8129,
    "gfaFloors": 7423.4,
    "gfaCirc": 705.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.2,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1563.54,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8129,
    "gfaFloors": 7423.4,
    "gfaCirc": 705.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.2,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1562.81,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12019.8,
    "gfaFloors": 11059.9,
    "gfaCirc": 959.9,
    "nfaTotal": 9284.1,
    "nrStorage": 104.1,
    "nrApartm": 156,
    "nfaGfa": 77.2,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1482.71,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10379.4,
    "gfaFloors": 9497.6,
    "gfaCirc": 881.8,
    "nfaTotal": 8009.7,
    "nrStorage": 90.1,
    "nrApartm": 136,
    "nfaGfa": 77.2,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1498.43,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10379.4,
    "gfaFloors": 9497.6,
    "gfaCirc": 881.8,
    "nfaTotal": 8009.7,
    "nrStorage": 90.1,
    "nrApartm": 136,
    "nfaGfa": 77.2,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1497.87,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10379.4,
    "gfaFloors": 9497.6,
    "gfaCirc": 881.8,
    "nfaTotal": 8009.7,
    "nrStorage": 90.1,
    "nrApartm": 136,
    "nfaGfa": 77.2,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1499,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10435.6,
    "gfaFloors": 9538.2,
    "gfaCirc": 897.4,
    "nfaTotal": 8056.9,
    "nrStorage": 90.6,
    "nrApartm": 134,
    "nfaGfa": 77.2,
    "volume": 28951.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4574.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1483.59,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12019.8,
    "gfaFloors": 11059.9,
    "gfaCirc": 959.9,
    "nfaTotal": 9284.1,
    "nrStorage": 104.1,
    "nrApartm": 156,
    "nfaGfa": 77.2,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1483.2,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.69,
    "gfaTotal": 11337.7,
    "gfaFloors": 10415.8,
    "gfaCirc": 921.9,
    "nfaTotal": 8749.4,
    "nrStorage": 98.1,
    "nrApartm": 142,
    "nfaGfa": 77.2,
    "volume": 32641.1,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4574.7,
    "surToVol": 0.21,
    "nrBuildings": 3,
    "costM2": 1467.06,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12003.7,
    "gfaFloors": 11051.8,
    "gfaCirc": 951.9,
    "nfaTotal": 9278.4,
    "nrStorage": 104,
    "nrApartm": 156,
    "nfaGfa": 77.3,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1485.13,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8543.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 728.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1544.56,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8543.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 728.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1545.25,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8551.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 736.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1543.14,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8551.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 736.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1543.82,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.6,
    "gfaFloors": 10060.2,
    "gfaCirc": 927.4,
    "nfaTotal": 8496.1,
    "nrStorage": 95.6,
    "nrApartm": 144,
    "nfaGfa": 77.3,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1492,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8121,
    "gfaFloors": 7423.4,
    "gfaCirc": 697.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.3,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1563.61,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8121,
    "gfaFloors": 7423.4,
    "gfaCirc": 697.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.3,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1562.88,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.6,
    "gfaFloors": 10060.2,
    "gfaCirc": 927.4,
    "nfaTotal": 8496.1,
    "nrStorage": 95.6,
    "nrApartm": 144,
    "nfaGfa": 77.3,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.07,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.6,
    "gfaFloors": 10060.2,
    "gfaCirc": 927.4,
    "nfaTotal": 8496.1,
    "nrStorage": 95.6,
    "nrApartm": 144,
    "nfaGfa": 77.3,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1492.53,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8543.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 728.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1545.93,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10987.6,
    "gfaFloors": 10060.2,
    "gfaCirc": 927.4,
    "nfaTotal": 8496.1,
    "nrStorage": 95.6,
    "nrApartm": 144,
    "nfaGfa": 77.3,
    "volume": 30566,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4750.2,
    "surToVol": 0.223,
    "nrBuildings": 3,
    "costM2": 1493.6,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8543.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 728.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1546.62,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8551.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 736.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1544.51,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.5,
    "gfaTotal": 8121,
    "gfaFloors": 7423.4,
    "gfaCirc": 697.6,
    "nfaTotal": 6274.6,
    "nrStorage": 70.9,
    "nrApartm": 108,
    "nfaGfa": 77.3,
    "volume": 21713.3,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4336,
    "surToVol": 0.276,
    "nrBuildings": 3,
    "costM2": 1564.33,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.85,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12003.7,
    "gfaFloors": 11051.8,
    "gfaCirc": 951.9,
    "nfaTotal": 9278.4,
    "nrStorage": 104,
    "nrApartm": 156,
    "nfaGfa": 77.3,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1484.64,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8551.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 736.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.3,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1545.2,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.6,
    "gfaTotal": 11063.1,
    "gfaFloors": 10138.1,
    "gfaCirc": 925,
    "nfaTotal": 8555.7,
    "nrStorage": 96.3,
    "nrApartm": 143,
    "nfaGfa": 77.3,
    "volume": 30753.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4962,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1489.76,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11157.7,
    "gfaFloors": 10259.4,
    "gfaCirc": 898.3,
    "nfaTotal": 8620.7,
    "nrStorage": 96.7,
    "nrApartm": 145,
    "nfaGfa": 77.3,
    "volume": 32050.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4464.7,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1492.64,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.6,
    "gfaTotal": 11063.1,
    "gfaFloors": 10138.1,
    "gfaCirc": 925,
    "nfaTotal": 8555.7,
    "nrStorage": 96.3,
    "nrApartm": 143,
    "nfaGfa": 77.3,
    "volume": 30753.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4962,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1490.29,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 12003.7,
    "gfaFloors": 11051.8,
    "gfaCirc": 951.9,
    "nfaTotal": 9278.4,
    "nrStorage": 104,
    "nrApartm": 156,
    "nfaGfa": 77.3,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1485.62,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11157.7,
    "gfaFloors": 10259.4,
    "gfaCirc": 898.3,
    "nfaTotal": 8620.7,
    "nrStorage": 96.7,
    "nrApartm": 145,
    "nfaGfa": 77.3,
    "volume": 32050.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4464.7,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1494.22,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9882.9,
    "gfaFloors": 9047.6,
    "gfaCirc": 835.3,
    "nfaTotal": 7638.8,
    "nrStorage": 85.9,
    "nrApartm": 127,
    "nfaGfa": 77.3,
    "volume": 27516.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4318.5,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1490.01,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8562,
    "gfaFloors": 7863.6,
    "gfaCirc": 698.4,
    "nfaTotal": 6627.8,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1530.79,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8535.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 720.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1546.67,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8535.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 720.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1545.98,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8586,
    "gfaFloors": 7879.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6646.7,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1528.02,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10371.4,
    "gfaFloors": 9513.6,
    "gfaCirc": 857.8,
    "nfaTotal": 8030.2,
    "nrStorage": 90.3,
    "nrApartm": 136,
    "nfaGfa": 77.4,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1499.06,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8535.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 720.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1547.36,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10371.4,
    "gfaFloors": 9513.6,
    "gfaCirc": 857.8,
    "nfaTotal": 8030.2,
    "nrStorage": 90.3,
    "nrApartm": 136,
    "nfaGfa": 77.4,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1499.63,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10371.4,
    "gfaFloors": 9513.6,
    "gfaCirc": 857.8,
    "nfaTotal": 8030.2,
    "nrStorage": 90.3,
    "nrApartm": 136,
    "nfaGfa": 77.4,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1500.2,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10371.4,
    "gfaFloors": 9513.6,
    "gfaCirc": 857.8,
    "nfaTotal": 8030.2,
    "nrStorage": 90.3,
    "nrApartm": 136,
    "nfaGfa": 77.4,
    "volume": 28967.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4464.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1498.5,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.52,
    "gfaTotal": 8535.5,
    "gfaFloors": 7815.4,
    "gfaCirc": 720.1,
    "nfaTotal": 6607.6,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 22859.9,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4540.8,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1548.05,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8562,
    "gfaFloors": 7863.6,
    "gfaCirc": 698.4,
    "nfaTotal": 6627.8,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1531.47,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8586,
    "gfaFloors": 7879.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6646.7,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1527.33,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8562,
    "gfaFloors": 7863.6,
    "gfaCirc": 698.4,
    "nfaTotal": 6627.8,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1532.16,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11987.7,
    "gfaFloors": 11043.8,
    "gfaCirc": 943.9,
    "nfaTotal": 9272.6,
    "nrStorage": 104,
    "nrApartm": 156,
    "nfaGfa": 77.4,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1486.59,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11987.7,
    "gfaFloors": 11043.8,
    "gfaCirc": 943.9,
    "nfaTotal": 9272.6,
    "nrStorage": 104,
    "nrApartm": 156,
    "nfaGfa": 77.4,
    "volume": 34676.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4750.2,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1487.08,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.6,
    "percBuilt": 0.6,
    "gfaTotal": 8586,
    "gfaFloors": 7879.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6646.7,
    "nrStorage": 74.8,
    "nrApartm": 111,
    "nfaGfa": 77.4,
    "volume": 23837.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3990.9,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1526.65,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.43,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.43,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.32,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.44,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8346.4,
    "gfaFloors": 7651.6,
    "gfaCirc": 694.8,
    "nfaTotal": 6465.8,
    "nrStorage": 72.7,
    "nrApartm": 108,
    "nfaGfa": 77.5,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1512.18,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8346.4,
    "gfaFloors": 7651.6,
    "gfaCirc": 694.8,
    "nfaTotal": 6465.8,
    "nrStorage": 72.7,
    "nrApartm": 108,
    "nfaGfa": 77.5,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1513.59,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8346.4,
    "gfaFloors": 7651.6,
    "gfaCirc": 694.8,
    "nfaTotal": 6465.8,
    "nrStorage": 72.7,
    "nrApartm": 108,
    "nfaGfa": 77.5,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1512.88,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8764.6,
    "gfaFloors": 8039.4,
    "gfaCirc": 725.2,
    "nfaTotal": 6793.3,
    "nrStorage": 76.7,
    "nrApartm": 117,
    "nfaGfa": 77.5,
    "volume": 23515.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4723.3,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1562.19,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8764.6,
    "gfaFloors": 8039.4,
    "gfaCirc": 725.2,
    "nfaTotal": 6793.3,
    "nrStorage": 76.7,
    "nrApartm": 117,
    "nfaGfa": 77.5,
    "volume": 23515.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4723.3,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1562.86,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.69,
    "gfaTotal": 9035.1,
    "gfaFloors": 8327.9,
    "gfaCirc": 707.2,
    "nfaTotal": 7006.7,
    "nrStorage": 78.6,
    "nrApartm": 114,
    "nfaGfa": 77.5,
    "volume": 25960.9,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3645.7,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1496.68,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11125.6,
    "gfaFloors": 10251.3,
    "gfaCirc": 874.3,
    "nfaTotal": 8617.3,
    "nrStorage": 96.6,
    "nrApartm": 145,
    "nfaGfa": 77.5,
    "volume": 32050.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4464.7,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1497.91,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8346.4,
    "gfaFloors": 7651.6,
    "gfaCirc": 694.8,
    "nfaTotal": 6465.8,
    "nrStorage": 72.7,
    "nrApartm": 108,
    "nfaGfa": 77.5,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1514.29,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11671.1,
    "gfaFloors": 10716.1,
    "gfaCirc": 955,
    "nfaTotal": 9043.4,
    "nrStorage": 101.8,
    "nrApartm": 153,
    "nfaGfa": 77.5,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1494.33,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11125.6,
    "gfaFloors": 10251.3,
    "gfaCirc": 874.3,
    "nfaTotal": 8617.3,
    "nrStorage": 96.6,
    "nrApartm": 145,
    "nfaGfa": 77.5,
    "volume": 32050.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4464.7,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1496.85,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11671.1,
    "gfaFloors": 10716.1,
    "gfaCirc": 955,
    "nfaTotal": 9043.4,
    "nrStorage": 101.8,
    "nrApartm": 153,
    "nfaGfa": 77.5,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1495.83,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11671.1,
    "gfaFloors": 10716.1,
    "gfaCirc": 955,
    "nfaTotal": 9043.4,
    "nrStorage": 101.8,
    "nrApartm": 153,
    "nfaGfa": 77.5,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1495.33,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11671.1,
    "gfaFloors": 10716.1,
    "gfaCirc": 955,
    "nfaTotal": 9043.4,
    "nrStorage": 101.8,
    "nrApartm": 153,
    "nfaGfa": 77.5,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1494.83,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.73,
    "gfaTotal": 11125.6,
    "gfaFloors": 10251.3,
    "gfaCirc": 874.3,
    "nfaTotal": 8617.3,
    "nrStorage": 96.6,
    "nrApartm": 145,
    "nfaGfa": 77.5,
    "volume": 32050.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4464.7,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1498.44,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9042,
    "gfaFloors": 8321.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7013,
    "nrStorage": 78.9,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1530.62,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8806.4,
    "gfaFloors": 8089.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6836,
    "nrStorage": 76.9,
    "nrApartm": 116,
    "nfaGfa": 77.6,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1519.14,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8806.4,
    "gfaFloors": 8089.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6836,
    "nrStorage": 76.9,
    "nrApartm": 116,
    "nfaGfa": 77.6,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1518.48,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9066,
    "gfaFloors": 8337.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7031.3,
    "nrStorage": 79.1,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1526.7,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9066,
    "gfaFloors": 8337.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7031.3,
    "nrStorage": 79.1,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1527.34,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8756.5,
    "gfaFloors": 8039.4,
    "gfaCirc": 717.1,
    "nfaTotal": 6793.3,
    "nrStorage": 76.7,
    "nrApartm": 117,
    "nfaGfa": 77.6,
    "volume": 23515.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4723.3,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1564.29,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.5,
    "gfaTotal": 8756.5,
    "gfaFloors": 8039.4,
    "gfaCirc": 717.1,
    "nfaTotal": 6793.3,
    "nrStorage": 76.7,
    "nrApartm": 117,
    "nfaGfa": 77.6,
    "volume": 23515.1,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 4723.3,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1563.61,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.77,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11679.1,
    "gfaFloors": 10732.2,
    "gfaCirc": 946.9,
    "nfaTotal": 9064,
    "nrStorage": 102,
    "nrApartm": 153,
    "nfaGfa": 77.6,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1494.38,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9066,
    "gfaFloors": 8337.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7031.3,
    "nrStorage": 79.1,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1527.99,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9066,
    "gfaFloors": 8337.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7031.3,
    "nrStorage": 79.1,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1528.64,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9042,
    "gfaFloors": 8321.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7013,
    "nrStorage": 78.9,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1531.92,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 9042,
    "gfaFloors": 8321.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7013,
    "nrStorage": 78.9,
    "nrApartm": 118,
    "nfaGfa": 77.6,
    "volume": 25241.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4166.4,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1531.27,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.4,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11679.1,
    "gfaFloors": 10732.2,
    "gfaCirc": 946.9,
    "nfaTotal": 9064,
    "nrStorage": 102,
    "nrApartm": 153,
    "nfaGfa": 77.6,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1493.37,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.5,
    "percBuilt": 0.62,
    "gfaTotal": 11679.1,
    "gfaFloors": 10732.2,
    "gfaCirc": 946.9,
    "nfaTotal": 9064,
    "nrStorage": 102,
    "nrApartm": 153,
    "nfaGfa": 77.6,
    "volume": 32531.6,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5166.7,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1493.87,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.92,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8806.4,
    "gfaFloors": 8089.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6836,
    "nrStorage": 76.9,
    "nrApartm": 116,
    "nfaGfa": 77.6,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1520.48,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8806.4,
    "gfaFloors": 8089.1,
    "gfaCirc": 717.3,
    "nfaTotal": 6836,
    "nrStorage": 76.9,
    "nrApartm": 116,
    "nfaGfa": 77.6,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1519.81,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.69,
    "gfaTotal": 9019.1,
    "gfaFloors": 8319.9,
    "gfaCirc": 699.2,
    "nfaTotal": 7001,
    "nrStorage": 78.5,
    "nrApartm": 114,
    "nfaGfa": 77.6,
    "volume": 25960.9,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 3645.7,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1499.91,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9580.6,
    "gfaFloors": 8850.9,
    "gfaCirc": 729.7,
    "nfaTotal": 7443.8,
    "nrStorage": 83.5,
    "nrApartm": 125,
    "nfaGfa": 77.7,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1512.58,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9235.1,
    "gfaFloors": 8487.4,
    "gfaCirc": 747.7,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1542.34,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8338.4,
    "gfaFloors": 7659.6,
    "gfaCirc": 678.8,
    "nfaTotal": 6479,
    "nrStorage": 72.9,
    "nrApartm": 108,
    "nfaGfa": 77.7,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1514.38,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8338.4,
    "gfaFloors": 7659.6,
    "gfaCirc": 678.8,
    "nfaTotal": 6479,
    "nrStorage": 72.9,
    "nrApartm": 108,
    "nfaGfa": 77.7,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1513.68,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9227,
    "gfaFloors": 8487.4,
    "gfaCirc": 739.6,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1544.95,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9227,
    "gfaFloors": 8487.4,
    "gfaCirc": 739.6,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1544.31,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9227,
    "gfaFloors": 8487.4,
    "gfaCirc": 739.6,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1545.59,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.5,
    "percBuilt": 0.6,
    "gfaTotal": 8338.4,
    "gfaFloors": 7659.6,
    "gfaCirc": 678.8,
    "nfaTotal": 6479,
    "nrStorage": 72.9,
    "nrApartm": 108,
    "nfaGfa": 77.7,
    "volume": 23193.6,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 3645.7,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1515.09,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9580.6,
    "gfaFloors": 8850.9,
    "gfaCirc": 729.7,
    "nfaTotal": 7443.8,
    "nrStorage": 83.5,
    "nrApartm": 125,
    "nfaGfa": 77.7,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1511.97,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9227,
    "gfaFloors": 8487.4,
    "gfaCirc": 739.6,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1543.68,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9580.6,
    "gfaFloors": 8850.9,
    "gfaCirc": 729.7,
    "nfaTotal": 7443.8,
    "nrStorage": 83.5,
    "nrApartm": 125,
    "nfaGfa": 77.7,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.2,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9235.1,
    "gfaFloors": 8487.4,
    "gfaCirc": 747.7,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1543.62,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.52,
    "gfaTotal": 9235.1,
    "gfaFloors": 8487.4,
    "gfaCirc": 747.7,
    "nfaTotal": 7173.9,
    "nrStorage": 81,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 24825.5,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 4957.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1542.98,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9221.6,
    "gfaFloors": 8495.6,
    "gfaCirc": 726,
    "nfaTotal": 7166.9,
    "nrStorage": 80.6,
    "nrApartm": 120,
    "nfaGfa": 77.7,
    "volume": 25638.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4378.1,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1529.82,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8267.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6986,
    "nrStorage": 78.6,
    "nrApartm": 117,
    "nfaGfa": 77.8,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1518.48,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9564.6,
    "gfaFloors": 8842.9,
    "gfaCirc": 721.7,
    "nfaTotal": 7438.1,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1514.43,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8798.4,
    "gfaFloors": 8097.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6848.6,
    "nrStorage": 77.1,
    "nrApartm": 116,
    "nfaGfa": 77.8,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1519.91,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8267.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6986,
    "nrStorage": 78.6,
    "nrApartm": 117,
    "nfaGfa": 77.8,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1517.83,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8267.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6986,
    "nrStorage": 78.6,
    "nrApartm": 117,
    "nfaGfa": 77.8,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1519.14,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9189.5,
    "gfaFloors": 8479.6,
    "gfaCirc": 709.9,
    "nfaTotal": 7148,
    "nrStorage": 80.4,
    "nrApartm": 120,
    "nfaGfa": 77.8,
    "volume": 25638.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4378.1,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1535.65,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9189.5,
    "gfaFloors": 8479.6,
    "gfaCirc": 709.9,
    "nfaTotal": 7148,
    "nrStorage": 80.4,
    "nrApartm": 120,
    "nfaGfa": 77.8,
    "volume": 25638.9,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4378.1,
    "surToVol": 0.248,
    "nrBuildings": 3,
    "costM2": 1535.01,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9564.6,
    "gfaFloors": 8842.9,
    "gfaCirc": 721.7,
    "nfaTotal": 7438.1,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1515.04,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8798.4,
    "gfaFloors": 8097.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6848.6,
    "nrStorage": 77.1,
    "nrApartm": 116,
    "nfaGfa": 77.8,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1520.57,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8798.4,
    "gfaFloors": 8097.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6848.6,
    "nrStorage": 77.1,
    "nrApartm": 116,
    "nfaGfa": 77.8,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1521.24,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.62,
    "gfaTotal": 8798.4,
    "gfaFloors": 8097.1,
    "gfaCirc": 701.3,
    "nfaTotal": 6848.6,
    "nrStorage": 77.1,
    "nrApartm": 116,
    "nfaGfa": 77.8,
    "volume": 24539.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 3792,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1521.91,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8267.6,
    "gfaCirc": 706.4,
    "nfaTotal": 6986,
    "nrStorage": 78.6,
    "nrApartm": 117,
    "nfaGfa": 77.8,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1519.79,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9548.6,
    "gfaFloors": 8834.9,
    "gfaCirc": 713.7,
    "nfaTotal": 7432.4,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1517.51,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9564.6,
    "gfaFloors": 8842.9,
    "gfaCirc": 721.7,
    "nfaTotal": 7438.1,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1516.27,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9548.6,
    "gfaFloors": 8834.9,
    "gfaCirc": 713.7,
    "nfaTotal": 7432.4,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1518.12,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9548.6,
    "gfaFloors": 8834.9,
    "gfaCirc": 713.7,
    "nfaTotal": 7432.4,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1516.89,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12354.6,
    "gfaFloors": 11388.1,
    "gfaCirc": 966.5,
    "nfaTotal": 9611.3,
    "nrStorage": 108.1,
    "nrApartm": 162,
    "nfaGfa": 77.8,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1496.42,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12354.6,
    "gfaFloors": 11388.1,
    "gfaCirc": 966.5,
    "nfaTotal": 9611.3,
    "nrStorage": 108.1,
    "nrApartm": 162,
    "nfaGfa": 77.8,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1496.9,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.73,
    "gfaTotal": 9564.6,
    "gfaFloors": 8842.9,
    "gfaCirc": 721.7,
    "nfaTotal": 7438.1,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 77.8,
    "volume": 27622.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 3792,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1515.66,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12354.6,
    "gfaFloors": 11388.1,
    "gfaCirc": 966.5,
    "nfaTotal": 9611.3,
    "nrStorage": 108.1,
    "nrApartm": 162,
    "nfaGfa": 77.8,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1497.37,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9757.6,
    "gfaFloors": 9009.1,
    "gfaCirc": 748.5,
    "nfaTotal": 7599.2,
    "nrStorage": 85.5,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1525.87,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.5,
    "gfaTotal": 9392.1,
    "gfaFloors": 8655.4,
    "gfaCirc": 736.7,
    "nfaTotal": 7312,
    "nrStorage": 82.5,
    "nrApartm": 126,
    "nfaGfa": 77.9,
    "volume": 25316.9,
    "areaGroundFloor": 1653.1,
    "areaRoof": 1653.1,
    "areaFac": 5110.6,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1564.23,
    "category": "GGG0",
    "distCores": 40.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.71,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9757.6,
    "gfaFloors": 9009.1,
    "gfaCirc": 748.5,
    "nfaTotal": 7599.2,
    "nrStorage": 85.5,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1526.47,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9725.5,
    "gfaFloors": 8993.1,
    "gfaCirc": 732.4,
    "nfaTotal": 7580.9,
    "nrStorage": 85.3,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1531.37,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9757.6,
    "gfaFloors": 9009.1,
    "gfaCirc": 748.5,
    "nfaTotal": 7599.2,
    "nrStorage": 85.5,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1527.67,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9725.5,
    "gfaFloors": 8993.1,
    "gfaCirc": 732.4,
    "nfaTotal": 7580.9,
    "nrStorage": 85.3,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1530.76,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9757.6,
    "gfaFloors": 9009.1,
    "gfaCirc": 748.5,
    "nfaTotal": 7599.2,
    "nrStorage": 85.5,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1527.07,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.67,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12370.7,
    "gfaFloors": 11404.2,
    "gfaCirc": 966.5,
    "nfaTotal": 9631.9,
    "nrStorage": 108.4,
    "nrApartm": 162,
    "nfaGfa": 77.9,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1495.05,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12370.7,
    "gfaFloors": 11404.2,
    "gfaCirc": 966.5,
    "nfaTotal": 9631.9,
    "nrStorage": 108.4,
    "nrApartm": 162,
    "nfaGfa": 77.9,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1494.58,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.7,
    "percBuilt": 0.62,
    "gfaTotal": 12370.7,
    "gfaFloors": 11404.2,
    "gfaCirc": 966.5,
    "nfaTotal": 9631.9,
    "nrStorage": 108.4,
    "nrApartm": 162,
    "nfaGfa": 77.9,
    "volume": 34497.2,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5583.2,
    "surToVol": 0.222,
    "nrBuildings": 3,
    "costM2": 1495.53,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.96,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9725.5,
    "gfaFloors": 8993.1,
    "gfaCirc": 732.4,
    "nfaTotal": 7580.9,
    "nrStorage": 85.3,
    "nrApartm": 127,
    "nfaGfa": 77.9,
    "volume": 27206.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4582.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1531.97,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.72,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.72,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8761.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7403.9,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 78,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1519.48,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8275.6,
    "gfaCirc": 698.4,
    "nfaTotal": 6999.2,
    "nrStorage": 78.7,
    "nrApartm": 117,
    "nfaGfa": 78,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1517.86,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8761.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7403.9,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 78,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1521.34,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8761.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7403.9,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 78,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1520.1,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.7,
    "percBuilt": 0.6,
    "gfaTotal": 8974,
    "gfaFloors": 8275.6,
    "gfaCirc": 698.4,
    "nfaTotal": 6999.2,
    "nrStorage": 78.7,
    "nrApartm": 117,
    "nfaGfa": 78,
    "volume": 24995.4,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4033,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1519.17,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.42,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.32,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9918.6,
    "gfaFloors": 9159.4,
    "gfaCirc": 759.2,
    "nfaTotal": 7740.2,
    "nrStorage": 87.4,
    "nrApartm": 129,
    "nfaGfa": 78,
    "volume": 26791.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5373.8,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1541.68,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8761.1,
    "gfaCirc": 728.9,
    "nfaTotal": 7403.9,
    "nrStorage": 83.3,
    "nrApartm": 125,
    "nfaGfa": 78,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1520.72,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.52,
    "gfaTotal": 9918.6,
    "gfaFloors": 9159.4,
    "gfaCirc": 759.2,
    "nfaTotal": 7740.2,
    "nrStorage": 87.4,
    "nrApartm": 129,
    "nfaGfa": 78,
    "volume": 26791.1,
    "areaGroundFloor": 1709.1,
    "areaRoof": 1709.1,
    "areaFac": 5373.8,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1542.27,
    "category": "G0GG",
    "distCores": 42.4,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.73,
    "gfaTotal": 13119.2,
    "gfaFloors": 12155.8,
    "gfaCirc": 963.4,
    "nfaTotal": 10234.1,
    "nrStorage": 114.8,
    "nrApartm": 172,
    "nfaGfa": 78,
    "volume": 37906.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5208.8,
    "surToVol": 0.201,
    "nrBuildings": 3,
    "costM2": 1482.44,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.73,
    "gfaTotal": 13135.4,
    "gfaFloors": 12163.9,
    "gfaCirc": 971.5,
    "nfaTotal": 10239.8,
    "nrStorage": 114.8,
    "nrApartm": 172,
    "nfaGfa": 78,
    "volume": 37906.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5208.8,
    "surToVol": 0.201,
    "nrBuildings": 3,
    "costM2": 1480.64,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.73,
    "gfaTotal": 13119.2,
    "gfaFloors": 12155.8,
    "gfaCirc": 963.4,
    "nfaTotal": 10234.1,
    "nrStorage": 114.8,
    "nrApartm": 172,
    "nfaGfa": 78,
    "volume": 37906.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5208.8,
    "surToVol": 0.201,
    "nrBuildings": 3,
    "costM2": 1483.78,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.73,
    "gfaTotal": 13119.2,
    "gfaFloors": 12155.8,
    "gfaCirc": 963.4,
    "nfaTotal": 10234.1,
    "nrStorage": 114.8,
    "nrApartm": 172,
    "nfaGfa": 78,
    "volume": 37906.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5208.8,
    "surToVol": 0.201,
    "nrBuildings": 3,
    "costM2": 1482.89,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4,
    "percBuilt": 0.73,
    "gfaTotal": 13135.4,
    "gfaFloors": 12163.9,
    "gfaCirc": 971.5,
    "nfaTotal": 10239.8,
    "nrStorage": 114.8,
    "nrApartm": 172,
    "nfaGfa": 78,
    "volume": 37906.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5208.8,
    "surToVol": 0.201,
    "nrBuildings": 3,
    "costM2": 1481.09,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9601.6,
    "gfaFloors": 8883.6,
    "gfaCirc": 718,
    "nfaTotal": 7506.2,
    "nrStorage": 84.4,
    "nrApartm": 126,
    "nfaGfa": 78.2,
    "volume": 26797.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4420.3,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1522.71,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8769.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7416.5,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 78.2,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1519.52,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9386,
    "gfaFloors": 8671.6,
    "gfaCirc": 714.4,
    "nfaTotal": 7344.2,
    "nrStorage": 82.6,
    "nrApartm": 123,
    "nfaGfa": 78.2,
    "volume": 26153.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4075.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1506,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9386,
    "gfaFloors": 8671.6,
    "gfaCirc": 714.4,
    "nfaTotal": 7344.2,
    "nrStorage": 82.6,
    "nrApartm": 123,
    "nfaGfa": 78.2,
    "volume": 26153.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4075.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1506.63,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8769.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7416.5,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 78.2,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1520.13,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.62,
    "gfaTotal": 9490,
    "gfaFloors": 8769.1,
    "gfaCirc": 720.9,
    "nfaTotal": 7416.5,
    "nrStorage": 83.4,
    "nrApartm": 125,
    "nfaGfa": 78.2,
    "volume": 26504.7,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4208.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1520.75,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.59,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9386,
    "gfaFloors": 8671.6,
    "gfaCirc": 714.4,
    "nfaTotal": 7344.2,
    "nrStorage": 82.6,
    "nrApartm": 123,
    "nfaGfa": 78.2,
    "volume": 26153.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4075.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1507.26,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.65,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.65,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9601.6,
    "gfaFloors": 8883.6,
    "gfaCirc": 718,
    "nfaTotal": 7506.2,
    "nrStorage": 84.4,
    "nrApartm": 126,
    "nfaGfa": 78.2,
    "volume": 26797.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4420.3,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1523.93,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.69,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.69,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.9,
    "percBuilt": 0.6,
    "gfaTotal": 9609.6,
    "gfaFloors": 8891.6,
    "gfaCirc": 718,
    "nfaTotal": 7519.3,
    "nrStorage": 84.6,
    "nrApartm": 126,
    "nfaGfa": 78.2,
    "volume": 26797.2,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4420.3,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1522.74,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.58,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.3,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9938,
    "gfaFloors": 9201.1,
    "gfaCirc": 736.9,
    "nfaTotal": 7794.8,
    "nrStorage": 87.7,
    "nrApartm": 132,
    "nfaGfa": 78.4,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1509.35,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9938,
    "gfaFloors": 9201.1,
    "gfaCirc": 736.9,
    "nfaTotal": 7794.8,
    "nrStorage": 87.7,
    "nrApartm": 132,
    "nfaGfa": 78.4,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1510.53,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9938,
    "gfaFloors": 9201.1,
    "gfaCirc": 736.9,
    "nfaTotal": 7794.8,
    "nrStorage": 87.7,
    "nrApartm": 132,
    "nfaGfa": 78.4,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1509.94,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10181.6,
    "gfaFloors": 9441.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7984.4,
    "nrStorage": 89.8,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1519.21,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10181.6,
    "gfaFloors": 9441.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7984.4,
    "nrStorage": 89.8,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1519.79,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10173.6,
    "gfaFloors": 9433.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7971.8,
    "nrStorage": 89.7,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1520.35,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10181.6,
    "gfaFloors": 9441.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7984.4,
    "nrStorage": 89.8,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1520.36,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10173.6,
    "gfaFloors": 9433.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7971.8,
    "nrStorage": 89.7,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1520.93,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10173.6,
    "gfaFloors": 9433.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7971.8,
    "nrStorage": 89.7,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1521.51,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.62,
    "gfaTotal": 10181.6,
    "gfaFloors": 9441.1,
    "gfaCirc": 740.5,
    "nfaTotal": 7984.4,
    "nrStorage": 89.8,
    "nrApartm": 134,
    "nfaGfa": 78.4,
    "volume": 28470.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4625,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1520.94,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.25,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9938,
    "gfaFloors": 9201.1,
    "gfaCirc": 736.9,
    "nfaTotal": 7794.8,
    "nrStorage": 87.7,
    "nrApartm": 132,
    "nfaGfa": 78.4,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1511.13,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.69,
    "gfaTotal": 10058.7,
    "gfaFloors": 9339.9,
    "gfaCirc": 718.8,
    "nfaTotal": 7881.7,
    "nrStorage": 88.4,
    "nrApartm": 129,
    "nfaGfa": 78.4,
    "volume": 28921,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4075.1,
    "surToVol": 0.22,
    "nrBuildings": 3,
    "costM2": 1496,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10696.2,
    "gfaFloors": 9954.9,
    "gfaCirc": 741.3,
    "nfaTotal": 8399.5,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1506.38,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10229.1,
    "gfaFloors": 9499.6,
    "gfaCirc": 729.5,
    "nfaTotal": 8026.3,
    "nrStorage": 90.3,
    "nrApartm": 135,
    "nfaGfa": 78.5,
    "volume": 28599,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4807.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1527.6,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 2.8,
    "percBuilt": 0.6,
    "gfaTotal": 9362,
    "gfaFloors": 8671.6,
    "gfaCirc": 690.4,
    "nfaTotal": 7351.6,
    "nrStorage": 82.7,
    "nrApartm": 123,
    "nfaGfa": 78.5,
    "volume": 26153.7,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4075.1,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1509.84,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.57,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.5,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.21,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10245.1,
    "gfaFloors": 9507.6,
    "gfaCirc": 737.5,
    "nfaTotal": 8039.5,
    "nrStorage": 90.4,
    "nrApartm": 135,
    "nfaGfa": 78.5,
    "volume": 28599,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4807.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1525.85,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.1,
    "percBuilt": 0.6,
    "gfaTotal": 10229.1,
    "gfaFloors": 9499.6,
    "gfaCirc": 729.5,
    "nfaTotal": 8026.3,
    "nrStorage": 90.3,
    "nrApartm": 135,
    "nfaGfa": 78.5,
    "volume": 28599,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4807.5,
    "surToVol": 0.237,
    "nrBuildings": 3,
    "costM2": 1527.02,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.28,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.69,
    "gfaTotal": 10026.6,
    "gfaFloors": 9323.9,
    "gfaCirc": 702.7,
    "nfaTotal": 7870.3,
    "nrStorage": 88.2,
    "nrApartm": 129,
    "nfaGfa": 78.5,
    "volume": 28921,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4075.1,
    "surToVol": 0.22,
    "nrBuildings": 3,
    "costM2": 1499.48,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.06,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10696.2,
    "gfaFloors": 9954.9,
    "gfaCirc": 741.3,
    "nfaTotal": 8399.5,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1507.48,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 10013.6,
    "gfaFloors": 9287.6,
    "gfaCirc": 726,
    "nfaTotal": 7864.4,
    "nrStorage": 88.5,
    "nrApartm": 132,
    "nfaGfa": 78.5,
    "volume": 27955.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4462.4,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1512.04,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10696.2,
    "gfaFloors": 9954.9,
    "gfaCirc": 741.3,
    "nfaTotal": 8399.5,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1506.93,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10696.2,
    "gfaFloors": 9954.9,
    "gfaCirc": 741.3,
    "nfaTotal": 8399.5,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1508.03,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 10013.6,
    "gfaFloors": 9287.6,
    "gfaCirc": 726,
    "nfaTotal": 7864.4,
    "nrStorage": 88.5,
    "nrApartm": 132,
    "nfaGfa": 78.5,
    "volume": 27955.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4462.4,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1511.45,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10712.2,
    "gfaFloors": 9962.9,
    "gfaCirc": 749.3,
    "nfaTotal": 8405.3,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1505.26,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10712.2,
    "gfaFloors": 9962.9,
    "gfaCirc": 749.3,
    "nfaTotal": 8405.3,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1504.16,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10712.2,
    "gfaFloors": 9962.9,
    "gfaCirc": 749.3,
    "nfaTotal": 8405.3,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.5,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1505.81,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10680.2,
    "gfaFloors": 9946.9,
    "gfaCirc": 733.3,
    "nfaTotal": 8393.8,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.6,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1508.57,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10680.2,
    "gfaFloors": 9946.9,
    "gfaCirc": 733.3,
    "nfaTotal": 8393.8,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.6,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1509.12,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10680.2,
    "gfaFloors": 9946.9,
    "gfaCirc": 733.3,
    "nfaTotal": 8393.8,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.6,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1509.67,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14250.9,
    "gfaFloors": 13267.9,
    "gfaCirc": 983,
    "nfaTotal": 11195.6,
    "nrStorage": 125.5,
    "nrApartm": 188,
    "nfaGfa": 78.6,
    "volume": 41135.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5667.5,
    "surToVol": 0.196,
    "nrBuildings": 3,
    "costM2": 1479.74,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14250.9,
    "gfaFloors": 13267.9,
    "gfaCirc": 983,
    "nfaTotal": 11195.6,
    "nrStorage": 125.5,
    "nrApartm": 188,
    "nfaGfa": 78.6,
    "volume": 41135.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5667.5,
    "surToVol": 0.196,
    "nrBuildings": 3,
    "costM2": 1478.91,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14250.9,
    "gfaFloors": 13267.9,
    "gfaCirc": 983,
    "nfaTotal": 11195.6,
    "nrStorage": 125.5,
    "nrApartm": 188,
    "nfaGfa": 78.6,
    "volume": 41135.3,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5667.5,
    "surToVol": 0.196,
    "nrBuildings": 3,
    "costM2": 1479.32,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10621.6,
    "gfaFloors": 9873.1,
    "gfaCirc": 748.5,
    "nfaTotal": 8362.7,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1510.84,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10873.1,
    "gfaFloors": 10113.1,
    "gfaCirc": 760,
    "nfaTotal": 8552.3,
    "nrStorage": 96.2,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1519.49,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10873.1,
    "gfaFloors": 10113.1,
    "gfaCirc": 760,
    "nfaTotal": 8552.3,
    "nrStorage": 96.2,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1518.95,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9914,
    "gfaFloors": 9201.1,
    "gfaCirc": 712.9,
    "nfaTotal": 7801.7,
    "nrStorage": 87.8,
    "nrApartm": 132,
    "nfaGfa": 78.7,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1514.76,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9914,
    "gfaFloors": 9201.1,
    "gfaCirc": 712.9,
    "nfaTotal": 7801.7,
    "nrStorage": 87.8,
    "nrApartm": 132,
    "nfaGfa": 78.7,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1514.16,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9914,
    "gfaFloors": 9201.1,
    "gfaCirc": 712.9,
    "nfaTotal": 7801.7,
    "nrStorage": 87.8,
    "nrApartm": 132,
    "nfaGfa": 78.7,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1513.57,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.62,
    "gfaTotal": 9914,
    "gfaFloors": 9201.1,
    "gfaCirc": 712.9,
    "nfaTotal": 7801.7,
    "nrStorage": 87.8,
    "nrApartm": 132,
    "nfaGfa": 78.7,
    "volume": 27768.3,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4250.6,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1512.98,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.71,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10664.1,
    "gfaFloors": 9938.9,
    "gfaCirc": 725.2,
    "nfaTotal": 8388.1,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1510.81,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10621.6,
    "gfaFloors": 9873.1,
    "gfaCirc": 748.5,
    "nfaTotal": 8362.7,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1512.5,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10857.1,
    "gfaFloors": 10105.1,
    "gfaCirc": 752,
    "nfaTotal": 8539.7,
    "nrStorage": 96.1,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1521.13,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10621.6,
    "gfaFloors": 9873.1,
    "gfaCirc": 748.5,
    "nfaTotal": 8362.7,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1511.39,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10664.1,
    "gfaFloors": 9938.9,
    "gfaCirc": 725.2,
    "nfaTotal": 8388.1,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1511.91,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10857.1,
    "gfaFloors": 10105.1,
    "gfaCirc": 752,
    "nfaTotal": 8539.7,
    "nrStorage": 96.1,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1521.67,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10621.6,
    "gfaFloors": 9873.1,
    "gfaCirc": 748.5,
    "nfaTotal": 8362.7,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1511.94,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10857.1,
    "gfaFloors": 10105.1,
    "gfaCirc": 752,
    "nfaTotal": 8539.7,
    "nrStorage": 96.1,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1522.75,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.73,
    "gfaTotal": 10664.1,
    "gfaFloors": 9938.9,
    "gfaCirc": 725.2,
    "nfaTotal": 8388.1,
    "nrStorage": 94.1,
    "nrApartm": 141,
    "nfaGfa": 78.7,
    "volume": 30851.5,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4250.6,
    "surToVol": 0.216,
    "nrBuildings": 3,
    "costM2": 1511.36,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3,
    "percBuilt": 0.6,
    "gfaTotal": 9997.5,
    "gfaFloors": 9287.6,
    "gfaCirc": 709.9,
    "nfaTotal": 7871.8,
    "nrStorage": 88.5,
    "nrApartm": 132,
    "nfaGfa": 78.7,
    "volume": 27955.5,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4462.4,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1513.85,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.67,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.3,
    "percBuilt": 0.62,
    "gfaTotal": 10873.1,
    "gfaFloors": 10113.1,
    "gfaCirc": 760,
    "nfaTotal": 8552.3,
    "nrStorage": 96.2,
    "nrApartm": 143,
    "nfaGfa": 78.7,
    "volume": 30435.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5041.5,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1520.03,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.85,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10641.1,
    "gfaFloors": 9903.6,
    "gfaCirc": 737.5,
    "nfaTotal": 8384.5,
    "nrStorage": 94.3,
    "nrApartm": 141,
    "nfaGfa": 78.8,
    "volume": 29757.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4849.7,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1517.35,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10641.1,
    "gfaFloors": 9903.6,
    "gfaCirc": 737.5,
    "nfaTotal": 8384.5,
    "nrStorage": 94.3,
    "nrApartm": 141,
    "nfaGfa": 78.8,
    "volume": 29757.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4849.7,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1516.24,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.6,
    "gfaTotal": 10641.1,
    "gfaFloors": 9903.6,
    "gfaCirc": 737.5,
    "nfaTotal": 8384.5,
    "nrStorage": 94.3,
    "nrApartm": 141,
    "nfaGfa": 78.8,
    "volume": 29757.3,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 4849.7,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1516.8,
    "category": "GCG0",
    "distCores": 41.7,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10605.5,
    "gfaFloors": 9873.1,
    "gfaCirc": 732.4,
    "nfaTotal": 8369.6,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.9,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1513.13,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10605.5,
    "gfaFloors": 9873.1,
    "gfaCirc": 732.4,
    "nfaTotal": 8369.6,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.9,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1514.23,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10605.5,
    "gfaFloors": 9873.1,
    "gfaCirc": 732.4,
    "nfaTotal": 8369.6,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.9,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1513.68,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.2,
    "percBuilt": 0.62,
    "gfaTotal": 10605.5,
    "gfaFloors": 9873.1,
    "gfaCirc": 732.4,
    "nfaTotal": 8369.6,
    "nrStorage": 94.2,
    "nrApartm": 141,
    "nfaGfa": 78.9,
    "volume": 29733.9,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 4667.1,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1514.79,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.79,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.14,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11305.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 760,
    "nfaTotal": 8930.6,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1512.68,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11305.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 760,
    "nfaTotal": 8930.6,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1512.16,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11305.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 760,
    "nfaTotal": 8930.6,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.2,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.69,
    "gfaTotal": 11082.2,
    "gfaFloors": 10351.9,
    "gfaCirc": 730.3,
    "nfaTotal": 8756.8,
    "nrStorage": 98.2,
    "nrApartm": 144,
    "nfaGfa": 79,
    "volume": 31881.1,
    "areaGroundFloor": 2283.8,
    "areaRoof": 2283.8,
    "areaFac": 4504.5,
    "surToVol": 0.213,
    "nrBuildings": 3,
    "costM2": 1494.4,
    "category": "CCG0",
    "distCores": 42.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11297.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 752,
    "nfaTotal": 8937.5,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79.1,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.22,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11297.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 752,
    "nfaTotal": 8937.5,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79.1,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.74,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11297.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 752,
    "nfaTotal": 8937.5,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79.1,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1514.26,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.6,
    "gfaTotal": 11268.7,
    "gfaFloors": 10519.6,
    "gfaCirc": 749.1,
    "nfaTotal": 8912.2,
    "nrStorage": 100.2,
    "nrApartm": 150,
    "nfaGfa": 79.1,
    "volume": 31559.1,
    "areaGroundFloor": 1968.5,
    "areaRoof": 1968.5,
    "areaFac": 5236.9,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1521.55,
    "category": "CGG0",
    "distCores": 41.7,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.17,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.4,
    "percBuilt": 0.62,
    "gfaTotal": 11297.1,
    "gfaFloors": 10545.1,
    "gfaCirc": 752,
    "nfaTotal": 8937.5,
    "nrStorage": 100.5,
    "nrApartm": 150,
    "nfaGfa": 79.1,
    "volume": 31699.5,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5083.7,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1514.78,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11827.8,
    "gfaFloors": 11066.9,
    "gfaCirc": 760.9,
    "nfaTotal": 9361,
    "nrStorage": 105,
    "nrApartm": 157,
    "nfaGfa": 79.1,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.3,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1499.88,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11827.8,
    "gfaFloors": 11066.9,
    "gfaCirc": 760.9,
    "nfaTotal": 9361,
    "nrStorage": 105,
    "nrApartm": 157,
    "nfaGfa": 79.1,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.3,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1500.37,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11811.7,
    "gfaFloors": 11058.9,
    "gfaCirc": 752.8,
    "nfaTotal": 9355.3,
    "nrStorage": 104.9,
    "nrApartm": 157,
    "nfaGfa": 79.2,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.2,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1501.86,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11811.7,
    "gfaFloors": 11058.9,
    "gfaCirc": 752.8,
    "nfaTotal": 9355.3,
    "nrStorage": 104.9,
    "nrApartm": 157,
    "nfaGfa": 79.2,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.2,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1502.35,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11988.7,
    "gfaFloors": 11217.1,
    "gfaCirc": 771.6,
    "nfaTotal": 9498.5,
    "nrStorage": 106.8,
    "nrApartm": 159,
    "nfaGfa": 79.2,
    "volume": 33665.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5500.2,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.3,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11988.7,
    "gfaFloors": 11217.1,
    "gfaCirc": 771.6,
    "nfaTotal": 9498.5,
    "nrStorage": 106.8,
    "nrApartm": 159,
    "nfaGfa": 79.2,
    "volume": 33665.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5500.2,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.79,
    "category": "G0CG",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY13_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11811.7,
    "gfaFloors": 11058.9,
    "gfaCirc": 752.8,
    "nfaTotal": 9355.3,
    "nrStorage": 104.9,
    "nrApartm": 157,
    "nfaGfa": 79.2,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.2,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1502.85,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11795.7,
    "gfaFloors": 11050.9,
    "gfaCirc": 744.8,
    "nfaTotal": 9349.5,
    "nrStorage": 104.8,
    "nrApartm": 157,
    "nfaGfa": 79.3,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.3,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1504.84,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11988.7,
    "gfaFloors": 11217.1,
    "gfaCirc": 771.6,
    "nfaTotal": 9505.4,
    "nrStorage": 106.9,
    "nrApartm": 159,
    "nfaGfa": 79.3,
    "volume": 33665.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5500.2,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.81,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11988.7,
    "gfaFloors": 11217.1,
    "gfaCirc": 771.6,
    "nfaTotal": 9505.4,
    "nrStorage": 106.9,
    "nrApartm": 159,
    "nfaGfa": 79.3,
    "volume": 33665.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5500.2,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1513.32,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.62,
    "gfaTotal": 11988.7,
    "gfaFloors": 11217.1,
    "gfaCirc": 771.6,
    "nfaTotal": 9505.4,
    "nrStorage": 106.9,
    "nrApartm": 159,
    "nfaGfa": 79.3,
    "volume": 33665.1,
    "areaGroundFloor": 2060.5,
    "areaRoof": 2060.5,
    "areaFac": 5500.2,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1514.3,
    "category": "G0GC",
    "distCores": 43.3,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY12_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11795.7,
    "gfaFloors": 11050.9,
    "gfaCirc": 744.8,
    "nfaTotal": 9349.5,
    "nrStorage": 104.8,
    "nrApartm": 157,
    "nfaGfa": 79.3,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.3,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1504.34,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.6,
    "percBuilt": 0.73,
    "gfaTotal": 11795.7,
    "gfaFloors": 11050.9,
    "gfaCirc": 744.8,
    "nfaTotal": 9349.5,
    "nrStorage": 104.8,
    "nrApartm": 157,
    "nfaGfa": 79.3,
    "volume": 34080.7,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 4709.3,
    "surToVol": 0.209,
    "nrBuildings": 3,
    "costM2": 1503.84,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.94,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.73,
    "gfaTotal": 12943.3,
    "gfaFloors": 12170.9,
    "gfaCirc": 772.4,
    "nfaTotal": 10316.7,
    "nrStorage": 115.7,
    "nrApartm": 173,
    "nfaGfa": 79.7,
    "volume": 37309.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5167.9,
    "surToVol": 0.203,
    "nrBuildings": 3,
    "costM2": 1496.76,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.73,
    "gfaTotal": 12943.3,
    "gfaFloors": 12170.9,
    "gfaCirc": 772.4,
    "nfaTotal": 10316.7,
    "nrStorage": 115.7,
    "nrApartm": 173,
    "nfaGfa": 79.7,
    "volume": 37309.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5167.9,
    "surToVol": 0.203,
    "nrBuildings": 3,
    "costM2": 1497.22,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.73,
    "gfaTotal": 12927.3,
    "gfaFloors": 12162.9,
    "gfaCirc": 764.4,
    "nfaTotal": 10311,
    "nrStorage": 115.6,
    "nrApartm": 173,
    "nfaGfa": 79.8,
    "volume": 37309.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5167.9,
    "surToVol": 0.203,
    "nrBuildings": 3,
    "costM2": 1498.11,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 3.9,
    "percBuilt": 0.73,
    "gfaTotal": 12927.3,
    "gfaFloors": 12162.9,
    "gfaCirc": 764.4,
    "nfaTotal": 10311,
    "nrStorage": 115.6,
    "nrApartm": 173,
    "nfaGfa": 79.8,
    "volume": 37309.9,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5167.9,
    "surToVol": 0.203,
    "nrBuildings": 3,
    "costM2": 1498.56,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14058.9,
    "gfaFloors": 13274.9,
    "gfaCirc": 784,
    "nfaTotal": 11272.5,
    "nrStorage": 126.4,
    "nrApartm": 189,
    "nfaGfa": 80.2,
    "volume": 40539.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5626.5,
    "surToVol": 0.198,
    "nrBuildings": 3,
    "costM2": 1494.13,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14058.9,
    "gfaFloors": 13274.9,
    "gfaCirc": 784,
    "nfaTotal": 11272.5,
    "nrStorage": 126.4,
    "nrApartm": 189,
    "nfaGfa": 80.2,
    "volume": 40539.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5626.5,
    "surToVol": 0.198,
    "nrBuildings": 3,
    "costM2": 1493.3,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 60055,
    "plotX": 60,
    "plotY": 55,
    "plotArea": 3300,
    "fsi": 4.3,
    "percBuilt": 0.73,
    "gfaTotal": 14058.9,
    "gfaFloors": 13274.9,
    "gfaCirc": 784,
    "nfaTotal": 11272.5,
    "nrStorage": 126.4,
    "nrApartm": 189,
    "nfaGfa": 80.2,
    "volume": 40539.1,
    "areaGroundFloor": 2411.8,
    "areaRoof": 2411.8,
    "areaFac": 5626.5,
    "surToVol": 0.198,
    "nrBuildings": 3,
    "costM2": 1493.71,
    "category": "G0CC",
    "distCores": 44.2,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting7_Yrichting6_SelectbuidlingsX0_SelecteerdieptesY14_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "3000-3500",
    "plotSizeId": 60055,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 8888.7,
    "gfaBin75Perc": 10004.3,
    "gfaBin100Perc": 14250.9,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 6732.2,
    "nfaBin75Perc": 7580.9,
    "nfaBin100Perc": 11272.5,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.2,
    "nfaGfaBin75": 76.8,
    "nfaGfaBin100": 80.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 2.7,
    "fsiBin75": 3,
    "fsiBin100": 4.3,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.257,
    "suVoBin75": 0.272,
    "suVoBin100": 0.307
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.71,
    "gfaTotal": 5896.2,
    "gfaFloors": 5273,
    "gfaCirc": 623.2,
    "nfaTotal": 4440.5,
    "nrStorage": 49.9,
    "nrApartm": 74,
    "nfaGfa": 75.3,
    "volume": 15805.3,
    "areaGroundFloor": 1555.8,
    "areaRoof": 1555.8,
    "areaFac": 2651.2,
    "surToVol": 0.266,
    "nrBuildings": 3,
    "costM2": 1506.79,
    "category": "C0GG",
    "distCores": 35.9,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX5_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.26,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.26,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.49,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.82,
    "gfaTotal": 8239.5,
    "gfaFloors": 7418.7,
    "gfaCirc": 820.8,
    "nfaTotal": 6149.3,
    "nrStorage": 68.8,
    "nrApartm": 105,
    "nfaGfa": 74.6,
    "volume": 24100.6,
    "areaGroundFloor": 1806.9,
    "areaRoof": 1806.9,
    "areaFac": 3157.8,
    "surToVol": 0.206,
    "nrBuildings": 3,
    "costM2": 1484.47,
    "category": "CCC0",
    "distCores": 32.7,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.76,
    "gfaTotal": 7384.2,
    "gfaFloors": 6822.9,
    "gfaCirc": 561.3,
    "nfaTotal": 5734.7,
    "nrStorage": 64.2,
    "nrApartm": 96,
    "nfaGfa": 77.7,
    "volume": 21163.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3197.6,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1526.09,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.08,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.76,
    "gfaTotal": 6636.6,
    "gfaFloors": 6086.9,
    "gfaCirc": 549.7,
    "nfaTotal": 5101.8,
    "nrStorage": 57.1,
    "nrApartm": 85,
    "nfaGfa": 76.9,
    "volume": 19011.1,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2856,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1527.5,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.54,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.76,
    "gfaTotal": 7827.4,
    "gfaFloors": 7149.3,
    "gfaCirc": 678.1,
    "nfaTotal": 5992.6,
    "nrStorage": 67.1,
    "nrApartm": 100,
    "nfaGfa": 76.6,
    "volume": 22362.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3411.7,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1508.27,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.76,
    "gfaTotal": 6812.5,
    "gfaFloors": 6131.8,
    "gfaCirc": 680.7,
    "nfaTotal": 5099.4,
    "nrStorage": 57.1,
    "nrApartm": 84,
    "nfaGfa": 74.9,
    "volume": 19607.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2896.9,
    "surToVol": 0.233,
    "nrBuildings": 3,
    "costM2": 1500.39,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.57,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.57,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.76,
    "gfaTotal": 5109.5,
    "gfaFloors": 4598.9,
    "gfaCirc": 510.6,
    "nfaTotal": 3824.6,
    "nrStorage": 42.8,
    "nrApartm": 63,
    "nfaGfa": 74.9,
    "volume": 14705.5,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2172.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1543.17,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.76,
    "gfaTotal": 5109.5,
    "gfaFloors": 4598.9,
    "gfaCirc": 510.6,
    "nfaTotal": 3824.6,
    "nrStorage": 42.8,
    "nrApartm": 63,
    "nfaGfa": 74.9,
    "volume": 14705.5,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2172.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1545.47,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.76,
    "gfaTotal": 5875.7,
    "gfaFloors": 5287.2,
    "gfaCirc": 588.5,
    "nfaTotal": 4392.1,
    "nrStorage": 49.2,
    "nrApartm": 72,
    "nfaGfa": 74.8,
    "volume": 16858.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2514.3,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1517.25,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.76,
    "gfaTotal": 5868.6,
    "gfaFloors": 5288.1,
    "gfaCirc": 580.5,
    "nfaTotal": 4389.2,
    "nrStorage": 49.2,
    "nrApartm": 72,
    "nfaGfa": 74.8,
    "volume": 16858.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2514.3,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1520.05,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4,
    "percBuilt": 0.76,
    "gfaTotal": 8794.5,
    "gfaFloors": 7899.5,
    "gfaCirc": 895,
    "nfaTotal": 6566.3,
    "nrStorage": 73.5,
    "nrApartm": 108,
    "nfaGfa": 74.7,
    "volume": 25111.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3794.3,
    "surToVol": 0.218,
    "nrBuildings": 3,
    "costM2": 1469.83,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.97,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.76,
    "gfaTotal": 5820.9,
    "gfaFloors": 5214.9,
    "gfaCirc": 606,
    "nfaTotal": 4346.2,
    "nrStorage": 48.7,
    "nrApartm": 72,
    "nfaGfa": 74.7,
    "volume": 16507.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2560,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1526.66,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.76,
    "gfaTotal": 5820.9,
    "gfaFloors": 5214.9,
    "gfaCirc": 606,
    "nfaTotal": 4346.2,
    "nrStorage": 48.7,
    "nrApartm": 72,
    "nfaGfa": 74.7,
    "volume": 16507.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2560,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1527.67,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.22,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.22,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.76,
    "gfaTotal": 6326.5,
    "gfaFloors": 5677.3,
    "gfaCirc": 649.2,
    "nfaTotal": 4726.8,
    "nrStorage": 53,
    "nrApartm": 78,
    "nfaGfa": 74.7,
    "volume": 18057.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2728.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1510.23,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.76,
    "gfaTotal": 7524,
    "gfaFloors": 6747.8,
    "gfaCirc": 776.2,
    "nfaTotal": 5621.1,
    "nrStorage": 63,
    "nrApartm": 93,
    "nfaGfa": 74.7,
    "volume": 21409.1,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3284.2,
    "surToVol": 0.232,
    "nrBuildings": 3,
    "costM2": 1493.21,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.8,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.8,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.76,
    "gfaTotal": 6318.5,
    "gfaFloors": 5669.3,
    "gfaCirc": 649.2,
    "nfaTotal": 4718.8,
    "nrStorage": 52.9,
    "nrApartm": 77,
    "nfaGfa": 74.7,
    "volume": 18057.3,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 2728.4,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1504.63,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.38,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.38,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4,
    "percBuilt": 0.76,
    "gfaTotal": 8793.7,
    "gfaFloors": 7890.6,
    "gfaCirc": 903.1,
    "nfaTotal": 6561.2,
    "nrStorage": 73.5,
    "nrApartm": 107,
    "nfaGfa": 74.6,
    "volume": 25111.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3794.3,
    "surToVol": 0.218,
    "nrBuildings": 3,
    "costM2": 1464.62,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.97,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.8,
    "percBuilt": 0.76,
    "gfaTotal": 8260.8,
    "gfaFloors": 7371.8,
    "gfaCirc": 889,
    "nfaTotal": 6150.7,
    "nrStorage": 68.9,
    "nrApartm": 102,
    "nfaGfa": 74.5,
    "volume": 23210.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3671.5,
    "surToVol": 0.23,
    "nrBuildings": 3,
    "costM2": 1479.94,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.95,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.08,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.8,
    "percBuilt": 0.76,
    "gfaTotal": 8308.5,
    "gfaFloors": 7445,
    "gfaCirc": 863.5,
    "nfaTotal": 6193.7,
    "nrStorage": 69.4,
    "nrApartm": 102,
    "nfaGfa": 74.5,
    "volume": 23561.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3625.8,
    "surToVol": 0.225,
    "nrBuildings": 3,
    "costM2": 1475.54,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.95,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.76,
    "gfaTotal": 7035.7,
    "gfaFloors": 6285.3,
    "gfaCirc": 750.4,
    "nfaTotal": 5240.4,
    "nrStorage": 58.7,
    "nrApartm": 86,
    "nfaGfa": 74.5,
    "volume": 19859.1,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3115.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1493.61,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.13,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.76,
    "gfaTotal": 7043.7,
    "gfaFloors": 6293.3,
    "gfaCirc": 750.4,
    "nfaTotal": 5248.4,
    "nrStorage": 58.8,
    "nrApartm": 87,
    "nfaGfa": 74.5,
    "volume": 19859.1,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3115.7,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1498.65,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.67,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.67,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.13,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.76,
    "gfaTotal": 7243.9,
    "gfaFloors": 6446.9,
    "gfaCirc": 797,
    "nfaTotal": 5389.4,
    "nrStorage": 60.4,
    "nrApartm": 90,
    "nfaGfa": 74.4,
    "volume": 20110.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3334.5,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1503.29,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.76,
    "gfaTotal": 7747.2,
    "gfaFloors": 6901.3,
    "gfaCirc": 845.9,
    "nfaTotal": 5762.1,
    "nrStorage": 64.6,
    "nrApartm": 95,
    "nfaGfa": 74.4,
    "volume": 21660.9,
    "areaGroundFloor": 1675.8,
    "areaRoof": 1675.8,
    "areaFac": 3503,
    "surToVol": 0.239,
    "nrBuildings": 3,
    "costM2": 1485.74,
    "category": "CCG0",
    "distCores": 30.9,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX4_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.1,
    "percBuilt": 0.75,
    "gfaTotal": 9073.8,
    "gfaFloors": 8063.6,
    "gfaCirc": 1010.2,
    "nfaTotal": 6992.9,
    "nrStorage": 78.4,
    "nrApartm": 117,
    "nfaGfa": 77.1,
    "volume": 27818.9,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3966.3,
    "surToVol": 0.202,
    "nrBuildings": 3,
    "costM2": 1532.46,
    "category": "CGC0",
    "distCores": 31.8,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.4,
    "percBuilt": 0.75,
    "gfaTotal": 9577.1,
    "gfaFloors": 8602.6,
    "gfaCirc": 974.5,
    "nfaTotal": 7140.1,
    "nrStorage": 80.1,
    "nrApartm": 120,
    "nfaGfa": 74.6,
    "volume": 27566.1,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3839.9,
    "surToVol": 0.199,
    "nrBuildings": 3,
    "costM2": 1455.02,
    "category": "CGC0",
    "distCores": 31.8,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 1,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.75,
    "gfaTotal": 7887.5,
    "gfaFloors": 7087.3,
    "gfaCirc": 800.2,
    "nfaTotal": 5879.4,
    "nrStorage": 66,
    "nrApartm": 99,
    "nfaGfa": 74.5,
    "volume": 22735.9,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3157.8,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1476.26,
    "category": "CGC0",
    "distCores": 31.8,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.75,
    "gfaTotal": 7887.5,
    "gfaFloors": 7087.3,
    "gfaCirc": 800.2,
    "nfaTotal": 5879.4,
    "nrStorage": 66,
    "nrApartm": 99,
    "nfaGfa": 74.5,
    "volume": 22735.9,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3157.8,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1475.51,
    "category": "CGC0",
    "distCores": 31.8,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.75,
    "gfaTotal": 7893.2,
    "gfaFloors": 7087.3,
    "gfaCirc": 805.9,
    "nfaTotal": 5879.4,
    "nrStorage": 66,
    "nrApartm": 99,
    "nfaGfa": 74.5,
    "volume": 22735.9,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3157.8,
    "surToVol": 0.212,
    "nrBuildings": 3,
    "costM2": 1476.7,
    "category": "CGC0",
    "distCores": 31.8,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6909.3,
    "gfaFloors": 6411.1,
    "gfaCirc": 498.2,
    "nfaTotal": 5426.8,
    "nrStorage": 61,
    "nrApartm": 93,
    "nfaGfa": 78.5,
    "volume": 19344.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3197.6,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1551.01,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.13,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1555.96,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1555.07,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1556.84,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6173.3,
    "gfaFloors": 5680.9,
    "gfaCirc": 492.4,
    "nfaTotal": 4799.9,
    "nrStorage": 54,
    "nrApartm": 82,
    "nfaGfa": 77.8,
    "volume": 17191.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2856,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1555.57,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.67,
    "gfaTotal": 8194.3,
    "gfaFloors": 7535.8,
    "gfaCirc": 658.5,
    "nfaTotal": 6358.9,
    "nrStorage": 71.5,
    "nrApartm": 108,
    "nfaGfa": 77.6,
    "volume": 22797.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3879.7,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1524.62,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7458.2,
    "gfaFloors": 6805.5,
    "gfaCirc": 652.7,
    "nfaTotal": 5731.9,
    "nrStorage": 64.5,
    "nrApartm": 97,
    "nfaGfa": 76.9,
    "volume": 20644.4,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3538.1,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1524.23,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.85,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.85,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5608.9,
    "gfaFloors": 5116.5,
    "gfaCirc": 492.4,
    "nfaTotal": 4300,
    "nrStorage": 48.3,
    "nrApartm": 72,
    "nfaGfa": 76.7,
    "volume": 15506.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2771.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1564.52,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5608.9,
    "gfaFloors": 5116.5,
    "gfaCirc": 492.4,
    "nfaTotal": 4300,
    "nrStorage": 48.3,
    "nrApartm": 72,
    "nfaGfa": 76.7,
    "volume": 15506.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2771.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1563.47,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.1,
    "percBuilt": 0.67,
    "gfaTotal": 4701.4,
    "gfaFloors": 4220.5,
    "gfaCirc": 480.9,
    "nfaTotal": 3546,
    "nrStorage": 39.9,
    "nrApartm": 60,
    "nfaGfa": 75.4,
    "volume": 12885.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2172.7,
    "surToVol": 0.283,
    "nrBuildings": 3,
    "costM2": 1562.61,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.01,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6777.5,
    "gfaFloors": 6072.2,
    "gfaCirc": 705.3,
    "nfaTotal": 5102.2,
    "nrStorage": 57.4,
    "nrApartm": 86,
    "nfaGfa": 75.3,
    "volume": 18491.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3196.4,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1512.32,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6777.5,
    "gfaFloors": 6072.2,
    "gfaCirc": 705.3,
    "nfaTotal": 5102.2,
    "nrStorage": 57.4,
    "nrApartm": 86,
    "nfaGfa": 75.3,
    "volume": 18491.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3196.4,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1511.45,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 6970,
    "gfaFloors": 6243.3,
    "gfaCirc": 726.7,
    "nfaTotal": 5248.2,
    "nrStorage": 59,
    "nrApartm": 89,
    "nfaGfa": 75.3,
    "volume": 18983,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1510.67,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5914.3,
    "gfaFloors": 5304.7,
    "gfaCirc": 609.6,
    "nfaTotal": 4453.1,
    "nrStorage": 50.1,
    "nrApartm": 75,
    "nfaGfa": 75.3,
    "volume": 16237.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1523.46,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 6970,
    "gfaFloors": 6243.3,
    "gfaCirc": 726.7,
    "nfaTotal": 5248.2,
    "nrStorage": 59,
    "nrApartm": 89,
    "nfaGfa": 75.3,
    "volume": 18983,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1511.51,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5448.9,
    "gfaFloors": 4903.9,
    "gfaCirc": 545,
    "nfaTotal": 4104.9,
    "nrStorage": 46.2,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 15038.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2514.3,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1536.08,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5768.6,
    "gfaFloors": 5159,
    "gfaCirc": 609.6,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.3,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1528.77,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5402.9,
    "gfaFloors": 4836.5,
    "gfaCirc": 566.4,
    "nfaTotal": 4066.2,
    "nrStorage": 45.7,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 14687.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2560,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1546.12,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5402.9,
    "gfaFloors": 4836.5,
    "gfaCirc": 566.4,
    "nfaTotal": 4066.2,
    "nrStorage": 45.7,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 14687.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2560,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1543.95,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5768.6,
    "gfaFloors": 5159,
    "gfaCirc": 609.6,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.3,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1527.76,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7127.3,
    "gfaFloors": 6389,
    "gfaCirc": 738.3,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.2,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1498.44,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7671.6,
    "gfaFloors": 6859.3,
    "gfaCirc": 812.3,
    "nfaTotal": 5768.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 20784.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1504.59,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.8,
    "percBuilt": 0.67,
    "gfaTotal": 8403,
    "gfaFloors": 7504.4,
    "gfaCirc": 898.6,
    "nfaTotal": 6318.2,
    "nrStorage": 71.1,
    "nrApartm": 106,
    "nfaGfa": 75.2,
    "volume": 22671.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 4008.4,
    "surToVol": 0.242,
    "nrBuildings": 3,
    "costM2": 1484.39,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.95,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4,
    "percBuilt": 0.67,
    "gfaTotal": 8694.4,
    "gfaFloors": 7795.8,
    "gfaCirc": 898.6,
    "nfaTotal": 6542.2,
    "nrStorage": 73.6,
    "nrApartm": 110,
    "nfaGfa": 75.2,
    "volume": 23884.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 4008.4,
    "surToVol": 0.229,
    "nrBuildings": 3,
    "costM2": 1481.31,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.97,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.08,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.67,
    "gfaTotal": 5216.2,
    "gfaFloors": 4665.4,
    "gfaCirc": 550.8,
    "nfaTotal": 3920.2,
    "nrStorage": 44.1,
    "nrApartm": 66,
    "nfaGfa": 75.2,
    "volume": 14196.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2472.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1546.76,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.7,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5774.3,
    "gfaFloors": 5159,
    "gfaCirc": 615.3,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.2,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1528.31,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7127.3,
    "gfaFloors": 6389,
    "gfaCirc": 738.3,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.2,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1500.09,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.67,
    "gfaTotal": 5210.4,
    "gfaFloors": 4665.4,
    "gfaCirc": 545,
    "nfaTotal": 3920.2,
    "nrStorage": 44.1,
    "nrApartm": 66,
    "nfaGfa": 75.2,
    "volume": 14196.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2472.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1546.2,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.08,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.06,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.06,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.7,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.67,
    "gfaTotal": 8043.1,
    "gfaFloors": 7181.9,
    "gfaCirc": 861.2,
    "nfaTotal": 6043.3,
    "nrStorage": 68,
    "nrApartm": 102,
    "nfaGfa": 75.1,
    "volume": 21728.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3839.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1493.7,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.67,
    "gfaTotal": 7850.6,
    "gfaFloors": 7010.8,
    "gfaCirc": 839.8,
    "nfaTotal": 5897.3,
    "nrStorage": 66.3,
    "nrApartm": 99,
    "nfaGfa": 75.1,
    "volume": 21236.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3752.2,
    "surToVol": 0.246,
    "nrBuildings": 3,
    "costM2": 1493.18,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1531.51,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1512.84,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1511.95,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6835.9,
    "gfaFloors": 6097.6,
    "gfaCirc": 738.3,
    "nfaTotal": 5136.2,
    "nrStorage": 57.8,
    "nrApartm": 86,
    "nfaGfa": 75.1,
    "volume": 18376.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1505.53,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1532.47,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1533.44,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1513.72,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6475.9,
    "gfaFloors": 5775,
    "gfaCirc": 700.9,
    "nfaTotal": 4861.3,
    "nrStorage": 54.7,
    "nrApartm": 82,
    "nfaGfa": 75.1,
    "volume": 17433,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1515.57,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1530.55,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7543.2,
    "gfaFloors": 6713.6,
    "gfaCirc": 829.6,
    "nfaTotal": 5656.4,
    "nrStorage": 63.6,
    "nrApartm": 95,
    "nfaGfa": 75,
    "volume": 20178.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1496.33,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.67,
    "gfaTotal": 7903.2,
    "gfaFloors": 7036.2,
    "gfaCirc": 867,
    "nfaTotal": 5931.3,
    "nrStorage": 66.7,
    "nrApartm": 99,
    "nfaGfa": 75,
    "volume": 21121.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3839.9,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1487.78,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.92,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7350.7,
    "gfaFloors": 6542.5,
    "gfaCirc": 808.2,
    "nfaTotal": 5510.4,
    "nrStorage": 62,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 19686.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3583.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1496.68,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7177.5,
    "gfaFloors": 6391,
    "gfaCirc": 786.5,
    "nfaTotal": 5381.5,
    "nrStorage": 60.5,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 19234.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3503,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1506.91,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7323.2,
    "gfaFloors": 6536.7,
    "gfaCirc": 786.5,
    "nfaTotal": 5493.4,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 19841.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3503,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1504.66,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7350.7,
    "gfaFloors": 6542.5,
    "gfaCirc": 808.2,
    "nfaTotal": 5510.4,
    "nrStorage": 62,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 19686.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3583.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1498.28,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6283.5,
    "gfaFloors": 5604,
    "gfaCirc": 679.5,
    "nfaTotal": 4715.3,
    "nrStorage": 53,
    "nrApartm": 79,
    "nfaGfa": 75,
    "volume": 16941.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3028,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1516.5,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6289.2,
    "gfaFloors": 5604,
    "gfaCirc": 685.2,
    "nfaTotal": 4715.3,
    "nrStorage": 53,
    "nrApartm": 79,
    "nfaGfa": 75,
    "volume": 16941.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3028,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1517.95,
    "category": "CGG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX3_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.65,
    "gfaTotal": 8002.6,
    "gfaFloors": 7367.4,
    "gfaCirc": 635.2,
    "nfaTotal": 6222.3,
    "nrStorage": 70.2,
    "nrApartm": 108,
    "nfaGfa": 77.8,
    "volume": 21549.5,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 4372.3,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1572.71,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.95,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.95,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.65,
    "gfaTotal": 7359,
    "gfaFloors": 6751.4,
    "gfaCirc": 607.6,
    "nfaTotal": 5703.6,
    "nrStorage": 64.4,
    "nrApartm": 99,
    "nfaGfa": 77.5,
    "volume": 19747.7,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3985,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1574.39,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6723.4,
    "gfaFloors": 6135.4,
    "gfaCirc": 588,
    "nfaTotal": 5184.9,
    "nrStorage": 58.5,
    "nrApartm": 90,
    "nfaGfa": 77.1,
    "volume": 17945.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3597.8,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1576.26,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6731.4,
    "gfaFloors": 6135.4,
    "gfaCirc": 596,
    "nfaTotal": 5184.9,
    "nrStorage": 58.5,
    "nrApartm": 90,
    "nfaGfa": 77,
    "volume": 17945.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3597.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1574.41,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6731.4,
    "gfaFloors": 6135.4,
    "gfaCirc": 596,
    "nfaTotal": 5184.9,
    "nrStorage": 58.5,
    "nrApartm": 90,
    "nfaGfa": 77,
    "volume": 17945.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3597.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1572.66,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6739.4,
    "gfaFloors": 6135.4,
    "gfaCirc": 604,
    "nfaTotal": 5184.9,
    "nrStorage": 58.5,
    "nrApartm": 90,
    "nfaGfa": 76.9,
    "volume": 17945.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3597.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1571.69,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6747.5,
    "gfaFloors": 6135.4,
    "gfaCirc": 612.1,
    "nfaTotal": 5184.9,
    "nrStorage": 58.5,
    "nrApartm": 90,
    "nfaGfa": 76.8,
    "volume": 17945.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3597.8,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1571.59,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.6,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.64,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.64,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.65,
    "gfaTotal": 6095.8,
    "gfaFloors": 5519.4,
    "gfaCirc": 576.4,
    "nfaTotal": 4666.2,
    "nrStorage": 52.7,
    "nrApartm": 81,
    "nfaGfa": 76.5,
    "volume": 16144.1,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3210.5,
    "surToVol": 0.287,
    "nrBuildings": 3,
    "costM2": 1574.59,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.65,
    "gfaTotal": 6111.9,
    "gfaFloors": 5519.4,
    "gfaCirc": 592.5,
    "nfaTotal": 4666.2,
    "nrStorage": 52.7,
    "nrApartm": 81,
    "nfaGfa": 76.3,
    "volume": 16144.1,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3210.5,
    "surToVol": 0.287,
    "nrBuildings": 3,
    "costM2": 1568.59,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.34,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.65,
    "gfaTotal": 5333.9,
    "gfaFloors": 4735.4,
    "gfaCirc": 598.5,
    "nfaTotal": 4004.7,
    "nrStorage": 45.2,
    "nrApartm": 69,
    "nfaGfa": 75.1,
    "volume": 13850.9,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2735.5,
    "surToVol": 0.301,
    "nrBuildings": 3,
    "costM2": 1556.63,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.65,
    "gfaTotal": 6454.2,
    "gfaFloors": 5716.5,
    "gfaCirc": 737.7,
    "nfaTotal": 4838.3,
    "nrStorage": 54.7,
    "nrApartm": 84,
    "nfaGfa": 75,
    "volume": 16720.7,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3247.9,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1528.26,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.65,
    "gfaTotal": 6454.2,
    "gfaFloors": 5716.5,
    "gfaCirc": 737.7,
    "nfaTotal": 4838.3,
    "nrStorage": 54.7,
    "nrApartm": 84,
    "nfaGfa": 75,
    "volume": 16720.7,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3247.9,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1530.08,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.47,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.46,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.46,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.65,
    "gfaTotal": 4840.7,
    "gfaFloors": 4287.4,
    "gfaCirc": 553.3,
    "nfaTotal": 3628.7,
    "nrStorage": 41,
    "nrApartm": 63,
    "nfaGfa": 75,
    "volume": 12540.5,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2435.9,
    "surToVol": 0.308,
    "nrBuildings": 3,
    "costM2": 1567.44,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.65,
    "gfaTotal": 6895.9,
    "gfaFloors": 6121.9,
    "gfaCirc": 774,
    "nfaTotal": 5173.5,
    "nrStorage": 58.4,
    "nrApartm": 88,
    "nfaGfa": 75,
    "volume": 17906.6,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3590.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1522.19,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.65,
    "gfaTotal": 5895.8,
    "gfaFloors": 5225.9,
    "gfaCirc": 669.9,
    "nfaTotal": 4421.5,
    "nrStorage": 49.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15285.8,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2991.7,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1537.63,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.26,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.65,
    "gfaTotal": 4840.7,
    "gfaFloors": 4287.4,
    "gfaCirc": 553.3,
    "nfaTotal": 3628.7,
    "nrStorage": 41,
    "nrApartm": 63,
    "nfaGfa": 75,
    "volume": 12540.5,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2435.9,
    "surToVol": 0.308,
    "nrBuildings": 3,
    "costM2": 1568.65,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.98,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.65,
    "gfaTotal": 5903.8,
    "gfaFloors": 5225.9,
    "gfaCirc": 677.9,
    "nfaTotal": 4421.5,
    "nrStorage": 49.9,
    "nrApartm": 76,
    "nfaGfa": 74.9,
    "volume": 15285.8,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2991.7,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1535.58,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.65,
    "gfaTotal": 5903.8,
    "gfaFloors": 5225.9,
    "gfaCirc": 677.9,
    "nfaTotal": 4421.5,
    "nrStorage": 49.9,
    "nrApartm": 76,
    "nfaGfa": 74.9,
    "volume": 15285.8,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2991.7,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1534.59,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.25,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.25,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.65,
    "gfaTotal": 7162.7,
    "gfaFloors": 6332.5,
    "gfaCirc": 830.2,
    "nfaTotal": 5357,
    "nrStorage": 60.5,
    "nrApartm": 93,
    "nfaGfa": 74.8,
    "volume": 18522.5,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3635.2,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1518.53,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.65,
    "gfaTotal": 7162.7,
    "gfaFloors": 6332.5,
    "gfaCirc": 830.2,
    "nfaTotal": 5357,
    "nrStorage": 60.5,
    "nrApartm": 93,
    "nfaGfa": 74.8,
    "volume": 18522.5,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3635.2,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1516.89,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.7,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.7,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.65,
    "gfaTotal": 5549.1,
    "gfaFloors": 4903.4,
    "gfaCirc": 645.7,
    "nfaTotal": 4147.4,
    "nrStorage": 46.8,
    "nrApartm": 72,
    "nfaGfa": 74.7,
    "volume": 14342.3,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2823.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1548.85,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.65,
    "gfaTotal": 6610,
    "gfaFloors": 5841.9,
    "gfaCirc": 768.1,
    "nfaTotal": 4940.2,
    "nrStorage": 55.8,
    "nrApartm": 85,
    "nfaGfa": 74.7,
    "volume": 17087.6,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3379,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1523.05,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.79,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.65,
    "gfaTotal": 6610,
    "gfaFloors": 5841.9,
    "gfaCirc": 768.1,
    "nfaTotal": 4940.2,
    "nrStorage": 55.8,
    "nrApartm": 85,
    "nfaGfa": 74.7,
    "volume": 17087.6,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3379,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1522.16,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.79,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.65,
    "gfaTotal": 6626,
    "gfaFloors": 5841.9,
    "gfaCirc": 784.1,
    "nfaTotal": 4940.2,
    "nrStorage": 55.8,
    "nrApartm": 85,
    "nfaGfa": 74.6,
    "volume": 17087.6,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 3379,
    "surToVol": 0.281,
    "nrBuildings": 3,
    "costM2": 1518.55,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.52,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.49,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.49,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.79,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.65,
    "gfaTotal": 5557.1,
    "gfaFloors": 4903.4,
    "gfaCirc": 653.7,
    "nfaTotal": 4147.4,
    "nrStorage": 46.8,
    "nrApartm": 72,
    "nfaGfa": 74.6,
    "volume": 14342.3,
    "areaGroundFloor": 1429.1,
    "areaRoof": 1429.1,
    "areaFac": 2823.2,
    "surToVol": 0.296,
    "nrBuildings": 3,
    "costM2": 1545.6,
    "category": "G0GG",
    "distCores": 34.1,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX0_SelecteerdieptesY11_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.2,
    "percBuilt": 0.75,
    "gfaTotal": 9250.7,
    "gfaFloors": 8317.7,
    "gfaCirc": 933,
    "nfaTotal": 6925.4,
    "nrStorage": 77.7,
    "nrApartm": 115,
    "nfaGfa": 74.9,
    "volume": 25948.7,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3713.6,
    "surToVol": 0.207,
    "nrBuildings": 3,
    "costM2": 1442.79,
    "category": "GCC0",
    "distCores": 31.8,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.75,
    "gfaTotal": 8056,
    "gfaFloors": 7214.4,
    "gfaCirc": 841.6,
    "nfaTotal": 5998.1,
    "nrStorage": 67.3,
    "nrApartm": 100,
    "nfaGfa": 74.5,
    "volume": 22988.6,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3284.2,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1469.62,
    "category": "GCC0",
    "distCores": 31.8,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.75,
    "gfaTotal": 8050.3,
    "gfaFloors": 7214.4,
    "gfaCirc": 835.9,
    "nfaTotal": 5998.1,
    "nrStorage": 67.3,
    "nrApartm": 100,
    "nfaGfa": 74.5,
    "volume": 22988.6,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3284.2,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1468.45,
    "category": "GCC0",
    "distCores": 31.8,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.75,
    "gfaTotal": 7652.9,
    "gfaFloors": 6852.7,
    "gfaCirc": 800.2,
    "nfaTotal": 5693,
    "nrStorage": 63.9,
    "nrApartm": 95,
    "nfaGfa": 74.4,
    "volume": 21826.1,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3157.8,
    "surToVol": 0.22,
    "nrBuildings": 3,
    "costM2": 1475.85,
    "category": "GCC0",
    "distCores": 31.8,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.85,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.1,
    "percBuilt": 0.75,
    "gfaTotal": 8944.9,
    "gfaFloors": 8006.1,
    "gfaCirc": 938.8,
    "nfaTotal": 6648.5,
    "nrStorage": 74.6,
    "nrApartm": 111,
    "nfaGfa": 74.3,
    "volume": 25493.8,
    "areaGroundFloor": 1651.4,
    "areaRoof": 1651.4,
    "areaFac": 3713.6,
    "surToVol": 0.21,
    "nrBuildings": 3,
    "costM2": 1461.86,
    "category": "GCC0",
    "distCores": 31.8,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.01,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7363.1,
    "gfaFloors": 6859.1,
    "gfaCirc": 504,
    "nfaTotal": 5803.8,
    "nrStorage": 65.3,
    "nrApartm": 99,
    "nfaGfa": 78.8,
    "volume": 20654.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3497.1,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1553.28,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6909.3,
    "gfaFloors": 6411.1,
    "gfaCirc": 498.2,
    "nfaTotal": 5426.8,
    "nrStorage": 61,
    "nrApartm": 93,
    "nfaGfa": 78.5,
    "volume": 19344.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3197.6,
    "surToVol": 0.241,
    "nrBuildings": 3,
    "costM2": 1551.01,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.13,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7080.9,
    "gfaFloors": 6576.9,
    "gfaCirc": 504,
    "nfaTotal": 5553.9,
    "nrStorage": 62.4,
    "nrApartm": 94,
    "nfaGfa": 78.4,
    "volume": 19812.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3455,
    "surToVol": 0.249,
    "nrBuildings": 3,
    "costM2": 1556.3,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.22,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1555.07,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1556.84,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6627.1,
    "gfaFloors": 6128.9,
    "gfaCirc": 498.2,
    "nfaTotal": 5176.9,
    "nrStorage": 58.2,
    "nrApartm": 88,
    "nfaGfa": 78.1,
    "volume": 18501.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3155.5,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1555.96,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.53,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.62,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.62,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6173.3,
    "gfaFloors": 5680.9,
    "gfaCirc": 492.4,
    "nfaTotal": 4799.9,
    "nrStorage": 54,
    "nrApartm": 82,
    "nfaGfa": 77.8,
    "volume": 17191.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2856,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1554.62,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6173.3,
    "gfaFloors": 5680.9,
    "gfaCirc": 492.4,
    "nfaTotal": 4799.9,
    "nrStorage": 54,
    "nrApartm": 82,
    "nfaGfa": 77.8,
    "volume": 17191.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2856,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1553.66,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6173.3,
    "gfaFloors": 5680.9,
    "gfaCirc": 492.4,
    "nfaTotal": 4799.9,
    "nrStorage": 54,
    "nrApartm": 82,
    "nfaGfa": 77.8,
    "volume": 17191.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2856,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1555.57,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.35,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.45,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.45,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6344.9,
    "gfaFloors": 5846.7,
    "gfaCirc": 498.2,
    "nfaTotal": 4926.9,
    "nrStorage": 55.4,
    "nrApartm": 83,
    "nfaGfa": 77.7,
    "volume": 17659.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3113.4,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1560.43,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6344.9,
    "gfaFloors": 5846.7,
    "gfaCirc": 498.2,
    "nfaTotal": 4926.9,
    "nrStorage": 55.4,
    "nrApartm": 83,
    "nfaGfa": 77.7,
    "volume": 17659.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3113.4,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1559.5,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.48,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.48,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7740.5,
    "gfaFloors": 7087.8,
    "gfaCirc": 652.7,
    "nfaTotal": 5981.9,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 77.3,
    "volume": 21486.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3580.2,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1522.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7740.5,
    "gfaFloors": 7087.8,
    "gfaCirc": 652.7,
    "nfaTotal": 5981.9,
    "nrStorage": 67.3,
    "nrApartm": 102,
    "nfaGfa": 77.3,
    "volume": 21486.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3580.2,
    "surToVol": 0.235,
    "nrBuildings": 3,
    "costM2": 1521.71,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.87,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.92,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.92,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5891.1,
    "gfaFloors": 5398.7,
    "gfaCirc": 492.4,
    "nfaTotal": 4549.9,
    "nrStorage": 51.2,
    "nrApartm": 77,
    "nfaGfa": 77.2,
    "volume": 16349.1,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2813.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1558.37,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5891.1,
    "gfaFloors": 5398.7,
    "gfaCirc": 492.4,
    "nfaTotal": 4549.9,
    "nrStorage": 51.2,
    "nrApartm": 77,
    "nfaGfa": 77.2,
    "volume": 16349.1,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2813.9,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1559.36,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.3,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.3,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7392.1,
    "gfaFloors": 6765.2,
    "gfaCirc": 626.9,
    "nfaTotal": 5707,
    "nrStorage": 64.2,
    "nrApartm": 97,
    "nfaGfa": 77.2,
    "volume": 20543.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3411.7,
    "surToVol": 0.238,
    "nrBuildings": 3,
    "costM2": 1523.38,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.8,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.11,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6062.7,
    "gfaFloors": 5564.5,
    "gfaCirc": 498.2,
    "nfaTotal": 4677,
    "nrStorage": 52.6,
    "nrApartm": 78,
    "nfaGfa": 77.1,
    "volume": 16817.1,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3071.3,
    "surToVol": 0.27,
    "nrBuildings": 3,
    "costM2": 1565.31,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.35,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.35,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5608.9,
    "gfaFloors": 5116.5,
    "gfaCirc": 492.4,
    "nfaTotal": 4300,
    "nrStorage": 48.3,
    "nrApartm": 72,
    "nfaGfa": 76.7,
    "volume": 15506.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2771.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1564.52,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5608.9,
    "gfaFloors": 5116.5,
    "gfaCirc": 492.4,
    "nfaTotal": 4300,
    "nrStorage": 48.3,
    "nrApartm": 72,
    "nfaGfa": 76.7,
    "volume": 15506.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2771.7,
    "surToVol": 0.273,
    "nrBuildings": 3,
    "costM2": 1563.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.15,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.17,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.17,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.6,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7176,
    "gfaFloors": 6523.3,
    "gfaCirc": 652.7,
    "nfaTotal": 5482,
    "nrStorage": 61.6,
    "nrApartm": 92,
    "nfaGfa": 76.4,
    "volume": 19802,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3496,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1526.89,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7176,
    "gfaFloors": 6523.3,
    "gfaCirc": 652.7,
    "nfaTotal": 5482,
    "nrStorage": 61.6,
    "nrApartm": 92,
    "nfaGfa": 76.4,
    "volume": 19802,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3496,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1527.71,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.74,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.1,
    "percBuilt": 0.67,
    "gfaTotal": 4701.4,
    "gfaFloors": 4220.5,
    "gfaCirc": 480.9,
    "nfaTotal": 3546,
    "nrStorage": 39.9,
    "nrApartm": 60,
    "nfaGfa": 75.4,
    "volume": 12885.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2172.7,
    "surToVol": 0.283,
    "nrBuildings": 3,
    "costM2": 1565.11,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.01,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7007.2,
    "gfaFloors": 6301.9,
    "gfaCirc": 705.3,
    "nfaTotal": 5284.1,
    "nrStorage": 59.4,
    "nrApartm": 89,
    "nfaGfa": 75.4,
    "volume": 19334,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3238.6,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1507.43,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7007.2,
    "gfaFloors": 6301.9,
    "gfaCirc": 705.3,
    "nfaTotal": 5284.1,
    "nrStorage": 59.4,
    "nrApartm": 89,
    "nfaGfa": 75.4,
    "volume": 19334,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3238.6,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1506.6,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5440.1,
    "gfaFloors": 4895.1,
    "gfaCirc": 545,
    "nfaTotal": 4102.1,
    "nrStorage": 46.1,
    "nrApartm": 69,
    "nfaGfa": 75.4,
    "volume": 15038.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2514.3,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1539.55,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.1,
    "percBuilt": 0.67,
    "gfaTotal": 4701.4,
    "gfaFloors": 4220.5,
    "gfaCirc": 480.9,
    "nfaTotal": 3546,
    "nrStorage": 39.9,
    "nrApartm": 60,
    "nfaGfa": 75.4,
    "volume": 12885.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2172.7,
    "surToVol": 0.283,
    "nrBuildings": 3,
    "costM2": 1563.86,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.01,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6268.4,
    "gfaFloors": 5627.3,
    "gfaCirc": 641.1,
    "nfaTotal": 4728,
    "nrStorage": 53.2,
    "nrApartm": 80,
    "nfaGfa": 75.4,
    "volume": 17181.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2896.9,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1521.9,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6268.4,
    "gfaFloors": 5627.3,
    "gfaCirc": 641.1,
    "nfaTotal": 4728,
    "nrStorage": 53.2,
    "nrApartm": 80,
    "nfaGfa": 75.4,
    "volume": 17181.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2896.9,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1520.97,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.1,
    "percBuilt": 0.67,
    "gfaTotal": 4701.4,
    "gfaFloors": 4220.5,
    "gfaCirc": 480.9,
    "nfaTotal": 3546,
    "nrStorage": 39.9,
    "nrApartm": 60,
    "nfaGfa": 75.4,
    "volume": 12885.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2172.7,
    "surToVol": 0.283,
    "nrBuildings": 3,
    "costM2": 1562.61,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.01,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.1,
    "percBuilt": 0.67,
    "gfaTotal": 4701.4,
    "gfaFloors": 4220.5,
    "gfaCirc": 480.9,
    "nfaTotal": 3546,
    "nrStorage": 39.9,
    "nrApartm": 60,
    "nfaGfa": 75.4,
    "volume": 12885.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2172.7,
    "surToVol": 0.283,
    "nrBuildings": 3,
    "costM2": 1561.37,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.01,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.01,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.01,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.01,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.81,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5440.1,
    "gfaFloors": 4895.1,
    "gfaCirc": 545,
    "nfaTotal": 4102.1,
    "nrStorage": 46.1,
    "nrApartm": 69,
    "nfaGfa": 75.4,
    "volume": 15038.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2514.3,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1538.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.14,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.14,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.14,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6268.4,
    "gfaFloors": 5627.3,
    "gfaCirc": 641.1,
    "nfaTotal": 4728,
    "nrStorage": 53.2,
    "nrApartm": 80,
    "nfaGfa": 75.4,
    "volume": 17181.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2896.9,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1520.03,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.4,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.39,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.39,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7335.7,
    "gfaFloors": 6565.9,
    "gfaCirc": 769.8,
    "nfaTotal": 5523.1,
    "nrStorage": 62.1,
    "nrApartm": 93,
    "nfaGfa": 75.3,
    "volume": 19926.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3452.7,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1500.4,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.78,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7481.4,
    "gfaFloors": 6711.6,
    "gfaCirc": 769.8,
    "nfaTotal": 5635.1,
    "nrStorage": 63.4,
    "nrApartm": 95,
    "nfaGfa": 75.3,
    "volume": 20533,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3452.7,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1498.32,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6780.6,
    "gfaFloors": 6075.3,
    "gfaCirc": 705.3,
    "nfaTotal": 5105,
    "nrStorage": 57.4,
    "nrApartm": 86,
    "nfaGfa": 75.3,
    "volume": 18491.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3196.4,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1512.5,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7481.4,
    "gfaFloors": 6711.6,
    "gfaCirc": 769.8,
    "nfaTotal": 5635.1,
    "nrStorage": 63.4,
    "nrApartm": 95,
    "nfaGfa": 75.3,
    "volume": 20533,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3452.7,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1497.54,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 6970,
    "gfaFloors": 6243.3,
    "gfaCirc": 726.7,
    "nfaTotal": 5248.2,
    "nrStorage": 59,
    "nrApartm": 89,
    "nfaGfa": 75.3,
    "volume": 18983,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1511.51,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 6970,
    "gfaFloors": 6243.3,
    "gfaCirc": 726.7,
    "nfaTotal": 5248.2,
    "nrStorage": 59,
    "nrApartm": 89,
    "nfaGfa": 75.3,
    "volume": 18983,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1510.67,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6780.6,
    "gfaFloors": 6075.3,
    "gfaCirc": 705.3,
    "nfaTotal": 5105,
    "nrStorage": 57.4,
    "nrApartm": 86,
    "nfaGfa": 75.3,
    "volume": 18491.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3196.4,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1511.64,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6780.6,
    "gfaFloors": 6075.3,
    "gfaCirc": 705.3,
    "nfaTotal": 5105,
    "nrStorage": 57.4,
    "nrApartm": 86,
    "nfaGfa": 75.3,
    "volume": 18491.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3196.4,
    "surToVol": 0.252,
    "nrBuildings": 3,
    "costM2": 1510.77,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.61,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.58,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.58,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.29,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7481.4,
    "gfaFloors": 6711.6,
    "gfaCirc": 769.8,
    "nfaTotal": 5635.1,
    "nrStorage": 63.4,
    "nrApartm": 95,
    "nfaGfa": 75.3,
    "volume": 20533,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3452.7,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1496.75,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.82,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.81,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.81,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 6970,
    "gfaFloors": 6243.3,
    "gfaCirc": 726.7,
    "nfaTotal": 5248.2,
    "nrStorage": 59,
    "nrApartm": 89,
    "nfaGfa": 75.3,
    "volume": 18983,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.25,
    "nrBuildings": 3,
    "costM2": 1512.35,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.66,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.66,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.66,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.23,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5402.9,
    "gfaFloors": 4836.5,
    "gfaCirc": 566.4,
    "nfaTotal": 4066.2,
    "nrStorage": 45.7,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 14687.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2560,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1545.04,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5768.6,
    "gfaFloors": 5159,
    "gfaCirc": 609.6,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.3,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1528.77,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5402.9,
    "gfaFloors": 4836.5,
    "gfaCirc": 566.4,
    "nfaTotal": 4066.2,
    "nrStorage": 45.7,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 14687.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2560,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1546.12,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5914.3,
    "gfaFloors": 5304.7,
    "gfaCirc": 609.6,
    "nfaTotal": 4453.1,
    "nrStorage": 50.1,
    "nrApartm": 75,
    "nfaGfa": 75.3,
    "volume": 16237.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1524.45,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5914.3,
    "gfaFloors": 5304.7,
    "gfaCirc": 609.6,
    "nfaTotal": 4453.1,
    "nrStorage": 50.1,
    "nrApartm": 75,
    "nfaGfa": 75.3,
    "volume": 16237.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1523.46,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.27,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5768.6,
    "gfaFloors": 5159,
    "gfaCirc": 609.6,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.3,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1527.76,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.5,
    "percBuilt": 0.67,
    "gfaTotal": 5402.9,
    "gfaFloors": 4836.5,
    "gfaCirc": 566.4,
    "nfaTotal": 4066.2,
    "nrStorage": 45.7,
    "nrApartm": 69,
    "nfaGfa": 75.3,
    "volume": 14687.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2560,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1543.95,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.12,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.12,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.12,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.12,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.67,
    "gfaTotal": 7871.8,
    "gfaFloors": 7063.6,
    "gfaCirc": 808.2,
    "nfaTotal": 5916.3,
    "nrStorage": 66.5,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 21742.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3625.8,
    "surToVol": 0.234,
    "nrBuildings": 3,
    "costM2": 1487.43,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.1,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6658.8,
    "gfaFloors": 5979.3,
    "gfaCirc": 679.5,
    "nfaTotal": 5009.2,
    "nrStorage": 56.3,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18390.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3070.1,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1508.54,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7127.3,
    "gfaFloors": 6389,
    "gfaCirc": 738.3,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.2,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1499.26,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7671.6,
    "gfaFloors": 6859.3,
    "gfaCirc": 812.3,
    "nfaTotal": 5768.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 20784.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1504.59,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7671.6,
    "gfaFloors": 6859.3,
    "gfaCirc": 812.3,
    "nfaTotal": 5768.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 20784.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1503.82,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.67,
    "gfaTotal": 7671.6,
    "gfaFloors": 6859.3,
    "gfaCirc": 812.3,
    "nfaTotal": 5768.4,
    "nrStorage": 64.9,
    "nrApartm": 98,
    "nfaGfa": 75.2,
    "volume": 20784.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1503.06,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.86,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.86,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.86,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7127.3,
    "gfaFloors": 6389,
    "gfaCirc": 738.3,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.2,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1498.44,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.67,
    "gfaTotal": 5219.3,
    "gfaFloors": 4668.5,
    "gfaCirc": 550.8,
    "nfaTotal": 3923,
    "nrStorage": 44.1,
    "nrApartm": 66,
    "nfaGfa": 75.2,
    "volume": 14196.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2472.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1545.85,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.7,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7127.3,
    "gfaFloors": 6389,
    "gfaCirc": 738.3,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.2,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1500.09,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.72,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.67,
    "gfaTotal": 5213.5,
    "gfaFloors": 4668.5,
    "gfaCirc": 545,
    "nfaTotal": 3923,
    "nrStorage": 44.1,
    "nrApartm": 66,
    "nfaGfa": 75.2,
    "volume": 14196.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2472.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1546.42,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.7,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.67,
    "gfaTotal": 5213.5,
    "gfaFloors": 4668.5,
    "gfaCirc": 545,
    "nfaTotal": 3923,
    "nrStorage": 44.1,
    "nrApartm": 66,
    "nfaGfa": 75.2,
    "volume": 14196.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2472.2,
    "surToVol": 0.278,
    "nrBuildings": 3,
    "costM2": 1545.29,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.09,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.07,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.07,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.7,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.67,
    "gfaTotal": 5774.3,
    "gfaFloors": 5159,
    "gfaCirc": 615.3,
    "nfaTotal": 4341.1,
    "nrStorage": 48.8,
    "nrApartm": 73,
    "nfaGfa": 75.2,
    "volume": 15631.2,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1528.31,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.22,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.2,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.2,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6658.8,
    "gfaFloors": 5979.3,
    "gfaCirc": 679.5,
    "nfaTotal": 5009.2,
    "nrStorage": 56.3,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18390.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3070.1,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1509.42,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.67,
    "gfaTotal": 5920,
    "gfaFloors": 5304.7,
    "gfaCirc": 615.3,
    "nfaTotal": 4453.1,
    "nrStorage": 50.1,
    "nrApartm": 75,
    "nfaGfa": 75.2,
    "volume": 16237.7,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2728.4,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1524,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.28,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.27,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.27,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6658.8,
    "gfaFloors": 5979.3,
    "gfaCirc": 679.5,
    "nfaTotal": 5009.2,
    "nrStorage": 56.3,
    "nrApartm": 84,
    "nfaGfa": 75.2,
    "volume": 18390.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3070.1,
    "surToVol": 0.247,
    "nrBuildings": 3,
    "costM2": 1507.66,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.55,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.52,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.52,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.2,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1513.72,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.67,
    "gfaTotal": 8043.1,
    "gfaFloors": 7181.9,
    "gfaCirc": 861.2,
    "nfaTotal": 6043.3,
    "nrStorage": 68,
    "nrApartm": 102,
    "nfaGfa": 75.1,
    "volume": 21728.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3839.9,
    "surToVol": 0.244,
    "nrBuildings": 3,
    "costM2": 1492.24,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.93,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.93,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.93,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.18,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.67,
    "gfaTotal": 7853.7,
    "gfaFloors": 7013.9,
    "gfaCirc": 839.8,
    "nfaTotal": 5900.1,
    "nrStorage": 66.4,
    "nrApartm": 99,
    "nfaGfa": 75.1,
    "volume": 21236.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3752.2,
    "surToVol": 0.246,
    "nrBuildings": 3,
    "costM2": 1491.89,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.91,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.91,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.19,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.8,
    "percBuilt": 0.67,
    "gfaTotal": 8340.3,
    "gfaFloors": 7473.3,
    "gfaCirc": 867,
    "nfaTotal": 6267.3,
    "nrStorage": 70.5,
    "nrApartm": 105,
    "nfaGfa": 75.1,
    "volume": 22941.4,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3839.9,
    "surToVol": 0.231,
    "nrBuildings": 3,
    "costM2": 1480.69,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.96,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.96,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.96,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.95,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.09,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6286.5,
    "gfaFloors": 5607,
    "gfaCirc": 679.5,
    "nfaTotal": 4718.1,
    "nrStorage": 53.1,
    "nrApartm": 79,
    "nfaGfa": 75.1,
    "volume": 16941.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3028,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1517.71,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6835.9,
    "gfaFloors": 6097.6,
    "gfaCirc": 738.3,
    "nfaTotal": 5136.2,
    "nrStorage": 57.8,
    "nrApartm": 86,
    "nfaGfa": 75.1,
    "volume": 18376.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1504.67,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6286.5,
    "gfaFloors": 5607,
    "gfaCirc": 679.5,
    "nfaTotal": 4718.1,
    "nrStorage": 53.1,
    "nrApartm": 79,
    "nfaGfa": 75.1,
    "volume": 16941.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3028,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1516.77,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.41,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1514.61,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.67,
    "gfaTotal": 7133,
    "gfaFloors": 6389,
    "gfaCirc": 744,
    "nfaTotal": 5360.2,
    "nrStorage": 60.3,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 19589.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.243,
    "nrBuildings": 3,
    "costM2": 1499.73,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.73,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.71,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.71,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.15,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.67,
    "gfaTotal": 7834.6,
    "gfaFloors": 7005,
    "gfaCirc": 829.6,
    "nfaTotal": 5880.4,
    "nrStorage": 66.1,
    "nrApartm": 99,
    "nfaGfa": 75.1,
    "volume": 21391.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.24,
    "nrBuildings": 3,
    "costM2": 1493.22,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.89,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.9,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.9,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.12,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1532.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6475.9,
    "gfaFloors": 5775,
    "gfaCirc": 700.9,
    "nfaTotal": 4861.3,
    "nrStorage": 54.7,
    "nrApartm": 82,
    "nfaGfa": 75.1,
    "volume": 17433,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1517.38,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1531.51,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6835.9,
    "gfaFloors": 6097.6,
    "gfaCirc": 738.3,
    "nfaTotal": 5136.2,
    "nrStorage": 57.8,
    "nrApartm": 86,
    "nfaGfa": 75.1,
    "volume": 18376.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3284.2,
    "surToVol": 0.259,
    "nrBuildings": 3,
    "costM2": 1505.53,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.63,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.6,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.6,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.39,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6475.9,
    "gfaFloors": 5775,
    "gfaCirc": 700.9,
    "nfaTotal": 4861.3,
    "nrStorage": 54.7,
    "nrApartm": 82,
    "nfaGfa": 75.1,
    "volume": 17433,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1516.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6475.9,
    "gfaFloors": 5775,
    "gfaCirc": 700.9,
    "nfaTotal": 4861.3,
    "nrStorage": 54.7,
    "nrApartm": 82,
    "nfaGfa": 75.1,
    "volume": 17433,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1515.57,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.48,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.47,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.47,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1512.84,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.67,
    "gfaTotal": 6621.6,
    "gfaFloors": 5920.7,
    "gfaCirc": 700.9,
    "nfaTotal": 4973.3,
    "nrStorage": 55.9,
    "nrApartm": 84,
    "nfaGfa": 75.1,
    "volume": 18039.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3115.7,
    "surToVol": 0.254,
    "nrBuildings": 3,
    "costM2": 1511.95,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.5,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.5,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.5,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.31,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.67,
    "gfaTotal": 6104.5,
    "gfaFloors": 5452.5,
    "gfaCirc": 652,
    "nfaTotal": 4586.4,
    "nrStorage": 51.6,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16489.5,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 2947.2,
    "surToVol": 0.268,
    "nrBuildings": 3,
    "costM2": 1530.55,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.32,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.31,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.31,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.5,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6806.1,
    "gfaFloors": 6068.5,
    "gfaCirc": 737.6,
    "nfaTotal": 5106.5,
    "nrStorage": 57.4,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 18291.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3334.5,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1519.88,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7323.2,
    "gfaFloors": 6536.7,
    "gfaCirc": 786.5,
    "nfaTotal": 5493.4,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 19841.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3503,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1505.46,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7543.2,
    "gfaFloors": 6713.6,
    "gfaCirc": 829.6,
    "nfaTotal": 5656.4,
    "nrStorage": 63.6,
    "nrApartm": 95,
    "nfaGfa": 75,
    "volume": 20178.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1496.33,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.67,
    "gfaTotal": 6292.2,
    "gfaFloors": 5607,
    "gfaCirc": 685.2,
    "nfaTotal": 4718.1,
    "nrStorage": 53.1,
    "nrApartm": 79,
    "nfaGfa": 75,
    "volume": 16941.6,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3028,
    "surToVol": 0.265,
    "nrBuildings": 3,
    "costM2": 1517.29,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.42,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.37,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.37,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.46,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.67,
    "gfaTotal": 7543.2,
    "gfaFloors": 6713.6,
    "gfaCirc": 829.6,
    "nfaTotal": 5656.4,
    "nrStorage": 63.6,
    "nrApartm": 95,
    "nfaGfa": 75,
    "volume": 20178.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3671.5,
    "surToVol": 0.255,
    "nrBuildings": 3,
    "costM2": 1497.89,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.83,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.82,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.82,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.34,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7353.8,
    "gfaFloors": 6545.6,
    "gfaCirc": 808.2,
    "nfaTotal": 5513.2,
    "nrStorage": 62,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 19686.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3583.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1498.46,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7353.8,
    "gfaFloors": 6545.6,
    "gfaCirc": 808.2,
    "nfaTotal": 5513.2,
    "nrStorage": 62,
    "nrApartm": 92,
    "nfaGfa": 75,
    "volume": 19686.9,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3583.7,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1496.86,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.78,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.78,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6806.1,
    "gfaFloors": 6068.5,
    "gfaCirc": 737.6,
    "nfaTotal": 5106.5,
    "nrStorage": 57.4,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 18291.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3334.5,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1522.47,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7177.5,
    "gfaFloors": 6391,
    "gfaCirc": 786.5,
    "nfaTotal": 5381.5,
    "nrStorage": 60.5,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 19234.8,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3503,
    "surToVol": 0.258,
    "nrBuildings": 3,
    "costM2": 1506.91,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.75,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.73,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.73,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.37,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.67,
    "gfaTotal": 6806.1,
    "gfaFloors": 6068.5,
    "gfaCirc": 737.6,
    "nfaTotal": 5106.5,
    "nrStorage": 57.4,
    "nrApartm": 87,
    "nfaGfa": 75,
    "volume": 18291.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3334.5,
    "surToVol": 0.263,
    "nrBuildings": 3,
    "costM2": 1520.74,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.62,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.59,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.59,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.43,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.67,
    "gfaTotal": 7323.2,
    "gfaFloors": 6536.7,
    "gfaCirc": 786.5,
    "nfaTotal": 5493.4,
    "nrStorage": 61.8,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 19841.3,
    "areaGroundFloor": 1468.5,
    "areaRoof": 1468.5,
    "areaFac": 3503,
    "surToVol": 0.251,
    "nrBuildings": 3,
    "costM2": 1503.85,
    "category": "GCG0",
    "distCores": 45,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX2_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.77,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.77,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.77,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.27,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.9,
    "percBuilt": 0.68,
    "gfaTotal": 8548.2,
    "gfaFloors": 7675.7,
    "gfaCirc": 872.5,
    "nfaTotal": 6416,
    "nrStorage": 72.1,
    "nrApartm": 109,
    "nfaGfa": 75.1,
    "volume": 23421.5,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3587.2,
    "surToVol": 0.217,
    "nrBuildings": 3,
    "costM2": 1458.14,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.97,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.97,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.97,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.97,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.03,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.1,
    "percBuilt": 0.68,
    "gfaTotal": 9047.5,
    "gfaFloors": 8109.3,
    "gfaCirc": 938.2,
    "nfaTotal": 6792.8,
    "nrStorage": 76.3,
    "nrApartm": 115,
    "nfaGfa": 75.1,
    "volume": 24836.8,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3839.9,
    "surToVol": 0.215,
    "nrBuildings": 3,
    "costM2": 1455.85,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.98,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.98,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.98,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.98,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.68,
    "gfaTotal": 7608.5,
    "gfaFloors": 6793.3,
    "gfaCirc": 815.2,
    "nfaTotal": 5680.3,
    "nrStorage": 63.9,
    "nrApartm": 97,
    "nfaGfa": 74.7,
    "volume": 21169,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3284.2,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1486.62,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.68,
    "gfaTotal": 7860.9,
    "gfaFloors": 7010,
    "gfaCirc": 850.9,
    "nfaTotal": 5868.6,
    "nrStorage": 66,
    "nrApartm": 100,
    "nfaGfa": 74.7,
    "volume": 21876.7,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3410.6,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1483.14,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.68,
    "gfaTotal": 7608.5,
    "gfaFloors": 6793.3,
    "gfaCirc": 815.2,
    "nfaTotal": 5680.3,
    "nrStorage": 63.9,
    "nrApartm": 97,
    "nfaGfa": 74.7,
    "volume": 21169,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3284.2,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1485.85,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.68,
    "gfaTotal": 7608.5,
    "gfaFloors": 6793.3,
    "gfaCirc": 815.2,
    "nfaTotal": 5680.3,
    "nrStorage": 63.9,
    "nrApartm": 97,
    "nfaGfa": 74.7,
    "volume": 21169,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3284.2,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1485.08,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.83,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.83,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.68,
    "gfaTotal": 7860.9,
    "gfaFloors": 7010,
    "gfaCirc": 850.9,
    "nfaTotal": 5868.6,
    "nrStorage": 66,
    "nrApartm": 100,
    "nfaGfa": 74.7,
    "volume": 21876.7,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3410.6,
    "surToVol": 0.224,
    "nrBuildings": 3,
    "costM2": 1482.4,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 5,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.9,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.88,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.88,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.04,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.68,
    "gfaTotal": 7356,
    "gfaFloors": 6576.5,
    "gfaCirc": 779.5,
    "nfaTotal": 5491.9,
    "nrStorage": 61.8,
    "nrApartm": 94,
    "nfaGfa": 74.7,
    "volume": 20461.4,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3157.8,
    "surToVol": 0.227,
    "nrBuildings": 3,
    "costM2": 1487.97,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.79,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.76,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.76,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 4.2,
    "percBuilt": 0.68,
    "gfaTotal": 9176.2,
    "gfaFloors": 8190.8,
    "gfaCirc": 985.4,
    "nfaTotal": 6847.2,
    "nrStorage": 77,
    "nrApartm": 117,
    "nfaGfa": 74.6,
    "volume": 25544.4,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3966.3,
    "surToVol": 0.214,
    "nrBuildings": 3,
    "costM2": 1468.07,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.99,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.99,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.99,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.99,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.02,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.68,
    "gfaTotal": 7883.8,
    "gfaFloors": 7024.5,
    "gfaCirc": 859.3,
    "nfaTotal": 5868.8,
    "nrStorage": 66,
    "nrApartm": 100,
    "nfaGfa": 74.4,
    "volume": 21771.8,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3457.4,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1479.2,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.6,
    "percBuilt": 0.68,
    "gfaTotal": 7883.8,
    "gfaFloors": 7024.5,
    "gfaCirc": 859.3,
    "nfaTotal": 5868.8,
    "nrStorage": 66,
    "nrApartm": 100,
    "nfaGfa": 74.4,
    "volume": 21771.8,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3457.4,
    "surToVol": 0.228,
    "nrBuildings": 3,
    "costM2": 1479.95,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.91,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.89,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.89,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.89,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.07,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.68,
    "gfaTotal": 8136.3,
    "gfaFloors": 7241.3,
    "gfaCirc": 895,
    "nfaTotal": 6057.2,
    "nrStorage": 68.1,
    "nrApartm": 103,
    "nfaGfa": 74.4,
    "volume": 22479.4,
    "areaGroundFloor": 1495.8,
    "areaRoof": 1495.8,
    "areaFac": 3583.7,
    "surToVol": 0.226,
    "nrBuildings": 3,
    "costM2": 1477.49,
    "category": "GGC0",
    "distCores": 30.9,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY11_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.94,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.05,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6067,
    "gfaFloors": 5575.4,
    "gfaCirc": 491.6,
    "nfaTotal": 4704.4,
    "nrStorage": 53.1,
    "nrApartm": 78,
    "nfaGfa": 77.5,
    "volume": 16307.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3370.8,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1568.45,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6067,
    "gfaFloors": 5575.4,
    "gfaCirc": 491.6,
    "nfaTotal": 4704.4,
    "nrStorage": 53.1,
    "nrApartm": 78,
    "nfaGfa": 77.5,
    "volume": 16307.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3370.8,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1567.48,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6067,
    "gfaFloors": 5575.4,
    "gfaCirc": 491.6,
    "nfaTotal": 4704.4,
    "nrStorage": 53.1,
    "nrApartm": 78,
    "nfaGfa": 77.5,
    "volume": 16307.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3370.8,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1567.48,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.31,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.36,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.36,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.3,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1567.93,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.2,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1566.87,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.3,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1567.93,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.2,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1566.87,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.2,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1567.91,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.2,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1567.91,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5613.2,
    "gfaFloors": 5127.4,
    "gfaCirc": 485.8,
    "nfaTotal": 4328.5,
    "nrStorage": 48.8,
    "nrApartm": 72,
    "nfaGfa": 77.1,
    "volume": 14997.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3071.3,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1566.88,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.16,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.18,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.18,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7030.5,
    "gfaFloors": 6388.5,
    "gfaCirc": 642,
    "nfaTotal": 5395.3,
    "nrStorage": 60.9,
    "nrApartm": 90,
    "nfaGfa": 76.7,
    "volume": 18686.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3795.5,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1534.98,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.68,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.74,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.74,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1567.36,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1568.5,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1568.5,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1567.36,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1567.36,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1566.22,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1566.22,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.3,
    "percBuilt": 0.57,
    "gfaTotal": 5159.4,
    "gfaFloors": 4679.4,
    "gfaCirc": 480,
    "nfaTotal": 3952.5,
    "nrStorage": 44.6,
    "nrApartm": 66,
    "nfaGfa": 76.6,
    "volume": 13687.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2771.7,
    "surToVol": 0.295,
    "nrBuildings": 3,
    "costM2": 1566.22,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.06,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.08,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.08,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.05,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.92,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.57,
    "gfaTotal": 6576.7,
    "gfaFloors": 5940.5,
    "gfaCirc": 636.2,
    "nfaTotal": 5019.3,
    "nrStorage": 56.7,
    "nrApartm": 84,
    "nfaGfa": 76.3,
    "volume": 17375.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3496,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1531.38,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3,
    "percBuilt": 0.57,
    "gfaTotal": 6576.7,
    "gfaFloors": 5940.5,
    "gfaCirc": 636.2,
    "nfaTotal": 5019.3,
    "nrStorage": 56.7,
    "nrApartm": 84,
    "nfaGfa": 76.3,
    "volume": 17375.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3496,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1531.38,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.49,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.53,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.53,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.49,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1536.73,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1534.84,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1533.9,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1533.9,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1535.78,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6228.3,
    "gfaFloors": 5617.9,
    "gfaCirc": 610.4,
    "nfaTotal": 4745.3,
    "nrStorage": 53.6,
    "nrApartm": 79,
    "nfaGfa": 76.2,
    "volume": 16432.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3327.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1534.84,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.38,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.4,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.4,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5669.1,
    "gfaFloors": 5044.5,
    "gfaCirc": 624.6,
    "nfaTotal": 4267.3,
    "nrStorage": 48.2,
    "nrApartm": 72,
    "nfaGfa": 75.3,
    "volume": 14755.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2896.9,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1526.52,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.8,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5669.1,
    "gfaFloors": 5044.5,
    "gfaCirc": 624.6,
    "nfaTotal": 4267.3,
    "nrStorage": 48.2,
    "nrApartm": 72,
    "nfaGfa": 75.3,
    "volume": 14755.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2896.9,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1525.49,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.8,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 1.9,
    "percBuilt": 0.57,
    "gfaTotal": 4251.9,
    "gfaFloors": 3783.4,
    "gfaCirc": 468.5,
    "nfaTotal": 3200.5,
    "nrStorage": 36.2,
    "nrApartm": 54,
    "nfaGfa": 75.3,
    "volume": 11066.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2172.7,
    "surToVol": 0.31,
    "nrBuildings": 3,
    "costM2": 1568.62,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 1.9,
    "percBuilt": 0.57,
    "gfaTotal": 4251.9,
    "gfaFloors": 3783.4,
    "gfaCirc": 468.5,
    "nfaTotal": 3200.5,
    "nrStorage": 36.2,
    "nrApartm": 54,
    "nfaGfa": 75.3,
    "volume": 11066.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2172.7,
    "surToVol": 0.31,
    "nrBuildings": 3,
    "costM2": 1565.86,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 1.9,
    "percBuilt": 0.57,
    "gfaTotal": 4251.9,
    "gfaFloors": 3783.4,
    "gfaCirc": 468.5,
    "nfaTotal": 3200.5,
    "nrStorage": 36.2,
    "nrApartm": 54,
    "nfaGfa": 75.3,
    "volume": 11066.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2172.7,
    "surToVol": 0.31,
    "nrBuildings": 3,
    "costM2": 1567.24,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5669.1,
    "gfaFloors": 5044.5,
    "gfaCirc": 624.6,
    "nfaTotal": 4267.3,
    "nrStorage": 48.2,
    "nrApartm": 72,
    "nfaGfa": 75.3,
    "volume": 14755.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2896.9,
    "surToVol": 0.282,
    "nrBuildings": 3,
    "costM2": 1524.45,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.2,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.16,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.16,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.8,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 1.9,
    "percBuilt": 0.57,
    "gfaTotal": 4251.9,
    "gfaFloors": 3783.4,
    "gfaCirc": 468.5,
    "nfaTotal": 3200.5,
    "nrStorage": 36.2,
    "nrApartm": 54,
    "nfaGfa": 75.3,
    "volume": 11066.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2172.7,
    "surToVol": 0.31,
    "nrBuildings": 3,
    "costM2": 1564.47,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3,
    "floorMax": 3,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.99,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6732.2,
    "gfaFloors": 5983,
    "gfaCirc": 749.2,
    "nfaTotal": 5060.1,
    "nrStorage": 57.2,
    "nrApartm": 85,
    "nfaGfa": 75.2,
    "volume": 17500.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3452.7,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1502.48,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6732.2,
    "gfaFloors": 5983,
    "gfaCirc": 749.2,
    "nfaTotal": 5060.1,
    "nrStorage": 57.2,
    "nrApartm": 85,
    "nfaGfa": 75.2,
    "volume": 17500.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3452.7,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1503.35,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6732.2,
    "gfaFloors": 5983,
    "gfaCirc": 749.2,
    "nfaTotal": 5060.1,
    "nrStorage": 57.2,
    "nrApartm": 85,
    "nfaGfa": 75.2,
    "volume": 17500.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3452.7,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1504.22,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6732.2,
    "gfaFloors": 5983,
    "gfaCirc": 749.2,
    "nfaTotal": 5060.1,
    "nrStorage": 57.2,
    "nrApartm": 85,
    "nfaGfa": 75.2,
    "volume": 17500.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3452.7,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1503.35,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6366.6,
    "gfaFloors": 5660.5,
    "gfaCirc": 706.1,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 81,
    "nfaGfa": 75.2,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1516.58,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6366.6,
    "gfaFloors": 5660.5,
    "gfaCirc": 706.1,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 81,
    "nfaGfa": 75.2,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1515.65,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.7,
    "percBuilt": 0.57,
    "gfaTotal": 8149.6,
    "gfaFloors": 7244.2,
    "gfaCirc": 905.4,
    "nfaTotal": 6127,
    "nrStorage": 69.2,
    "nrApartm": 103,
    "nfaGfa": 75.2,
    "volume": 21189.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 4176.9,
    "surToVol": 0.257,
    "nrBuildings": 3,
    "costM2": 1485.38,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.95,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.94,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.94,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.93,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.35,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.57,
    "gfaTotal": 7783.8,
    "gfaFloors": 6921.6,
    "gfaCirc": 862.2,
    "nfaTotal": 5852.9,
    "nrStorage": 66.1,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20245.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 4008.4,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1495.39,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.41,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.57,
    "gfaTotal": 7783.8,
    "gfaFloors": 6921.6,
    "gfaCirc": 862.2,
    "nfaTotal": 5852.9,
    "nrStorage": 66.1,
    "nrApartm": 99,
    "nfaGfa": 75.2,
    "volume": 20245.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 4008.4,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1494.64,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.41,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6732.2,
    "gfaFloors": 5983,
    "gfaCirc": 749.2,
    "nfaTotal": 5060.1,
    "nrStorage": 57.2,
    "nrApartm": 85,
    "nfaGfa": 75.2,
    "volume": 17500.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3452.7,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1502.48,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.58,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.55,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.55,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6366.6,
    "gfaFloors": 5660.5,
    "gfaCirc": 706.1,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 81,
    "nfaGfa": 75.2,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1514.73,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.44,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6178.2,
    "gfaFloors": 5489.4,
    "gfaCirc": 688.8,
    "nfaTotal": 4640.5,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1516.58,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6181.3,
    "gfaFloors": 5492.5,
    "gfaCirc": 688.8,
    "nfaTotal": 4643.3,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1513.93,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5320.7,
    "gfaFloors": 4721.9,
    "gfaCirc": 598.8,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1529.16,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7064,
    "gfaFloors": 6276.5,
    "gfaCirc": 787.5,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1506.91,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6181.3,
    "gfaFloors": 5492.5,
    "gfaCirc": 688.8,
    "nfaTotal": 4643.3,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1515.83,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6181.3,
    "gfaFloors": 5492.5,
    "gfaCirc": 688.8,
    "nfaTotal": 4643.3,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1514.88,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.37,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.34,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.34,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7064,
    "gfaFloors": 6276.5,
    "gfaCirc": 787.5,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1508.57,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4760.9,
    "gfaFloors": 4228.3,
    "gfaCirc": 532.6,
    "nfaTotal": 3573.7,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75.1,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1548.78,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.57,
    "gfaTotal": 7595.4,
    "gfaFloors": 6750.5,
    "gfaCirc": 844.9,
    "nfaTotal": 5707.4,
    "nrStorage": 64.5,
    "nrApartm": 96,
    "nfaGfa": 75.1,
    "volume": 19754.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3920.7,
    "surToVol": 0.262,
    "nrBuildings": 3,
    "costM2": 1494.92,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.84,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.84,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.84,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.42,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.5,
    "percBuilt": 0.57,
    "gfaTotal": 7795.4,
    "gfaFloors": 6921.6,
    "gfaCirc": 873.8,
    "nfaTotal": 5852.9,
    "nrStorage": 66.1,
    "nrApartm": 98,
    "nfaGfa": 75.1,
    "volume": 20245.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 4008.4,
    "surToVol": 0.26,
    "nrBuildings": 3,
    "costM2": 1486.43,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.88,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.87,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.87,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.84,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.41,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7064,
    "gfaFloors": 6276.5,
    "gfaCirc": 787.5,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 90,
    "nfaGfa": 75.1,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1507.74,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.69,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6178.2,
    "gfaFloors": 5489.4,
    "gfaCirc": 688.8,
    "nfaTotal": 4640.5,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1515.63,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5320.7,
    "gfaFloors": 4721.9,
    "gfaCirc": 598.8,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1529.16,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.11,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5315,
    "gfaFloors": 4721.9,
    "gfaCirc": 593.1,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1529.67,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4764,
    "gfaFloors": 4231.4,
    "gfaCirc": 532.6,
    "nfaTotal": 3576.5,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75.1,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1546.55,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.8,
    "percBuilt": 0.57,
    "gfaTotal": 6178.2,
    "gfaFloors": 5489.4,
    "gfaCirc": 688.8,
    "nfaTotal": 4640.5,
    "nrStorage": 52.4,
    "nrApartm": 78,
    "nfaGfa": 75.1,
    "volume": 16065.5,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3196.4,
    "surToVol": 0.277,
    "nrBuildings": 3,
    "costM2": 1514.68,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.36,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.33,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.33,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.31,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.69,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5315,
    "gfaFloors": 4721.9,
    "gfaCirc": 593.1,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1528.56,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4764,
    "gfaFloors": 4231.4,
    "gfaCirc": 532.6,
    "nfaTotal": 3576.5,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75.1,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1547.79,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE033_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.03,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.04,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.04,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4760.9,
    "gfaFloors": 4228.3,
    "gfaCirc": 532.6,
    "nfaTotal": 3573.7,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75.1,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1547.54,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.02,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5315,
    "gfaFloors": 4721.9,
    "gfaCirc": 593.1,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1529.67,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.4,
    "percBuilt": 0.57,
    "gfaTotal": 5315,
    "gfaFloors": 4721.9,
    "gfaCirc": 593.1,
    "nfaTotal": 3993.3,
    "nrStorage": 45.1,
    "nrApartm": 67,
    "nfaGfa": 75.1,
    "volume": 13811.6,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2728.4,
    "surToVol": 0.289,
    "nrBuildings": 3,
    "costM2": 1528.56,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.1,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.1,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.1,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.08,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.87,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4949.3,
    "gfaFloors": 4399.4,
    "gfaCirc": 549.9,
    "nfaTotal": 3719.2,
    "nrStorage": 42,
    "nrApartm": 63,
    "nfaGfa": 75.1,
    "volume": 12868.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2560,
    "surToVol": 0.297,
    "nrBuildings": 3,
    "costM2": 1546.3,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4949.3,
    "gfaFloors": 4399.4,
    "gfaCirc": 549.9,
    "nfaTotal": 3719.2,
    "nrStorage": 42,
    "nrApartm": 63,
    "nfaGfa": 75.1,
    "volume": 12868.1,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2560,
    "surToVol": 0.297,
    "nrBuildings": 3,
    "costM2": 1547.48,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.05,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.05,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.05,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.95,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.57,
    "gfaTotal": 7435.5,
    "gfaFloors": 6599,
    "gfaCirc": 836.5,
    "nfaTotal": 5578.8,
    "nrStorage": 63,
    "nrApartm": 94,
    "nfaGfa": 75,
    "volume": 19302.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3839.9,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1495.8,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.45,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.57,
    "gfaTotal": 7250.1,
    "gfaFloors": 6431,
    "gfaCirc": 819.1,
    "nfaTotal": 5436.1,
    "nrStorage": 61.4,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 18810.8,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3752.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1494.71,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.49,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.57,
    "gfaTotal": 7435.5,
    "gfaFloors": 6599,
    "gfaCirc": 836.5,
    "nfaTotal": 5578.8,
    "nrStorage": 63,
    "nrApartm": 94,
    "nfaGfa": 75,
    "volume": 19302.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3839.9,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1495.8,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.45,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1506.52,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1518.28,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1505.6,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1506.52,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5646.8,
    "gfaFloors": 5015.4,
    "gfaCirc": 631.4,
    "nfaTotal": 4237.9,
    "nrStorage": 47.8,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14669.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2947.2,
    "surToVol": 0.287,
    "nrBuildings": 3,
    "costM2": 1534.65,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1519.25,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1517.3,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1518.28,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.57,
    "gfaTotal": 7247.1,
    "gfaFloors": 6428,
    "gfaCirc": 819.1,
    "nfaTotal": 5433.3,
    "nrStorage": 61.4,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 18810.8,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3752.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1494.5,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.49,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.3,
    "percBuilt": 0.57,
    "gfaTotal": 7247.1,
    "gfaFloors": 6428,
    "gfaCirc": 819.1,
    "nfaTotal": 5433.3,
    "nrStorage": 61.4,
    "nrApartm": 91,
    "nfaGfa": 75,
    "volume": 18810.8,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3752.2,
    "surToVol": 0.267,
    "nrBuildings": 3,
    "costM2": 1495.31,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.76,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.75,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.75,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.74,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.49,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1504.68,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.4,
    "percBuilt": 0.57,
    "gfaTotal": 7441.2,
    "gfaFloors": 6599,
    "gfaCirc": 842.2,
    "nfaTotal": 5578.8,
    "nrStorage": 63,
    "nrApartm": 93,
    "nfaGfa": 75,
    "volume": 19302.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3839.9,
    "surToVol": 0.264,
    "nrBuildings": 3,
    "costM2": 1487.57,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 5,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.81,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.79,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.79,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.8,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.45,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1505.6,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4766.7,
    "gfaFloors": 4228.3,
    "gfaCirc": 538.4,
    "nfaTotal": 3573.7,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1549.39,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.2,
    "percBuilt": 0.57,
    "gfaTotal": 4766.7,
    "gfaFloors": 4228.3,
    "gfaCirc": 538.4,
    "nfaTotal": 3573.7,
    "nrStorage": 40.4,
    "nrApartm": 60,
    "nfaGfa": 75,
    "volume": 12376.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2472.2,
    "surToVol": 0.302,
    "nrBuildings": 3,
    "costM2": 1548.15,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.3,
    "floorMax": 4,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE033_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.04,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.02,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.02,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.02,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.96,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6344.2,
    "gfaFloors": 5631.4,
    "gfaCirc": 712.8,
    "nfaTotal": 4756.7,
    "nrStorage": 53.7,
    "nrApartm": 81,
    "nfaGfa": 75,
    "volume": 16471.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3334.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1522.87,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6344.2,
    "gfaFloors": 5631.4,
    "gfaCirc": 712.8,
    "nfaTotal": 4756.7,
    "nrStorage": 53.7,
    "nrApartm": 81,
    "nfaGfa": 75,
    "volume": 16471.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3334.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1523.8,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6378.1,
    "gfaFloors": 5660.5,
    "gfaCirc": 717.6,
    "nfaTotal": 4786.1,
    "nrStorage": 54.1,
    "nrApartm": 80,
    "nfaGfa": 75,
    "volume": 16556.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3284.2,
    "surToVol": 0.275,
    "nrBuildings": 3,
    "costM2": 1504.68,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.45,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.42,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.42,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.66,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1516.32,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5646.8,
    "gfaFloors": 5015.4,
    "gfaCirc": 631.4,
    "nfaTotal": 4237.9,
    "nrStorage": 47.8,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14669.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2947.2,
    "surToVol": 0.287,
    "nrBuildings": 3,
    "costM2": 1533.61,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.9,
    "percBuilt": 0.57,
    "gfaTotal": 6344.2,
    "gfaFloors": 5631.4,
    "gfaCirc": 712.8,
    "nfaTotal": 4756.7,
    "nrStorage": 53.7,
    "nrApartm": 81,
    "nfaGfa": 75,
    "volume": 16471.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3334.5,
    "surToVol": 0.279,
    "nrBuildings": 3,
    "costM2": 1521.95,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.43,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.41,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.41,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.41,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.72,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1517.3,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 6018.2,
    "gfaFloors": 5337.9,
    "gfaCirc": 680.3,
    "nfaTotal": 4512,
    "nrStorage": 50.9,
    "nrApartm": 76,
    "nfaGfa": 75,
    "volume": 15613.4,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3115.7,
    "surToVol": 0.28,
    "nrBuildings": 3,
    "costM2": 1516.32,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.29,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.28,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.28,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.75,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5646.8,
    "gfaFloors": 5015.4,
    "gfaCirc": 631.4,
    "nfaTotal": 4237.9,
    "nrStorage": 47.8,
    "nrApartm": 72,
    "nfaGfa": 75,
    "volume": 14669.9,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 2947.2,
    "surToVol": 0.287,
    "nrBuildings": 3,
    "costM2": 1532.57,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 3.7,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.19,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.15,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.15,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.86,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 5832.9,
    "gfaFloors": 5169.9,
    "gfaCirc": 663,
    "nfaTotal": 4369.3,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.9,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1515.59,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1509.72,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1509.72,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1508.85,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6896.1,
    "gfaFloors": 6108.5,
    "gfaCirc": 787.6,
    "nfaTotal": 5162,
    "nrStorage": 58.3,
    "nrApartm": 86,
    "nfaGfa": 74.9,
    "volume": 17867.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3583.7,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1495.47,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7081.4,
    "gfaFloors": 6276.5,
    "gfaCirc": 804.9,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1496.63,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7081.4,
    "gfaFloors": 6276.5,
    "gfaCirc": 804.9,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1497.46,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 5832.9,
    "gfaFloors": 5169.9,
    "gfaCirc": 663,
    "nfaTotal": 4369.3,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.9,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1516.6,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1508.85,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6896.1,
    "gfaFloors": 6108.5,
    "gfaCirc": 787.6,
    "nfaTotal": 5162,
    "nrStorage": 58.3,
    "nrApartm": 86,
    "nfaGfa": 74.9,
    "volume": 17867.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3583.7,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1496.32,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE016_FLOORSSIDE023_FLOORSSIDE035_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.65,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7081.4,
    "gfaFloors": 6276.5,
    "gfaCirc": 804.9,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1497.46,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE036_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 5832.9,
    "gfaFloors": 5169.9,
    "gfaCirc": 663,
    "nfaTotal": 4369.3,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.9,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1517.61,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE045_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.24,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.2,
    "percBuilt": 0.57,
    "gfaTotal": 7081.4,
    "gfaFloors": 6276.5,
    "gfaCirc": 804.9,
    "nfaTotal": 5304.8,
    "nrStorage": 59.9,
    "nrApartm": 89,
    "nfaGfa": 74.9,
    "volume": 18358.7,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3671.5,
    "surToVol": 0.269,
    "nrBuildings": 3,
    "costM2": 1496.63,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.7,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.68,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.68,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.66,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.54,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1507.97,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE014_FLOORSSIDE023_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5829.8,
    "gfaFloors": 5166.8,
    "gfaCirc": 663,
    "nfaTotal": 4366.5,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.9,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1517.39,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE044_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.6,
    "percBuilt": 0.57,
    "gfaTotal": 5829.8,
    "gfaFloors": 5166.8,
    "gfaCirc": 663,
    "nfaTotal": 4366.5,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.9,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1516.39,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE025_FLOORSSIDE034_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.23,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.23,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.23,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.16,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6715.6,
    "gfaFloors": 5953.9,
    "gfaCirc": 761.7,
    "nfaTotal": 5030.7,
    "nrStorage": 56.8,
    "nrApartm": 85,
    "nfaGfa": 74.9,
    "volume": 17415.2,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3503,
    "surToVol": 0.274,
    "nrBuildings": 3,
    "costM2": 1507.97,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.3,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE024_FLOORSSIDE036_FLOORSSIDE043_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.56,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.54,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.54,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.62,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 2.7,
    "percBuilt": 0.57,
    "gfaTotal": 5838.6,
    "gfaFloors": 5169.9,
    "gfaCirc": 668.7,
    "nfaTotal": 4369.3,
    "nrStorage": 49.3,
    "nrApartm": 73,
    "nfaGfa": 74.8,
    "volume": 15122,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3028,
    "surToVol": 0.284,
    "nrBuildings": 3,
    "costM2": 1517.15,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4,
    "floorMax": 5,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE015_FLOORSSIDE023_FLOORSSIDE034_FLOORSSIDE046_AXO_DA01.png",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.25,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.24,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.24,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.24,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.83,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  },
  {
    "uniqueID": 55040,
    "plotX": 55,
    "plotY": 40,
    "plotArea": 2200,
    "fsi": 3.1,
    "percBuilt": 0.57,
    "gfaTotal": 6893,
    "gfaFloors": 6105.4,
    "gfaCirc": 787.6,
    "nfaTotal": 5159.3,
    "nrStorage": 58.3,
    "nrApartm": 86,
    "nfaGfa": 74.8,
    "volume": 17867.3,
    "areaGroundFloor": 1261.1,
    "areaRoof": 1261.1,
    "areaFac": 3583.7,
    "surToVol": 0.271,
    "nrBuildings": 3,
    "costM2": 1496.13,
    "category": "GGG0",
    "distCores": 43.6,
    "floorAv": 4.7,
    "floorMax": 6,
    "img": "Xrichting6_Yrichting3_SelectbuidlingsX1_SelecteerdieptesY10_FLOORSSIDE013_FLOORSSIDE026_FLOORSSIDE035_FLOORSSIDE043_AXO_DA01.png1",
    "bin": "2000-2500",
    "plotSizeId": 55040,
    "gfaPlotPerc": 0.64,
    "gfaBin50Perc": 6334.6,
    "gfaBin75Perc": 7145.7,
    "gfaBin100Perc": 10091.7,
    "nfaPlotPerc": 0.61,
    "nfaBin50Perc": 4786.1,
    "nfaBin75Perc": 5385.6,
    "nfaBin100Perc": 3057.8,
    "nfaGfaPlotPerc": 0.61,
    "nfaGfaBin50": 75.1,
    "nfaGfaBin75": 75.5,
    "nfaGfaBin100": 81.2,
    "fsiPlotPerc": 0.56,
    "fsiBin50": 3,
    "fsiBin75": 3.4,
    "fsiBin100": 4.8,
    "suVoPlotPerc": 0.58,
    "suVoBin50": 0.266,
    "suVoBin75": 0.282,
    "suVoBin100": 0.311
  }
];
