import { ProvinceManager } from "./province-manager/province-manager";
import { PlotManager } from "./plot-manager/plot-manager";
import { activeMapFeatures } from "./active-features";
import { LocationManager } from "./location-manager/location-manager";
import { ProvinceEvent } from "./province-manager/province-manager.models";
import {Store} from "@ngrx/store";
import {MapManager} from "@planalogic/design-system";
import {FreemiumMapService} from "./freemium-map.service";

export class FreemiumMap {
  public provinces!: ProvinceManager;
  public plots!: PlotManager;
  public location!: LocationManager;
  private mapService!: FreemiumMapService;

  constructor(
    public readonly manager: MapManager,
    private readonly store: Store
  ) { }

  public toggleFeature(feature: string, value?: boolean): void {
    this.manager.features.toggleFeature(feature, value);
  }

  public setMapService(service: FreemiumMapService): void {
    this.mapService = service;

    // todo maybe streamline this a bit better.. executing a reset beforehand?
    this.registerMapFeatures();
    this.createPlotManager();
    this.createProvinceManager();
    this.createLocationManager();
  }

  public restore(): void {
    this.plots.restore();
  }

  public destroy(): void {
    this.provinces.destroy();
  }

  private getProvinceEvents(): ProvinceEvent[] {
    return [ this.plots.clickEvent ];
  }

  private createProvinceManager(): void {
    this.provinces = new ProvinceManager(this.mapService, this.getProvinceEvents());
    this.provinces.loadProvince('ut');
  }

  private createPlotManager(): void {
    this.plots = new PlotManager(this.mapService, this.store);
  }

  private createLocationManager(): void {
    this.location = new LocationManager(this.mapService, this.store);
  }

  private registerMapFeatures(): void {
    activeMapFeatures.forEach(({ name, feature}) =>
      this.manager.features.registerFeature(name, feature));
  }
}
