import {Component, TemplateRef, ViewChild} from "@angular/core";

@Component({
  selector: 'pl-drop-down-item',
  template: `
    <ng-template #blueprint>
      <ng-content></ng-content>
    </ng-template>`
})
export class DropDownItemComponent {
  @ViewChild('blueprint') blueprint!: TemplateRef<any>;
}
