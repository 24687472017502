import {AfterViewInit, Component, ContentChildren, Input, OnDestroy, QueryList, ViewEncapsulation} from "@angular/core";
import {LineLegendComponent} from "./legend/legend-item.component";
import {LineComponent} from "./line/line.component";
import {PlAngularUtils} from "@planalogic/utilities";

@Component({
  selector: 'pl-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: [ './line-graph.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LineGraphComponent implements AfterViewInit {
  @Input() graphTitle!: string;
  @Input() showLegend = true;
  @Input() skipSpacer = false;
  @Input() interactable = true;
  @Input() showLabels = false;
  @Input() moveLegendDown = false; // todo super weird property, implement a version per page that encapsulates this

  @ContentChildren(LineLegendComponent) legendList!: QueryList<LineLegendComponent>;
  @ContentChildren(LineComponent) linesList!: QueryList<LineComponent>;

  public legends!: LineLegendComponent[];
  public lines!: LineComponent[];

  public ngAfterViewInit(): void {
    this.updateLegends();
    this.legendList.changes.subscribe(() => this.updateLegends());

    this.updateLines();
    this.linesList.changes.subscribe(() => this.updateLines());
  }

  private updateLines(): void {
    PlAngularUtils.waitForPropagation(() => this.lines = this.linesList.toArray());
  }

  private updateLegends(): void {
    PlAngularUtils.waitForPropagation(() => this.legends = this.legendList.toArray());
  }
}
