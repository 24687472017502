import {map, Observable} from "rxjs";
import {PlotAddress, PlotAddressResponse} from "../freemium-map/freemium-map.models";
import { ApiService } from "@planalogic/toolbox";
import { Injectable } from "@angular/core";
import {Premise} from "../../../state/plot/plot.models";

@Injectable()
export class FreemiumApiService {
  constructor(private apiService: ApiService) { }

  public getAddressInformation(identifier: string): Observable<PlotAddressResponse> {
    return this.apiService.get<any>( // todo fix any
      `free?q=gekoppeld_perceel:${ identifier }`,
      { server: 'locationService' }
    ).pipe(map(data => data.response.docs[0]));
  }

  public getResidenceInformation(residenceId: string): Observable<Premise[]> {
    const url = `?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=bag:verblijfsobject&outputFormat=application/json&FILTER=<Filter><PropertyIsEqualTo><PropertyName>pandidentificatie</PropertyName><Literal>${ residenceId }</Literal></PropertyIsEqualTo></Filter>`

    return this.apiService.get<any>(url, { server: 'bag' });
  }
}
