import { NgModule } from '@angular/core';
import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab/tab.component';
import { CommonModule } from '@angular/common';

const tabGroupDeclarations = [
  TabGroupComponent,
  TabComponent
];

@NgModule({
  declarations: [ ...tabGroupDeclarations ],
  imports: [
    CommonModule
  ],
  exports: [ ...tabGroupDeclarations ]
})
export class PlTabGroupModule { }
