import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { PickerCircle } from "./picker-circle/picker-circle";
import { filter, fromEvent, Subject, switchMap, takeUntil, tap } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: 'pl-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: [ './color-picker.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent implements AfterViewInit {
  @Input() id!: string;
  @Input() fixedPosition = false;

  @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  @Output() picked = new EventEmitter<string>();

  public get canvas(): HTMLCanvasElement | null {
    return this.canvasRef?.nativeElement || null;
  }

  private context!: CanvasRenderingContext2D | null;
  private picker!: PickerCircle;
  private destroyer$ = new Subject<void>();

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.initialze();
  }

  private initialze(): void {
    const host = this.elementRef.nativeElement;
    if (!host) return;
    const canvas = this.canvas;
    if (!canvas) return;

    const { width, height } = host.getBoundingClientRect() || { };
    canvas.width = width;
    canvas.height = height - 2;

    this.context = this.canvas?.getContext('2d') || null;
    if (!this.context) return;

    this.picker = new PickerCircle(canvas, 10, 10, 7, this.fixedPosition);
    this.draw(canvas, this.context);

    this.observeSelecting(canvas);
    this.observeDragging(canvas);
  }

  public draw(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D): void {
    const { width, height } = canvas.getBoundingClientRect() || { };
    if (!width || !height) return;

    this.addColors(context, width, height);
    this.addGrays(context, width, height);

    this.picker.drawPicker();
  }

  private addColors(context: CanvasRenderingContext2D, width: number, height: number): void {
    const gradient = context.createLinearGradient(0, 0, width, 0 );
    gradient.addColorStop(0, '#f00');
    gradient.addColorStop(.17, '#f0f');
    gradient.addColorStop(.34, '#00f');
    gradient.addColorStop(.51, '#0ff');
    gradient.addColorStop(.68, '#0f0');
    gradient.addColorStop(.85, '#ff0');
    gradient.addColorStop(1, '#f00');

    context.fillStyle = gradient;
    context.fillRect(0, 0, width, height);
  }

  private addGrays(context: CanvasRenderingContext2D, width: number, height: number): void {
    const gradient = context.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, '#fff');
    gradient.addColorStop(.5, '#ffffff00');
    gradient.addColorStop(.5, '#00000000');
    gradient.addColorStop(1, '#000');

    context.fillStyle = gradient;
    context.fillRect(0, 0, width, height);
  }

  private observeSelecting(canvas: HTMLCanvasElement): void {
    fromEvent<MouseEvent>(canvas, 'mousedown').pipe(
      takeUntil(this.destroyer$),
      filter(({ clientX, clientY }) => !this.picker.clickedInside(clientX, clientY))
    ).subscribe(({ clientX, clientY }) => this.setPickerPosition(canvas, clientX, clientY));
  }

  private observeDragging(canvas: HTMLCanvasElement): void {
    fromEvent<MouseEvent>(canvas, 'mousedown').pipe(
      takeUntil(this.destroyer$),
      filter(({ clientX, clientY }) => this.picker.clickedInside(clientX, clientY)),
      switchMap(() => fromEvent<MouseEvent>(canvas, 'mousemove').pipe(
        takeUntil(fromEvent(canvas, 'mouseup').pipe(
          take(1)
        ))
      ))
    ).subscribe(({ clientX, clientY }) => this.setPickerPosition(canvas, clientX, clientY));
  }

  private setPickerPosition(canvas: HTMLCanvasElement, x: number, y: number): void {
    const context = this.context as CanvasRenderingContext2D;

    this.picker.dragToPosition(x, y);
    this.draw(canvas, context);

    const color = this.picker.getColor();
    color && this.picked.emit(color);
  }
}
