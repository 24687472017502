import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DropDownItemComponent} from "./drop-down-item.component";
import {DropDownComponent} from "./drop-down.component";

const declarations = [
  DropDownComponent,
  DropDownItemComponent
];

@NgModule({
  declarations,
  imports: [ CommonModule ],
  exports: declarations
})
export class DropDownModule { }
