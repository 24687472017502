import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ApiService} from '@planalogic/toolbox';
import {AutoSuggestComponent, SuggestSource} from '@planalogic/design-system';
import {KadastraalSuggestSource} from './sources/kadastraal/kadastraal.suggest-source';
import {AddressSuggestSource} from './sources/address/address.suggest-source';
import {BagAddress, BagKadastraal} from "../../freemium-map/freemium-map.models";

const AddressSourceType = 'Address';
const KadastraalSourceType = 'Registry';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [ './search-bar.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent {
  @Output() foundAddress = new EventEmitter<BagAddress>();
  @Output() foundKadastraal = new EventEmitter<BagKadastraal>();
  @Output() hasReset = new EventEmitter<void>();

  @ViewChild('autoSuggest') autoSuggest!: AutoSuggestComponent;

  public sources: Record<string, SuggestSource> = {
    [AddressSourceType]: new AddressSuggestSource(this.apiService),
    [KadastraalSourceType]: new KadastraalSuggestSource(this.apiService)
  };

  public suggestSource: SuggestSource = this.sources[AddressSourceType];

  public form = new UntypedFormGroup({
    search: new UntypedFormControl()
  });

  constructor(private apiService: ApiService) { }

  public reset(): void {
    this.autoSuggest.reset();
  }

  public onSearchTerm(term: string): void {
    const identifierRegex = /^[a-zA-Z0-9]{4,6}-?[aA-zZ]{0,2}-?[0-9]{0,8}$/;
    const isPossiblyIdentifier = identifierRegex.test(term);
console.log('Possibly:', isPossiblyIdentifier);
    const suggestSource = isPossiblyIdentifier ?
      this.sources[KadastraalSourceType] :
      this.sources[AddressSourceType];

    if (this.suggestSource !== suggestSource) {
      this.suggestSource = suggestSource;
    }
  }

  public searchResult(value: any): void {
    this.suggestSource === this.sources[KadastraalSourceType] ?
      this.foundKadastraal.emit(value) :
      this.foundAddress.emit(value);

    this.reset();
  }
}
