import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-bar-graph-bar',
  templateUrl: './bar.component.html',
  styleUrls: [ './bar.component.scss' ]
})
export class BarGraphBarComponent {
  @Input() amount!: string;
  @Input() height!: number;

  constructor() { }
}
