import { AfterViewInit, Component, ContentChildren, Input, QueryList } from "@angular/core";
import { LinePointComponent } from "./point/line-point.component";

@Component({
  selector: 'pl-graph-line',
  template: ``
})
export class LineComponent implements AfterViewInit {
  @Input() name!: string;

  @ContentChildren(LinePointComponent) pointList!: QueryList<LinePointComponent>;

  public points!: LinePointComponent[];

  ngAfterViewInit(): void {
    this.points = this.pointList.toArray();
    this.pointList.changes.subscribe(() => this.points = this.pointList.toArray())
  }
}
