import { MapboxGeoJSONFeature } from 'mapbox-gl';
import {Store} from "@ngrx/store";
import {selectActivePlot} from "../../../../../state/plot/selectors/plot.selectors";
import {ActivePlotInformation} from "../../../../../state/plot/plot.models";
import {filter} from "rxjs/operators";

export class PerceelDataSource {
  public data$ = this.store.select<ActivePlotInformation>(selectActivePlot(this.identifier)).pipe(
    filter(information => !!Object.keys(information))
  );

  get features(): MapboxGeoJSONFeature[] {
    return this.attachedFeatures;
  }

  constructor(public readonly identifier: string,
              private attachedFeatures: MapboxGeoJSONFeature[],
              private readonly store: Store) { }

  public setFeatures(features: MapboxGeoJSONFeature[]): void {
    this.attachedFeatures = features;
  }
}
