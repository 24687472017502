import { AnyLayer, AnySourceData, Map as MapBoxMap } from "mapbox-gl";
import { MapFeatureManager } from "./features";
import { MapEventManager } from "./events/event-manager";

export class MapManager {
  public readonly features = new MapFeatureManager(this);
  public readonly events = new MapEventManager(this);

  private readonly sources = new Map<string, AnySourceData>();
  private readonly layers = new Map<string, AnyLayer>();

  constructor(public readonly map: MapBoxMap,
              public readonly container: HTMLElement,
              public readonly name: string
  ) { }

  public createSourceLayer(id: string, sourceData: AnySourceData): void {
    this.sources.set(id, sourceData);
    this.map.addSource(id, sourceData);
  }

  public removeLayer(id: string): void {
    this.layers.delete(id);
    this.map.removeLayer(id);
  }

  public createMapLayer(id: string, layer: AnyLayer): void {
    this.layers.set(id, layer);
    this.map.addLayer(layer);
  }
}
