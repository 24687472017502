import { Component } from "@angular/core";
import {Router} from "@angular/router";

@Component({
  selector: 'landing-page',
  templateUrl: './landing.component.html',
  styleUrls: [ './landing.component.scss' ]
})
export class LandingPageComponent {
  constructor(private readonly router: Router) { }

  goToFreemium(): void {
    this.router.navigate([ '/freemium' ]);
  }
}
