import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {selectPremises} from "../../../../../state/plot/selectors/plot.selectors";
import {Residence} from "../../../../../state/plot/plot.models";

@Component({
  selector: 'premises',
  templateUrl: './premises.component.html',
  styleUrls: [ './premises.component.scss' ]
})
export class PremisesComponent implements OnChanges {
  @Input() identifier!: string;

  public premises$!: Observable<Residence[]>;

  constructor(private store: Store) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { identifier } = changes;

    if (identifier?.currentValue) {
      this.premises$ = this.store.select(selectPremises(this.identifier));
    }
  }
}
