export interface BagAddress {
  gekoppeld_perceel: string[];
  centroide_ll: string;
  provincieafkorting: string;
}

export type PlotsizingResponse = {
  properties: PlotSizingResult;
}

export interface PlotSizingResult {
  bouwvlak_vlag: 1 | 0;
  maatvoering_vlag: 1 | 0;
  bouwvlak_id: string;
  kadastraalnummer: string;
  kadastraalnummers_in_bouwvlak: string;
  // max_bebouwd_opp: string;
  // max_bebouwingspercentage: string;
  // max_bebouwingspercentage_terrein: string;
  max_bouwhoogte: string;
  // max_bouwlagen: string;
  // max_goothoogte: string;
  // max_wooneenheden: string;
  // min_bouwhoogte: string;
  // min_goothoogte: string;
  plangebied: string;
  perceeloppervlakte: number;
  deeloppervlakte: number;
}

// dimensions: string;
// surfaceSize: number;
// plotSize: number;
// planningArea: string;
// buildYear: number;
// surfaceAndCircumference?: any;
// purpose: string;
// currentHeight: number;
// maxHeight: number;
// province: string;

export interface PlotSizing {
  includedIdentifiers: string[];
  loaded?: boolean;
  planningArea: string;
  buildingAreaSize: number;
  maxHeight: number;
  plotSize: number;
}

export interface PlotAddressResponse {
  adresseerbaarobject_id: string;
  bron: string;
  buurtcode: string;
  buurtnaam: string;
  centroide_ll: string;
  centroide_rd: string;
  gekoppeld_appartement: string[];
  gekoppeld_perceel: string[];
  gemeentecode: string;
  gemeentenaam: string;
  huis_nlt: string;
  huisnummer: number;
  id: string;
  identificatie: string;
  nummeraanduiding_id: string;
  openbareruimte_id: string;
  openbareruimtetype: string;
  postcode: string;
  provincieafkorting: string;
  provinciecode: string;
  provincienaam: string;
  rdf_seealso: string;
  score: number;
  straatnaam: string;
  straatnaam_verkort: string;
  type: string;
  waterschapscode: string;
  waterschapsnaam: string;
  weergavenaam: string;
  wijkcode: string;
  wijknaam: string;
  woonplaatscode: string;
  woonplaatsnaam: string;
}

export interface PlotAddress {
  province: string;
}

export interface BagKadastraal {
  type: string;
  bron: string;
  centroide_ll: string;
  centroide_rd: string;
  id: string;
  identificatie: string;
  kadastrale_aanduiding: string[];
  kadastrale_gemeentecode: string;
  kadastrale_gemeentenaam: string;
  kadastrale_grootte: number;
  kadastrale_sectie: string;
  perceelnummer: number;
  weergavenaam: string;
}

export enum FreemiumColor {
  MaatvoeringLayer = 'maatvoering layer',
  PlotBorders = 'plot borders',
  ActivePlotsFill = 'active plots border',
  ActivePlotsBorder = 'active plots border'
}
