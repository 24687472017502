<ng-container *ngIf="demoPlot$ | async as plot">
  <pl-scout-page>
    <ng-container page-header-menu>
      <img src="/assets/icons/login-gray.svg" alt="login icon">
    </ng-container>

    <!-- CONTENT -->
    <ng-container *ngIf="data$ | async as data">
      <img *ngIf="data.img" [src]="'/assets/demo/' + data.img" class="building-placeholder" />
    </ng-container>

    <pl-scout-side-bar>
      <div class="side-bar-item">
        <a routerLink="/freemium">
          <img src="/assets/icons/freemium-gradient.svg" alt="freemium">
        </a>
      </div>

      <div class="side-bar-item">
        <img src="/assets/icons/subscription-gradient.svg" alt="subscription">
      </div>

      <div class="side-bar-item">
        <a routerLink="/pay-per-use">
          <img src="/assets/icons/ppu-gray.svg" alt="pay per use">
        </a>
      </div>
    </pl-scout-side-bar>

    <div scout-info-panel>
      <ng-container *ngIf="data$ | async as data">
        <ng-container *ngIf="demoPlot$ | async as plot">
          <pl-expander dataTitle="Plot data">
            <plot-data [plot]="plot"></plot-data>
          </pl-expander>
        </ng-container>

        <pl-expander dataTitle="Design data" [expanded]="true">
          <div class="table">
            <div class="tr">
              <div class="td">FSI:</div>
              <div class="td">{{ data.fsi }}</div>
            </div>

            <div class="tr">
              <div class="td">GFA:</div>
              <div class="td">{{ data.gfaTotal }} m²</div>
            </div>

            <div class="tr">
              <div class="td">NFA:</div>
              <div class="td">{{ data.nfaTotal }} m²</div>
            </div>

            <div class="tr">
              <div class="td">NFA / GFA:</div>
              <div class="td">{{ data.nfaGfa }} %</div>
            </div>

            <div class="tr">
              <!-- todo lijkt me niet -->
              <div class="td">Surface to volume:</div>
              <div class="td">{{ data.surToVol }}</div>
            </div>

            <!--          <div class="tr">-->
            <!--            <div class="td">Height:</div>-->
            <!--            <div class="td">{{ data.?? }}</div>-->
            <!--          </div>-->

            <div class="tr">
              <div class="td">Floors average:</div>
              <div class="td">{{ data.floorAv }}</div>
            </div>

            <div class="tr">
              <div class="td">Floors max.:</div>
              <div class="td">{{ data.floorMax }}</div>
            </div>

            <div class="tr">
              <!-- todo lijkt me niet -->
              <div class="td">Built on:</div>
              <div class="td">{{ data.percBuilt | percent }}</div>
            </div>
          </div>

          <section>
            <button (click)="changeParameters()">Change parameters</button>
          </section>
        </pl-expander>
      </ng-container>
    </div>

    <pl-scout-corner>
      <img class="scatterplot-placeholder" src="/assets/demo/scatterPlot6055GfaSutovo.png">
    </pl-scout-corner>

    <div page-footer>
      <section class="section--xl">
        <subscription-totals [totals]="plot.totals"></subscription-totals>
      </section>
    </div>
  </pl-scout-page>
</ng-container>
