import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfiguration } from './api.module';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CallType, HttpOptions } from './api.models';

type ResponseHeader = { headers: HttpHeaders };

@Injectable()
export class ApiService {
  private get defaultServer(): string {
    const isLocal = [ 'localhost', '127.0.0.1' ].includes(location.hostname);
    const { serverList } = this.configuration;

    return isLocal ? serverList?.local : serverList.default;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly configuration: ApiConfiguration
  ) {
    console.log('Creating api service with configuration:', configuration);
  }

  public registerServer(name: string, address: string): void {
    this.configuration.serverList[name] = address;
  }

  public get<ResponseData>(url: string, options?: HttpOptions): Observable<ResponseData> {
    return this.createCall(CallType.Get, url, void 0, options);
  }

  public post<ResponseData>(url: string, body: unknown, options?: HttpOptions): Observable<ResponseData> {
    return this.createCall(CallType.Post, url, body, options);
  }

  public patch<ResponseData>(url: string, body?: unknown, options?: HttpOptions): Observable<ResponseData> {
    return this.createCall(CallType.Patch, url, body, options);
  }

  public put<ResponseData>(url: string, body: unknown, options?: HttpOptions): Observable<ResponseData> {
    return this.createCall(CallType.Put, url, body, options);
  }

  public delete<ResponseData>(url: string, options?: HttpOptions): Observable<ResponseData> {
    return this.createCall(CallType.Delete, url, options);
  }

  public getResponseHeader(url: string, body?: unknown, options?: HttpOptions): Observable<HttpHeaders> {
    return this.createCall<HttpHeaders, ResponseHeader>(CallType.Post, url, body, { ...options, observe: 'response' })
      .pipe(map(({ headers }) => headers));
  }

  private createCall<ResponseData, ReturnType = ResponseData>(
    callType: CallType,
    url: string,
    body?: unknown,
    options?: HttpOptions): Observable<ReturnType> {

    // force http client to any, its complex overload design makes an otherwise simple generic solution, unnecessary complex
    const http = this.http as any;
    const callUrl = this.getCallUrl(url, options?.server);
    const parameters = this.getCallParameters(callUrl, body, options);

    return http[ callType ](...parameters);
  }

  private getCallParameters(url: string, body?: unknown, options?: HttpOptions): unknown[] {
    return [ url, body || options, options ];
  }

  private getCallUrl(endpoint: string, server?: string): string {
    server = server ? this.configuration?.serverList[server] : this.defaultServer;

    return `${ server }${ endpoint }`;
  }
}
