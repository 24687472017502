<button (click)="toggleItems()" class="{{ showItems ? 'active' : '' }}">
  {{ buttonTitle }}

  <div class="icon">
    <img src="/assets/icons/chevron-up.png">
  </div>
</button>

<div #itemsContainer *ngIf="showItems" class="items-container">
  <div *ngFor="let item of items" class="item">
     <ng-container *ngTemplateOutlet="item.blueprint"></ng-container>
  </div>
</div>
