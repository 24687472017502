import { NgModule } from '@angular/core';
import { AutoSuggestModule } from './controls/auto-suggest/auto-suggest.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CheckboxModule } from "./controls/checkbox/checkbox.module";
import {SelectColorModule} from "./controls/select-color/select-color.module";

const formImports = [
  AutoSuggestModule,
  CheckboxModule,
  SelectColorModule,

  // todo pick one
  FormsModule,
  ReactiveFormsModule
];

@NgModule({
  imports: [ ...formImports ],
  exports: [ ...formImports ]
})
export class PlFormsModule { }
