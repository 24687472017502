import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  OnDestroy
} from '@angular/core';
import { AbstractFormControlComponent } from '../../abstract';
import { NgControl } from '@angular/forms';
import { SuggestData, SuggestSource } from './source/source.models';
import { debounceTime, isObservable, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import {PlDomUtils} from "../../../../../utilities/src/lib/dom/dom.utils";
import {PlAngularUtils} from "@planalogic/utilities";

@Component({
  selector: 'auto-suggest',
  templateUrl: './auto-suggest.component.html',
  styleUrls: ['./auto-suggest.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoSuggestComponent extends AbstractFormControlComponent implements OnInit, OnDestroy {
  @Input() id!: string; // todo add string utils
  @Input() source!: SuggestSource;
  @Input() debounce = 0;
  @Input() placeholder = '';
  @Output() lookup = new EventEmitter<string>();
  @Output() searchTerm = new EventEmitter<string>();
  @Output() selectedValue = new EventEmitter<any>();
  @Output() hasReset = new EventEmitter<void>();

  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('suggestionContainer') suggestionContainer!: ElementRef<HTMLDivElement>;

  public suggestions: SuggestData[] = [];
  public showPanel = false;
  public viewValue = '';

  private suggest$ = new Subject<string>();
  private destroyer$ = new Subject<void>();

  private monitorClicks = false;

  // @ts-ignore - todo dive into override and potentially adjust the ts configuration
  constructor(protected control: NgControl, protected elementRef: ElementRef) {
    super(control, elementRef);
  }

  ngOnInit(): void {
    this.setupSuggestionStream();
  }

  ngOnDestroy() {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public updateValue(value: any, external?: boolean): void {
    this.propagateValue(value);

    if (value && !external) {
      this.selectedValue.emit(value);
    }
  }

  public suggest(term: string): void {
    if (!term || term.length < 4) return;

    this.searchTerm.emit(term);
    this.suggest$.next(term);
  }

  public onEnter(): void {
    this.selectSuggestion(this.suggestions[0]);
  }

  public selectSuggestion(suggestion: SuggestData): void {
    this.showPanel = false;
    this.suggestions = [];

    this.viewValue = suggestion.label;
    this.source.lookup(suggestion.value)
      .pipe(take(1))
      .subscribe((value: any) => this.updateValue(value));
  }

  public reset(resetInput = true): void {
    this.updateValue(null);
    this.showPanel = false;
    this.suggestions = [];

    if (resetInput) {
      this.inputElement.nativeElement.value = '';
      this.hasReset.emit();
    }
  }

  private setShowPanel(value: boolean): void {
    this.showPanel = value;

    if (value) return;

    this.monitorClicks = false;
  }

  private setupSuggestionStream(): void {
    this.suggest$
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this.destroyer$),
        switchMap(term => (
          this.getSuggestions(term).pipe(takeUntil(this.destroyer$))
        ))
      ).subscribe((suggestions: SuggestData[]) => {
          this.suggestions = suggestions;

          this.setShowPanel(this.suggestions.length > 0)

          if (this.monitorClicks) return;

          PlAngularUtils.waitForPropagation(() => {
            const container = this.suggestionContainer?.nativeElement;
            if (!container) return;
            PlDomUtils.clickedOutside(container).subscribe(() => this.reset(false));
          });
      });
  }

  private getSuggestions(term: string): Observable<SuggestData[]> {
    const suggestions = this.source.suggest(term);

    return isObservable(suggestions) ? suggestions : of(suggestions);
  }
}
