import { NgModule, Type } from "@angular/core";
import { FreemiumPageComponent } from "./freemium.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { SharedModule } from "../../shared/shared.module";
import { MapModule } from "@planalogic/design-system";
import { RouterModule } from "@angular/router";
import { PlotDataComponent } from "./components/plot-data/plot-data.component";
import {FreemiumApiService} from "./api-service/freemium.api-service";
import {FreemiumMapService} from "./freemium-map/freemium-map.service";
import {PremisesComponent} from "./components/plot-data/premises/premises.component";
import {FreemiumTotalsComponent} from "./graphs/freemium-totals/freemium-totals.component";

const declarations: Type<any>[] = [
  FreemiumPageComponent,
  SearchBarComponent,
  PlotDataComponent,
  PremisesComponent,
  FreemiumTotalsComponent
];

@NgModule({
  declarations,
  providers: [
    FreemiumApiService,
    FreemiumMapService
  ],
  imports: [
    SharedModule,
    RouterModule,
    MapModule.forRoot('pk.eyJ1IjoicGxhbmFsb2dpYyIsImEiOiJjbDFkam5nOWEwOXZqM2JsbnB5a2dhaG82In0.saVqAPS15uT-1_VgGqY0rw')
  ],
  exports: [
    FreemiumPageComponent,

    // todo maybe handle this differently?
    PlotDataComponent
  ]
})
export class FreemiumModule { }

