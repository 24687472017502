<input #inputElement
       type="text"
       [value]="viewValue"
       [placeholder]="placeholder"
       (keyup.enter)="onEnter()"
       (focus)="inputElement.select()"
       (input)="suggest(inputElement.value)">

<div #suggestionContainer *ngIf="showPanel" class="suggestion-container">
  <div *ngFor="let suggestion of suggestions" class="suggestion" (click)="selectSuggestion(suggestion)">
    {{ suggestion.label }}
  </div>
</div>
