<pl-scout-page [skipCorner]="true">
  <ng-container page-header-menu>
    <img src="/assets/icons/reset-selection.svg" (click)="reset(); searchBar.reset()" alt="reset icon" style="cursor: pointer;">
    <img src="/assets/icons/login-gray.svg" alt="login icon">
  </ng-container>

  <!-- [center]="[4.914236, 52.060233]" Linschoten -->
  <!-- [center]="[5.044891, 52.302688]" Weesp -->
  <div #mapParent class="map-container">
    <ng-container *ngIf="loadNewMap">
      <pl-redux-map #mapElement
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    name="freemium-map"
                    [zoom]="14"
                    [center]="[4.914236, 52.060233]"
                    (loaded)="mapLoaded($event)">
      </pl-redux-map>
    </ng-container>
  </div>

  <pl-scout-side-bar>
    <div class="side-bar-item side-bar-item--active">
      <img src="/assets/icons/freemium-gradient.svg" alt="freemium">
    </div>

    <div class="side-bar-item" (click)="navigateTo('/subscription')">
      <img src="/assets/icons/subscription-gray.svg" alt="subscription">
    </div>

    <div class="side-bar-item" (click)="navigateTo('/pay-per-use')">
      <img src="/assets/icons/ppu-gray.svg" alt="pay per use">
    </div>
  </pl-scout-side-bar>

  <ng-container scout-info-panel>
    <search-bar #searchBar
                (hasReset)="reset()"
                (foundAddress)="foundAddress($event)"
                (foundKadastraal)="foundKadastraal($event)">
    </search-bar>

    <section class="plots-container" *ngIf="showInitialWelcomeText">
      <ng-container *ngTemplateOutlet="welcomeText"></ng-container>
    </section>

    <section class="plots-container" *ngIf="plots$ | async as plots">
      <ng-container *ngIf="plots.length === 0 || !plots">
        <ng-container *ngTemplateOutlet="welcomeText"></ng-container>
      </ng-container>

      <section *ngFor="let plot of plots; trackBy: trackByKadastraalNr" class="plot">
        <pl-expander [dataTitle]="plot.identifier" [expanded]="true">
          <ng-container content-toggle-icons>
            <img class="eye-icon" (click)="viewLocation($event, plot.centroide)" src="/assets/demo/eye-icon.svg">
          </ng-container>

          <plot-data [plot]="plot" nextStep="/subscription" (goNext)="navigateTo('/subscription')"></plot-data>
        </pl-expander>
      </section>
    </section>
  </ng-container>

  <ng-container scout-expansion-panel [formGroup]="featureForm">
    <pl-checkbox id="toggle-aerial"
                 class="form-control"
                 formControlName="aerial"
                 (checked)="toggleFeature(ActiveFeature.Aerial, $event)">
      Luchtfoto
    </pl-checkbox>

    <pl-checkbox id="toggle-3d-buildings"
                 class="form-control"
                 formControlName="threeD"
                 (checked)="toggleFeature(ActiveFeature.ThreeD, $event)">
      3D gebouwen
    </pl-checkbox>

    <pl-checkbox id="toggle-related-plots"
                 class="form-control"
                 formControlName="relatedPlots"
                 (checked)="toggleFeature(ActiveFeature.RelatedPlots, $event)">
      Gerelateerde kabels selecteren
    </pl-checkbox>

    <div class="color-container">
      <pl-select-color formControlName="maatvoeringColor"
                       class="form-control"
                       (selected)="selectColor(FreemiumColor.MaatvoeringLayer, $event)">
        Maatvoering kleur
      </pl-select-color>

      <pl-select-color formControlName="plotBorderColor"
                       class="form-control"
                       (selected)="selectColor(FreemiumColor.PlotBorders, $event)">
        Perceel randen
      </pl-select-color>

      <pl-select-color formControlName="activePlotsColor"
                       class="form-control"
                       (selected)="selectColor(FreemiumColor.ActivePlotsBorder, $event)">
        Randen geselecteerde kavels
      </pl-select-color>
    </div>
  </ng-container>

  <pl-scout-corner>
    <img src="/assets/demo/scatterPlot6055GfaSutovo.png">
  </pl-scout-corner>

  <div page-footer>

    <section>
      <ng-container *ngIf="plots$ | async as plots">
        <freemium-totals-graph *ngIf="plots.length > 0" [totals]="plots[0].totals"></freemium-totals-graph>
      </ng-container>
    </section>
  </div>
</pl-scout-page>

<ng-template #welcomeText>
  <h3>How to use the building scout</h3>

  <p>To start, enter any address or land registry number to automatically retrieve related open data.  Alternatively, explore our interactive map to scout locations.</p>
  <br/>
  <p>Client access to underlying data and designs.</p>
  <p>Free insights in high level feasibility.</p>
  <br/>
  <p>After selecting a location, the graphs below the map show the plot’s performance on feasibility metrics relative to its peer group.</p>
  <br/>
  <p>Log-in and gain access to all plot-specific data and insights based on thousands of designs. Planalogic helps real estate professional determine feasibility by:</p>

  <section>
    <ul>
      <li>Evaluating economic performance and sustainability indicators</li>
      <li>Browsing & filtering through thousands of options</li>
      <li>Testing against benchmark data: best-in-class scenarios and peer-group performance</li>
    </ul>
  </section>
</ng-template>
