import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { NgControl } from "@angular/forms";
import { AbstractFormControlComponent } from "../../abstract";

@Component({
  selector: 'pl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends AbstractFormControlComponent<boolean> {
  @Input() id!: string;
  @Output() checked = new EventEmitter<boolean>();

  constructor(protected override control: NgControl, protected override elementRef: ElementRef) {
    super(control, elementRef);
  }

  public updateValue(value: boolean, external?: boolean): void {
    this.propagateValue(value);

    if (!external) {
      this.checked.emit(value);
    }
  }
}
