<div class="landing-content">
  <div class="text-content">
    <h3>Feasibility studies <br/>in a blink of an eye</h3>

    <p>Planalogic provides real time insight in the potential of any given location. Through our library of billions of designs, real estate professionals can instantly</p>

    <ul>
      <li>Assess performance of a plot</li>
      <li>Review underlying designs and data</li>
      <li>Benchmark scenarios against best in class studies</li>
      <li>Perform a second opinion on existing designs</li>
    </ul>

    <div class="button-container">
      <button (click)="goToFreemium()">Get started!</button>
    </div>
  </div>

  <div class="visual-demo">
    <img src="/assets/demo/landing-placeholder.gif">
  </div>
</div>
