import { Routes } from "@angular/router";
import { LandingPageComponent } from "./pages/landing/landing.component";
import { FreemiumPageComponent } from "./pages/freemium/freemium.component";
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { PayPerUseComponent } from "./pages/pay-per-use/pay-per-use.component";

export const appRoutes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingPageComponent },
  { path: 'freemium', component: FreemiumPageComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'pay-per-use', component: PayPerUseComponent }
];
