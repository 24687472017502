export abstract class AbstractMapFeature {
  protected toggled = false;

  get isToggled(): boolean {
    return this.toggled;
  }

  public toggle(value?: boolean): void {
    const newValue = typeof value === 'undefined' ? !this.toggled : value;

    newValue ? this.toggleOn() : this.toggleOff();
    this.toggled = newValue;
  }

  protected toggleOn(): void { return; }
  protected toggleOff(): void { return; }
}
