<ng-container *ngIf="demoPlot$ | async as plot">
  <pl-scout-page>
    <ng-container page-header-menu>
      <img src="/assets/icons/login-gray.svg" alt="login icon">
    </ng-container>

    <!-- CONTENT -->
    <ng-container *ngIf="data$ | async as data">
      <img *ngIf="data.img" [src]="'/assets/demo/' + data.img" class="building-placeholder" />

      <div class="filter-container">
        <h3 class="content-header">Change parameters</h3>

        <pl-drop-down buttonTitle="Access">
          <pl-drop-down-item>
            <input type="checkbox" id="corridors" checked="true"><label class="checkbox-input-label" for="corridors">Corridors</label>
          </pl-drop-down-item>

          <pl-drop-down-item>
            <input type="checkbox" id="gallery" checked="true"><label class="checkbox-input-label" for="gallery">Gallery</label>
          </pl-drop-down-item>

          <pl-drop-down-item>
            <input type="checkbox" id="porch" checked="true"><label class="checkbox-input-label" for="porch">Porch</label>
          </pl-drop-down-item>
        </pl-drop-down>

        <pl-drop-down buttonTitle="Floors">
          <pl-drop-down-item>
            <label class="dropdown-label" for="min-floors">Min.:</label>
            <input class="small-input" id="min-floors" type="text" value="3">
          </pl-drop-down-item>

          <pl-drop-down-item>
            <label class="dropdown-label" for="max-floors">Max.:</label>
            <input class="small-input" id="max-floors" type="text" value="7">
          </pl-drop-down-item>
        </pl-drop-down>

        <pl-drop-down buttonTitle="Dimensions">
          <pl-drop-down-item>
            <label class="dropdown-label" for="width">Width:</label>
            <input class="small-input" type="text" value="60" id="width">
          </pl-drop-down-item>

          <pl-drop-down-item>
            <label class="dropdown-label" for="length">Length:</label>
            <input class="small-input" type="text" value="55" id="length">
          </pl-drop-down-item>

          <pl-drop-down-item>
            <label class="dropdown-label" for="height">Height:</label>
            <input class="small-input" type="text" value="10" id="height">
          </pl-drop-down-item>
        </pl-drop-down>
      </div>
    </ng-container>

    <pl-scout-side-bar>
      <div class="side-bar-item">
        <a routerLink="/freemium">
          <img src="/assets/icons/freemium-gradient.svg" alt="freemium">
        </a>
      </div>

      <div class="side-bar-item">
        <a routerLink="/subscription">
          <img src="/assets/icons/subscription-gradient.svg" alt="subscription">
        </a>
      </div>

      <div class="side-bar-item">
        <img src="/assets/icons/ppu-gradient.svg" alt="pay per use">
      </div>
    </pl-scout-side-bar>

    <div scout-info-panel>
      <ng-container *ngIf="data$ | async as data">
        <ng-container *ngIf="demoPlot$ | async as plot">
          <pl-expander dataTitle="Plot data">
            <plot-data [plot]="plot"></plot-data>
          </pl-expander>
        </ng-container>

        <pl-expander dataTitle="Design data" [expanded]="true">
          <div class="table">
            <div class="tr">
              <div class="td">FSI:</div>
              <div class="td">{{ data.fsi }}</div>
            </div>

            <div class="tr">
              <div class="td">GFA:</div>
              <div class="td">{{ data.gfaTotal }} m²</div>
            </div>

            <div class="tr">
              <div class="td">NFA:</div>
              <div class="td">{{ data.nfaTotal }} m²</div>
            </div>

            <div class="tr">
              <div class="td">NFA / GFA:</div>
              <div class="td">{{ data.nfaGfa }} %</div>
            </div>

            <div class="tr">
              <!-- todo lijkt me niet -->
              <div class="td">Facade / volume:</div>
              <div class="td">{{ data.areaFac }}</div>
            </div>

            <!--          <div class="tr">-->
            <!--            <div class="td">Height:</div>-->
            <!--            <div class="td">{{ data.?? }}</div>-->
            <!--          </div>-->

            <div class="tr">
              <div class="td">Floors average:</div>
              <div class="td">{{ data.floorAv }}</div>
            </div>

            <div class="tr">
              <div class="td">Floors max.:</div>
              <div class="td">{{ data.floorMax }}</div>
            </div>

            <div class="tr">
              <!-- todo lijkt me niet -->
              <div class="td">Built on:</div>
              <div class="td">{{ data.percBuilt | percent }}</div>
            </div>
          </div>
        </pl-expander>
      </ng-container>
    </div>

    <pl-scout-corner>
      <img class="scatterplot-placeholder" src="/assets/demo/scatterPlot6055GfaSutovo.png">
    </pl-scout-corner>

    <div page-footer>
      <div class="footer-container">
        <section class="section--xl">
          <subscription-totals [totals]="plot.totals"></subscription-totals>
        </section>

        <img src="/assets/demo/spiderweb-placeholder.png">
      </div>
    </div>
  </pl-scout-page>
</ng-container>
