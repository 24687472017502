import {UpdateStateConfiguration} from "./redux.models";
import {PlObjectUtils} from "../data-structures";
import {Action, Store} from "@ngrx/store";
import {PlArrayUtils} from "../data-structures/array/array.utils";

export class PlReduxUtils {
  static updateState<T extends Record<string, any>>(state: T, configuration: UpdateStateConfiguration | UpdateStateConfiguration[]): T {
    const configurations = PlArrayUtils.normalizeToArray(configuration);
    const clone = PlObjectUtils.clone<T>(state, {});

    configurations.forEach(({ path, value }) => PlObjectUtils.updateValueByPath(clone, path, value));

    return clone;
  }

  static dispatchMultiple(store: Store, actions: Action[]): void {
    actions.forEach(action => store.dispatch(action));
  }
}
